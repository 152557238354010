import Composition from "../../../../../shared/helpers/Composition";
import createReducer from "../../../../../shared/helpers/createReducer";
import ListReducer from "../../../../../../../src_shared/list/ListReducer";

export const getStateRoot = (state) => state.marketing.facebookMarketing.advertisements.list;
export const prefix = 'MARKETING_FACEBOOK_ADVERTISEMENTS_LIST_';

const getInitState = () => ListReducer.getInitState({
	waiting: true,
	isActive: true,
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	{
		SET_FACEBOOK_AD_ID: (state, action) => ({...state, facebookAdId: action.id})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

