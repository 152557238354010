import React, {useState} from "react";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import useTableRowClick from "../../../../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../../shared/EmptyList";
import SwitchField from "../../../../../../../../src_shared/components/form/switchField/SwitchField";
import ServerImage from "../../../../../../shared/components/serverImage/ServerImage";
import Loader from "../../../../../../../../src_shared/components/loader/Loader";
import {useUnitPrice} from "../../../../../../shared/helpers/Price";
import {MARKETING_INCREASE_SALE_VIEW} from "./SelectGoalModal";
import {convertToUserTimeZone} from "../../../../../../shared/helpers/dateHelpers";
import useWindowDimensions from "../../../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
                   createAd,
                   isWaitingForAd,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick('');
    const {getPrice} = useUnitPrice();
    const [showMobileStats, setShowMobileStats] = useState(null);
    const {width} = useWindowDimensions();

    const getPreparedRedirectPath = (item) => {
        const path = +item?.status === 1 ? `${RouterPaths.MarketingFacebookAdvertisements}/details/statistics/${item.id}` : `${RouterPaths.MarketingFacebookAdvertisements}/details/${item.id}`
        return path.slice(1);
    }

    const ExtraComponent = () => (
        <button
            onClick={createAd}
            style={{borderRadius: 8}}
            className={`button primary medium-size mt-3 mb-5 ${isWaitingForAd ? 'loading-button' : ''}`}
            type="button"
        >
            {isWaitingForAd && <Loader isLoading={true}/>}
            {getLangText('createAd')}
        </button>
    )

    const TdStats = ({item, title, field, className = ''}) => {
        const getValue = () => {
            if (field === 'date') {
                return convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')
            }
            if (field === 'roas') return !!+item.roas ? `${item.roas}x` : '-'
            if (field === 'sold_value') return getPrice(item.sold_value);
            if (field === 'budget_spent') return getPrice(item.budget_spent);
            return item[field]
        }
        return (
            <td
                data-thead={`${getLangText(title) || title}:`}
                style={{
                    display: width > 767 ? 'table-cell' : showMobileStats === item.id ? 'flex' : 'none',
                }}
                className={`text-right with-mobile-thead inline-thead w:sm-auto w-full ${className}`}
            >
                {getValue()}
            </td>
        )
    }


    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase with-bg"
            imgSrc="/assets/images/svg/marketing/fb.svg"
            title={getLangText('noAddedAds')}
            text={getLangText('createAdToIncreaseReach')}
            imgStyle={{width: 44, height: 44}}
            ExtraComponent={ExtraComponent}
        >
            <div className="table-wrapper">
                <div className="table-responsive rounded-b-medium">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="shop_ad_id"
                                onClick={updateSortOptions}
                                className={`${getSortClass('shop_ad_id')}`}
                            >
                                    <span name="shop_ad_id">
                                {getLangText('numberShortLabel')}
                                    </span>
                            </th>
                            <th className="whitespace">
                                {getLangText('turnOn')}
                            </th>
                            <th
                                name="products_total"
                                onClick={updateSortOptions}
                                className={`whitespace-nowrap ${getSortClass('products_total')}`}
                            >
                                    <span name="products_total">
                                        {getLangText('productLabel')}
                                    </span>
                            </th>
                            <th
                                name="status"
                                onClick={updateSortOptions}
                                className={`${getSortClass('status')}`}
                            >
                                    <span name="status">
                                       {getLangText('statusLabel')}
                                    </span>
                            </th>
                            <th
                                name="new_clients_total"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('new_clients_total')}`}
                            >
                                    <span name="new_clients_total">
                                        {getLangText('newClients1')}
                                    </span>
                            </th>
                            <th
                                name="sold_value"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('sold_value')}`}
                            >
                                    <span name="sold_value">
                                        {getLangText('salesValue')}
                                    </span>
                            </th>
                            <th
                                name="budget_spent"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('budget_spent')}`}
                            >
                                    <span name="budget_spent">
                                        {getLangText('budgetSpent')}
                                    </span>
                            </th>
                            <th
                                name="available_squ"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('available_squ')}`}
                            >
                                    <span name="available_squ">
                                        {getLangText('availablePieces')}
                                    </span>
                            </th>
                            <th
                                name="sold_qty"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('sold_qty')}`}
                            >
                                    <span name="sold_qty">
                                        {getLangText('soldPieces')}
                                    </span>
                            </th>
                            <th
                                name="roas"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('roas')}`}
                            >
                                    <span name="roas">
                                        ROAS
                                    </span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('date')}`}
                            >
                                    <span name="date">
                                        {getLangText('createdDateLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items?.map((item) => (
                            <tr
                                {...onTableRowClick(getPreparedRedirectPath(item))}
                            >
                                <td
                                    data-thead={`${getLangText('numberShortLabel')}`}
                                    className="number with-mobile-thead inline-thead mb-lg-0 mb-1">
                                    <div className="text-xs font-bold text-desc mr-2">{item.shop_ad_id}</div>
                                </td>
                                <td className="sm:ml-0 ml-auto">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            askForRemoveItem(item);
                                        }}
                                    >
                                        <SwitchField
                                            value={(+item.status === 1 || +item.status === 2)}
                                            id="active"
                                            className="switch-form blue-version inline-label light-version flex mb-0"
                                        />
                                    </button>
                                </td>
                                {/*<td>*/}
                                {/*    <div*/}
                                {/*        className="w-[34px] h-[34px] rounded-medium border sm:flex items-center justify-center mr-2 p-[6px] hidden">*/}
                                {/*        <img*/}
                                {/*            src={`/assets/images/marketing/${+item.ad_target === MARKETING_INCREASE_SALE_VIEW ? 'rocket' : 'thumbs-up-like'}.svg`}*/}
                                {/*            alt=""/>*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                                <td
                                    style={{color: 'black'}}
                                    className="text-black sm:mb-0 mb-1 sm:w-auto w-full"
                                >
                                    {!!item.product?.id ?
                                        <div className="flex items-center">
                                            <div
                                                className="w-[32px] min-w-[32px] h-[32px] rounded-[5px] overflow-hidden mr-2">
                                                {item?.product?.photo_small ?
                                                    <ServerImage
                                                        className="w-full h-full object-cover"
                                                        src={item?.product?.photo_small}
                                                        alt={item?.product?.name}/> :
                                                    <img
                                                        className="w-full h-full object-cover"
                                                        src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </div>
                                            <div className="flex max-w-[240px] sm:min-w-[200px] sm:max-w-[350px]">
                                                <div>
                                                    <div className="line-clamp-1">
                                                        {item?.product?.name}
                                                    </div>
                                                    {+item.roas >= 4 &&
                                                        <div
                                                            className="flex items-center text-[10px] text-[#3b82f6] font-bold">
                                                            <img src="/assets/images/svg/grow.svg" alt=""/>
                                                            <div className="ml-1">{getLangText('highScores')}</div>
                                                        </div>
                                                    }
                                                </div>
                                                {!!item?.products_total &&
                                                    <div
                                                        className="custom-badge px-1 font-weight-bold inline-block ml-2">
                                                        +{item?.products_total}
                                                    </div>
                                                }
                                            </div>
                                        </div> :
                                        <div className="flex items-center">
                                            {+item.ad_target === MARKETING_INCREASE_SALE_VIEW ?
                                                <div
                                                    className="w-[32px] flex justify-center items-center h-[32px] rounded-[5px] bg-[#f9f9f9] mr-2">
                                                    <img
                                                        className="w-[22px] mt-[4px] ml-0.5"
                                                        src="/assets/images/svg/no-product.svg" alt=""/>
                                                </div> :
                                                <div
                                                    className="w-[32px] h-[32px] rounded-medium border sm:flex items-center justify-center mr-2 p-[6px] hidden">
                                                    <img
                                                        src={`/assets/images/marketing/thumbs-up-like.svg`}
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                            <div
                                                className={`font-medium whitespace-nowrap ${+item.ad_target === MARKETING_INCREASE_SALE_VIEW ? 'text-desc' : ''}`}>
                                                {getLangText(+item.ad_target === MARKETING_INCREASE_SALE_VIEW ? 'noProduct' : 'adForFanPageLikes')}
                                            </div>
                                        </div>
                                    }
                                </td>
                                <td className="sm:w-auto w-full">
                                    {+item.status === 1 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg green small-status min-w-[95px]">
                                            <i className="icon-tick-c text-xs"/>
                                            {getLangText('active_label')}
                                        </div>
                                    }
                                    {+item.status === 2 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg blue small-status min-w-[95px] h-[28px] inline-flex items-center justify-center">
                                            <img
                                                className="inline-block w-[12px] mr-[4px] align-bottom"
                                                src="/assets/images/svg/half-time.svg" alt=""/>
                                            {getLangText('processing')}
                                        </div>
                                    }
                                    {+item.status === 3 &&
                                        <div
                                            style={{minWidth: 95}}
                                            className="status-bg grey small-status min-w-[95px]">
                                            <i className="icon-cross-c text-xs"/>
                                            {getLangText('not_active_label')}
                                        </div>
                                    }
                                    <div
                                        className="w-[26px] h-[26px] rounded-medium border sm:hidden inline-flex items-center justify-center ml-2 p-[6px]">
                                        <img
                                            src={`/assets/images/marketing/${+item.ad_target === MARKETING_INCREASE_SALE_VIEW ? 'rocket' : 'thumbs-up-like'}.svg`}
                                            alt=""/>
                                    </div>
                                    <button
                                        className="sm:hidden text-desc flex items-center text-xs font-bold mt-1"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (item.id === showMobileStats) {
                                                setShowMobileStats(null)
                                            } else {
                                                setShowMobileStats(item.id);
                                            }

                                        }}
                                        type="button"
                                    >
                                        {getLangText(item.id === showMobileStats ? 'hideLabel' : 'showStats')}
                                        <i class={`icon-arrows text-[8px] inline-block ml-1 ${item.id === showMobileStats ? 'rotate-180' : ''}`}/>
                                    </button>
                                </td>
                                <TdStats className="sm:mt-0 mt-0.5" title="newClients1" field="new_clients_total"
                                         item={item}/>
                                <TdStats title="salesValue" field="sold_value" item={item}/>
                                <TdStats title="budgetSpent" field="budget_spent" item={item}/>
                                <TdStats title="availablePieces" field="available_squ" item={item}/>
                                <TdStats title="soldPieces" field="sold_qty" item={item}/>
                                <TdStats title="ROAS" field="roas" item={item}/>
                                <TdStats title="createdDateLabel" field="date" item={item}
                                         className="date whitespace-nowrap"/>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;