import { combineReducers } from 'redux';
import listReducer from './list/listReducer';
import filtersReducer from './filters/filtersReducer';
import formReducer from './form/formReducer';

export default combineReducers({
    list: listReducer,
    filters: filtersReducer,
    form: formReducer,
});
