import React, {memo, useEffect, useRef, useState} from 'react';
import ClientListDropdown from "../components/ClientListDropdown.jsx";
import SortDropdown from "../components/SortDropdown.jsx";
import SearchClients from "../components/SeachClients.jsx";
import EmptyList from "../../../../../shared/EmptyList.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../../messenger/loader/Loader.jsx";
import ClientAvatar from "../../../../shared/Avatar.jsx";
import OrderStatus from "../../../../shared/OrderStatus.jsx";
import MoreActions from "../components/moreActions/MoreActions.jsx";
import ProductRow from "../components/ProductRow.jsx";
import {
    addProductsToClient,
    getLiveDetailsClients,
    liveDetailsAddNewProductToCustomer, resetAndUpdateClients,
    setNextPage, updateFieldInLiveDetails
} from "../../LiveDetailsActions.js";
import {useInView} from "react-hook-inview";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import {
    DEB_ELLE_FRINGUE_SHOP_ID, GRACEFULLY_MADE__SHOP_ID, KASIA_FASHION_LAB_SHOP_ID, LALEE_TRENDS_SHOP_ID,
    SKLEP_ODZIEZOWY_U_EMI_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";

const ClientsList = ({id}) => {

    const {
        clients,
        isClientsLoading,
        isClientGroupLoading,
        hasMoreClients,
        isCancelled,
        isCartStatusSent,
        isSummarySent,
        totalClients,
        sortBy,
        liveInfo,
    } = useSelector((state) => state.lives.details)
    const {getPrice} = useUnitPrice();
    const {userData} = useSelector((state) => state.session);

    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const clientGroupWrapperRef = useRef(null);
    const [ref, inView] = useInView()
    const showInfoAlert = isCartStatusSent === '0' || isSummarySent === '0';
    const [activeClientGroupId, setActiveClientGroupId] = useState(null);
    const collapsedButtonClick = (clientGroupId) => {
        if (clientGroupId === activeClientGroupId) {
            return setActiveClientGroupId(null);
        }
        setActiveClientGroupId(clientGroupId)
    }

    const isOrderCreated = (client) => client.order_created > 0

    const getTotalPrice = (products) => products.reduce((prev, curr) => {
        const safetyPrice = (price) => curr.is_deleted === '1' ? 0 : price
        if (!!curr.sizes?.length && !!curr.variant_id) {
            const matchedVariant = curr.sizes.find((i) => +i.id === +curr.variant_id)
            return prev + (safetyPrice(matchedVariant?.price) * curr.quantity)
        }
        return prev + (safetyPrice(curr.price) * curr.quantity)
    }, 0);

    const isClientGroupClass = (id) => {
        const isActive = activeClientGroupId === id ? 'active' : ''
        return `client-group ${isActive}`
    }

    useEffect(() => {
        if (id) {
            dispatch(getLiveDetailsClients(id))
        }
    }, [id])

    useEffect(() => {
        if (inView && hasMoreClients) {
            onNextPage()
        }
    }, [inView, hasMoreClients])

    const onNextPage = () => {
        dispatch(setNextPage())
        dispatch(getLiveDetailsClients(id))
    }

    const toggleClientSort = (value) => {
        dispatch(updateFieldInLiveDetails('sortBy', value))
        dispatch(resetAndUpdateClients(id))
    }

    return (
        <div
            ref={clientGroupWrapperRef}
            className="default-shadow-box"
        >
            <div className="clients-header">
                <div className="title">{getLangText('asideClientsLabel')} <span className="badge">{totalClients}</span>
                </div>
                <SearchClients/>
                <ClientListDropdown
                    liveId={id}
                    isCancelled={isCancelled}
                />
                <SortDropdown
                    toggleClientSort={toggleClientSort}
                    sortBy={sortBy}
                />
            </div>
            {showInfoAlert &&
                <div className="form-info-box w-100">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('liveNotSentInfoAlertTitle')}</div>
                        <div className="desc">
                            <ul>
                                <li>{getLangText('liveNotSentInfoAlertDescription2')}</li>
                                <li>{getLangText('liveNotSentInfoAlertDescription2')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            <EmptyList
                items={clients}
                isLoading={isClientsLoading}
                className="small-size"
                imgSrc="/assets/images/svg/no-clients.svg"
                title={getLangText('noClientsLabel')}
            >
                <InfiniteScroll
                    dataLength={clients.length}
                    next={onNextPage}
                    loader={<Loader className="static-loader"/>}
                    hasMore={hasMoreClients}
                >
                    <div className="client-group-wrapper">
                        {clients.map((client, index) => (
                            <div
                                key={client.id}
                                className={isClientGroupClass(client.id)}
                            >
                                <div
                                    className="top-part"
                                    onClick={() => collapsedButtonClick(client.id)}
                                >
                                    <div className="avatar-col">
                                        <ClientAvatar
                                            className="mb-1"
                                            photo={client.photo}
                                            type={client.social_type}
                                        />
                                    </div>
                                    <div className="name-with-status">
                                        <div className="name">
                                            <div>
                                                {(+userData.id === DEB_ELLE_FRINGUE_SHOP_ID || +userData.id === KASIA_FASHION_LAB_SHOP_ID || +userData.id === GRACEFULLY_MADE__SHOP_ID || +userData.id === LALEE_TRENDS_SHOP_ID) &&
                                                    <div className="font-bold mr-1 inline-block">
                                                        {index + 1}.
                                                    </div>
                                                }
                                                {client.client_name}
                                            </div>
                                            {client.shop_client_id &&
                                                <div className="client-number">
                                                    <span
                                                        className={SKLEP_ODZIEZOWY_U_EMI_SHOP_ID === +userData.id ? 'font-semibold' : ''}>{getLangText('clientNumberLabel')}: {client.shop_client_id}</span>
                                                </div>
                                            }
                                        </div>
                                        <OrderStatus
                                            client={client}
                                            summarySent={client.summary_sent}
                                            cartSent={client.cart_status_sent}
                                            orderCreated={client.order_created}
                                        />
                                    </div>
                                    <div
                                        className="price">{getPrice(Math.round(getTotalPrice(client.products) * 100) / 100)}</div>
                                    <div className="buttons-group">
                                        <MoreActions
                                            isOrderCreated={isOrderCreated(client)}
                                            client={client}
                                        />
                                        <div className="button text-only">
                                            <i className="icon-arrows"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="products-row-wrapper">
                                    <div className="inputs-wrapper only-first-row-labels">
                                        {client.products.map((item) => (
                                            <ProductRow
                                                products={client.products}
                                                key={item.id}
                                                item={item}
                                                shop_client_id={client.shop_client_id}
                                                clientId={client.id}
                                                isOrderCreated={isOrderCreated(client)}
                                                liveInfo={liveInfo}
                                            />
                                        ))}
                                    </div>
                                    {!isOrderCreated(client) &&
                                        <div className="buttons-row">
                                            <button
                                                type="button"
                                                className="button text-blue-button add-new-row"
                                                onClick={() => dispatch(liveDetailsAddNewProductToCustomer(client.shop_client_id))}
                                                tabIndex={client.products[client.products.length - 1]?.tabIndex}
                                            >
                                                <i className="icon-plus"/>
                                                <span
                                                    className="d-none d-md-block">{getLangText('addNextProductLabel')}</span>
                                                <span className="d-md-none">{getLangText('addProductLabel')}</span>
                                            </button>
                                            <button
                                                type="submit"
                                                className="button primary the-smallest-size"
                                                tabIndex={client.products[client.products.length - 1]?.tabIndex}
                                                onClick={() => dispatch(addProductsToClient(client.shop_client_id))}
                                            >
                                                {isClientGroupLoading &&
                                                    <Loader/>
                                                }
                                                <span>{getLangText('saveChangesButton')}</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{height: '1px'}}
                        className="for-intersection" ref={ref}
                    />
                </InfiniteScroll>
            </EmptyList>
        </div>
    );
};

export default memo(ClientsList);
