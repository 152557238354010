import { combineReducers } from 'redux';
import overallReducer from './overall/overallReducer';
import signupsReducer from './signups/signupsReducer';
import salesReducer from './sales/salesReducer';

export default combineReducers({
  overall: overallReducer,
  signups: signupsReducer,
  sales: salesReducer,
});
