import React, { useEffect, useMemo, useState } from 'react';
import useLang from '../../../../../../src_shared/hooks/useLang';
import { useUnitPrice } from '../../../../shared/helpers/Price';
import {
    findOrderPaymentStatusById,
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_BM_TRANSFER,
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_PICKUP,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER,
    ORDER_PAYMENT_PAYPO,
} from '../../../../shared/enums/PaymentStatuses';
import {
    AILEMA_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    CALZADO_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    SHOP_PRZYDA_CI_SIE,
    STYLOWE_BUTY_SHOP_ID,
    TNK_SHOP_ID,
} from '../../../../../../src_shared/enums/TrustedShopsIds';
import { SHOP_ORIGIN_FR } from '../../../../shared/enums/ShopOrigins';
import { onlinePaymentsIdArray } from '../../../../shared/components/paymentMethods/PaymentMethodsForm';
import Payment from './components/Payment';

const PaymentMethodsNew = ({
    items,
    shopInfo,
    shopPayment,
    onPaymentChange,
    values,
    updateValues,
    hasError,
    setPaymentMethodsValidation,
    className = '',
    paymentValidationActive,
    isOrderPickupSelected = false,
    hiddenPickupMethod = false,
    separatedTransferMethods = false,
}) => {
    const { getLangText } = useLang();
    const [cardDetailsVisible, setCardDetailsVisible] = useState(false);
    const { getPrice } = useUnitPrice();
    const isPopularBoxActive = useMemo(
        () => items?.some((i) => onlinePaymentsIdArray.includes(i.type)),
        [items],
    );
    const checkIsPaymentOnline = (id) =>
        onlinePaymentsIdArray.includes(id) || id?.includes('stripe_');

    const getPriceForDescription = (item) => {
        if (item.type !== ORDER_PAYMENT_PICKUP) return '';
        return getPrice(item.price, shopInfo?.currency_shortcut);
    };

    const getPaymentCommissionPrice = (id) => {
        if (id !== ORDER_PAYMENT_PICKUP) return '';
        if (id === ORDER_PAYMENT_PAYPAL) {
            return shopPayment?.paypal_commission
                ? getPrice(
                      shopPayment?.paypal_commission,
                      shopInfo?.currency_shortcut,
                  )
                : getLangText('freeLabel');
        } else if (
            id === ORDER_PAYMENT_BLIK ||
            id === ORDER_PAYMENT_BLUEMEDIA ||
            (id === ORDER_PAYMENT_BM_TRANSFER &&
                +shopInfo?.id === AILEMA_SHOP_ID)
        ) {
            return shopPayment?.bm_commission
                ? getPrice(
                      shopPayment?.bm_commission,
                      shopInfo?.currency_shortcut,
                  )
                : getLangText('freeLabel');
        } else if (id === ORDER_PAYMENT_TRANSFER) return '';
        else return isPopularBoxActive ? '' : getLangText('freeLabel');
    };

    const optionsWhenOrderPickupSelected =
        ((+shopInfo?.id === BUTIK_MADAME_SHOP_ID ||
            +shopInfo?.id === TNK_SHOP_ID) &&
            isOrderPickupSelected) ||
        hiddenPickupMethod
            ? items?.filter((x) => x.type !== ORDER_PAYMENT_PICKUP)
            : items;

    const options = useMemo(
        () =>
            optionsWhenOrderPickupSelected?.map((i) => ({
                ...i,
                description: !!+i.price
                    ? +values.shopInfo?.order_stage > 1
                        ? getLangText('paidLabel')
                        : getPriceForDescription(i)
                    : getPaymentCommissionPrice(i.type),
                label:
                    i.label ||
                    getLangText(findOrderPaymentStatusById(i.type)?.label) ||
                    findOrderPaymentStatusById(i.type)?.label,
                id: i.type,
            })),
        [optionsWhenOrderPickupSelected, shopPayment],
    );

    const onlinePaymentsOptions = options.filter((i) =>
        checkIsPaymentOnline(i.id),
    );
    const restPaymentsOptions = options.filter(
        (i) => !checkIsPaymentOnline(i.id),
    );

    const preparedOptions = separatedTransferMethods
        ? onlinePaymentsOptions
        : options;

    const onTransferAccountChange = (id) => {
        onPaymentChange({
            payment: `${ORDER_PAYMENT_TRANSFER}_${id}`,
            transfer_account_id: id,
        });
    };

    const getPreparedPaymentLabel = (item) => {
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            +shopInfo?.id === SHOP_BAZAR_SHOP_ID
        ) {
            return getLangText('paymentMethodPaypalWithProvisionLabel');
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            +shopInfo?.id === CALZADO_SHOP_ID
        ) {
            return getLangText('paypalForeignTransfers');
        }
        if (item.id === ORDER_PAYMENT_PAYPAL && +shopInfo?.id === 6369) {
            return 'PayPal - Choisir paiement "ENTRE PROCHE"';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL_AUTOMATIC &&
            +shopInfo?.id === 5479
        ) {
            return 'Paiement PayPal en 4x (á partir de 30E)';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL_AUTOMATIC &&
            shopInfo?.origin === SHOP_ORIGIN_FR
        ) {
            return 'PayPal / Paiement CB / Paiement en 4 fois (à partir de 30E)';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            shopInfo?.origin === SHOP_ORIGIN_FR
        ) {
            return 'PayPal (Entre proches)';
        }
        if (
            item.id === ORDER_PAYMENT_PICKUP &&
            +shopInfo?.id === STYLOWE_BUTY_SHOP_ID
        ) {
            return 'Za pobraniem';
        }
        if (item.id === ORDER_PAYMENT_PAYPO) {
            return getLangText({
                key: 'paypoLabel',
                data: ['PayPo'],
            });
        }
        return getLangText(item.label) || item.label;
    };

    useEffect(() => {
        if (values.payment === ORDER_PAYMENT_STRIPE_CARD) {
            setCardDetailsVisible(true);
        } else if (cardDetailsVisible) {
            setCardDetailsVisible(false);
            setPaymentMethodsValidation(false);
        }
    }, [values.payment]);

    return (
        <>
            <div className="popular-box">
                <div className="text">
                    <i className="icon-safe" />
                    {getLangText('mostPopularOptionsLabel')}
                </div>
            </div>
            {hasError && (
                <div className="form-info-box alert-style red w-100 mb-2">
                    <div>
                        <i className="icon-info-c" />
                    </div>
                    <div className="title">
                        {getLangText('customerBasketPaymentTypeLabel')}
                    </div>
                </div>
            )}
            <div
                className={`data-boxes-wrapper ${className} separated ${hasError ? 'error-group' : ''}`}>
                {!options.length ? (
                    <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                        <div>
                            <i className="icon-info-c" />
                        </div>
                        <div>
                            {+shopInfo?.id === SHOP_PRZYDA_CI_SIE ? (
                                <div className="title">
                                    Nie możemy aktualnie zrealizować płatności.
                                    Prosimy wrócić później.
                                </div>
                            ) : (
                                <div className="title">
                                    {getLangText(
                                        'paymentMethodNoDeliveriesMethodsLabel',
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    preparedOptions.map((item) => (
                        <Payment
                            item={item}
                            values={values}
                            updateValues={updateValues}
                            paymentValidationActive={paymentValidationActive}
                            onPaymentChange={onPaymentChange}
                            onTransferAccountChange={onTransferAccountChange}
                            shopInfo={shopInfo}
                            key={item.id}
                            getPreparedPaymentLabel={getPreparedPaymentLabel}
                            cardDetailsVisible={cardDetailsVisible}
                            checkIsPaymentOnline={checkIsPaymentOnline}
                        />
                    ))
                )}
                {separatedTransferMethods && restPaymentsOptions?.length && (
                    <div className="mt-4">
                        <div className="text-desc font-bold text-sm mb-2">
                            {getLangText('otherPayments')}
                        </div>
                        {restPaymentsOptions.map((item) => (
                            <Payment
                                item={item}
                                values={values}
                                updateValues={updateValues}
                                paymentValidationActive={
                                    paymentValidationActive
                                }
                                onPaymentChange={onPaymentChange}
                                onTransferAccountChange={
                                    onTransferAccountChange
                                }
                                shopInfo={shopInfo}
                                key={item.id}
                                getPreparedPaymentLabel={
                                    getPreparedPaymentLabel
                                }
                                cardDetailsVisible={cardDetailsVisible}
                                checkIsPaymentOnline={checkIsPaymentOnline}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default PaymentMethodsNew;
