import React from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const ValidationBox = ({title, visible, className = ''}) => {
    const {getLangText} = useLang();

    if (!visible) return null;

    return (
        <div className={`bg-[#FEF2F2] rounded-[5px] flex items-center p-2 text-[#981B1B] mt-2 ${className}`}>
            <div>
                <i className="icon-alert-c" />
            </div>
            <div className="font-bold text-sm ml-2">
                {getLangText(title)}
            </div>
        </div>
    );
};

export default ValidationBox;
