export const initState = {
    search: '',
    items: [],
    isLoading: true,
    isFirstStepPage: false,
    sortBy: null,
    sortDir: 'asc',
};

export const reduceMap = {
    TABLE_UPDATE_SEARCH: (state, action) => {
        if (action.searchText == null) {
            console.error(
                'listReducer error: action ' +
                    'TABLE_UPDATE_SEARCH has invalid data!',
            );
            console.info(action);
            return state;
        }
        return { ...state, search: action.searchText };
    },
    TABLE_UPDATE_ALL_ITEMS: (state, action) => ({
        ...state,
        isLoading: false,
        items: action.items,
        isError: false,
    }),
    TABLE_UPDATE_SORT: (state, action) => {
        console.log(state, action);
        if (action.sortDir !== 'asc' && action.sortDir !== 'desc') {
            console.error(
                'listReducer error: action ' +
                    'TABLE_UPDATE_SORT has invalid data!',
            );
            console.info(action);
            return state;
        }
        return { ...state, sortBy: action.sortBy, sortDir: action.sortDir };
    },
    TABLE_UPDATE_ITEM: (state, action) => {
        const { id, field, value } = action;
        return {
            ...state,
            items: state.items.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          [field]: value,
                      }
                    : item,
            ),
        };
    },
    TABLE_ADD_TO_BEGIN: (state, action) => {
        const { item } = action;
        if (!item) {
            console.error(
                'listReducer error: action ' +
                    'TABLE_ADD_TO_BEGIN has invalid data!',
            );
            console.info(action);
            return state;
        }

        const matched = state.items.find((i) => i.id === item.id);

        if (matched && item.fromFacebookSocket) {
            return state;
        }

        if (matched) {
            return {
                ...state,
                items: state.items.map((i) => (item.id === i.id ? item : i)),
            };
        }

        return {
            ...state,
            items: [item, ...state.items],
        };
    },
    TABLE_ADD_ITEM: (state, action) => {
        const { item } = action;
        return {
            ...state,
            items: [...state.items, item],
        };
    },
    TABLE_MARK_ITEM_TO_REMOVE: (state, action) => {
        if (action.itemId === undefined) {
            console.error(
                'listReducer error: action ' +
                    'TABLE_MARK_ITEM_TO_REMOVE has invalid data!',
            );
            console.info(action);
            return state;
        }
        return { ...state, markedToRemove: action.itemId };
    },
    TABLE_REMOVE_ITEM: (state, action) => {
        const filteredItems = state.items.filter((i) => i.id !== action.itemId);
        return { ...state, items: filteredItems };
    },
};

export default {
    reduceMap,
    initState,
};
