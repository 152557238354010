import React, {useCallback, useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import {Autoplay, Pagination, Navigation} from "swiper/modules";
import useLang from "../../../../../src_shared/hooks/useLang";

const options = [
    {
        id: 'tim',
        title: 'marketingReviewTim',
        desc: 'marketingReviewTimSubLabel',
        shop_name: 'TIM Moda',
        logo: 'tim.webp',
        name: 'Piotr i Marzena',
    },
    {
        id: 'nasza-bajka',
        title: 'marketingReviewNaszaBajka',
        desc: 'marketingReviewNaszaBajkaSubLabel',
        shop_name: 'Nasza Bajka',
        logo: 'nasza-bajka.webp',
        name: 'Marzena',
    },
    {
        id: 'ailema',
        title: 'marketingReviewAilema',
        desc: 'marketingReviewAilemaSubLabel',
        shop_name: 'Ailema',
        logo: 'ailema.webp',
        name: 'Katarzyna',
    },
]

const Reviews = ({reviewsSectionRef}) => {
    const {getLangText} = useLang();
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current?.swiper?.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current?.swiper?.slideNext();
    }, []);

    return (
        <section
            ref={reviewsSectionRef}
            className="marketing-swiper-bg mt-16 mb-8 lg:my-16 text-center py-12 sm:py-20 overflow-hidden sm:px-0 px-6"
        >
            <div
                class="light-grey-subtitle mb-7 text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold sm:whitespace-pre-wrap"
                dangerouslySetInnerHTML={{__html: getLangText('reviewsAboutSelmoMarketing')}}/>
            <div
                onMouseEnter={() => sliderRef.current.swiper.autoplay?.stop()}
                onMouseLeave={() => sliderRef.current.swiper.autoplay?.start()}
                className="relative max-w-[520px] mx-auto"
            >
                <Swiper
                    onSlideChangeTransitionEnd={(e) => e.activeIndex === 2 &&  sliderRef.current?.swiper?.loopCreate()}
                    initialSlide={1}
                    ref={sliderRef}
                    modules={[Navigation, Pagination]}
                    slidesPerView={1.15}
                    breakpoints={{
                        576: {slidesPerView: 1, spaceBetween: 20},
                    }}
                    loop={true}
                    grabCursor={true}
                    pagination={{clickable: true}}
                    spaceBetween={15}
                    speed={1000}
                    // autoplay={true}
                    className="reviews-swiper"
                >
                    {options.map((i) => (
                        <SwiperSlide
                            style={{height: 'auto'}} key={i.id}>
                            <div className="bg-[#fff] shadow-default-small text-left h-full flex flex-col rounded-[20px] p-6 lg:p-10">
                                <div className={'flex items-center'}>
                                    <div
                                        className="sm:w-[48px] sm:min-w-[48px] sm:h-[48px] w-[32px] min-w-[32px] h-[32px] rounded-full overflow-hidden mr-2">
                                        <img src={`/assets/images/marketing/${i.logo}`}
                                             alt={i.shop_name}
                                        />
                                    </div>
                                    <div>
                                        <div className="font-bold sm:text-[16px] text-sm text-[#101827]">
                                            {i.shop_name}
                                        </div>
                                        <div className="sm:text-sm text-xs font-medium text-[#6b7280]">
                                            {i.name}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:text-2xl font-bold my-[12px]">
                                    {getLangText(i.title)}
                                </div>
                                <div className="leading-[1.63] lg:text-[16px] text-sm font-medium text-[#6b7280] mb-2">
                                    {getLangText(i.desc)}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="absolute z-10 bottom-0 left-0 right-0">
                    <div className="flex justify-between items-center">
                        <div
                            className="bg-[#fff] border border-[#e5e7eb] shadow-default-small mr-1 hover:opacity-85 cursor-pointer transition duration-200 right-[30px] rounded-full w-[24px] h-[24px] flex items-center justify-center"
                            onClick={handlePrev}
                        >
                            <i class="icon-arrow-left text-[6px] block"/>
                        </div>
                        <div
                            className="bg-[#fff] border border-[#e5e7eb] shadow-default-small hover:opacity-85 cursor-pointer transition duration-200 right-0 rounded-full w-[24px] h-[24px] flex items-center justify-center"
                            onClick={handleNext}
                        >
                            <i class="icon-arrow-left text-[6px] block rotate-180" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Reviews;