import React from 'react';
import { RouterPaths } from '../../../../../../../../routes/RouterPath';
import useTableRowClick from '../../../../../../../shared/hooks/useTableRowClick';
import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import EmptyList from '../../../../../../../shared/EmptyList';
import Loader from '../../../../../../../../../src_shared/components/loader/Loader';
import moment from 'moment';

const Table = ({
    items,
    isWaiting,
    updateSortOptions,
    getSortClass,
    createAd,
    isWaitingForAd,
}) => {
    const { getLangText } = useLang();
    const onTableRowClick = useTableRowClick('');

    const getDetailsRedirectPath = (id) => {
        return `${RouterPaths.MarketingSmsCampaignsDetails}/${id}`.slice(1);
    };

    const formatDateLabel = (dateString) => {
        const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss');

        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const timeFormatted = date.format('HH:mm');

        if (date.isSame(today, 'day')) {
            return `${getLangText('todayLabel')}, ${timeFormatted}`;
        }

        if (date.isSame(yesterday, 'day')) {
            return `${getLangText('yesterdayLabel')}, ${timeFormatted}`;
        }

        return `${date.format('DD.MM.YYYY')} • ${timeFormatted}`;
    };

    const ExtraComponent = () => (
        <button
            onClick={createAd}
            style={{ borderRadius: 8 }}
            className={`button primary medium-size mt-3 mb-5 ${isWaitingForAd ? 'loading-button' : ''}`}
            type="button">
            {isWaitingForAd && <Loader isLoading={true} />}
            {getLangText('sendSmsMessage')}
        </button>
    );

    const renderStatusBadge = (status) => {
        if (status === 1) {
            return (
                <div className="status-bg blue small-status w-[87px] inline-flex items-center">
                    <img
                        src="/assets/images/svg/pencil.svg"
                        alt="Created"
                        className="w-4 h-4 mr-[2px]"
                    />
                    {getLangText('smsCreatedLabel')}
                </div>
            );
        }

        if (status === 2) {
            return (
                <div className="status-bg orange small-status w-[87px] inline-flex items-center">
                    <img
                        src="/assets/images/svg/pie.svg"
                        alt="Processing"
                        className="w-4 h-4 mr-[2px]"
                    />
                    {getLangText('smsProcessingLabel')}
                </div>
            );
        }

        return (
            <div className="status-bg green small-status w-[87px] inline-flex items-center">
                <img
                    src="/assets/images/svg/tick-green.svg"
                    alt="Sent"
                    className="w-4 h-4 mr-[2px]"
                />
                {getLangText('smsSentLabel')}
            </div>
        );
    };

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase with-bg"
            imgSrc="/assets/images/svg/marketing/sms.svg"
            title={getLangText('noAddedCampaigns')}
            text={getLangText('createCampaignToIncreaseReachAndSales')}
            imgStyle={{ width: 44, height: 44 }}
            ExtraComponent={ExtraComponent}>
            <div className="table-wrapper">
                <div className="table-responsive rounded-b-medium">
                    <table className="table live-table smaller-padd mobile-block">
                        <thead>
                            <tr>
                                <th
                                    name="shop_sms_id"
                                    onClick={updateSortOptions}
                                    className={`${getSortClass('shop_sms_id')}`}>
                                    <span name="shop_sms_id">
                                        {getLangText('numberShortLabel')}
                                    </span>
                                </th>
                                <th
                                    name="message"
                                    onClick={updateSortOptions}
                                    className={`whitespace-nowrap ${getSortClass('message')}`}>
                                    <span name="message">
                                        {getLangText('smsMessageLabel')}
                                    </span>
                                </th>
                                <th
                                    name="status"
                                    onClick={updateSortOptions}
                                    className={`whitespace-nowrap ${getSortClass('status')}`}>
                                    <span name="status">
                                        {getLangText('statusLabel')}
                                    </span>
                                </th>
                                <th
                                    name="date"
                                    onClick={updateSortOptions}
                                    className={`text-right whitespace ${getSortClass('date')}`}>
                                    <span name="date">
                                        {getLangText('dateLabel')}
                                    </span>
                                </th>
                                <th
                                    name="receivers"
                                    onClick={updateSortOptions}
                                    className={`text-right whitespace ${getSortClass('receivers')}`}>
                                    <span name="receivers">
                                        {getLangText('receiversLabel')}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items?.map((item) => (
                                <tr
                                    {...onTableRowClick(
                                        getDetailsRedirectPath(item.id),
                                    )}>
                                    <td className="number sm:!table-cell !flex items-center">
                                        <div className="text-xs font-bold sm:text-desc text-[#000] sm:mr-2">
                                            <span className="sm:hidden">#</span>
                                            {item.shop_sms_id}
                                        </div>
                                    </td>
                                    <td className="sm:truncate sm:text-[#101827] text-[#4B5563] overflow-hidden sm:max-w-[450px] sm:order-none order-1 sm:w-auto w-full sm:mt-0 sm:mb-0 mb-2 mt-1">
                                        {item.message ||
                                            getLangText('smsDraft')}
                                    </td>
                                    <td className="sm:ml-0 ml-auto">{renderStatusBadge(+item.status)}</td>
                                    <td className="sm:order-none order-2">
                                        <div
                                            className="text-right sm:block flex items-center text-[#4B5563] sm:border-0 border sm:p-0 p-0.5 pr-1 sm:rounded-none rounded-medium">
                                            <div
                                                className="sm:hidden flex items-center justify-center mr-1 bg-[#f9f9f9] rounded-full p-1">
                                                <i class="icon-date text-[12px]"/>
                                            </div>
                                            {formatDateLabel(item.date)}
                                        </div>
                                    </td>
                                    <td className="text-right sm:ml-0 ml-auto sm:order-none order-3">
                                        <div
                                            className="text-right sm:block flex items-center sm:text-[#101827] text-[#4B5563] sm:border-0 border sm:p-0 p-0.5 pr-1 sm:rounded-none rounded-medium">
                                            <div
                                                className="sm:hidden flex items-center justify-center mr-1 bg-[#f9f9f9] rounded-full p-1">
                                                <i class="icon-profile text-[12px]"/>
                                            </div>
                                            {item.receivers}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
