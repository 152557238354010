import React, {useEffect, useState} from 'react';
import ConfirmModal from "../../../../../live/details/default/summary/export/ConfirmModal";
import useDataApi from "../../../../../../../src_shared/hooks/useDataApi";
import Loader from "../../../../../shared/Loader";
import {services} from "../../../../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import ListActions from "../../ListActions";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import FacebookNotificationsForm from "./components/FacebookNotificationsForm";
import {createSelector} from "reselect";
import {getStateRoot} from "../../listReducer";
import MobileNotificationsForm from "./components/MobileNotificationsForm";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import SendNotificationsModel from "../../../../../../../modules/models/notifications/SendNotificationsModel";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";

const SendNotificationsModal = ({isModalVisible, cancelItemRemoval, afterSend}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();

    const {isSuccessFormView, waitingForNotificationSent} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const sendNotifications = () => dispatch(ListActions.sendNotifications(values, afterSend));

    const messageNotificationApp = getLangText({
        key: 'mobileNotificationsAppExtraTextLabel',
        data: [userData.name]
    })

    const [{data, isLoading}] = useDataApi(services.API_CLIENTS_SEND_NOTIFICATIONS, {
        notification_message: ''
    })
    const [values, setValues] = useState({
        message: '',
        link: '',
        mobile_app: +userData.id === TEST_ACCOUNT_SHOP_ID ? '1' : '0',
    })

    const [{getFieldProps, isWaiting: isWaitingForMobileNotifications, updateValues, onSave: sendMobileNotifications}] = useFormLocal({
        rest: services.API_MOBILE_CLIENTS_NOTIFICATIONS,
        model: SendNotificationsModel,
        afterSubmit: () => {
            dispatch(showAlert('successfulSentNotificationsLabel'))
            cancelItemRemoval();
        },
        initialAutoLoad: false,
    });

    useEffect(() => {
        if (isSuccessFormView) {
            updateValues({
                message: values.message.replace(messageNotificationApp, ''),
                url: values.link,
            });
        }
    }, [isSuccessFormView, values, data.notification_message])

    useEffect(() => {
        return () => dispatch(ListActions.setSuccessSendFormView(false));
    }, [])

    return (
        <ConfirmModal
            title={getLangText('sendNotificationsLabel')}
            SubTitle={() => <span
                className="mt-1 d-block">{getLangText('eachClientCanSendNotificationsOnceIn24HoursLabel')}</span>}
            saveButton={getLangText('sendButton')}
            show={isModalVisible}
            isWaiting={waitingForNotificationSent || isWaitingForMobileNotifications}
            onSubmit={isSuccessFormView ? sendMobileNotifications : sendNotifications}
            hide={cancelItemRemoval}
            dialogClassName="w-450"
            withModalHeader={!isSuccessFormView}
        >
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                {isSuccessFormView ?
                    <MobileNotificationsForm getFieldProps={getFieldProps}/> :
                    <FacebookNotificationsForm
                        values={values}
                        setValues={setValues}
                        data={data}
                        messageNotificationApp={messageNotificationApp}
                    />
                }
            </div>
        </ConfirmModal>
    );
};

export default SendNotificationsModal;
