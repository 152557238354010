import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouterPaths } from './RouterPath';
import Marketing from '../app/marketing/Marketing';
import FacebookMarketing from '../app/marketing/facebook/FacebookMarketing';
import SmsMarketing from '../app/marketing/sms/SmsMarketing';
import MarketingOther from '../app/marketing/other/Other';
import FirstStepsFacebook from '../app/marketing/facebook/submitted/firstSteps/FirstSteps';
import Statistics from '../app/marketing/facebook/submitted/statistics/Statistics';
import SubmittedWrapperFacebook from '../app/marketing/facebook/submitted/SubmittedWrapper';
import SubmittedWrapperSms from '../app/marketing/sms/submitted/SubmittedWrapper';
import FacebookAdvertisementsList from '../app/marketing/facebook/submitted/advertisements/list/List';
import { AdsPermissionRoute } from './AdsPermissionRoute';
import Accounts from '../app/marketing/facebook/submitted/accounts/Accounts';
import SmsCampaignsList from '../app/marketing/sms/submitted/campaigns/list/List';
import SmsPermissionRoute from './SmsPermissionRoute';
import SmsReceiversList from '../app/marketing/sms/submitted/receivers/list/List';

const MarketingRoute = ({ path }) => {
    return (
        <Route path={path}>
            <Marketing>
                <Switch>
                    <Route
                        component={FacebookMarketing}
                        exact
                        path={RouterPaths.MarketingFacebook}
                    />
                    <Route
                        component={SmsMarketing}
                        exact
                        path={RouterPaths.MarketingSms}
                    />
                    <Route
                        exact
                        path={RouterPaths.MarketingSelmoApp}
                        component={MarketingOther}
                    />
                    <Route
                        exact
                        path={RouterPaths.MarketingEmail}
                        component={MarketingOther}
                    />
                    <Route path={RouterPaths.MarketingFacebook}>
                        <AdsPermissionRoute>
                            <SubmittedWrapperFacebook>
                                <Switch>
                                    <Route
                                        exact
                                        component={FirstStepsFacebook}
                                        path={
                                            RouterPaths.MarketingFacebookFirstSteps
                                        }
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.MarketingFacebookAdvertisements
                                        }
                                        component={FacebookAdvertisementsList}
                                    />
                                    <Route
                                        exact
                                        component={Statistics}
                                        path={
                                            RouterPaths.MarketingFacebookStatistics
                                        }
                                    />
                                    <Route
                                        exact
                                        component={Accounts}
                                        path={
                                            RouterPaths.MarketingFacebookAccounts
                                        }
                                    />
                                </Switch>
                            </SubmittedWrapperFacebook>
                        </AdsPermissionRoute>
                    </Route>
                    <Route path={RouterPaths.MarketingSms}>
                        <SmsPermissionRoute>
                            <SubmittedWrapperSms>
                                <Switch>
                                    <Route
                                        exact
                                        component={SmsCampaignsList}
                                        path={RouterPaths.MarketingSmsCampaigns}
                                    />
                                    <Route
                                        exact
                                        component={SmsReceiversList}
                                        path={RouterPaths.MarketingSmsReceivers}
                                    />
                                </Switch>
                            </SubmittedWrapperSms>
                        </SmsPermissionRoute>
                    </Route>
                    <Redirect to={RouterPaths.MarketingFacebook} />
                </Switch>
            </Marketing>
        </Route>
    );
};

export default MarketingRoute;
