import React from 'react';
import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import EmptyList from '../../../../../../../shared/EmptyList';
import moment from 'moment';

const Table = ({ items, isWaiting, updateSortOptions, getSortClass }) => {
    const { getLangText } = useLang();

    const formatDateLabel = (dateString) => {
        const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss');

        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const timeFormatted = date.format('HH:mm');

        if (date.isSame(today, 'day')) {
            return `${getLangText('todayLabel')}, ${timeFormatted}`;
        }

        if (date.isSame(yesterday, 'day')) {
            return `${getLangText('yesterdayLabel')}, ${timeFormatted}`;
        }

        return `${date.format('DD.MM.YYYY')} • ${timeFormatted}`;
    };

    const getFormattedName = (item) => {
        if (item.name) {
            return item.name;
        }

        if (item.first_name && item.last_name) {
            return `${item.first_name} ${item.last_name}`;
        }

        return null;
    };

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase with-bg"
            imgSrc="/assets/images/svg/marketing/sms.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('clientsWillAppearHere')}
            imgStyle={{ width: 44, height: 44 }}>
            <div className="table-wrapper">
                <div className="table-responsive rounded-b-medium">
                    <table className="table non-interactive-table smaller-padd">
                        <thead>
                            <tr>
                                <th>{getLangText('nameAndSurnameLabel')}</th>
                                <th>{getLangText('phoneLabel')}</th>
                                <th
                                    name="update_date"
                                    onClick={updateSortOptions}
                                    className={`text-right whitespace ${getSortClass('update_date')}`}>
                                    <span name="update_date">
                                        {getLangText('dateLabel')}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items?.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="mr-2 flex items-center gap-2">
                                            <img
                                                src={
                                                    item.photo ??
                                                    '/assets/images/default/user.svg'
                                                }
                                                alt="User"
                                                className="w-6 h-6 rounded-full"
                                            />
                                            <span className="font-bold">
                                                {getFormattedName(item)}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="truncate overflow-hidden max-w-[450px]">
                                        {item.phone}
                                    </td>
                                    <td>
                                        <div className="text-right text-[#4B5563]">
                                            {formatDateLabel(item.update_date)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
