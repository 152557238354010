import React from 'react';
import { useDispatch } from 'react-redux';
import { useRemoveModal } from '../../../shared/hooks/useRemoveModal';
import { services } from '../../../../RestServices';
import EditOrderFormActions from '../form/FormActions';
import ConfirmModal from '../../list/components/export/ConfirmModal';
import StylesLoader from '../../../shared/Loader';
import useLang from '../../../../../src_shared/hooks/useLang';
import { useDownloadFile } from '../../../../../src_shared/hooks/useDownloadFile';
import { usePrintFile } from '../../../../../src_shared/hooks/usePrintFile';
import useFormLocal from '../../../../../src_shared/hooks/useFormLocal';
import { showAlert } from '../../../shared/components/alert/AlertActions';
import Loader from '../../../shared/Loader';
import Model from '../../../../../src_shared/modules/model/Model';
import { convertToUserTimeZone } from '../../../shared/helpers/dateHelpers';

const GlsLabel = ({ data }) => {
    const dispatch = useDispatch();
    const { getLangText } = useLang();

    const loadData = () =>
        dispatch(EditOrderFormActions.loadData(data.shop_order_id));

    const [{ onSave, isWaiting }] = useFormLocal({
        rest: `${services.API_SHIPMENT_GLS_SK_LABEL}/${data.id}`,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit: loadData,
        onError: (e) =>
            dispatch(showAlert(e.response?.data?.message, '', 'alert-fail')),
    });

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(
        `${services.API_SHIPMENT_GLS_SK_LABEL}/${data.id}`,
        loadData,
    );

    const { download, waiting: waitingForLabel } = useDownloadFile({
        rest: `${services.API_SHIPMENT_GLS_SK_LABEL}/${data.id}`,
        fileName: 'DPD',
    });

    const { print, waiting: waitingForPrint } = usePrintFile({
        rest: `${services.API_SHIPMENT_GLS_SK_LABEL}/${data.id}`,
    });

    if (!+data.shippingData.shop_gls_sk_active) {
        return null;
    }

    return (
        <>
            {!data.shippingData.gls_parcel_id ? (
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <div className="flex items-center">
                                <h2>GLS</h2>
                                <div className="ml-auto">
                                    <a
                                        href="https://gls-group.com/SK/sk/home/"
                                        target="_blank"
                                        rel="nofollow noreferrer">
                                        <img
                                            style={{ height: 20 }}
                                            height={20}
                                            src="/assets/images/gls.png"
                                            alt="gls"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="section-header__description">
                                {getLangText({
                                    key: 'addLabelByLabel',
                                    data: ['GLS'],
                                })}
                            </div>
                        </div>
                    </div>
                    {!data.shipping_address_exist ? (
                        <div className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c" />
                            </div>
                            <div>
                                <div className="title">
                                    {getLangText('noDeliveryAddressLabel')}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <button
                            onClick={onSave}
                            type="button"
                            className={`button primary w-full ${isWaiting ? 'loading-button' : ''}`}>
                            {isWaiting && <Loader isLoading />}
                            {getLangText('generateLabelLabel')}
                        </button>
                    )}
                </section>
            ) : (
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <div className="flex items-center">
                                <h2>GLS</h2>
                                <div className="ml-auto">
                                    <a
                                        href="https://gls-group.com/SK/sk/home/"
                                        target="_blank"
                                        rel="nofollow noreferrer">
                                        <img
                                            style={{ height: 20 }}
                                            height={20}
                                            src="/assets/images/gls.png"
                                            alt="gls"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="section-header__description">
                                {getLangText('manageLabelLabel')} GLS
                            </div>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <StylesLoader
                            className="small-loader"
                            isLoading={waitingForLabel || waitingForPrint}
                        />
                        <div className="tripple-buttons">
                            <div>
                                <button
                                    onClick={print}
                                    type="button"
                                    className="button border-button big-width position-relative">
                                    {getLangText('printButton')}
                                    <i className="icon-print" />
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={download}
                                    type="button"
                                    className="button border-button big-width position-relative">
                                    {getLangText('previewButton')}
                                    <i className="icon-eye" />
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    className="button border-button big-width">
                                    {getLangText('removeButton')}
                                    <i className="icon-bin" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.shippingData.gls_parcel_date && (
                        <div className="info-label mt-3">
                            <i className="icon-tick-c" />
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>
                                    {convertToUserTimeZone(
                                        data.shippingData.gls_parcel_date,
                                    ).format('DD.MM.YYYY')}
                                </span>
                                <span>
                                    {convertToUserTimeZone(
                                        data.shippingData.gls_parcel_date,
                                    ).format('HH:mm:ss')}
                                </span>
                            </div>
                        </div>
                    )}
                </section>
            )}
            {modalOpen && (
                <ConfirmModal
                    title={getLangText('wantToRemoveLabelLabel')}
                    SubTitle={() => (
                        <>{getLangText('actionCannotBeUnDoneLabel')}</>
                    )}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            )}
        </>
    );
};

export default GlsLabel;
