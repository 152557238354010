export const RouterPaths = {
    Admin: '/admin/',
    AdminPremiumShops: '/admin/oplacone',
    AdminLostShops: '/admin/nieoplacone',

    AdminShopsList: '/admin/sklepy/',
    AdminShopifyList: '/admin/shopify/',

    AdminDemoList: '/admin/demo',

    AdminClientsList: '/admin/klienci',

    AdminNewsList: '/admin/posts/news',
    AdminNewsForm: '/admin/posts/news/:id/',
    AdminPostCategories: '/admin/posts/categories',
    AdminPostTranslations: '/admin/posts/translations',
    AdminPostCategory: '/admin/posts/categories/:id/',
    AdminPostSettings: '/admin/posts/settings',
    AdminPostSubSettings: '/admin/posts/settings/sub-categories',
    AdminPostTagSettings: '/admin/posts/settings/tags',
    AdminPostSubSettingsForm: '/admin/posts/settings/sub-categories/:id/',
    AdminPostTagsSettingsForm: '/admin/posts/settings/tags/:id/',
    AdminPostSettingsForm: '/admin/posts/settings/:id/',

    AdminStatsOverall: '/admin/statystyki-mobilne',
    AdminStatsSignups: '/admin/statystyki-mobilne/rejestracje',
    AdminStatsSales: '/admin/statystyki-mobilne/sprzedaz',

    AdminMarketing: '/admin/marketing',
    AdminMarketingFacebook: '/admin/marketing/facebook',
    AdminMarketingFacebookClients: '/admin/marketing/facebook/clients',
    AdminMarketingFacebookClient: '/admin/marketing/facebook/clients/:id',
    AdminMarketingFacebookAdvertisements: '/admin/marketing/facebook/advertisements',
    AdminMarketingFacebookAdvertisement: '/admin/marketing/facebook/advertisements/:id',

    AdminMarketingSms: '/admin/marketing/sms',
    AdminMarketingSmsClients: '/admin/marketing/sms/clients',
    AdminMarketingSmsClient: '/admin/marketing/sms/clients/:id',
    AdminMarketingSmsAdvertisements: '/admin/marketing/sms/advertisements',
    AdminMarketingSmsAdvertisement: '/admin/marketing/sms/advertisements/:id',

    AdminInvoices: '/admin/faktury',

    AdminUsers: '/admin/uzytkownicy',
    AdminUser: '/admin/uzytkownicy/:id',
    AdminUserOrders: '/admin/uzytkownicy/:shopId/zamowienia',
    AdminOrderChangeLog:
        '/admin/uzytkownicy/:shopId/zamowienia/:orderId/changelog',

    AdminLeads: '/admin/leady',
    AdminFreshMan: '/admin/swiezaki',
    AdminLiveNotification: '/admin/live-notification',
    AdminGlobalAlert: '/admin/global-alert',

    AdminSubscription: '/admin/subskrypcja',
    AdminSubscriptionFunctionalities: '/admin/subskrypcja/funkcjonalnosci',
    AdminSubscriptionFunctionality: '/admin/subskrypcja/funkcjonalnosci/:id/',
    AdminSubscriptionPricing: '/admin/subskrypcja/cennik',
    AdminSubscriptionPricingForm: '/admin/subskrypcja/cennik/:id/',

    AdminSales: '/admin/sales/',

    AdminSalesDashboard: '/admin/sales/dashboard/',
    AdminSalesTrialsList: '/admin/sales/dashboard/trials',
    AdminSalesCloseToLostList: '/admin/sales/dashboard/closeToLost',
    AdminSalesFinishedTrialList: '/admin/sales/dashboard/finishedTrial',
    AdminSalesFreshmanList: '/admin/sales/dashboard/freshman',
    AdminSalesLeadsList: '/admin/sales/dashboard/leads',
    AdminSalesLostList: '/admin/sales/dashboard/lost',
    AdminSalesClientsList: '/admin/sales/dashboard/clients',

    AdminSalesMachineMessages: '/admin/sales/messages',
    AdminSalesMachineMessagesSend: '/admin/sales/messages/:id',

    AdminSalesSegment: '/admin/sales/segment/',
    AdminSalesSegmentSelected: '/admin/sales/segment/:id/',

    AdminSalesStats: '/admin/sales/stats',
    AdminSalesStatsEdit: '/admin/sales/stats/:id',
    AdminSalesHelp: '/admin/sales/help',
    AdminSalesRanking: '/admin/sales/ranking',

    AdminCustomerService: '/admin/customer-service/',
    AdminCustomerServiceQuests: '/admin/customer-service/quests',
    AdminCustomerServiceQuest: '/admin/customer-service/quests/:id',
};
