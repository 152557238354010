import React from 'react';
import { useSelector } from 'react-redux';
import useLang from '../../../../src_shared/hooks/useLang';
import Field from '../../../../src_shared/components/form/field/Field';
import useFormLocal from '../../../../src_shared/hooks/useFormLocal';
import SwitchField from '../../../../src_shared/components/form/switchField/SwitchField';
import ComponentLoader from '../../../../src_shared/components/ui/componentLoader/ComponentLoader';
import Loader from '../../../../src_shared/components/loader/Loader';
import { services } from '../../../RestServices';
import SettingsPostaBezHranicModel from '../../../../modules/models/settings/SettingsPostaBezHranicModel';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../../routes/RouterPath';

const PostaBezHranic = () => {
    const { waitingForResponse } = useSelector((state) => state.session);
    const { getLangText } = useLang();

    const [{ onSave, isWaiting, getFieldProps, isLoading }] = useFormLocal({
        rest: services.API_SETTINGS_POSTA_BEZ_HRANIC,
        model: SettingsPostaBezHranicModel,
    });

    if (waitingForResponse || isLoading) {
        return <ComponentLoader />;
    }

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>
                            {getLangText('settings_nav_integrations')}
                        </Link>
                    </li>
                    <li className="active">Pošta bez hranic</li>
                </ul>
            </div>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <img
                                    style={{
                                        marginLeft: 0,
                                        height: '20px',
                                        width: 'auto',
                                    }}
                                    src="/assets/images/posta-bez-hranic.webp"
                                    alt="Pošta bez hranic"
                                />
                            </h1>
                            <div className="description">
                                {getLangText('realizeOrdersFasterLabel')}
                            </div>
                        </div>
                        <div className="settings-form pt-3">
                            <div className="steps-wrapper">
                                <div className="step border-step">
                                    <div className="step-header">
                                        <div className="number">1</div>
                                        <div className="title d-block">
                                            <a
                                                href="https://www.postabezhranic.cz/"
                                                target="_blank"
                                                rel="noreferrer">
                                                <img
                                                    style={{
                                                        marginLeft: 0,
                                                        height: '20px',
                                                        width: 'auto',
                                                    }}
                                                    src="/assets/images/posta-bez-hranic.webp"
                                                    alt="Pošta bez hranic"
                                                />
                                            </a>
                                            <div>
                                                {getLangText(
                                                    'settings_integrations_furgonetka_login_1',
                                                )}
                                            </div>
                                        </div>
                                        <a
                                            target="_blank"
                                            href="https://www.postabezhranic.cz/"
                                            className="button primary small-size ml-auto"
                                            rel="noreferrer">
                                            {getLangText(
                                                'settings_integrations_fakturownia_description_4',
                                            )}
                                        </a>
                                    </div>
                                </div>
                                <div className="step border-step mt-3 pb-lg-5 pb-3">
                                    <div className="step-header align-items-start">
                                        <div className="number">2</div>
                                        <div className="title flex-grow-1 d-block">
                                            <div className="mb-3">
                                                {getLangText({
                                                    key: 'copyFromPostaBezHranicLabel',
                                                    data: ['Pošta bez hranic'],
                                                })}
                                            </div>
                                            <Field
                                                {...getFieldProps('user_id')}
                                                label={getLangText(
                                                    'userIdLabel',
                                                )}
                                            />
                                            <Field
                                                {...getFieldProps('api_key')}
                                                label={getLangText(
                                                    'apiKeyLabel',
                                                )}
                                            />
                                            <div className="d-flex align-items-center">
                                                <SwitchField
                                                    {...getFieldProps('active')}
                                                    label={getLangText(
                                                        'activeLabel',
                                                    )}
                                                    id="active"
                                                    className="switch-form inline-label big-label light-version mb-0 mt-0"
                                                />
                                                <button
                                                    onClick={onSave}
                                                    type="button"
                                                    className={`button primary ml-auto w-auto ${isWaiting ? 'loading-button' : ''}`}>
                                                    {isWaiting && (
                                                        <Loader isLoading />
                                                    )}
                                                    {getLangText('saveButton')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostaBezHranic;
