import useLang from '../../../../../../../src_shared/hooks/useLang';
import React from 'react';
import useList from '../../../../../../../src_shared/list/useList';
import ListActions from './ListActions';
import { getStateRoot } from './listReducer';
import SearchInput from '../../../../../shared/components/form/SearchInput';
import Pagination from '../../../../../shared/components/pagination/Pagination';
import Table from './components/Table/Table';

const SmsReceiversList = () => {
    const { getLangText } = useLang();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage,
        },
        list: { items, isWaiting },
        search,
        sort: { updateSortOptions, getSortClass },
        remove: { askForRemoveItem, markedToRemove },
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <>
            <div className="table-list-wrapper">
                <div
                    style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                    className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part">
                            <div>
                                <h1 className="title mb-sm-0 mb-2">
                                    <span>
                                        {getLangText('marketingSMSReceivers')}
                                    </span>
                                    {totalItems > 0 && (
                                        <div className="badge">
                                            {totalItems}
                                        </div>
                                    )}
                                </h1>
                                <p className="text-[12px] text-[#6B7280] font-medium">
                                    {getLangText(
                                        'marketingSMSReceiversDescription',
                                    )}
                                </p>
                            </div>
                            <SearchInput
                                className="ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
        </>
    );
};

export default SmsReceiversList;
