import { useEffect, useState } from 'react';
import { selmoUrl } from '../api/api';
import { useDispatch } from 'react-redux';
import { getGETParamsUrl } from '../list/ListActions';
import api from '../../services/axios/axios';
import { showAlert } from '../../components/app/shared/components/alert/AlertActions';

const useFetchData = (config) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const {
    rest = '',
    initialData = {},
    initialAutoLoad = true,
    extraParams = {},
    method = 'get',
    afterSubmit = () => {},
    onError = (e) => {
      setIsError(true);
      setErrorMessage(e?.response?.data?.message);
      dispatch(
        showAlert(
          e.response?.data?.message || 'sthWentWrongWarning',
          '',
          'alert-fail'
        )
      );
    },
  } = config;

  const [isLoading, setIsLoading] = useState(initialAutoLoad);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(initialData);

  const fetchData = async (search) => {
    const params = extraParams;
    setIsError(false);
    setIsLoading(true);

    if (typeof search === 'string') {
      params.superSearch = search;
    }

    try {
      const { data } = await api[method](
        `${selmoUrl}/${rest}${getGETParamsUrl(params)}`
      );
      setData(data?.item || data?.items);
      afterSubmit(data?.item || data?.items);
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const search = {
    updateSearchText: setSearchText,
    searchText,
    callSearch: fetchData,
  };

  useEffect(() => {
    if (initialAutoLoad) {
      fetchData();
    }
  }, [initialAutoLoad]);

  return [
    {
      data,
      isLoading,
      isError,
      fetchData,
      search,
      setData,
      errorMessage,
      setIsLoading,
    },
  ];
};

export default useFetchData;
