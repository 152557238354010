import Model from '../../../src_shared/modules/model/Model';

export class MarketingPurchaseModel extends Model {
    getModel() {
        return {
            country_id: 103,
            selected_payment: 'p24',
        };
    }

    buildDTO(data) {
        if (data.finished) {
            return {
                phone: data.phone,
                email: data.email,
            };
        }

        return {
            invoice_data: {
                country_id: 103,
            },
            country_id: 103,
            selected_payment: data.selected_payment,
            with_name: data.with_name,
        };
    }
}

export default new MarketingPurchaseModel();
