import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";

const NewClients = ({values, isLoading}) => {
    const {getLangText} = useLang();

    return (
        <div className="ml-auto">
            <div className="p-[12px] border rounded-big flex items-center">
                <div
                    class="w-[29px] min-w-[29px] bg-[#f0faff] text-[#5FA5F9] text-[10px] flex items-center justify-center h-[29px] rounded-full">
                    <i class="icon-profile "/>
                </div>
                <div className="font-bold text-[#4b5563] ml-1 text-xs">
                    {getLangText('newClients')}:
                </div>
                {isLoading ?
                    <div
                        className="animate-pulse mt-auto h-[20px] bg-gray-200 rounded-medium dark:bg-gray-700 w-[10px]"/> :
                    <div className="font-bold text-sm ml-1">
                        {values.new_clients_total}
                    </div>
                }
            </div>
        </div>
    );
};

export default NewClients;