import React, {useState} from "react";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import Table from "./components/Table";
import useLang from "../../../../src_shared/hooks/useLang";
import SearchInput from "../../shared/components/form/SearchInput";
import Pagination from "../../shared/components/pagination/Pagination";
import useList from "../../../../src_shared/list/useList";
import ConfirmRemoveModal from "../../../../src_shared/components/modal/ConfirmRemoveModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import SelmoProBoxWrapper from "../../shared/components/SelmoProBox/SelmoProBox";
import FirstStepPage from "./components/FirstStepPage";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {RouterPaths} from "../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import {
    SHOP_ORIGIN_EN,
    SHOP_ORIGIN_PL,
} from "../../shared/enums/ShopOrigins";
import ImportLivesButton from "./components/ImportLivesButton";
import {TASHAS_JEWELS_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const LivesOverlayList = () => {
    const {getLangText} = useLang();
    const {refresh_token_link} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData} = useSelector((state) => state.session);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove, confirmItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const preparedLang = () => {
        if (userData.origin === SHOP_ORIGIN_PL) return SHOP_ORIGIN_PL
        return SHOP_ORIGIN_EN;
    }

    const HeaderComponent = () => (
        <div className="px-6 sm:px-10 sm:-mt-10 mb-6 sm:mb-10 -mt-4">
            <div className="relative rounded-[10px] overflow-hidden">
                <img src={`/assets/images/multiLanguage/${preparedLang()}/liveOverlay/locked-banner.webp`} alt=""/>
                <img src="/assets/images/svg/selmo-pro-white.svg" className="absolute top-3 left-3" alt=""/>
            </div>
        </div>
    )

    if (isWaiting) return <ComponentLoader/>

    if (!items?.length && !!+userData.selmo_pro_active) return <FirstStepPage refresh_token_link={refresh_token_link}/>

    return (
        <SelmoProBoxWrapper
            title="overlayForLive"
            desc="overlayForLiveDesc"
            variant={2}
            HeaderComponent={HeaderComponent}
            redirect={RouterPaths.SelmoProOverlay}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getLangText('overlay')} - Selmo`}</title>
            </Helmet>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className={`top-part ${(+userData.id === TASHAS_JEWELS_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) ? 'flex-wrap' : ''}`}>
                            <h1 className="title mb-sm-0 mb-2 sm:grow-0 grow">
                                {getLangText('overlay')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <SearchInput
                                className="sm:ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            {(+userData.id === TASHAS_JEWELS_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                                <ImportLivesButton/>
                            }
                            <OverlayTrigger
                                placement='auto-end'
                                overlay={
                                    <Tooltip
                                        className="small-size"
                                        id="refresh-price"
                                    >
                                        {getLangText('createOnlyFromMobileApp')}
                                    </Tooltip>
                                }
                            >
                                <button
                                    disabled={true}
                                    className="button not-active disabled primary small-size ml-2"
                                    type="button"
                                >
                                    {getLangText('addButton')}
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmRemoveModal
                    show={isModalVisible}
                    title={getLangText('delete_modal_accept')}
                    subTitle={`${markedToRemove?.shelf}`}
                    hide={cancelItemRemoval}
                    confirm={confirmItemRemoval}
                    dialogClassName="w-420"
                />
            }
        </SelmoProBoxWrapper>
    );
};

export default LivesOverlayList;
