import React, {useState} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import {services} from "../../../../../RestServices";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../../routes/RouterPath";
import Completing from "./components/completing/Completing";
import {parseParams} from "../../../../../../src_shared/list/ListActions";
import AssigningProducts from "./components/assigningProducts/AssigningProducts";
import {useSelector} from "react-redux";
import {
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    WIKA_EWA_FASHION_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";

const ORDER_CARD_SCANNER_COMPLETING_VIEW = 'ORDER_CARD_SCANNER_COMPLETING_VIEW'
const ORDER_CARD_SCANNER_ASSIGNING_PRODUCTS_VIEW = 'ORDER_CARD_SCANNER_ASSIGNING_PRODUCTS_VIEW'

const tabs = [
    {id: ORDER_CARD_SCANNER_COMPLETING_VIEW, label: 'completing'},
    {id: ORDER_CARD_SCANNER_ASSIGNING_PRODUCTS_VIEW, label: 'addingProduct'},
]

const OrderCard = () => {
    const {id} = useParams();
    const {getLangText} = useLang();
    const {search} = useLocation();
    const query = parseParams(search);
    const defaultParamsView = (+query.defaultView === 1 || !query.defaultView) ? ORDER_CARD_SCANNER_COMPLETING_VIEW : ORDER_CARD_SCANNER_ASSIGNING_PRODUCTS_VIEW
    const [activeView, setActiveView] = useState(defaultParamsView);
    const {userData} = useSelector((state) => state.session);

    const canAddProducts = (+userData.id !== CHIQUE_SHOP_ID && +userData.id !== WIKA_EWA_FASHION_ID && +userData.id !== BUTIK_MADAME_SHOP_ID)

    const [{values, fetchData, isLoading}] = useFormLocal({
        rest: `${services.API_WAREHOUSE_ORDER}/${id}?clickAction=${query.clickAction || '0'}`,
        model: new Model(),
    });

    return (
        <div className="">
            <div className="header-bar breadcrumbs-in-react-app">
                <Link
                    to={!!+query.fromShelf ? `${RouterPaths.Storage}/${values.shelf_id || values.size_shelf_id}` : RouterPaths.StorageScanner}
                    className="button back-to-panels mb-lg-3 mt-lg-0">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            <div className="grid sm:grid-cols-5 sm:gap-4">
                <div className="sm:col-span-3">
                    <div className="bg-[white] rounded-[5px] px-6 relative">
                        <div className={`font-bold pt-3 -mx-1 sm:-mx-2 text-lg ${!canAddProducts ? 'mb-3' : ''}`}>
                            {getLangText(activeView === ORDER_CARD_SCANNER_COMPLETING_VIEW ? 'completing' : 'addingProduct')}
                        </div>
                        {canAddProducts &&
                            <div className="tabs-wrapper mb-3 -mx-6">
                                {tabs.map((i) => (
                                    <button
                                        type="butotn"
                                        key={i.id}
                                        onClick={() => setActiveView(i.id)}
                                        className={i.id === activeView ? 'active' : ''}
                                    >
                                        {getLangText(i.label)}
                                    </button>
                                ))}
                            </div>
                        }
                        {activeView === ORDER_CARD_SCANNER_COMPLETING_VIEW &&
                            <Completing
                                fetchData={fetchData}
                                isLoadingOrder={isLoading}
                                values={values}
                            />
                        }
                        {activeView === ORDER_CARD_SCANNER_ASSIGNING_PRODUCTS_VIEW &&
                            <AssigningProducts
                                fetchData={fetchData}
                                isLoadingOrder={isLoading}
                                values={values}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderCard;
