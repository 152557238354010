import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import LiveSaleItem from "./Item";
import {useSelector} from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";

const LiveSale = ({items = [], currency, live_views, values}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const viewsContent = () => (
        <div
            className="ml-auto px-2 py-1 rounded-sm bg-[rgba(0,0,0,0.8)] text-[#fff] font-bold text-xs flex items-center">
            <div
                className="relative animate-showAndHide flex h-[3px] w-[3px] min-w-[3px] mr-0.5">
                <div className="absolute inline-flex h-full w-full rounded-full bg-[#fff]"/>
            </div>
            <i className="icon-clients mr-1"/>
            {live_views}
        </div>
    )

    return (
        <div className="default-shadow-box summary-box loader-parent mt-3">
            <div className="flex items-center">
                <div>
                    <div className="font-bold">
                        {getLangText('liveSale')}
                    </div>
                    <div className="text-desc text-sm">
                        {getLangText('bellowFindCurrentPurchases')}
                    </div>
                </div>
                {!!+live_views &&
                    ((!+values?.is_mobile_app_live || !values?.facebook_live_id) ?
                            viewsContent() :
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id="live_views">
                                        <div>
                                            Facebook:&nbsp;
                                            <strong>{values.fb_live_views}</strong>
                                        </div>
                                        <div>
                                            {getLangText('selmoApp')}:&nbsp;
                                            <strong>{values.mobile_live_views}</strong>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                {viewsContent()}
                            </OverlayTrigger>
                    )
                }
            </div>
            <div className="mt-3 space-y-2 sm:grow-0 grow">
                {!!items.length ?
                    items.map((item) => (
                        <LiveSaleItem
                            origin={userData.origin}
                            key={item.id} item={item} currency={currency}/>
                    )) :
                    <div className="text-center py-3">
                        <div className="w-[80px] mx-auto">
                            <img
                                className="w-full"
                                src="/assets/images/empty/orders-empty.svg"
                                alt=""
                            />
                        </div>
                        <div
                            className="font-bold text-sm whitespace-pre-wrap mt-2"
                            dangerouslySetInnerHTML={{__html: getLangText('hereWillBeSoldProducts')}}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default LiveSale;
