import React, { useState, useEffect } from 'react';

const Slider = ({
    min = 0,
    max = 100,
    value,
    defaultValue = 50,
    step = 1,
    onChange,
    disabled,
}) => {
    const [internalValue, setInternalValue] = useState(
        value !== undefined ? value : defaultValue,
    );

    useEffect(() => {
        if (value !== undefined) {
            setInternalValue(value);
        }
    }, [value]);

    const handleChange = (e) => {
        const newValue = parseFloat(e.target.value);
        setInternalValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    const fillPercentage = ((internalValue - min) / (max - min)) * 100;

    return (
        <div className="relative w-full h-[23px]">
            <div className="absolute top-1/2 left-0 right-0 h-[8px] -translate-y-1/2 bg-[#e5e7eb] rounded-full overflow-hidden">
                <div
                    className={`absolute top-0 left-0 h-full bg-[#5fa5fa] rounded-full`}
                    style={{ width: `${fillPercentage}%` }}
                />
            </div>
            <input
                type="range"
                min={min}
                max={max}
                value={internalValue}
                step={step}
                onChange={handleChange}
                className={`absolute top-0 left-0 w-full h-full opacity-0 z-10 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                disabled={disabled}
            />
            <div
                className={`absolute top-1/2 -translate-y-1/2 w-[23px] h-[23px] pointer-events-none ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                style={{ left: `calc(${fillPercentage}% - 12px)` }}>
                <img
                    src="/assets/images/svg/marketing/slider-thumb.svg"
                    alt="Slider thumb"
                    className="w-full h-full"
                />
            </div>
        </div>
    );
};

export default Slider;
