import React, {useCallback, useEffect, useRef, useState} from 'react';
import ClientAvatar from "../../../shared/Avatar.jsx";
import ClientHintDropdown from "./CustomerSelect.jsx";
import axios from "axios";
import debounce from "lodash.debounce";
import {selmoUrl} from "../../../../../../src_shared/api/api.js";
import optimizedMemo from "../../../../../../src_shared/helpers/optimizedMemo.js";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";
import {useSelector} from "react-redux";
import {GRACEFULLY_MADE__SHOP_ID, LALEE_TRENDS_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const ClientInput = ({
                         updateField,
                         customer,
                         customers,
                         extraClass = '',
                         rest = '/apilive/clients',
                         hintItem,
                         label,
                         clientAvatar,
                         hasCustomerError = false,
                         updateProductOnNameChange = () => {
                         },
                         isLiveFinished,
                         customerId,
                         showErrorAlert,
                         liveId,
                         lastCartNumber,
                         index,
                     }) => {
    const inputRef = useRef(null)
    const [hints, setHints] = useState([]);
    const [hintsVisible, setHintsVisible] = useState(null);
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const hintDropdownFocus = (e) => {
        if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
            document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
        }
    }

    const searchHints = async (name) => {
        if (name.length > 0) {
            try {
                const {data} = await api.get(`${selmoUrl}${rest}?search=${name}&live_id=${liveId}`)
                setHints(data)
                setHintsVisible(customerId)

            } catch (e) {
                setHints([])
                console.warn('Cannot get clients')
            }
        }
    }

    const debouncedSave = useCallback(
        debounce((name) => searchHints(name), 500),
        []
    );

    const removeClient = () => {
        updateField('photo', null)
        updateField('name', '')
        updateField('shop_client_id', null)
        updateField('notSelmoClient', false)
        updateField('facebook_id', null)
        updateField('social_type', null)
        updateField('cart_number', null)
        setTimeout(() => {
            inputRef.current?.focus()
        })
    }

    const onCustomerNameChange = (e) => {
        updateField('name', e.target.value)
        debouncedSave(e.target.value)
    }

    const isError = hasCustomerError && !customer.shop_client_id;

    const customerInputFormClassNames = () => {
        const errorClass = isError ? 'error-group' : '';
        const customerNumberClass = customer.shop_client_id ? 'with-client-number' : '';
        const errorAlertClass = showErrorAlert ? 'with-error-alert' : '';
        return `form-group ${extraClass} ${errorClass} ${customerNumberClass} ${errorAlertClass}`
    }

    const getInputValue = () => {
        if (!!customer.cart_number) {
            return `${customer.cart_number}. ${customer.name}`
        }
        if (!!customer.future_cart_number) {
            return `${customer.future_cart_number}. ${customer.name}`
        }
        return customer.name;
    }


    useEffect(() => {
        if (customer.shop_client_id && !customer.cart_number && (+userData.id === GRACEFULLY_MADE__SHOP_ID || +userData.id === LALEE_TRENDS_SHOP_ID)) {
            const existingDuplicate = customers.find(i => i.shop_client_id === customer.shop_client_id && i.future_cart_number);

            if (existingDuplicate) {
                updateField('future_cart_number', existingDuplicate?.future_cart_number);
            } else {
                const newFutureCartNumber = (
                    +lastCartNumber + (+index + 1) - +customers?.filter((i) => i.cart_number)?.length
                );
                updateField('future_cart_number', newFutureCartNumber);
            }
        }
    }, [customer.shop_client_id, customer.cart_number]);


    // useEffect(() => {
    //     if (customer.shop_client_id && !customer.cart_number && +userData.id === GRACEFULLY_MADE__SHOP_ID) {
    //         updateField('future_cart_number', (+lastCartNumber + (+index + 1) - +customers?.filter((i) => i.cart_number)?.length))
    //     }
    // }, [customer.shop_client_id, customer.cart_number])

    return (
        <div className="tooltip-parent">
            {isError &&
                <div className="tooltip-content">
                    {getLangText('selectClientFromListLabel')}
                </div>
            }
            <div className={customerInputFormClassNames()}>
                <label className="control-label">{getLangText(label) || label}</label>
                {clientAvatar(customer)}
                <input
                    ref={inputRef}
                    onChange={(e) => onCustomerNameChange(e)}
                    onKeyDown={hintDropdownFocus}
                    type="text"
                    value={getInputValue()}
                    tabIndex={customer.tabIndex}
                    autoFocus={customer.tabIndex > 1}
                    className={`form-control ${isLiveFinished ? 'order-created' : ''}`}
                    autoComplete="off"
                    required
                    disabled={isLiveFinished || !!customer.shop_client_id || customer.notSelmoClient}
                />
                {customer.shop_client_id &&
                    <div className="client-number">
                        {getLangText('clientNumberFullLabel')}: {customer.shop_client_id}
                    </div>
                }
                {!isLiveFinished && (!!customer.shop_client_id || customer.notSelmoClient) &&
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id="remove-product">
                                {getLangText('clientsRemoveClientTitle')}
                            </Tooltip>
                        }
                    >
                        <button
                            type="button"
                            className="remove-product"
                            onClick={removeClient}
                        >
                            <i className="icon-cross"/>
                        </button>
                    </OverlayTrigger>
                }
                {(isError && showErrorAlert) &&
                    <div className="error-text">
                        {getLangText('fieldIsRequiredLabel')}
                    </div>
                }
                <ClientHintDropdown
                    onChange={updateField}
                    customer={customer}
                    hints={hints}
                    setHints={setHints}
                    hintsVisible={hintsVisible}
                    setHintsVisible={setHintsVisible}
                    hintItem={hintItem}
                    updateProductOnNameChange={updateProductOnNameChange}
                    customerId={customerId}
                />
            </div>
        </div>
    );
};

ClientInput.defaultProps = {
    label: 'clientLabel',
    clientAvatar: (client) => (
        <ClientAvatar
            photo={client.photo}
            type={client.social_type}
        />
    ),
    showErrorAlert: false,

}

export default optimizedMemo(ClientInput);
