import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouterPaths } from '../RouterPath';
import SettingsProductCodes from '../../app/settings/productCodes/SettingsProductCodes';
import Alert from '../../app/shared/components/alert/Alert';
import SenderDataForm from '../../app/settings/integrations/shippyPro/senderDataForm/SenderDataForm';
import Templates from '../../app/settings/integrations/shippyPro/templates/Templates';
import TemplateForm from '../../app/settings/integrations/shippyPro/templates/TemplateForm';
import SettingsLayout from '../../settings/Layout';
import Dashboard from '../../settings/dashboard/Dashboard';
import General from '../../settings/general/General';
import Terms from '../../settings/terms/Terms';
import DiscountCodes from '../../settings/discountCodes/list/DiscountCodes';
import DiscountCode from '../../settings/discountCodes/form/DiscountCode';
import Platform from '../../settings/platform/Platform';
import MessengerTemplates from '../../settings/messengerTemplates/MessengerTemplates';
import PaymentsDashboard from '../../settings/payments/page/Page';
import BlueMediaLanding from '../../settings/payments/blueMedia/landing/Landing';
import BlueMediaConfiguration from '../../settings/payments/blueMedia/configuration/Configuration';
import TransferPage from '../../settings/payments/transfer/Page';
import EditTransfer from '../../settings/payments/transfer/editTransfer/EditTransfer';
import Pickup from '../../settings/payments/pickup/Pickup';
import ShopPickup from '../../settings/payments/shopPickup/ShopPickup';
import Paypal from '../../settings/payments/paypal/Paypal';
import ShippingPage from '../../settings/shippings/Page';
import ShippingFormPage from '../../settings/shippings/form/ShippingFormPage';
import ShippingCountryFormPage from '../../settings/shippings/shippingCountry/ShippingCountryFormPage';
import IntegrationDashboard from '../../settings/integrations/dashboard/Dashboard';
import Fakturownia from '../../settings/integrations/fakturownia/Fakturownia';
import Furgonetka from '../../settings/integrations/furgonetka/Furgonetka';
import InPostSettings from '../../settings/integrations/inPost/InPostSettings';
import PackagesList from '../../settings/packages/list/PackagesList';
import PackagesFormPage from '../../settings/packages/form/PackagesFormPage';
import VoiceMessagesList from '../../settings/voiceMessages/list/VoiceMessagesList';
import VoiceMessagesFormPage from '../../settings/voiceMessages/form/VoiceMessagesFormPage';
import PasswordForm from '../../settings/account/password/PasswordForm';
import MetaDashboard from '../../settings/meta/dashboard/Dashboard';
import FacebookSettings from '../../settings/meta/facebook/Facebook';
import InstagramSettings from '../../settings/meta/instagram/Instagram';
import Configuration from '../../app/settings/integrations/shippyPro/configuration/Configuration';
import Stripe from '../../settings/payments/stripe/Stripe';
import AutomaticallyNotifications from '../../settings/automaticallyNotifications/AutomaticallyNotifications';
import SettingsPaxy from '../../settings/integrations/paxy/Paxy';
import Lusopay from '../../settings/payments/lusopay/Lusopay';
import MondialRelayConfiguration from '../../settings/integrations/mondialRelay/configuration/Configuration';
import MondialRelaySenderDataForm from '../../settings/integrations/mondialRelay/senderDataForm/SenderDataForm';
import Settlements from '../../settings/settlements/Page';
import OrlenConfiguration from '../../settings/integrations/orlen/configuration/Configuration';
import OrlenSenderDataForm from '../../settings/integrations/orlen/senderDataForm/SenderDataForm';
import TeamRoute from './teamRoute/TeamRoute';
import ShipmondoConfiguration from '../../settings/integrations/shipmondo/configuration/Configuration';
import ShipmondoDataForm from '../../settings/integrations/shipmondo/senderDataForm/SenderDataForm';
import MobilePay from '../../settings/payments/mobilePay/MobilePay';
import AutomaticTransferConfiguration from '../../settings/payments/automaticTransfer/AutomaticTransfer';
import WoocommerceConfiguration from '../../settings/integrations/woocommerce/configuration/Configuration';
import { USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY } from '../../app/shared/enums/UserPermissionsTypes';
import UserPermissionRoute from '../UserPermissionRoute';
import DpdConfiguration from '../../settings/integrations/dpd/configuration/Configuration';
import DpdTemplates from '../../settings/integrations/dpd/templates/Templates';
import DpdTemplateForm from '../../settings/integrations/dpd/templates/TemplateForm';
import GlsConfiguration from '../../settings/integrations/gls/configuration/Configuration';
import PaypalAutomation from '../../settings/payments/paypal/PaypalAutomation';
import AutomaticTransferLanding from '../../settings/payments/automaticTransfer/landing/Landing';
import AutomationsRoute from './automationsRoute/AutomationsRoute';
import MobileApp from '../../settings/mobileApp/MobileApp';
import PrestaShopConfiguration from '../../settings/integrations/prestaShop/configuration/Configuration';
import QRProductSettings from '../../settings/qrCodes/qrProduct/QRProduct';
import SelmoProRoute from '../SelmoProRoute';
import CustomIntegration from '../../settings/integrations/custom/CustomIntegration';
import GlsFranceConfiguration from '../../settings/integrations/glsFrance/configuration/Configuration';
import ShopifyConfiguration from '../../settings/integrations/shopify/configuration/Configuration';
import ShopifyConnecting from '../../settings/integrations/shopify/connecting/Connecting';
import GeneralDashboard from '../../settings/general/dashboard/Dashboard';
import PaymentsShipmentsDashboard from '../../settings/shippings/dashboard/Dashboard';
import ProfileDashboard from '../../settings/account/dashboard/Dashboard';
import RedirectWithQuery from '../../../src_shared/helpers/RedirectWithQuery';
import TabsWrapper from '../../settings/components/TabsWrapper';
import ColissimoConfiguration from '../../settings/integrations/colissimo/configuration/Configuration';
import ColissimoDataForm from '../../settings/integrations/colissimo/senderDataForm/SenderDataForm';
import UspsConfiguration from '../../settings/integrations/usps/configuration/Configuration';
import UspsSenderDataForm from '../../settings/integrations/usps/senderDataForm/SenderDataForm';
import UspsTemplateForm from '../../settings/integrations/usps/templates/TemplateForm';
import UspsTemplates from '../../settings/integrations/usps/templates/Templates';
import ProductsImport from '../../settings/productsImport/ProductsImport';
import PrivateTestRoute from '../TestRoute';
import ChangeFanpage from '../../settings/changeFanpage/ChangeFanpage';
import PostaBezHranic from '../../settings/integrations/postaBezHranic/PostaBezHranic';

const SettingsRoute = () => {
    return (
        <UserPermissionRoute
            path={[RouterPaths.Settings, RouterPaths.SettingsOld]}
            allowedPermissions={[USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY]}>
            <SettingsLayout>
                <Switch>
                    <Route
                        exact
                        path={RouterPaths.SettingsGeneral}
                        component={General}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsShop}
                        component={GeneralDashboard}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsPaymentsShipments}
                        component={PaymentsShipmentsDashboard}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsTerms}
                        component={Terms}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsProductCodes}
                        component={SettingsProductCodes}
                    />
                    <Route path={RouterPaths.SettingsDiscountCodes}>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.SettingsDiscountCodes}
                                component={DiscountCodes}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsDiscountCodesEditFull}
                                component={DiscountCode}
                            />
                        </Switch>
                    </Route>
                    <Route path={RouterPaths.SettingsPackages}>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.SettingsPackages}
                                component={PackagesList}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPackagesEditFull}
                                component={PackagesFormPage}
                            />
                        </Switch>
                    </Route>
                    <Route path={RouterPaths.SettingsVoiceMessage}>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.SettingsVoiceMessage}
                                component={VoiceMessagesList}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsVoiceMessageEditFull}
                                component={VoiceMessagesFormPage}
                            />
                        </Switch>
                    </Route>
                    <Route
                        exact
                        path={RouterPaths.SettingsPlatform}
                        component={Platform}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsAutomaticallyNotifications}
                        component={AutomaticallyNotifications}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsProductsImport}
                        component={ProductsImport}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsMessenger}
                        component={MessengerTemplates}
                    />
                    <PrivateTestRoute
                        exact
                        path={RouterPaths.SettingsChangeFanPage}
                        component={ChangeFanpage}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsSettlements}
                        component={Settlements}
                    />
                    <Route
                        path={[
                            RouterPaths.SettingsPayments,
                            RouterPaths.SettingsPaymentsOld,
                        ]}>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.SettingsPayments}
                                component={PaymentsDashboard}
                            />
                            <Route
                                path={
                                    RouterPaths.SettingsPaymentsStripeReAuthOld
                                }>
                                <RedirectWithQuery
                                    path={RouterPaths.SettingsPaymentsStripe}
                                />
                            </Route>
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsStripe}
                                component={Stripe}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsOnlineLanding}
                                component={BlueMediaLanding}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsOnline}
                                component={BlueMediaConfiguration}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsAutomaticTransfer}
                                component={AutomaticTransferConfiguration}
                            />
                            <Route
                                exact
                                path={
                                    RouterPaths.SettingsAutomaticTransferLanding
                                }
                                component={AutomaticTransferLanding}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsTransfer}
                                component={TransferPage}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsTransferEdit}
                                component={EditTransfer}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsPickup}
                                component={Pickup}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsShopPickup}
                                component={ShopPickup}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsPaypal}
                                component={Paypal}
                            />
                            <Route
                                exact
                                path={
                                    RouterPaths.SettingsPaymentsPaypalAutomation
                                }
                                component={PaypalAutomation}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsLusopay}
                                component={Lusopay}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsPaymentsMobilePay}
                                component={MobilePay}
                            />
                        </Switch>
                    </Route>
                    <Route path={RouterPaths.SettingsShipping}>
                        <Switch>
                            <Route
                                path={RouterPaths.SettingsShipping}
                                exact
                                component={ShippingPage}
                            />
                            <Route
                                path={RouterPaths.SettingsShippingEditFull}
                                exact
                                component={ShippingFormPage}
                            />
                            <Route
                                path={
                                    RouterPaths.SettingsShippingCountryEditFull
                                }
                                exact
                                component={ShippingCountryFormPage}
                            />
                        </Switch>
                    </Route>
                    <Route
                        path={[
                            RouterPaths.SettingsIntegration,
                            RouterPaths.SettingsIntegrationOld,
                        ]}>
                        <Switch>
                            <Route
                                path={RouterPaths.SettingsIntegration}
                                exact
                                component={IntegrationDashboard}
                            />
                            <Route
                                exact
                                path={
                                    RouterPaths.SettingsIntegrationFakturownia
                                }
                                component={Fakturownia}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationPaxy}
                                component={SettingsPaxy}
                            />
                            <Route
                                path={
                                    RouterPaths.SettingsIntegrationFurgonetkaOld
                                }>
                                <RedirectWithQuery
                                    path={
                                        RouterPaths.SettingsIntegrationFurgonetka
                                    }
                                />
                            </Route>
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationFurgonetka}
                                component={Furgonetka}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationInpost}
                                component={InPostSettings}
                            />
                            <Route
                                path={RouterPaths.SettingsIntegrationShippyPro}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShippyPro
                                        }
                                        component={Configuration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShippyProData
                                        }
                                        component={SenderDataForm}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShippyProTemplates
                                        }
                                        component={Templates}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShippyProTemplate
                                        }
                                        component={TemplateForm}
                                    />
                                </Switch>
                            </Route>
                            <Route path={RouterPaths.SettingsIntegrationUsps}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationUsps
                                        }
                                        component={UspsConfiguration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationUspsData
                                        }
                                        component={UspsSenderDataForm}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationUspsTemplates
                                        }
                                        component={UspsTemplates}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationUspsTemplate
                                        }
                                        component={UspsTemplateForm}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={
                                    RouterPaths.SettingsIntegrationMondialRelay
                                }>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationMondialRelay
                                        }
                                        component={MondialRelayConfiguration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationMondialRelayData
                                        }
                                        component={MondialRelaySenderDataForm}
                                    />
                                </Switch>
                            </Route>
                            <Route path={RouterPaths.SettingsIntegrationOrlen}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationOrlen
                                        }
                                        component={OrlenConfiguration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationOrlenData
                                        }
                                        component={OrlenSenderDataForm}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={RouterPaths.SettingsIntegrationShipmondo}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShipmondo
                                        }
                                        component={ShipmondoConfiguration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShipmondoData
                                        }
                                        component={ShipmondoDataForm}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={RouterPaths.SettingsIntegrationColissimo}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationColissimo
                                        }
                                        component={ColissimoConfiguration}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationColissimoData
                                        }
                                        component={ColissimoDataForm}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={
                                    RouterPaths.SettingsIntegrationWoocommerce
                                }>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationWoocommerce
                                        }
                                        component={WoocommerceConfiguration}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={[
                                    RouterPaths.SettingsIntegrationShopify,
                                    RouterPaths.SettingsIntegrationShopifyOld,
                                ]}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShopify
                                        }
                                        component={ShopifyConfiguration}
                                    />
                                    <Route
                                        path={
                                            RouterPaths.SettingsIntegrationShopifyCallbackOld
                                        }>
                                        <RedirectWithQuery
                                            path={
                                                RouterPaths.SettingsIntegrationShopifyCallback
                                            }
                                        />
                                    </Route>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationShopifyCallback
                                        }
                                        component={ShopifyConnecting}
                                    />
                                    <Redirect
                                        to={
                                            RouterPaths.SettingsIntegrationShopify
                                        }
                                    />
                                </Switch>
                            </Route>
                            <Route
                                path={
                                    RouterPaths.SettingsIntegrationPrestaShop
                                }>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationPrestaShop
                                        }
                                        component={PrestaShopConfiguration}
                                    />
                                </Switch>
                            </Route>
                            <Route path={RouterPaths.SettingsIntegrationDpd}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationDpdTemplates
                                        }
                                        component={DpdTemplates}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationDpdTemplate
                                        }
                                        component={DpdTemplateForm}
                                    />
                                    <Route
                                        exact
                                        path={
                                            RouterPaths.SettingsIntegrationDpd
                                        }
                                        component={DpdConfiguration}
                                    />
                                </Switch>
                            </Route>
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationsGls}
                                component={GlsConfiguration}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationsGlsFrance}
                                component={GlsFranceConfiguration}
                            />
                            <Route
                                exact
                                path={
                                    RouterPaths.SettingsIntegrationPostaBezHranic
                                }
                                component={PostaBezHranic}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsIntegrationCustom}
                                component={CustomIntegration}
                            />
                        </Switch>
                    </Route>
                    <Route path={RouterPaths.SettingsAccount}>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.SettingsAccountChangePassword}
                                component={PasswordForm}
                            />
                            <Route
                                exact
                                path={RouterPaths.SettingsAccount}
                                component={ProfileDashboard}
                            />
                        </Switch>
                    </Route>
                    <Route
                        path={RouterPaths.SettingsMeta}
                        exact
                        component={MetaDashboard}
                    />
                    <Route path={RouterPaths.SettingsFacebookOld}>
                        <RedirectWithQuery
                            path={RouterPaths.SettingsFacebook}
                        />
                    </Route>
                    <Route
                        exact
                        path={RouterPaths.SettingsFacebook}
                        component={FacebookSettings}
                    />
                    <Route path={RouterPaths.SettingsInstagramOld}>
                        <RedirectWithQuery
                            path={RouterPaths.SettingsInstagram}
                        />
                    </Route>
                    <Route
                        exact
                        path={RouterPaths.SettingsInstagram}
                        component={InstagramSettings}
                    />
                    <Route
                        exact
                        path={RouterPaths.SettingsMobileApp}
                        component={MobileApp}
                    />
                    <SelmoProRoute
                        allowField="depot_system"
                        exact
                        component={QRProductSettings}
                        path={RouterPaths.SettingsQRProduct}
                    />
                    <TeamRoute path={RouterPaths.SettingsTeam} />
                    <AutomationsRoute path={RouterPaths.SettingsAutomations} />
                    <Route path={RouterPaths.Settings} component={Dashboard} />
                    <Redirect to={RouterPaths.Settings} />
                </Switch>
                <Alert />
            </SettingsLayout>
        </UserPermissionRoute>
    );
};

export default SettingsRoute;
