import React, {useState} from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import Products from "./components/products/Products";
import ValidationBox from "../ValidationBox";
import BoxWrapper from "../BoxWrapper";
import AssignProductsModal from "../../../../../../../products/mobileReels/form/assignProducts/Form";

const AssignProducts = ({values, setValue, hasError, isActive}) => {
    const {getLangText} = useLang();
    const [showAssignProductsModal, setShowAssignProductsModal] = useState(false);

    return (
        <BoxWrapper
            title="selectProductsToPromote"
            desc="checkHaveProductsInStock"
        >
            {/*{isActive &&*/}
            <div className="mt-3">
                {!!values?.products?.length ?
                    <Products
                        setModalVisible={setShowAssignProductsModal}
                        products={values.products}
                        setProducts={(products) => setValue('products', products)}
                        type="MARKETING"
                        isActive={isActive}
                    /> :
                    (isActive ?
                            <button
                                onClick={() => setShowAssignProductsModal(true)}
                                type="button"
                                className="text-center py-16 w-full"
                            >
                                <div
                                    className="w-[30px] mx-auto h-[30px] rounded-[5px] bg-[#E5E7EB] flex items-center justify-center text-[#230C34] mb-2">
                                    <i className="icon-plus"/>
                                </div>
                                <div className="font-bold sm:text-lg">{getLangText('liveAssignProductLabel')}</div>
                                <div
                                    className="font-medium text-sm text-desc">{getLangText('itWillAppearToBuy')}</div>
                                <div className="button primary small-size mt-3" style={{fontSize: 14}}>
                                    {getLangText('liveAssignProductLabel')}
                                </div>
                            </button> :
                            <div
                                className="text-center py-6 w-full"
                            >
                                <img
                                    className="mx-auto"
                                    src="/assets/images/empty/products-empty.svg" alt="empty products"/>
                                <div className="font-bold text-sm">{getLangText('noAssignedProductsLabel')}</div>
                            </div>
                    )
                }
                {showAssignProductsModal &&
                    <AssignProductsModal
                        hideModal={() => setShowAssignProductsModal(false)}
                        modalVisible={showAssignProductsModal}
                        updateProducts={(products) => setValue('products', products)}
                        defaultSelectedProducts={values.products}
                    />
                }
                <ValidationBox
                    visible={hasError}
                    title="selectProductToGo"
                />
            </div>
            {/*}*/}
        </BoxWrapper>
    );
};

export default AssignProducts;
