export const GENDER_FEMALE = 'FEMALE';
export const GENDER_MALE = 'MALE';

export const GENDER_TYPES = [
  {
    value: GENDER_FEMALE,
    label: 'Kobieta',
  },
  {
    value: GENDER_MALE,
    label: 'Mężczyzna',
  },
];
