import React, {useEffect, useState} from 'react';
import useLang from "../../hooks/useLang";
import {getFromLS, saveToLS} from "../../helpers/LS";

const ToggleContent = ({
                           children,
                           title,
                           className = '',
                           onlyIcon,
                           id,
                           defaultVisible = false,
                           ToggleHeader = null,
                           onChange = (visibility) => {},
                       }) => {

    const {getLangText} = useLang();
    const [isVisible, setIsVisible] = useState(defaultVisible);

    const handleOpen = (visibility) => {
        setIsVisible(visibility);
        onChange(visibility);
    }

    useEffect(() => {
        if (defaultVisible) {
            handleOpen(true);
        }
    }, [defaultVisible])

    return (
        <div className={`toggle-content-wrapper ${className}`}>
            <div
                onClick={() => handleOpen(!isVisible)}
                className={`toggle-header ${isVisible ? 'open' : ''}`}
            >
                {ToggleHeader ? ToggleHeader(isVisible) :
                    <>
                        <div className="toggle-title">{title}</div>
                        {onlyIcon ?
                            <div
                                className="ml-auto shadow-selmo-border border rounded-medium w-[20px] h-[20px] flex items-center justify-center text-[6px]">
                                <i className={`block icon-arrows transition-all ${isVisible ? 'rotate-180' : ''}`}/>
                            </div> :
                            <div className="button text-only">
                                {isVisible ? getLangText('collapseLabel') : getLangText('expandLabel')}
                                <i className="icon-arrows"/>
                            </div>
                        }
                    </>
                }
            </div>
            {isVisible &&
                children
            }
        </div>
    );
};

export default ToggleContent;
