import React, { lazy, Suspense, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom';
import { RouterPaths } from './components/routes/RouterPath';
import ScrollTop from './src_shared/hooks/ScrollTop';
import KnowledgeBaseRoute from './components/routes/KnowledgeBaseRoute';
import PaymentsRoute from './components/routes/PaymentsRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import LangsActions from './components/app/shared/langs/LangsActions';
import AuthRoute from './components/routes/AuthRoute';
import TutorialRoute from './components/routes/TutorialRoute';
import { getGETParamsUrl, parseParams } from './src_shared/list/ListActions';
import RefreshToken from './components/app/refreshToken/RefreshToken';
import IntercomWrapper from './components/app/shared/IntercomWrapper';
import InstagramCallback from './components/app/instagramCallback/InstagramCallback';
// import AdminRoutes from "./components/admin/components/AdminRoutes";
// import BasketRoute from "./components/routes/BasketRoute";
import ShopCartAuth from './components/app/shopCartAuth/ShopCartAuth';
import AppWrapper, { posthogOptions } from './components/app/AppWrapper';
import moment from 'moment';
import 'moment-timezone';
import api from './services/axios/axios';
import './styles/vendor/inpost.min.css';
import { PostHogProvider } from 'posthog-js/react';
import PaymentProcessing from './components/app/paymentProcessing/PaymentProcessing';
import animationData from './data.json';
import Lottie from 'lottie-react';
import CheckSessionWrapper from './components/app/CheckSessionWrapper';
import MaintenancePage from './components/app/maintenance/Page';
import SettingsRoutes from './components/routes/settings/SettingsRoute';
import SelmoAppRoutes from './components/app/SelmoApp';
import 'moment/locale/pt';
import 'moment/locale/cs';
import 'moment/locale/sv';
import 'moment/locale/lt';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/sk';
import 'moment/locale/de';
import 'moment/locale/fr';
import LogoutRoute from './components/app/login/logout/LogoutRoute';
import LoginGoogle from './components/app/login/google/LoginGoogle';
import LoginFacebook from './components/app/login/facebook/LoginFacebook';
import CheckoutRoute from './components/routes/CheckoutRoute';
import ErrorPage from './components/app/errorPage/Page';
import MobileNotificationsAuthRoute from './components/routes/MobileNotificationsAuthRoute';
import MobileApRedirect from './components/app/mobileAppRedirect/MobileAppRedirect';
import Newsletter from './components/app/newsletter/Newsletter';
import PaypalCallback from './components/settings/payments/paypal/PaypalCallback';
import LandingWebinarConfirmEmail from './components/app/landingWebinarAuth/ConfirmEmail';
import SelmoPaczkaTerms from './components/app/selmoPaczkaTerms/SelmoPaczkaTerms';
import TagManager from 'react-gtm-module/dist/TagManager';
import RedirectWithQuery from './src_shared/helpers/RedirectWithQuery';
import TrackingPage from './components/app/basket/trackingPage/TrackingPage';
import { saveToLS } from './src_shared/helpers/LS';
import DownloadVideoPage from './components/app/downloadVideoPage/DownloadVideoPage';
import ShopifyIntegration from './components/app/integrations/ShopifyIntegration';
import history from './components/history';
import OrderCreate from './components/app/orderCreate/Page';
import Alert from './components/app/shared/components/alert/Alert';

export const getRedirectToSelmoApp = () => {
    const baseUrl = 'https://app.selmo.io';
    const currentUrl = window.location.href.split(window.location.origin)[1];

    window.location.href = `${baseUrl}${currentUrl}`;
};

// getRedirectToSelmoApp();

export const LottieLoading = ({ className = '' }) => {
    const queryParams = history.location.search;
    const parsedParams = parseParams(queryParams);
    if (!!+parsedParams.mobileApp) return <div className="intro-loader" />;
    return (
        <div className={`intro-loader ${className}`}>
            <Lottie animationData={animationData} loop={true} />
        </div>
    );
};

// const SettingsRoutes = lazy(() => import('./components/routes/settings/SettingsRoute'));
// const SelmoAppRoutes = lazy(() => import('./components/app/SelmoApp'));
const AdminRoutes = lazy(
    () => import('./components/admin/components/AdminRoutes'),
);
const BasketRoutes = lazy(() => import('./components/routes/BasketRoute'));

const App = () => {
    const { isMaintenancePage, isErrorPage } = useSelector(
        (state) => state.session,
    );
    const dispatch = useDispatch();
    const queryLang = parseParams(window.location.search);
    const preparedLang = queryLang.lang
        ? queryLang.lang
        : dispatch(LangsActions.getLang());

    useEffect(() => {
        dispatch(LangsActions.setAsyncLang(preparedLang));
        api.defaults.headers.common['lang'] = preparedLang;
        moment.tz.setDefault('Europe/Warsaw');
    }, []);

    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-W43PPMK' });
    }, []);

    if (isMaintenancePage) {
        return <MaintenancePage />;
    }

    if (isErrorPage) {
        return <ErrorPage />;
    }

    return (
        <IntercomWrapper>
            <Suspense fallback={<LottieLoading />}>
                <Router>
                    <ScrollTop />
                    <Switch>
                        <KnowledgeBaseRoute path={RouterPaths.KnowledgeBase} />
                        <Route
                            component={SelmoPaczkaTerms}
                            path={RouterPaths.SelmoPaczkaTerms}
                        />
                        <Route
                            component={DownloadVideoPage}
                            path={RouterPaths.DownloadSelmoApka}
                        />
                        <Route
                            component={MobileApRedirect}
                            path={RouterPaths.MobileAppBackToApp}
                        />
                        <Route
                            component={ShopifyIntegration}
                            path={RouterPaths.IntegrationsShopifyHash}
                        />
                        <Route
                            component={BasketRoutes}
                            path={[
                                RouterPaths.BasketSingle,
                                RouterPaths.BasketSingleEN,
                            ]}
                        />
                        <CheckoutRoute path={RouterPaths.Checkout} />
                        <Route
                            path={RouterPaths.Newsletter}
                            component={Newsletter}
                        />
                        <Route
                            path={RouterPaths.ShopCartAuth}
                            component={ShopCartAuth}
                        />
                        <Route
                            path={RouterPaths.PaymentProcessing}
                            component={PaymentProcessing}
                        />
                        <Route
                            exact
                            path={RouterPaths.Logout}
                            component={LogoutRoute}
                        />
                        <Route
                            exact
                            path={RouterPaths.LoginGoogle}
                            component={LoginGoogle}
                        />
                        <Route
                            exact
                            path={RouterPaths.LoginFacebook}
                            component={LoginFacebook}
                        />
                        <Route
                            exact
                            path={RouterPaths.PaypalAutomationReturn}
                            component={PaypalCallback}
                        />
                        <Route
                            exact
                            path={RouterPaths.LoginGoogleExpansion}
                            render={() => <LoginGoogle isExpansion={true} />}
                        />
                        <Route
                            exact
                            path={RouterPaths.LoginFacebookExpansion}
                            render={() => <LoginFacebook isExpansion={true} />}
                        />
                        <Route
                            exact
                            path={RouterPaths.OrderCreate}
                            component={OrderCreate}
                        />
                        <AuthRoute path={RouterPaths.Auth} />
                        <Route
                            path={RouterPaths.Tutorial}
                            component={TutorialRoute}
                        />
                        <Route
                            path={RouterPaths.LandingWebinarConfirm}
                            component={LandingWebinarConfirmEmail}
                        />
                        <MobileNotificationsAuthRoute
                            path={RouterPaths.MobileNotificationsAuth}
                        />
                        <PrivateRoute
                            exact
                            path={RouterPaths.InstagramCallback}
                            component={InstagramCallback}
                        />
                        <PrivateRoute
                            exact
                            path={RouterPaths.RefreshToken}
                            component={RefreshToken}
                        />
                        <Route path={RouterPaths.CartTrackingNoParam}>
                            <Switch>
                                <Route
                                    exact
                                    path={RouterPaths.CartTracking}
                                    component={TrackingPage}
                                />
                                <Route
                                    exact
                                    path={RouterPaths.CartTrackingNoParam}
                                    component={TrackingPage}
                                />
                            </Switch>
                        </Route>
                        <Route>
                            <CheckSessionWrapper>
                                <PostHogProvider
                                    apiKey="phc_ePheWHOSpLMyVFJGGQqZIHyP7SnUD9jGP2mIFVnu4Ei"
                                    options={posthogOptions}>
                                    <AppWrapper>
                                        <Switch>
                                            <PrivateRoute
                                                path={RouterPaths.Admin}
                                                component={AdminRoutes}
                                            />
                                            <PrivateRoute
                                                component={PaymentsRoute}
                                                path={RouterPaths.Payments}
                                            />
                                            <PrivateRoute
                                                path={[
                                                    RouterPaths.Settings,
                                                    RouterPaths.SettingsOld,
                                                ]}
                                                component={SettingsRoutes}
                                            />
                                            <PrivateRoute
                                                component={SelmoAppRoutes}
                                            />
                                        </Switch>
                                    </AppWrapper>
                                </PostHogProvider>
                            </CheckSessionWrapper>
                        </Route>
                    </Switch>
                </Router>
            </Suspense>
        </IntercomWrapper>
    );
};

export default App;
