import createReducer from '../../shared/helpers/createReducer';
import Composition from '../../shared/helpers/Composition';
import ListReducer from '../../../../../../src_shared/list/ListReducer';

export const getStateRoot = (state) => state.admin.demo.list;
export const prefix = 'ADMIN_DEMO_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () => new Composition(ListReducer.getReduceMap());

export const getInstance = () =>
    createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
