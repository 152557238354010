import React from 'react';
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {services} from "../../../RestServices";
import api from "../../../../services/axios/axios";
import QrScanner from "../../shared/components/QRScanner/QRScanner";
import OrderSearch from "./OrderSerach/OrderSearch";
import {isMobile} from "../../shared/helpers/isMobile";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../shared/components/alert/AlertActions";
import RealDeviceScanner from "../../shared/realDeviceScanner/RealDeviceScanner";
import {SHOP_BAZAR_SHOP_ID, TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const Scanner = () => {
    const {getLangText} = useLang();
    const history = useHistory();
    const mobile = isMobile();
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);

    const getOrder = async (id, fromRealDeviceScanner = false) => {
        if (!id) {
            dispatch(showAlert('sthWentWrongWarning', 'codeNotExist', 'alert-fail'))
            return;
        }
        try {
            const {data} = await api.get(`/${services.API_WAREHOUSE_ORDER}?inpost_tracking_number=${id}`);
            if (!data?.item?.id) {
                dispatch(showAlert('sthWentWrongWarning', id, 'alert-fail'))
            } else {
                history.push(`${RouterPaths.StorageScanner}/order/${data?.item?.id}?clickAction=1${fromRealDeviceScanner ? '&defaultScanner=3' : ''}`);
            }
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', id, 'alert-fail'))
            console.log(e)
        }
    };

    const handleResult = async (result, withParse = true) => {
        const data = withParse ? result?.replace('%', '') : result;
        try {
            let parsed = data;
            if (withParse) {
                JSON.parse(data)
                parsed = JSON.parse(data);
            }
            // switch (parsed.type) {
            //     case 'ORDER':
            //         history.push(`${RouterPaths.StorageScanner}/order/${parsed.id}`);
            //         break;
            //     case 'PRODUCT':
            //         const productRoute = `${RouterPaths.StorageScanner}/product/${parsed.product_id}`;
            //         if (parsed.product_size_id) {
            //             history.push(`${productRoute}/${parsed.product_size_id}`);
            //             return;
            //         }
            //         history.push(productRoute);
            //         break;
            //     case 'SHELF':
            //         history.push(`${RouterPaths.Storage}/${parsed.shelf_id}`);
            //         break;
            //     default:
            //         await getOrder(result);
            //         return;
            // }
            if (parsed.type === 'ORDER' || parsed.type === 'PRODUCT' || parsed.type === 'SHELF') {
                const productRoute = `${RouterPaths.StorageScanner}/product/${parsed.product_id}`;
                if (parsed.product_id) {
                    if (parsed.product_size_id) {
                        history.push(`${productRoute}/${parsed.product_size_id}`);
                        return;
                    }
                    history.push(productRoute);
                } else if (parsed.shelf_id) {
                    history.push(`${RouterPaths.Storage}/${parsed.shelf_id}`);
                } else {
                    history.push(`${RouterPaths.StorageScanner}/order/${parsed.id}${!withParse ? '?defaultScanner=3' : ''}`);
                }
            } else {
                throw new Error('Unknown type')
            }
        } catch (e) {
            await getOrder(data, !withParse);
        }
    }


    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <Link to={RouterPaths.Storage} className="button back-to-panels mb-lg-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            {!mobile ?
                (
                    (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID) ?
                        <RealDeviceScanner afterScan={(result) => handleResult(result, false)} /> :
                        <div
                            className="bg-[#fff] w-full h-[400px] rounded-[5px] flex items-center justify-center font-medium text-center"
                            dangerouslySetInnerHTML={{__html: getLangText('qrScannerAvailableInMobileVersion')}}
                        />
                ) :
                <>
                    <div className="completing-scanner">
                        <QrScanner
                            defaultActiveScanner={1}
                            handleResult={handleResult}
                        />
                    </div>
                    <OrderSearch/>
                </>
            }
        </>
    );
};

export default Scanner;
