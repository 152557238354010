// sklep
export const PRODUCT_SOURCE_SHOP = 'SHOP'
// selmo.live
// wiadomosc messenger fb
export const PRODUCT_SOURCE_FB_MESSAGE = 'FB_MESSAGE'
//  komentarz fb
export const PRODUCT_SOURCE_FB_COMMENT = 'FB_COMMENT'
//  wiadomosc ig
export const PRODUCT_SOURCE_IG_MESSAGE = 'IG_MESSAGE'
//  komentarz ig
export const PRODUCT_SOURCE_IG_COMMENT = 'IG_COMMENT'
// panel zamowienie
export const PRODUCT_SOURCE_PANEL_ORDER = 'PANEL_ORDER'
// Panel messenger
export const PRODUCT_SOURCE_PANEL_MESSENGER = 'PANEL_MESSENGER'
// nasze transmisje
export const PRODUCT_SOURCE_LIVE = 'LIVE'
export const PRODUCT_RESERVE_LIST = 'RESERVE_LIST'
export const PRODUCT_IG_AUTOMATION = 'IG_AUTOMATION'
export const PRODUCT_MOBILE_APP = 'MOBILE_APP'
export const PRODUCT_SHOP_PICKUP = 'SHOP_PICKUP'
export const PRODUCT_UPSELL = 'UPSELL'
export const PRODUCT_MR_AUTOMATE_CLOSE_PACKAGE = 'MR_AUTOMATE_CLOSE_PACKAGE'
export const PRODUCT_MOBILE_APP_LIVE = 'MOBILE_APP_LIVE'
export const PRODUCT_MOBILE_APP_POST = 'MOBILE_APP_POST'
export const PRODUCT_MOBILE_APP_OLD_LIVE = 'MOBILE_APP_OLD_LIVE'


export const ProductsSourceTypes = [
	{id: PRODUCT_SOURCE_LIVE, icon: 'selmo', lang: 'productSourceLiveOnSelmoLabel'},
	{id: PRODUCT_SOURCE_PANEL_MESSENGER, icon: 'selmo', lang: 'productSourceMessageOnSelmoLabel'},
	{id: PRODUCT_SOURCE_PANEL_ORDER, icon: 'selmo', lang: 'productSourceOrderPanelLiveLabel'},
	{id: PRODUCT_RESERVE_LIST, icon: 'selmo', lang: 'productSourceReserveListLabel'},
	{id: PRODUCT_SOURCE_FB_MESSAGE, icon: 'facebook', lang: 'productSourceMessengerLabel'},
	{id: PRODUCT_SOURCE_FB_COMMENT, icon: 'facebook', lang: 'productSourceFacebookCommentLabel'},
	{id: PRODUCT_SOURCE_IG_MESSAGE, icon: 'insta', lang: 'productSourceInstagramMessageLabel'},
	{id: PRODUCT_SOURCE_IG_COMMENT, icon: 'insta', lang: 'productSourceInstagramCommentLabel'},
	{id: PRODUCT_SOURCE_SHOP, icon: 'cart', lang: 'productSourceShopLabel'},
	{id: PRODUCT_IG_AUTOMATION, icon: 'insta', lang: 'instagramAutomationLabel'},
	{id: PRODUCT_MOBILE_APP, icon: 'selmo', lang: 'productSourceMobileAppOnSelmoLabel'},
	{id: PRODUCT_SHOP_PICKUP, icon: 'selmo', lang: 'pickupFromShop'},
	{id: PRODUCT_UPSELL, icon: 'selmo', lang: 'upsell'},
	{id: PRODUCT_MR_AUTOMATE_CLOSE_PACKAGE, icon: 'selmo', lang: 'mrAutomatedCloseOpenPackage'},
	{id: PRODUCT_MOBILE_APP_LIVE, icon: 'selmo', lang: 'liveInSelmoApp'},
	{id: PRODUCT_MOBILE_APP_POST, icon: 'selmo', lang: 'salesPostInSelmoApp'},
	{id: PRODUCT_MOBILE_APP_OLD_LIVE, icon: 'selmo', lang: 'oldLiveInSelmoApp'},
]

export const getProductSourceTypeIcon = (type) => ProductsSourceTypes.find((i) => i.id === type);


