import React from 'react';
import Group from '../group/Group';

const SwitchField = (props) => {
    const { name, setValue, value, disabled, extraProps, id, labelFirst } =
        props;

    const onChangeClick = (e) => {
        if (e.target.checked) {
            setValue(name, '1');
        } else {
            setValue(name, '0');
        }
    };

    return (
        <Group {...props}>
            <div
                className={`switcher-wrapper ${labelFirst ? 'label-first' : ''} ${value ? 'active' : ''}`}>
                <input
                    type="checkbox"
                    className="checkbox"
                    id={String(id)}
                    name={name}
                    checked={+value}
                    disabled={disabled}
                    onChange={onChangeClick}
                    {...extraProps}
                />
                <label htmlFor={String(id)} className="switch" />
            </div>
        </Group>
    );
};

SwitchField.defaultProps = {
    setValue: () => {},
    showLabel: true,
    labelFirst: false,
    label: '',
    value: '',
    activeValidation: false,
    required: false,
    name: '',
    disabled: false,
    extraProps: {},
    displayTextError: true,
    id: '',
    yesButton: 'Tak',
    noButton: 'Nie',
};

export default SwitchField;
