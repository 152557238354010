import useLang from '../../../../../../../src_shared/hooks/useLang';
import React from 'react';
import useList from '../../../../../../../src_shared/list/useList';
import ListActions from './ListActions';
import { getStateRoot } from './listReducer';
import useSubmitData from '../../../../../../../src_shared/hooks/useSubmitData';
import { services } from '../../../../../../RestServices';
import SearchInput from '../../../../../shared/components/form/SearchInput';
import Pagination from '../../../../../shared/components/pagination/Pagination';
import Table from './components/Table/Table';
import Loader from '../../../../../shared/Loader';
import { RouterPaths } from '../../../../../../routes/RouterPath';
import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';
import FormDownload from '../../components/FormDownload';

const SmsCampaignsList = () => {
    const { getLangText } = useLang();
    const history = useHistory();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage,
        },
        list: { items, isWaiting },
        search,
        sort: { updateSortOptions, getSortClass },
        remove: { askForRemoveItem, markedToRemove },
    } = useList({
        ListActions,
        getStateRoot,
    });

    const [
        {
            onSave: createSmsCampaign,
            isWaiting: isWaitingForCreatingSmsCampaign,
        },
    ] = useSubmitData({
        rest: services.API_MARKETING_SMS,
        afterSubmit: (response) => {
            history.push(
                `${RouterPaths.MarketingSmsCampaigns}/details/${response.id}`,
            );
        },
    });

    return (
        <>
            <div className="table-list-wrapper">
                <div
                    style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                    className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part">
                            <h1 className="title mb-sm-0 mb-2 !flex-nowrap">
                                <span>SMS</span>
                                {totalItems > 0 && (
                                    <div className="badge">{totalItems}</div>
                                )}
                            </h1>
                            <SearchInput
                                className="ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            <button
                                onClick={createSmsCampaign}
                                className={`button primary small-size ml-2 `}
                                type="button">
                                <Loader
                                    isLoading={isWaitingForCreatingSmsCampaign}
                                />
                                <span>{getLangText('sendSmsMessage')}</span>
                            </button>
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                        createAd={createSmsCampaign}
                        isWaitingForAd={isWaitingForCreatingSmsCampaign}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
        </>
    );
};

export default SmsCampaignsList;
