import useLang from '../../../../../../src_shared/hooks/useLang';
import PermissionFormModal from "./PermissionFormModal";
import {useState} from "react";

const FormDownload = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="bg-white rounded-medium mb-3 p-6">
            <div className="flex gap-2 bg-[#FEF2F2] rounded-[8px] p-3.5">
                <div>
                    <i className="icon-info-c text-[#F87171] text-[20px]"/>
                </div>
                <div className="pr-3.5">
                    <p className="text-[#981B1B] text-sm font-bold">
                        {getLangText('fillAgreementForm')}
                    </p>
                    <p className="text-[#C64846] text-[14px] mt-1 font-medium">
                        {getLangText('downloadAgreementForm')}
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="button border-button shadow-selmo-border"
                    >
                        {getLangText('completeData')}
                    </button>
                </div>
            </div>
            {showModal &&
                <PermissionFormModal
                    isOpen={showModal}
                    hideModal={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default FormDownload;
