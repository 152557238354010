import React from 'react';
import Modal from 'react-bootstrap/Modal';
import useLang from '../../../../src_shared/hooks/useLang';
import DeliveryAddressForm from './DeliveryAddressForm';
import Loader from '../../shared/Loader';

const DeliveryAddressFormModal = ({
    hideModal,
    modalVisible,
    values,
    setValue,
    onSave,
    getFieldProps,
    isWaiting,
    validateErrors,
    shopId,
    isAddressValid,
}) => {
    const { getLangText } = useLang();

    const handleSetValue = (field, value) => {
        const newAddress = { ...values.address };
        newAddress[field] = value;
        setValue('address', newAddress);
    };

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}>
            <Modal.Header className="with-border">
                <Modal.Title>
                    {getLangText('customerBasketDeliveryDataLabel')}
                </Modal.Title>
                <button onClick={hideModal} type="button" className="button">
                    <i className="icon-cross" />
                </button>
            </Modal.Header>
            <Modal.Body className="pt-4 delivery-address-form-modal">
                <DeliveryAddressForm
                    values={values}
                    setValue={handleSetValue}
                    onSave={onSave}
                    getFieldProps={getFieldProps}
                    validateErrors={validateErrors}
                    shopId={shopId}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button">
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    onClick={onSave}
                    disabled={!isAddressValid}
                    className={`button primary ${isWaiting ? 'loading-button' : ''} ${!isAddressValid ? 'not-active' : ''}`}>
                    {isWaiting && <Loader isLoading />}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeliveryAddressFormModal;
