export const POLISH_TYPES = 'pl'
export const ENGLISH_TYPES = 'en'
export const ENGLISH_US_TYPES = 'us'
export const ITALY_TYPES = 'it'
export const FRENCH_TYPES = 'fr'
export const PORTUGUESE_TYPES = 'pt'
export const SLOVAK_TYPES = 'sk'
export const PHILIPPINES_TYPES = 'ph'
export const DANISH_TYPES = 'dk'
export const LITHUANIA_TYPES = 'lt'
export const CHECH_TYPES = 'cs'
export const SPANISH_TYPES = 'es'
export const UKRAINIAN_TYPES = 'ua'
export const MEXICAN_TYPES = 'es-mx'


export const ORIGIN_TYPES = [
	{
		value: POLISH_TYPES, label: 'Polski',
	},
	{
		value: ENGLISH_US_TYPES, label: 'Angielski US',
	},
	{
		value: ITALY_TYPES, label: 'Włoski',
	},
	{
		value: FRENCH_TYPES, label: 'Francuski',
	},
	{
		value: PORTUGUESE_TYPES, label: 'Portugalski',
	},
	{
		value: DANISH_TYPES, label: 'Duński',
	},
	{
		value: SPANISH_TYPES, label: 'Hiszpański',
	},
	{
		value: MEXICAN_TYPES, label: 'Mexican',
	},
	{
		value: SLOVAK_TYPES, label: 'Słowacki',
	},
	{
		value: ENGLISH_TYPES, label: 'Angielski',
	},
	{
		value: LITHUANIA_TYPES, label: 'Litewski',
	},
	{
		value: CHECH_TYPES, label: 'Czeski',
	},
	{
		value: PHILIPPINES_TYPES, label: 'Philippines',
	},
	{
		value: UKRAINIAN_TYPES, label: 'Ukrainian',
	},
]

export const findOriginById = (value) => ORIGIN_TYPES.find((i) => i.value === +value);
