export const defaultLang = "pl";

export const supportedLangs = {
	pl: "Polski",
	us: "English (US)",
	cs: "Čeština",
	it: 'Italiano',
	fr: 'Français',
	'es-mx': 'Español (México)',
	es: 'Español (España)',
	de: 'Deutsch',
	pt: 'Português',
	sk: 'Slovenský',
	ph: 'Pilipinas',
	se: 'Sverige',
	dk: 'Dansk',
	en: "English (UK)",
	lt: "Lietuva",
	ua: 'Yкраїнська',
};

export const supportedOptionLangs = [
	{
		label: 'Polski',
		value: 'pl',
		flag: 'pl',
		panel_poeditorId: '0d45339380',
		lp_poeditorId: 'ea55c888cc',
		shop_poeditorId: '55a167c2ac',
	},
	{
		label: 'English (US)',
		value: 'us',
		flag: 'us',
		panel_poeditorId: 'b9a32d9a03',
		lp_poeditorId: '1caf50f7e7',
		shop_poeditorId: '6cf6875e1b',
	},
	{
		label: 'Italiano',
		value: 'it',
		flag: 'it',
		panel_poeditorId: 'a070ddf3fe',
		lp_poeditorId: '70cef901e7',
		shop_poeditorId: '020039b2c7',
	},
	{
		label: 'Français',
		value: 'fr',
		flag: 'fr',
		panel_poeditorId: 'd7fab896f0',
		lp_poeditorId: 'a4cc8be6a6',
		shop_poeditorId: '9e6fa04368',
	},
	{
		label: 'Deutsch',
		value: 'de',
		flag: 'de',
		panel_poeditorId: 'b116402d3b',
		lp_poeditorId: '242cc6ccc8',
		shop_poeditorId: 'eab86720b2',
	},
	{
		label: 'Español (México)',
		value: 'es-mx',
		flag: 'mx',
		panel_poeditorId: '82965e6de2',
		lp_poeditorId: 'ded97f36dd',
		shop_poeditorId: '76f20f0007',
	},
	{
		label: 'Español (España)',
		value: 'es',
		flag: 'es',
		panel_poeditorId: '82965e6de2',
		lp_poeditorId: 'ded97f36dd',
		shop_poeditorId: '76f20f0007',
	},
	{
		label: 'Português',
		value: 'pt',
		flag: 'pt',
		panel_poeditorId: '96a305187f',
		lp_poeditorId: 'e04a8ff0dd',
		shop_poeditorId: '52c5d89be6',
	},
	{
		label: 'Slovenský',
		value: 'sk',
		flag: 'sk',
		panel_poeditorId: '4087c15856',
		lp_poeditorId: '3b58fb9e8f',
		shop_poeditorId: '5b9d8fcfa2',
	},
	{
		label: 'Dansk',
		value: 'dk',
		flag: 'dk',
		panel_poeditorId: '215a24515b',
		lp_poeditorId: '4f3723b210',
		shop_poeditorId: '78bddae9af',
	},
	{
		label: 'English (UK)',
		value: 'en',
		flag: 'en',
		panel_poeditorId: 'b9a32d9a03',
		lp_poeditorId: '1caf50f7e7',
		shop_poeditorId: '6cf6875e1b',
	},
	{
		label: 'Lietuva',
		value: 'lt',
		flag: 'lt',
		panel_poeditorId: '85116f22c5',
		lp_poeditorId: '870fbae887',
		shop_poeditorId: '',
	},
	{
		label: 'Čeština',
		value: 'cs',
		flag: 'cs',
		panel_poeditorId: 'ae05f33848',
		lp_poeditorId: '185cf46a35',
		shop_poeditorId: '',
	},
	{
		label: 'Yкраїнська',
		value: 'ua',
		flag: 'ua',
		panel_poeditorId: '',
		lp_poeditorId: '95078d8750',
		shop_poeditorId: '',
	},
	// {
	// 	label: 'Pilipinas',
	// 	value: 'ph',
	// 	flag: 'ph',
	// 	panel_poeditorId: '1a540c8d0a',
	// 	lp_poeditorId: 'ba3c4809ae',
	// 	shop_poeditorId: 'd81d08474e',
	// },
	// {
	// 	label: 'Sverige',
	// 	value: 'se',
	// 	flag: 'se',
	// },
]
export const langUrl = "/locales/{lang}.json";
