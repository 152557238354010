import React from 'react';
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import PersonItem from "./PersonItem";

const SearchPeopleDropdown = ({isLoadingUsers, usersData, updateConnectedUsers, updateUsers, searchText}) => {
    const {getLangText} = useLang();
    return (
        <div
            style={{minHeight: 'auto'}}
            className="loader-parent"
        >
            {isLoadingUsers ?
                <div className="mt-3">
                    <Loader
                        className="static-loader"
                        isLoading={isLoadingUsers}
                    />
                </div>
                :
                !!searchText &&
                (!!usersData.length ?
                        <div
                            className="animate-[fadeIn_.3s_ease_forwards] max-h-[250px] overflow-y-auto bg-[#fff] border rounded-[8px]">
                            {usersData.map((item) => (
                                <PersonItem
                                    item={item}
                                    key={item.app_client_id}
                                    updateConnectedUsers={updateConnectedUsers}
                                    updateUsers={updateUsers}
                                    inModal={true}
                                />
                            ))}
                        </div> :
                        <div className="font-medium text-sm text-desc">{getLangText('noResultsLabel')}</div>
                )
            }
        </div>
    );
};

export default SearchPeopleDropdown;
