const URL_REGEX =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

const MessageBubble = ({ message, placeholder }) => {
    const formatMessageWithLinks = (text) => {
        if (!text) {
            return null;
        }

        const parts = [];
        const matches = text.match(URL_REGEX) || [];

        if (matches.length === 0) {
            return text;
        }

        let lastIndex = 0;

        for (const [index, match] of matches.entries()) {
            const matchIndex = text.indexOf(match, lastIndex);

            if (matchIndex > lastIndex) {
                parts.push(
                    <span key={`text-${index}`}>
                        {text.substring(lastIndex, matchIndex)}
                    </span>,
                );
            }

            parts.push(
                <a
                    key={`link-${index}`}
                    href={match.startsWith('www.') ? `https://${match}` : match}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline">
                    {match}
                </a>,
            );

            lastIndex = matchIndex + match.length;
        }

        if (lastIndex < text.length) {
            parts.push(
                <span key="text-last">{text.substring(lastIndex)}</span>,
            );
        }

        return parts;
    };

    return (
        <div className="absolute bg-[#E9E9EB] rounded-xl p-2.5 top-[18%] left-[9%] max-w-[65%]">
            <p className="text-sm break-words whitespace-normal overflow-wrap-break-word font-medium">
                {formatMessageWithLinks(message || placeholder)}
            </p>
            <img
                src="/assets/images/svg/marketing/message-bubble-tail.svg"
                alt=""
                className="absolute -bottom-[1px] -left-[3px] w-[13px]"
            />
        </div>
    );
};

export default MessageBubble;
