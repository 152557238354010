import Composition from '../../../../../shared/helpers/Composition';
import createReducer from '../../../../../shared/helpers/createReducer';
import FormReducer from '../../../../../../../src_shared/form/FormReducer';
import MarketingSmsCampaignFormModel from '../../../../../../../modules/models/marketing/MarketingSmsCampaignFormModel';

export const getStateRoot = (state) =>
    state.marketing.smsMarketing.campaigns.form;

export const prefix = 'MARKETING_SMS_CAMPAIGNS_FORM_';

export const getInitState = () =>
    FormReducer.getInitState(MarketingSmsCampaignFormModel.getModel(), {
        waiting: true,
    });

const getReduceMap = () => new Composition(FormReducer.getReduceMap());

export const getInstance = () =>
    createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
