const AreaButton = ({ onClick, title, icon }) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className="shadow-selmo-border button border-button rounded-[5px] flex items-center px-[10px] !py-[6px]">
            <span className="text-[12px] text-[#6b7280] font-bold mr-1">
                {title}
            </span>
            {icon}
        </button>
    );
};

export default AreaButton;
