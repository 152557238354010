import React, { useState } from 'react';
import useLang from '../../../../src_shared/hooks/useLang';
import { Redirect } from 'react-router-dom';
import { RouterPaths } from '../../../routes/RouterPath';
import useFetchData from '../../../../src_shared/hooks/useFetchData';
import { services } from '../../../RestServices';
import ComponentLoader from '../../../../src_shared/components/ui/componentLoader/ComponentLoader';
import { Helmet } from 'react-helmet';
import Faq from '../../notifications/landing/components/Faq/Faq';
import InvoiceDataMarketingModal from '../components/InvoiceDataMarketingModal';
import { useSelector } from 'react-redux';
import MarketingOther from '../other/Other';
import { createPortal } from 'react-dom';
import FormDownload from './submitted/components/FormDownload';
import { SHOP_ORIGIN_PL } from '../../shared/enums/ShopOrigins';

const BENEFITS_LABELS = [
    'effectiveCustomerReach',
    'immediateShipping',
    'intuitiveSmsEditor',
];

const QUESTIONS = [
    {
        id: 0,
        title: 'howToUseSmsMarketing',
        desc: 'howToUseSmsMarketingDesc',
    },
    {
        id: 1,
        title: 'canISendSmsToAllClients',
        desc: 'canISendSmsToAllClientsDesc',
    },
    {
        id: 2,
        title: 'whatClientShouldDoToReceiveSms',
        desc: 'whatClientShouldDoToReceiveSmsDesc',
    },
    {
        id: 3,
        title: 'faqNotificationsLabel2',
        desc: 'smsCostDesc',
    },
    {
        id: 4,
        title: 'areSmsRODOCompliant',
        desc: 'areSmsRODOCompliantDesc',
    },
];

const SmsMarketing = () => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);
    const [showModal, setShowModal] = useState(null);
    const [isActive, setActive] = useState(0);
    const activeView = 3;

    const [{ isLoading, data, setData }] = useFetchData({
        rest: `${services.API_MARKETING_LANDING}/${activeView}`,
    });

    const isPaid = !!+data.paid || false;

    if (userData.origin !== SHOP_ORIGIN_PL) {
        return <MarketingOther />;
    }

    if (isLoading) {
        return (
            <ComponentLoader style={{ borderRadius: 12, overflow: 'hidden' }} />
        );
    }

    if (isPaid) {
        return <Redirect to={RouterPaths.MarketingSmsCampaigns} />;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SMS - Selmo</title>
            </Helmet>
            <div className="animate-[fadeIn_.3s_ease_forwards]">
                <div
                    className={`flex flex-col lg:flex-row h-full lg:h-[550px] bg-white relative sm:rounded-[12px] shadow-default pt-12 px-14 pb-12 lg:pb-0 overflow-hidden ${!!showModal ? '' : ''}`}>
                    <div className="w-full h-1/2 lg:h-full lg:w-1/2 flex flex-col gap-[20px]">
                        <p className="uppercase text-[#5fa5fa] text-xs font-bold tracking-[1px]">
                            {getLangText('marketingSMSTitle')}
                        </p>
                        <p
                            className="light-grey-subtitle text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                                __html: getLangText(
                                    'sendSmsMessagesToYourClients',
                                ),
                            }}
                        />
                        <p
                            className="text-sm text-[#4b5563] font-medium leading-[23px]"
                            dangerouslySetInnerHTML={{
                                __html: getLangText(
                                    'sendPersonalizedSmsMessages',
                                ),
                            }}
                        />
                        <div className="flex flex-col gap-3">
                            {BENEFITS_LABELS.map((label) => (
                                <div key={label} className="flex items-center">
                                    <img
                                        src="/assets/images/svg/marketing/tick.svg"
                                        alt={label}
                                        className="w-4 h-4"
                                    />
                                    <p className="text-xs ml-2 font-medium">
                                        {getLangText(label)}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="mt-3">
                            <button
                                type="button"
                                onClick={() => setShowModal(activeView)}
                                className="button medium-size text-center !rounded-[12px] primary">
                                {getLangText('activeFor')} 99 zł
                            </button>
                            <p className="text-xs text-[#4b5563] mt-2.5 font-medium">
                                * {getLangText('activeForSubLabel')}
                            </p>
                        </div>
                    </div>
                    <div className="relative w-full hidden lg:flex h-1/2 lg:h-full lg:w-1/2 justify-end">
                        <img
                            src="/assets/images/svg/marketing/iphone-emoji.svg"
                            alt="Emoji iPhone"
                            className="z-10 absolute right-0"
                        />
                        <div
                            className="absolute bottom-0 w-full h-[200px] z-20"
                            style={{
                                background:
                                    'linear-gradient(to top, white, transparent)',
                            }}></div>
                    </div>
                </div>
                <div className="sm:rounded-[12px] shadow-default bg-white mt-3 flex flex-col items-center p-16">
                    <div
                        class="light-grey-subtitle my-3 text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold sm:whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{
                            __html: getLangText('faqPageTitleSpan'),
                        }}
                    />
                    <div className="max-w-[580px] mt-4">
                        <Faq
                            setActive={setActive}
                            isActive={isActive}
                            questions={QUESTIONS}
                            smallSize={true}
                        />
                    </div>
                </div>
            </div>
            {showModal && (
                <InvoiceDataMarketingModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(null)}
                    extend={false}
                    setData={setData}
                    type="sms"
                />
            )}
        </>
    );
};

export default SmsMarketing;
