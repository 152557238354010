import React from 'react';
import BoxWrapper from "../BoxWrapper";
import SwitchField from "../../../../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import ValidationBox from "../ValidationBox";

const TargetGroup = ({setValue, getFieldProps, getError, isActive}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const hasError = getError('target_current_clients') || getError('target_new_clients')

    return (
        <BoxWrapper
            title="targetGroup"
            desc="targetGroupSubLabel"
        >
            <div className="space-y-2">
                <div>
                    <SwitchField
                        {...getFieldProps('target_current_clients')}
                        name="target_current_clients"
                        className="switch-form align-items-center hidden-error-label inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                        setValue={setValue}
                        label={getLangText('currentClients')}
                        subLabel={getLangText('adWillBeDisplayedForCurrentClients')}
                        disabled={!isActive}
                    />
                </div>
                <div>
                    <SwitchField
                        {...getFieldProps('target_new_clients')}
                        name="target_new_clients"
                        className="switch-form align-items-center hidden-error-label inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                        setValue={setValue}
                        label={getLangText('newClients')}
                        subLabel={getLangText('adWillBeDisplayedForNewClients')}
                        disabled={!isActive}
                    />
                </div>
                {isActive &&
                    <div className="form-info-box w-100 items-center small-line-height">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div className="font-medium text-sm text-[#0e276d]"
                             dangerouslySetInnerHTML={{__html: getLangText('bySelectingBothTargetGroups')}}
                        />
                    </div>
                }
            </div>
            <ValidationBox
                className="mt-3"
                visible={hasError}
                title={'selectAtLeastOneTargetGroup'}
            />
        </BoxWrapper>
    );
};

export default TargetGroup;
