import useLang from "../../../../../../../src_shared/hooks/useLang";
import React, {useState} from "react";
import useList from "../../../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import SearchInput from "../../../../../shared/components/form/SearchInput";
import Pagination from "../../../../../shared/components/pagination/Pagination";
import ConfirmModal from "../../../../../../../src_shared/components/modal/ConfirmModal";
import Table from "./components/Table";
import SelectGoalModal from "./components/SelectGoalModal";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath";

const FacebookAdvertisementsList = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const {userData} = useSelector((state) => state.session);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const [{onSave: changeActivity, isWaiting: isWaitingForChangingActivity}] = useSubmitData(
        {
            rest: `${services.API_MARKETING_ACTIVE_AD}/${markedToRemove?.id}`,
            values: {
                status: (+markedToRemove?.status === 1 || +markedToRemove?.status === 2) ? 3 : 2,
            },
            afterSubmit: () => {
                loadData()
                cancelItemRemoval();
            }
        });

    // if (!facebookAdId && !isWaiting) return history.push(`${RouterPaths.MarketingFacebookFirstSteps}?required=fb_ad_account_id`)

    return (
        <>
            <div className="table-list-wrapper">
                <div
                    style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                    className="default-shadow-box pb-0"
                >
                    <div className="table-header">
                        <div className="top-part">
                            <h1 className="title mb-sm-0 mb-2">
                                {getLangText('advertisements')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <SearchInput
                                className="ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            {+userData.facebook_ads_active !== 2 &&
                                <button
                                    onClick={() => setShowModal(true)}
                                    className={`button primary small-size ml-2 `}
                                    type="button"
                                >
                                    {getLangText('createAd')}
                                </button>
                            }
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                        createAd={() => setShowModal(true)}
                        isWaitingForAd={false}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmModal
                    show={isModalVisible}
                    title={getLangText((+markedToRemove?.status === 1 || +markedToRemove?.status === 2) ? 'wantToTurnOfAd' : 'wantToTurnOnAd')}
                    isWaiting={isWaitingForChangingActivity}
                    hide={cancelItemRemoval}
                    onSubmit={changeActivity}
                    dialogClassName="small-modal"
                    saveButton={getLangText('yesButton')}
                />
            }
            {showModal &&
                <SelectGoalModal
                    hideModal={() => setShowModal(false)}
                    show={showModal}
                />
            }
        </>
    );
};

export default FacebookAdvertisementsList;
