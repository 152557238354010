import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
    INFINIK_SHOP_ID,
    KOKARDECZKA_SHOP_ID,
    PRZEMO_SHOP_ID,
    SASSI_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
} from '../../../../src_shared/enums/TrustedShopsIds';
import { RouterPaths } from '../../../routes/RouterPath';
import useLang from '../../../../src_shared/hooks/useLang';
import useWindowDimensions from '../../messenger/hooks/useWindowDimensions/useWindowDimensions';
import Profile from '../header/Profile';
import { useDispatch, useSelector } from 'react-redux';
import ServerImage from '../components/serverImage/ServerImage';
import AdminPermissionsWrapper from '../../../../src_shared/components/adminPermissionsWrapper/AdminPermissionsWrapper';
import {
    SHOP_ORIGIN_DA,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_US,
} from '../enums/ShopOrigins';
import MessengerLink from './components/MessengerLink';
import UserPermissionsWrapper from '../../../routes/UserPermissionsWrapper';
import {
    USER_PERMISSION_STATS_MENU_VISIBILITY,
    USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY,
    USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY,
    USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY,
    USER_PERMISSIONS_LIVE_MENU_VISIBILITY,
    USER_PERMISSIONS_LIVE_OVERLAY_MENU_VISIBILITY,
    USER_PERMISSIONS_ORDERS_MENU_VISIBILITY,
    USER_PERMISSIONS_SALES_POSTS_MENU_VISIBILITY,
    USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY,
    USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY,
} from '../enums/UserPermissionsTypes';
import ProductsMenu from './components/ProductsMenu';
import SubAside from './components/SubAside/SubAside';
import { asideSubMenuSetVisible } from './AsideActions';
import VacationButton from '../components/VacationButton/VacationButton';
import SelmoAppAd from './components/SelmoAppAd';
import BlackWeekButton from '../components/BlackWeekButton/BlackWeekButton';
import RequiredActionsAside from './components/RequiredActionsAside';

export const subMenuWidth = 340;

const Aside = ({ userData }) => {
    const { getLangText } = useLang();
    const asideRef = useRef();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { origin } = useSelector((state) => state.session.userData);
    const { subMenuOpen } = useSelector((state) => state.shared.aside);

    const body = document.querySelector('body');
    const showSubMenu = (toggle) =>
        dispatch(
            asideSubMenuSetVisible(toggle, getAsidePosition() + subMenuWidth),
        );

    const toggleMobileMenu = () => {
        if (body.classList.contains('open-menu')) {
            body.classList.remove('open-menu');
            showSubMenu(false);
        } else {
            body.classList.add('open-menu');
        }
    };

    const closeMobileMenu = () => {
        body.classList.remove('open-menu');
        if (subMenuOpen) {
            showSubMenu(false);
        }
    };

    const getAsidePosition = () => {
        if (asideRef.current) {
            const rect = asideRef.current.getBoundingClientRect();
            return rect.left + window.pageXOffset + rect.width;
        }
        return null;
    };

    const redirectToProperMarketingPage = () => {
        if (!!+userData.facebook_ads_active) {
            return RouterPaths.MarketingFacebookAdvertisements;
        }

        if (!!+userData.sms_active) {
            return RouterPaths.MarketingSmsCampaigns;
        }

        return RouterPaths.Marketing;
    };

    return (
        <aside ref={asideRef} style={{ zIndex: 11 }} className="main-aside">
            <div style={{ overflow: 'visible' }}>
                <div
                    className={`main-aside-wrapper overflow-y-auto lg:z-auto z-[999] lg:w-[210px] ${
                        subMenuOpen ? 'open' : ''
                    }`}>
                    <Link
                        to="/"
                        style={{
                            width: !!+userData.selmo_pro_active
                                ? 'auto'
                                : '100px',
                        }}
                        className="brand-name">
                        {!!+userData.selmo_pro_active ? (
                            <img
                                style={{ width: 'auto', height: 28 }}
                                src="/assets/images/svg/selmo-pro.svg"
                                alt="logo-selmo"
                            />
                        ) : (
                            <img
                                src="/assets/images/logo.svg"
                                alt="logo-selmo"
                            />
                        )}
                    </Link>
                    <div onClick={toggleMobileMenu} className="hamburger-btn">
                        <div className="bar1" />
                        <div className="bar3" />
                    </div>
                    {width < 991 && (
                        <div className="right-aside-wrapper">
                            {!!+userData.depot_system && (
                                <Link
                                    onClick={closeMobileMenu}
                                    className="ml-auto mr-3 flex hover:no-underline items-center"
                                    to={RouterPaths.StorageScanner}>
                                    <i className="icon-qr-code" />
                                </Link>
                            )}
                            <Profile userData={userData} />
                        </div>
                    )}
                    <Link
                        onClick={closeMobileMenu}
                        to="/"
                        className={`shop-info-box lg:border transition-all ${
                            subMenuOpen
                                ? 'delay-200 border-[#DFE1E4]'
                                : 'border-transparent'
                        }`}>
                        <div>
                            <figure>
                                {userData.logo && userData.logo !== '0' ? (
                                    <ServerImage
                                        src={userData.logo}
                                        alt={userData.name}
                                    />
                                ) : (
                                    <img
                                        src="/assets/images/default/shop.svg"
                                        alt={userData.name}
                                    />
                                )}
                            </figure>
                        </div>
                        {userData.name}
                    </Link>
                    <ul>
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                exact
                                activeClassName="active"
                                to="/">
                                <i className="icon-home" />
                                <span>
                                    {getLangText('asideDashboardLabel')}
                                </span>
                            </NavLink>
                        </li>
                        <MessengerLink closeMobileMenu={closeMobileMenu} />
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_ORDERS_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to="/zamowienia">
                                    <i className="icon-orders" />
                                    <span>
                                        {getLangText('asideOrdersLabel')}
                                    </span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        <ProductsMenu closeMobileMenu={closeMobileMenu} />
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to="/klienci">
                                    <i className="icon-clients" />
                                    <span>
                                        {getLangText('asideClientsLabel')}
                                    </span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        {(+userData.id === KOKARDECZKA_SHOP_ID ||
                            +userData.id === PRZEMO_SHOP_ID ||
                            +userData.id === TEST_ACCOUNT_SHOP_ID ||
                            +userData.id === INFINIK_SHOP_ID ||
                            +userData.id === SASSI_SHOP_ID ||
                            origin === SHOP_ORIGIN_DA ||
                            origin === SHOP_ORIGIN_US ||
                            (!!+userData.selmo_pro_active &&
                                origin === SHOP_ORIGIN_FR)) && (
                            <UserPermissionsWrapper
                                allowedPermissions={[
                                    USER_PERMISSION_STATS_MENU_VISIBILITY,
                                ]}>
                                <li>
                                    <NavLink
                                        onClick={closeMobileMenu}
                                        activeClassName="active"
                                        to="/statystyki">
                                        <i className="icon-stats" />
                                        <span>
                                            {getLangText('asideStatsLabel')}
                                        </span>
                                    </NavLink>
                                </li>
                            </UserPermissionsWrapper>
                        )}
                        <li>
                            <div className="text-xs font-bold text-[#9CA3AF] p-[8px] mt-3.5">
                                {getLangText('toolsLabel')}
                            </div>
                        </li>
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_LIVE_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to="/transmisje">
                                    <i className="icon-live" />
                                    <span>
                                        {getLangText('asideLivesLabel')}
                                    </span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        {(userData.origin === SHOP_ORIGIN_PL ||
                            userData.origin === SHOP_ORIGIN_FR) && (
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    isActive={(_, location) =>
                                        location.pathname.startsWith(
                                            '/marketing',
                                        )
                                    }
                                    to={redirectToProperMarketingPage()}>
                                    <i
                                        style={{ top: -0.5 }}
                                        className="icon-selmo-marketing"
                                    />
                                    <span> {getLangText('marketing')}</span>
                                    <div className="ml-auto bg-gradient-to-b from-[#F85F91] to-[#ED3158] text-[white] text-[8px] rounded-[3px] px-[3px] py-[1px] tracking-[0.5px] font-bold">
                                        NEW
                                    </div>
                                </NavLink>
                            </li>
                        )}
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_SALES_POSTS_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to={RouterPaths.SalesPost}>
                                    <i className="icon-add" />
                                    <span>{getLangText('salesPost')}</span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_LIVE_OVERLAY_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to={RouterPaths.LiveOverlay}>
                                    <i
                                        style={{ top: 0 }}
                                        className="icon-overlay"
                                    />
                                    <span>{getLangText('overlay')}</span>
                                    {!+userData.selmo_pro_active && (
                                        <img
                                            style={{ display: 'inline-block' }}
                                            className="w-[20px] ml-auto inline-block mb-[1px]"
                                            src="/assets/images/svg/pro.svg"
                                            alt="pro"
                                        />
                                    )}
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY,
                            ]}>
                            {!!+userData.instagram_automation && (
                                <li>
                                    <NavLink
                                        onClick={closeMobileMenu}
                                        activeClassName="active"
                                        to={RouterPaths.Automation}>
                                        <i className="icon-automate" />
                                        <span>
                                            {getLangText('automationLabel')}
                                        </span>
                                    </NavLink>
                                </li>
                            )}
                        </UserPermissionsWrapper>
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY,
                            ]}>
                            <li>
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to={RouterPaths.Notifications}>
                                    <i className="icon-notify" />
                                    {getLangText('notificationsLabel')}
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        {/*<UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY]}>*/}
                        {/*<li>*/}
                        {/*    <NavLink*/}
                        {/*        onClick={closeMobileMenu}*/}
                        {/*        activeClassName="active"*/}
                        {/*        to={RouterPaths.Analytics}*/}
                        {/*    >*/}
                        {/*        <i className="icon-analytics"/>*/}
                        {/*        {getLangText('analytics')}*/}
                        {/*        {!+userData.selmo_pro_active &&*/}
                        {/*            <img*/}
                        {/*                style={{display: 'inline-block'}}*/}
                        {/*                className="w-[20px] ml-auto inline-block mb-[1px]"*/}
                        {/*                src="/assets/images/svg/pro.svg"*/}
                        {/*                alt="pro"*/}
                        {/*            />*/}
                        {/*        }*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        {/*</UserPermissionsWrapper>*/}
                        <UserPermissionsWrapper
                            allowedPermissions={[
                                USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY,
                            ]}>
                            <li className="hidden-desktop">
                                <NavLink
                                    activeClassName="active"
                                    to={RouterPaths.Settings}
                                    onClick={closeMobileMenu}>
                                    <i className="icon-settings" />
                                    <span>
                                        {getLangText('asideSettingsLabel')}
                                    </span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        {/*<li className="hidden-desktop hidden-mobile-navbar">*/}
                        {/*	<NavLink*/}
                        {/*		onClick={closeMobileMenu}*/}
                        {/*		activeClassName="active"*/}
                        {/*		target="_blank"*/}
                        {/*		to={RouterPaths.KnowledgeBase}*/}
                        {/*	>*/}
                        {/*		<i className="icon-help"/>*/}
                        {/*		<div>*/}
                        {/*			<div>*/}
                        {/*				<span>Baza wiedzy</span>*/}
                        {/*			</div>*/}
                        {/*			<div className="bottom-text">*/}
                        {/*				Uzyskaj pomoc*/}
                        {/*			</div>*/}
                        {/*		</div>*/}
                        {/*	</NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <button
                                type="button"
                                className={`higher-macbook:text-sm text-xs font-[600] px-[7px] py-[5px] w-full transition-all rounded-[5px] text-left ${
                                    subMenuOpen
                                        ? 'bg-[#F9FAFB] delay-200 text-[#000]'
                                        : 'hover:text-[#000] text-[#697281]'
                                }`}
                                onClick={(e) => showSubMenu(!subMenuOpen)}>
                                <i
                                    style={{ top: 1, position: 'relative' }}
                                    className={`icon-more min-w-[17px] w-[17px] mr-[8px] transition-all inline-block ${
                                        subMenuOpen
                                            ? 'delay-200 text-[#000]'
                                            : 'text-[#9CA3AF]'
                                    }`}
                                />
                                <span>
                                    {getLangText(
                                        subMenuOpen ? 'less' : 'moreLabel',
                                    )}
                                </span>
                            </button>
                        </li>
                        {/*<SelmoAppAd closeMobileMenu={closeMobileMenu}/>*/}
                        <li className="sm:hidden">
                            <BlackWeekButton
                                closeMobileMenu={closeMobileMenu}
                                mobile
                            />
                        </li>
                        {!!+userData.selmo_pro_active && (
                            <UserPermissionsWrapper
                                allowedPermissions={[
                                    USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY,
                                ]}>
                                <li className="sm:hidden">
                                    <Link
                                        onClick={closeMobileMenu}
                                        to={RouterPaths.MrAutomate}
                                        style={{ padding: 4, paddingLeft: 7 }}
                                        className={`bg-[#fff] rounded-[5px] flex items-center p-[2px] pl-[6px] hover:no-underline selmo-pro-box light-version transition-all border mt-3 ${
                                            subMenuOpen
                                                ? 'delay-200 border-[#DFE1E4]'
                                                : 'border-transparent'
                                        }`}>
                                        <div className="font-bold">
                                            <div className="text-[#000] text-sm">
                                                {getLangText(
                                                    'panAutomacikLabel',
                                                )}
                                            </div>
                                            <div className="text-xs text-[#279bff] font-bold flex items-center">
                                                <div className="relative animate-showAndHide flex h-[3px] w-[3px] min-w-[3px] mr-0.5">
                                                    <span className="absolute inline-flex h-full w-full rounded-full bg-[#279bff]"></span>
                                                </div>
                                                &nbsp;
                                                {getLangText('workInProgress')}
                                            </div>
                                        </div>
                                        <div className="ml-auto w-[40px] h-[40px] rounded-[5px] overflow-hidden">
                                            <img
                                                style={{ display: 'block' }}
                                                src="/assets/images/automate-mini.webp"
                                                className="w-full h-full object-cover"
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                </li>
                            </UserPermissionsWrapper>
                        )}
                        {+userData.selmo_boss === 69 && (
                            <li className="sm:hidden">
                                <RequiredActionsAside
                                    subMenuOpen={subMenuOpen}
                                    closeMobileMenu={closeMobileMenu}
                                />
                            </li>
                        )}
                        {(!+userData.selmo_pro_active ||
                            !!+userData?.selmo_pro_qualification) && (
                            <li className="sm:hidden">
                                <NavLink
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    className="mt-2"
                                    to={RouterPaths.SelmoPro}>
                                    <i
                                        style={{ top: -0.5, fontSize: 17 }}
                                        className="icon-upgrade top-0"
                                    />
                                    <span>Selmo Pro</span>
                                </NavLink>
                            </li>
                        )}
                        <li className="hidden-desktop hidden-mobile-navbar">
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to="/pomoc/">
                                <i className="icon-help" />
                                <div>
                                    <div>
                                        <span>
                                            {getLangText('asideHelpLabel')}
                                        </span>
                                    </div>
                                    <div className="bottom-text">
                                        {getLangText('watchTheVideoLabel')}
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                        {/*<ImproveSelmo hiddenOnDesktop/>*/}
                    </ul>
                    <div className="bottom-part">
                        <ul>
                            {/*<ImproveSelmo/>*/}
                            {+userData.selmo_boss === 69 && (
                                <li className="">
                                    <RequiredActionsAside
                                        subMenuOpen={subMenuOpen}
                                        closeMobileMenu={closeMobileMenu}
                                    />
                                </li>
                            )}
                            {!!+userData.selmo_pro_active && (
                                <UserPermissionsWrapper
                                    allowedPermissions={[
                                        USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY,
                                    ]}>
                                    <li>
                                        <Link
                                            onClick={closeMobileMenu}
                                            to={RouterPaths.MrAutomate}
                                            style={{
                                                padding: 4,
                                                paddingLeft: 7,
                                            }}
                                            className={`bg-[#fff] rounded-[5px] flex items-center p-[2px] pl-[6px] hover:no-underline selmo-pro-box light-version transition-all border mt-2 ${
                                                subMenuOpen
                                                    ? 'delay-200 border-[#DFE1E4] hover:border-[#d5e9f4]'
                                                    : 'border-transparent'
                                            }`}>
                                            <div className="font-bold">
                                                <div className="text-[#000] text-sm">
                                                    {getLangText(
                                                        'panAutomacikLabel',
                                                    )}
                                                </div>
                                                <div className="text-xs text-[#279bff] font-bold flex items-center">
                                                    <div className="relative animate-showAndHide flex h-[3px] w-[3px] min-w-[3px] mr-0.5">
                                                        <span className="absolute inline-flex h-full w-full rounded-full bg-[#279bff]"></span>
                                                    </div>
                                                    &nbsp;
                                                    {getLangText(
                                                        'workInProgress',
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ml-auto w-[40px] h-[40px] rounded-[5px] overflow-hidden">
                                                <img
                                                    style={{ display: 'block' }}
                                                    src="/assets/images/automate-mini.webp"
                                                    className="w-full h-full object-cover"
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                </UserPermissionsWrapper>
                            )}
                            {(!+userData.selmo_pro_active ||
                                !!+userData?.selmo_pro_qualification) && (
                                <li>
                                    <NavLink
                                        className="mt-2"
                                        onClick={closeMobileMenu}
                                        activeClassName="active"
                                        to={RouterPaths.SelmoPro}>
                                        <i
                                            style={{ top: -0.5, fontSize: 17 }}
                                            className="icon-upgrade top-0"
                                        />
                                        <span>Selmo Pro</span>
                                    </NavLink>
                                </li>
                            )}
                            {!userData.user_info.status && (
                                <li>
                                    <Link
                                        onClick={closeMobileMenu}
                                        to={RouterPaths.Payments}
                                        data-text="Wybierz plan"
                                        className="choose-plan">
                                        <span>
                                            {getLangText(
                                                'asideSelectPlanLabel',
                                            )}
                                        </span>
                                    </Link>
                                </li>
                            )}
                            <AdminPermissionsWrapper>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        onClick={closeMobileMenu}
                                        activeClassName="active"
                                        to={RouterPaths.Admin}>
                                        {/*<img className="d-inline-block mr-2"*/}
                                        {/*     style={{borderRadius: '50%', objectFit: 'cover'}} width={30} height={30}*/}
                                        {/*     src="/assets/images/flags/przemo.png" alt=""/>*/}
                                        <div className="w-[22px] h-[22px] rounded-full bg-[#697281] text-white flex items-center justify-center mr-2">
                                            A
                                        </div>
                                        <span>Admin</span>
                                    </NavLink>
                                </li>
                            </AdminPermissionsWrapper>
                            <UserPermissionsWrapper
                                allowedPermissions={[
                                    USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY,
                                ]}>
                                <li className="active">
                                    <NavLink
                                        to={RouterPaths.Settings}
                                        activeClassName="active"
                                        onClick={closeMobileMenu}>
                                        <i className="icon-settings" />
                                        <span>
                                            {getLangText('asideSettingsLabel')}
                                        </span>
                                    </NavLink>
                                </li>
                            </UserPermissionsWrapper>
                        </ul>
                    </div>
                </div>
                {subMenuOpen !== null && <SubAside />}
            </div>
        </aside>
    );
};

export default Aside;
