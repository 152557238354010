import { Helmet } from 'react-helmet';
import useLang from '../../../src_shared/hooks/useLang';
import { useState, useMemo } from 'react';
import Product from './components/Product';
import useFetchData from '../../../src_shared/hooks/useFetchData';
import { services } from '../../RestServices';
import { useParams } from 'react-router-dom';
import ErrorPage from '../errorPage/Page';
import { LottieLoading } from '../../../App';
import useFormLocal from '../../../src_shared/hooks/useFormLocal';
import OrderCreateModel from '../../admin/modules/models/OrderCreateModel';
import DeliveryAddressFormModal from './components/DeliveryAddressFormModal';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { showAlert } from '../shared/components/alert/AlertActions';
import Alert from '../shared/components/alert/Alert';
import { formatPrice } from '../../../src_shared/helpers/price';
import { useEffect } from 'react';

export const getNewId = () => new Date().getTime();

const OrderCreate = () => {
    const dispatch = useDispatch();
    const { getLangText } = useLang();
    const { id } = useParams();
    const [addressModalVisible, setAddressModalVisible] = useState(false);

    const [{ data, isLoading, isError }] = useFetchData({
        rest: `${services.API_CART_ORDER_PRODUCTS}/${id}`,
    });

    const [
        {
            onSave,
            isWaiting,
            values,
            setValue,
            getFieldProps,
            updateValues,
            setNestedValue,
            validateErrors,
            setIsValidationActive,
        },
    ] = useFormLocal({
        rest: `${services.API_CART_ORDER_PRODUCTS}/${id}`,
        model: OrderCreateModel,
        initialAutoLoad: false,
        afterSubmit: (response) => {
            window.location.href = response.cart_url;
        },
    });

    const handleAddProduct = () => {
        const emptyProduct = { id: uuidv4(), name: '', price: '', qty: '1' };
        updateValues({
            products: [...values.products, emptyProduct],
        });
    };

    const handleOpenAddressModal = () => {
        let productsToValidate = [...values.products];

        if (values.products.length > 1) {
            const filteredProducts = values.products.filter(
                (product) =>
                    !(
                        product.name === '' &&
                        product.price === '' &&
                        product.qty === '1'
                    ),
            );

            if (filteredProducts.length !== values.products.length) {
                updateValues({
                    products: filteredProducts,
                });

                productsToValidate = filteredProducts;
            }
        }

        const isValid = OrderCreateModel.validateProducts(productsToValidate);

        if (isValid) {
            setIsValidationActive(false);
            setAddressModalVisible(true);
        } else {
            setIsValidationActive(true);
        }
    };

    const totalPrice = useMemo(() => {
        return values.products.reduce(
            (acc, product) => acc + +product.price * +product.qty,
            0,
        );
    }, [values.products]);

    const isAddressValid = useMemo(() => {
        return OrderCreateModel.validateAddress(values.address);
    }, [values.address]);

    if (isLoading) {
        return <LottieLoading />;
    }

    if (isError) {
        return <ErrorPage />;
    }

    return (
        <>
            <Helmet>
                <title>{getLangText('orderCreate') + ' - ' + data?.name}</title>
            </Helmet>
            <main className="flex flex-col items-center justify-center">
                <div className="flex flex-col items-start gap-3.5 mt-10">
                    <h1 className="text-2xl font-bold">
                        {getLangText('orderCreate')}
                    </h1>
                    <div className="flex flex-col default-shadow-box p-4 w-full sm:w-[500px] gap-5">
                        <div className="w-full flex flex-col items-center justify-center">
                            <img
                                src={data?.logo}
                                alt={data?.name}
                                className="w-28 h-28 object-cover rounded-full shadow-md"
                            />
                            <p className="text-md font-bold mt-2.5">
                                {data?.name}
                            </p>
                            {data?.description && (
                                <p className="text-sm text-[#9CA3AF] text-center">
                                    {data?.description}
                                </p>
                            )}
                        </div>
                        <div className="w-full flex flex-col">
                            <h2 className="text-lg font-bold self-start mb-2.5">
                                {getLangText('asideProductsLabel')}
                            </h2>
                            {values.products?.map((product) => (
                                <Product
                                    key={product.id}
                                    product={product}
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    setValue={setValue}
                                    setNestedValue={setNestedValue}
                                    validateErrors={validateErrors}
                                />
                            ))}
                            <button
                                type="button"
                                onClick={handleAddProduct}
                                className="button text-blue-button add-new-row">
                                <i className="icon-plus" />
                                {getLangText('addNextProduct')}
                            </button>
                        </div>
                        <p className="self-end">
                            <span>{getLangText('orderPrice')}: </span>
                            <span className="font-bold">
                                {formatPrice(totalPrice)}{' '}
                                {data?.currency_shortcut}
                            </span>
                        </p>
                        <div className="w-full flex justify-end">
                            <button
                                type="button"
                                className="button primary"
                                onClick={handleOpenAddressModal}>
                                <span className="font-bold">
                                    {getLangText('orderCreate')}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
            {addressModalVisible && (
                <DeliveryAddressFormModal
                    shopId={data?.id}
                    hideModal={() => setAddressModalVisible(false)}
                    modalVisible={addressModalVisible}
                    values={values}
                    setValue={setValue}
                    updateValues={updateValues}
                    onSave={onSave}
                    getFieldProps={getFieldProps}
                    isWaiting={isWaiting}
                    validateErrors={validateErrors}
                    isAddressValid={isAddressValid}
                />
            )}
            <Alert />
        </>
    );
};

export default OrderCreate;
