import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../../../shared/Loader';
import useLang from '../../../../../../src_shared/hooks/useLang';
import useFormLocal from '../../../../../../src_shared/hooks/useFormLocal';
import { services } from '../../../../../RestServices';
import Model from '../../../../../../src_shared/modules/model/Model';
import Field from '../../../../../../src_shared/components/form/field/Field';
import CharactersCounter from '../../../../../../src_shared/components/ui/CharactersCounter';
import {
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_FR,
} from '../../../../shared/enums/ShopOrigins';
import { useSelector } from 'react-redux';

const FORMS = {
    [SHOP_ORIGIN_PL]: 'https://r2.selmo.io/smsapi-selmo-oswiadczenie.pdf',
    [SHOP_ORIGIN_FR]:
        'https://r2.selmo.io/selmo-marketing-acces-page-et-pub.pdf',
};

const PermissionFormModal = ({ isOpen, hideModal }) => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);

    const afterSubmit = () => {
        window.open(FORMS[userData.origin]);
        hideModal();
    };

    const [{ onSave, isWaiting, getFieldProps, values }] = useFormLocal({
        rest: services.API_MARKETING_SMS_SHOP_NAME,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit,
    });

    return (
        <Modal
            dialogClassName="default-modal w-420"
            show={isOpen}
            onHide={hideModal}>
            <form onSubmit={onSave}>
                <Modal.Header>
                    <Modal.Title>
                        {getLangText('completeDaneInSenderField')}
                    </Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button">
                        <i className="icon-cross" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="characters-counter-parent">
                        <Field
                            {...getFieldProps('name')}
                            className="mb-sm-0 mb-3"
                            label={getLangText('enterNameSenderField')}
                            placeholder={getLangText('typeName')}
                            extraProps={{
                                maxLength: 11,
                                minLength: 3,
                                required: true,
                            }}
                        />
                        <CharactersCounter value={values.name} max={11} />
                    </div>
                    <div className="form-info-box small-line-height mt-2 w-100">
                        <div>
                            <i className="icon-info-c" />
                        </div>
                        <div>
                            <div className="title">
                                {getLangText('maxLength11Characters')}
                            </div>
                            <div className="desc">
                                {getLangText(
                                    'nameCannotContainPolishCharacters',
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 pt-4 border-t">
                        <div className="font-bold text-lg leading-[22px] mb-3">
                            {getLangText('downloadAndCompleteForm')}
                        </div>
                        <div className="text-desc text-sm font-medium">
                            <div>{getLangText('sendScanOrPhotoOnAddress')}</div>
                            <strong>kontakt@selmo.io</strong>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className={`button primary small-size ${isWaiting ? 'loading-button' : ''}`}>
                        {isWaiting && <Loader isLoading />}
                        {getLangText('downloadAgreementFormButton')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default PermissionFormModal;
