import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";

import {selmoUrl} from "../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";
import {services} from "../../../RestServices";
import {
	CUSTOMER_BASKET_DELIVERY_METHODS_VIEW,
	CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW, CUSTOMER_BASKET_PAYMENT_IN_PROGRESS_VIEW
} from "../../shared/enums/CustomerBasketViews";
import BasketDeliveryMethodsFormActions from "../deliveryMethods/FormActions";
import {batch} from "react-redux";
import BasketPaymentMethodsFormActions from "../paymentMethods/FormActions";
import {getGETParamsUrl, parseParams} from "../../../../src_shared/list/ListActions";
import {validateEmail} from "../../../../src_shared/form/validation/ValidationError";
import {validatePhone, validateZipCode} from "../../../../modules/models/basket/BasketDeliveryAddressModel";
import BasketDeliveryAddressFormActions from "../deliveryAddress/FormActions";
import api from "../../../../services/axios/axios";
import {
	getPointMachineType,
} from "../secondStep/SecondStep";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_US} from "../../shared/enums/ShopOrigins";
import history from "../../../history";

export class BasketPageFormActions extends FormActions {
	setBasketView(view) {
		return {
			type: `${this.prefix}SET_BASKET_VIEW`,
			view,
		}
	}

	loadData(magicUrl, shopId) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}?magic_url=${magicUrl}&shop_id=${shopId}`)
				const parsedItem = dispatch(this.parseData(data.item));
				const origin = parsedItem?.shopInfo?.origin;
				const isInValidEmail = !!parsedItem.shippingAddress.email ? validateEmail(parsedItem.shippingAddress.email) : true;
				const isInvalidZipCode = !!parsedItem.shippingAddress.zip_code ? validateZipCode(parsedItem.shippingAddress.zip_code, {
					...parsedItem.shippingAddress,
					selectedCountry: {
						name: parsedItem.shippingAddress.country_name
					}
				}) : true;
				const isInvalidPhone = !!parsedItem.shippingAddress.phone ? validatePhone(parsedItem.shippingAddress.phone, {
					...parsedItem.shippingAddress,
					selectedCountry: {
						name: parsedItem.shippingAddress.country_name
					}
				}) : true;
				const isDeliveryAddressCompleted = (
					!!parsedItem
					&& !!parsedItem.shippingAddress?.first_name
					&& !!parsedItem.shippingAddress?.last_name
					&& !isInValidEmail
					&& !!parsedItem.shippingAddress?.country_id
					&& !!parsedItem.shippingAddress?.street
					&& !!parsedItem.shippingAddress?.building_number
					&& !isInvalidZipCode
					&& !isInvalidPhone
					&& (origin !== SHOP_ORIGIN_US || !!parsedItem.shippingAddress?.state)
				);
				const queryParams = history.location.search;
				const parsedParams = parseParams(queryParams)
				if (isDeliveryAddressCompleted && !+parsedParams.addressDataStep) {
					dispatch(this.setBasketView(CUSTOMER_BASKET_DELIVERY_METHODS_VIEW))
				}
				if (!!+parsedItem.is_order_finished) {
					dispatch(this.setBasketView(CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW))
				}
				if (!!+parsedItem.payment_in_progress) {
					dispatch(this.setBasketView(CUSTOMER_BASKET_PAYMENT_IN_PROGRESS_VIEW))
				}
				const preparedShippingAddress = dispatch(BasketDeliveryAddressFormActions.parseData(data.item.shippingAddress))

				batch(() => {
					dispatch(this.data.updateValues(parsedItem))
					dispatch(BasketDeliveryAddressFormActions.data.updateValues({
						...preparedShippingAddress,
						magic_url: magicUrl,
						shop_id: shopId,
						shipping_cart: data.item.shipping_cart,
						origin,
					}))
					dispatch(BasketDeliveryMethodsFormActions.data.updateValues({
						shipping: parsedItem.overall?.shipping_value?.id,
						pointMachineCode: parsedItem.overall?.shipping_value?.inpost_box_code || parsedItem.overall?.shipping_value?.mondial_box_code || parsedItem.overall?.shipping_value?.orlen_paczka_box_psd_code || parsedItem.overall?.shipping_value?.dpd_box_code || parsedItem.overall?.shipping_value?.box_code,
						pointMachineStreet: parsedItem.overall?.shipping_value?.inpost_box_street || parsedItem.overall?.shipping_value?.mondial_box_street || parsedItem.overall?.shipping_value?.orlen_paczka_box_street || parsedItem.overall?.shipping_value?.dpd_box_street || parsedItem.overall?.shipping_value?.box_street,
						pointMachineCity: parsedItem.overall?.shipping_value?.inpost_box_city || parsedItem.overall?.shipping_value?.mondial_box_city || parsedItem.overall?.shipping_value?.orlen_paczka_box_city || parsedItem.overall?.shipping_value?.dpd_box_city || parsedItem.overall?.shipping_value?.box_city,
						pointMachineId: parsedItem.overall?.shipping_value?.orlen_paczka_box_code,
						pointMachineName: parsedItem.overall?.shipping_value?.dpd_box_name || parsedItem.overall?.shipping_value?.box_name,
						pointMachineZipCode: parsedItem.overall?.shipping_value?.box_zipcode,
						pointMachineType: getPointMachineType(parsedItem.overall?.shipping_value),
						open_cart_status: (+parsedItem.overall?.open_cart_status === 1 && !+parsedItem.overall.open_cart_surcharges && (+shopId === TEST_ACCOUNT_SHOP_ID || +shopId === BUTIK_MADAME_SHOP_ID)) ? null : parsedItem.overall?.open_cart_status,
						package_type_id: parsedItem.overall?.package_type?.id,
						openCartEnabled: !!+parsedItem.shopInfo?.open_cart_enabled,
						orderStage: +parsedItem.shopInfo?.order_stage,
						agreement: +parsedItem.shippingAddress?.agreement,
						selmo_marketing_agreement: +parsedItem.shippingAddress?.selmo_marketing_agreement,
						shop_marketing_agreement: +parsedItem.shippingAddress?.shop_marketing_agreement,
						stripe_tax_enabled: !!+parsedItem.shopInfo?.stripe_tax_enabled,
						shipping_cart: data.item.shipping_cart
					}))
					dispatch(BasketDeliveryMethodsFormActions.updateShippingMethods(parsedItem.shippingMethods))
					dispatch(BasketPaymentMethodsFormActions.data.setValue('payment_methods', parsedItem.paymentMethods))
					if (parsedItem.overall?.payment_value?.type) {
						dispatch(BasketPaymentMethodsFormActions.data.setValue('payment', parsedItem.overall?.payment_value?.type))
					}
					if (parsedItem.overall?.open_cart_status) {
						dispatch(BasketDeliveryMethodsFormActions.setPaymentsMethodsVisible(true));
					} else {
						dispatch(BasketPaymentMethodsFormActions.data.setValue('payment', null))
					}
				})
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	getAndUpdatePrices(magicUrl, shopId) {
		return async (dispatch) => {
			const params = {
				magic_url: magicUrl,
				shop_id: shopId,
			};
			try {
				const {data} = await api.get(`${selmoUrl}/${services.API_CART_OVERALL_INFO}${getGETParamsUrl(params)}`, {
					magic_url: magicUrl,
					shop_id: shopId,
				})
				dispatch(this.data.setValue('overall', data.item))
			} catch (e) {
				console.error('Error send value on server')
			}
		}
	}
}

export const getInstance = () => new BasketPageFormActions({
	prefix,
	getStateRoot,
	model: BasketDeliveryMethodModel,
	restService: services.API_CART_CART_INFO,
});

export default getInstance();

