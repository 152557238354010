import useLang from "../../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../../RestServices";
import {Modal} from "react-bootstrap";
import React from "react";
import useSubmitData from "../../../../../../../../src_shared/hooks/useSubmitData";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import {useHistory} from "react-router-dom";
import Loader from "../../../../../../../../src_shared/components/loader/Loader";

export const MARKETING_INCREASE_SALE_VIEW = 1;
export const MARKETING_MORE_FAN_PAGE_LIKES_VIEW = 2;

const options = [
    {id: MARKETING_INCREASE_SALE_VIEW, label: 'increaseSaleAndReach', icon: 'rocket'},
    {id: MARKETING_MORE_FAN_PAGE_LIKES_VIEW, label: 'getMoreFanpageLikes', icon: 'thumbs-up-like'},
]

const SelectGoalModal = ({show, hideModal}) => {
    const {getLangText} = useLang();
    const history = useHistory();

    const [{onSave: createAd, isWaiting: isWaitingForAd}] = useSubmitData({
        rest: services.API_MARKETING_AD,
        afterSubmit: (response) => history.push(`${RouterPaths.MarketingFacebookAdvertisements}/details/${response.id}`),
    });

    return (
        <Modal
            show={show}
            className="add-order-modal"
            dialogClassName="modal-dialog-centered default-modal medium-modal full-height-mobile"
            contentClassName="rounded-xl sm:py-10 p-3.5"
            // backdrop={false}
            onHide={hideModal}
        >
            <Modal.Body
                style={{
                    margin: 0,
                }}
                className="defined-variants-modal-body payments-container">
                <button
                    onClick={hideModal}
                    type="button"
                    className="absolute right-0 top-0 sm:top-[-22px] w-[28px] h-[28px] text-[#101827] bg-[#f3f4f6] flex items-center justify-center text-[8px] rounded-full"
                >
                    <i className="icon-cross"/>
                </button>
                <div className="sm:text-center">
                    <div className="text-2xl font-bold">
                        {getLangText('selectAdGoal')}
                    </div>
                    <div className="font-medium text-sm text-desc mb-4">
                        {getLangText('selectAdGoalSubLabel')}
                    </div>
                </div>
                <div className="loader-parent">
                    {isWaitingForAd && <Loader className="small-loader" isLoading={true} /> }
                    <div className="space-y-2">
                        {options.map((item) => (
                            <button
                                type="button"
                                key={item.id}
                                className="border rounded-big shadow-selmo-border flex items-center p-3 w-full text-left hover:border-[#3C61EA] group transition-all"
                                onClick={(e) => createAd(e, {type: item.id})}
                            >
                                <div
                                    className="w-[44px] shadow-selmo-border h-[44px] rounded-big border flex items-center justify-center mr-2">
                                    <img src={`/assets/images/marketing/${item.icon}.svg`} alt=""/>
                                </div>
                                <div className="font-bold grow leading-[20px]">
                                    {getLangText(item.label)}
                                </div>
                                <i className="icon-arrows text-desc block text-[8px] -rotate-90 group-hover:text-[#3C61EA] transition-all"/>
                            </button>
                        ))}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SelectGoalModal;
