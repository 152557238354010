import React, {useEffect} from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import BoxWrapper from "../BoxWrapper";
import SwitchField from "../../../../../../../../../src_shared/components/form/switchField/SwitchField";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import {prepareRealLang} from "../../../../../../../../../services/i18n/i18nAPI";
import {useSelector} from "react-redux";
import {convertToUserTimeZone} from "../../../../../../../shared/helpers/dateHelpers";
import ValidationBox from "../ValidationBox";
import moment from "moment/moment";
import {variants} from "../../../../../../../../../src_shared/helpers/helper";
import {dniVariants} from "../../../../../../../payments/Form";
import {MARKETING_INCREASE_SALE_VIEW} from "../../../list/components/SelectGoalModal";
import {useUnitPrice} from "../../../../../../../shared/helpers/Price";

export const DAILY_BUDGET = 1;
export const TOTAL_BUDGET = 2;

const tabs = [
    {id: DAILY_BUDGET, label: 'dailyBudget'},
    {id: TOTAL_BUDGET, label: 'totalBudget'},
]
const Budget = ({values, setValue, getFieldProps, hasError}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const startDate = convertToUserTimeZone(new Date()).toDate()
    const endDate = convertToUserTimeZone(values.end_date).toDate();
    const daysDifference = Math.ceil(moment(endDate).diff(startDate, 'hours') / 24) + 1;
    const {getPrice} = useUnitPrice();

    const {userData} = useSelector((state) => state.session);
    const currency = userData.currency_info?.text;
    const minBudgetValue = +userData.currency_info?.id === 1 ? 50 : 20;

    useEffect(() => {
        if (+values.budget_type === TOTAL_BUDGET) {
            setValue('end_date_active', 1)
        }
    }, [values.budget_type]);

    // useEffect(() => {
    //     if (!!userData?.currency_info?.id) {
    //         setValue('budget_value', minBudgetValue)
    //     }
    // }, [userData?.currency_info?.id]);

    const minBudget =
        (+values.budget_type === TOTAL_BUDGET && values.end_date)
            ? minBudgetValue * +daysDifference
            : minBudgetValue;

    useEffect(() => {
        if (+values.budget_type === TOTAL_BUDGET && values.end_date) {
            if (+values.budget_value < +minBudget) {
                setValue('budget_value', minBudget);
            }
        }
    }, [values.budget_type, values.end_date, values.start_date, values.budget_value, daysDifference, minBudget]);

    const handleDecrease = () => {
        if (+values.budget_value - minBudgetValue >= minBudget) {
            setValue('budget_value', +values.budget_value - minBudgetValue);
        } else {
            setValue('budget_value', minBudget);
        }
    }


    return (
        <BoxWrapper
            title="adjustAdBudget"
            desc="adjustAdBudgetDesc"
        >
            <div className="grid grid-cols-2 bg-[#f3f4f6] rounded-medium p-1 mt-3">
                {tabs.map((item) => (
                    <div
                        key={item.id}
                        className={+item.id === +values.budget_type ? 'shadow-default-small rounded-medium overflow-hidden' : ''}>
                        <button
                            onClick={() => setValue('budget_type', item.id)}
                            className={`text w-full text-xs py-2 transition-all font-bold ${+item.id === +values.budget_type ? 'bg-[#fff] text-[#101827]' : 'text-[#6b7280]'}`}
                            type="button"
                        >
                            {getLangText(item.label)}
                        </button>
                    </div>
                ))}
            </div>
            <div>
                <div className="flex items-center justify-between bg-[#f9fafb] rounded-medium px-3 py-2 mt-2">
                    <div className="shadow-default-small">
                        <div class="tooltip-parent">
                            {(+values.budget_value - minBudgetValue) < minBudget && +values.budget_type === TOTAL_BUDGET &&
                                <div className="tooltip-content left-position">
                                    {getLangText({
                                        key: 'minBudgetForDays',
                                        data: [`${minBudget} ${currency}`, `${daysDifference} ${getLangText(variants(daysDifference, dniVariants))}`, `${+values.budget_value / daysDifference} ${currency}`]
                                    })}
                                </div>
                            }
                            <button
                                className="w-[40px] h-[40px] rounded-[6px] flex items-center justify-center bg-[#fff] text-[#6b7280] text-xl"
                                onClick={handleDecrease}
                                type="button"
                                // disabled={(+values.budget_value - minBudgetValue) < minBudget}
                            >
                                <span className="-mt-0.5">-</span>
                            </button>
                        </div>
                    </div>
                    <div className="text-[36px] font-medium text-[#101827]">
                        {values.budget_value} <span className="text-[24px] text-[#4b5563]">{currency}</span>
                    </div>
                    <div className="shadow-default-small rounded-[6px] overflow-hidden">
                        <button
                            className="w-[40px] h-[40px] flex items-center justify-center bg-[#fff] rounded-[6px] text-[#6b7280] text-[10px]"
                            onClick={() => setValue('budget_value', +values.budget_value + minBudgetValue)}
                            type="button"
                        >
                            <i className="icon-plus"/>
                        </button>
                    </div>
                </div>
                <div className="form-info-box align-items-center w-100 mb-0 mt-2 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div
                            className="title font-weight-normal"
                            dangerouslySetInnerHTML={{
                                __html: getLangText({
                                    key: +values.budget_type === DAILY_BUDGET ? 'everydayYouWillSpendMoney1' : 'youWillSpendMoneyForWholeAd',
                                    data: [getPrice(values.budget_value)]
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="border-t pt-4 mt-4 mb-3">
                    <div class="tooltip-parent">
                        {+values.budget_type === TOTAL_BUDGET &&
                            <div className="tooltip-content left-position">
                                {getLangText('totalBudgetRequireToSetEndDate')}
                            </div>
                        }
                        <SwitchField
                            {...getFieldProps('end_date_active')}
                            name="end_date_active"
                            className="switch-form inline-label big-label light-version mb-0"
                            setValue={setValue}
                            label={getLangText('setFinishDate')}
                            disabled={+values.budget_type === TOTAL_BUDGET}
                        />
                    </div>
                    {!!+values.end_date_active &&
                        <>
                            <div className="grid grid-cols-2 gap-4 mt-3 animate-[slideAndShow_.3s_ease_forwards]">
                                <div className="form-group calendar-input mb-2">
                                    <div className="control-label">
                                        {getLangText('dateLabel')}
                                    </div>
                                    <DatePicker
                                        portalId="my-popper"
                                        className="form-control"
                                        selected={values.end_date}
                                        onChange={(value) => setValue('end_date', value)}
                                        locale={lang}
                                        dateFormat="dd.MM.y"
                                        minDate={convertToUserTimeZone(moment(new Date()).add(1, 'day')).toDate()}
                                    />
                                    <i className="icon-calendar"/>
                                </div>
                                <div className="form-group mb-2">
                                    <div className="control-label">
                                        {getLangText('hour')}
                                    </div>
                                    <div className="react-time-picker-wrapper">
                                        <TimePicker
                                            clearIcon={false}
                                            className="form-control flex items-center"
                                            disableClock={true}
                                            locale={prepareRealLang(lang)}
                                            onChange={(value) => setValue('end_time', value)}
                                            value={values.end_time}
                                            format="HH:mm"
                                        />
                                        <i className="icon-time"/>
                                    </div>
                                </div>
                            </div>
                            <ValidationBox
                                className="mb-4"
                                visible={hasError}
                                title={'totalBudgetRequireToSetEndDate'}
                            />
                        </>
                    }
                </div>
                {+values.ad_target === MARKETING_INCREASE_SALE_VIEW &&
                    <SwitchField
                        {...getFieldProps('unactive_when_sold')}
                        name="unactive_when_sold"
                        className="switch-form inline-label big-label align-items-start light-version mb-0"
                        setValue={setValue}
                        label={getLangText('turnWhenProductsWillBeSold')}
                        subLabel={getLangText('turnWhenProductsWillBeSoldSubLabel')}
                    />
                }
            </div>
        </BoxWrapper>
    );
};

export default Budget;
