import Graphemer from 'graphemer';

const DOUBLE_GRAPHEMES = new Set('^{}[]~\\|€');
const SPECIAL_GRAPHEMES = new Set('ąćęłńóśźżĄĆĘŁŃÓŚŹŻ');
const PRICE_PER_PART = 0.15;

const LIMITS = {
    standard: {
        singlePart: 160,
        multiPart: 153,
    },
    special: {
        singlePart: 70,
        multiPart: 67,
    },
};

const splitter = new Graphemer();

function isDoubleGrapheme(grapheme) {
    return DOUBLE_GRAPHEMES.has(grapheme);
}

function isSpecialGrapheme(grapheme) {
    return (
        SPECIAL_GRAPHEMES.has(grapheme) ||
        (/\p{Emoji}/u.test(grapheme) && !/^\d$/u.test(grapheme))
    );
}

function calculateCharacterLimit(parts, hasSpecial) {
    const limits = hasSpecial ? LIMITS.special : LIMITS.standard;
    return parts === 1 ? limits.singlePart : limits.multiPart * parts;
}

function calculateEffectiveLength(message) {
    const graphemes = splitter.splitGraphemes(message);
    let totalLength = 0;
    let hasSpecial = false;

    for (const grapheme of graphemes) {
        if (isDoubleGrapheme(grapheme)) {
            totalLength += 2;
        } else if (isSpecialGrapheme(grapheme)) {
            totalLength += grapheme.length;
            hasSpecial = true;
        } else {
            totalLength += 1;
        }
    }

    return { length: totalLength, hasSpecial };
}

export function processMessage(message) {
    const { length: totalLength, hasSpecial } =
        calculateEffectiveLength(message);

    let parts = 1;

    while (totalLength > calculateCharacterLimit(parts, hasSpecial)) {
        ++parts;
    }

    const limit = calculateCharacterLimit(parts, hasSpecial);

    return {
        hasSpecial,
        parts,
        limit,
        price: parts * PRICE_PER_PART,
        length: totalLength,
    };
}
