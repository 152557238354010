import React from 'react';
import CheckboxField from '../../../../src_shared/components/form/checkboxField/CheckboxField';
import useLang from '../../../../src_shared/hooks/useLang';
import Field from '../../../../src_shared/components/form/field/Field';

const InvoiceData = ({
    getFieldProps,
    setValue,
    values,
    inOrder = false,
    validateErrors,
}) => {
    const { getLangText } = useLang();

    return (
        <div className="w-full">
            <CheckboxField
                {...getFieldProps('want_invoice')}
                value={values.address.want_invoice}
                LabelComponent={getLangText('wantInvoiceLabel')}
                setValue={setValue}
            />
            {!!+values.address.want_invoice && (
                <Field
                    {...getFieldProps('nip')}
                    value={values.address.nip}
                    label={getLangText('nipLabel')}
                    setValue={setValue}
                    floatedLabel={!inOrder}
                    className="w-full"
                    getError={validateErrors?.address?.nip}
                />
            )}
        </div>
    );
};

export default InvoiceData;
