import React from 'react';

const ImportLivesButton = () => {
    return (
        <a
            className="button border-button ml-2"
            target="_blank"
            href="https://app.selmo.io/cron2/importLatestFBLiveStream?shop_id=16541"
        >
            Import current live
        </a>
    );
};

export default ImportLivesButton;