import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import {useSelector} from "react-redux";
import FiltersActions from "../../filters/FiltersActions.js";
import {getStateRoot as getFiltersStateRoot} from '../../filters/filtersReducer.js'
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions.js";
import ProductsTable from "./components/table/Table.jsx";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import StorageWrapper from "../../Page";
import DateRangePicker from "../../../shared/components/form/DateRangePicker";
import {createSelector} from "reselect";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import SearchInput from "../../../shared/components/form/SearchInput";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import StatusFilter from "../../../../../src_shared/components/filters/statusFilter/StatusFilter";
import {ProductsSourceTypes} from "../../../shared/enums/ProductSourceType";
import {TIM_SHOP_ID, WIKA_EWA_FASHION_ID} from "../../../../../src_shared/enums/TrustedShopsIds";
import ExportModal from "../../../../../src_shared/components/exportModal/ExportModal";
import {services} from "../../../../RestServices";
import {ORDER_STATUES} from "../../../shared/enums/OrderStatuses";
import {
    USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY, USER_PERMISSIONS_PRODUCT_TOTAL_QUANTITY_VISIBILITY
} from "../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";
import SingleSelectFiltersStatus
    from "../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import yesNoTypes from "../../../shared/enums/YesNoTypes";

const XLS_EXPORT_MODAL = 'XLS_EXPORT_MODAL';
const PDF_EXPORT_MODAL = 'PDF_EXPORT_MODAL';
const PDF_CLIENTS_EXPORT_MODAL = 'PDF_CLIENTS_EXPORT_MODAL';

const ProductsLatestList = () => {

    const {userData} = useSelector((state) => state.session);
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const {
        fromDate,
        toDate,
        source,
        statuses
    } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));
    const {totalPrice, quantity} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot))

    const [openExportModal, setOpenExportModal] = useState('');

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    });

    const preparedDateParams = () => {
        const params = {}
        if (fromDate) {
            params.dateFrom = convertDate(fromDate);
        }

        if (toDate) {
            params.dateTo = convertDate(toDate);
        }

        if (search.searchText) {
            params.superSearch = search.searchText;
        }

        if (source?.length) {
            params['source[]'] = source;
        }

        if (statuses?.length) {
            params['status[]'] = statuses
        }

        return getGETParamsUrl(params);
    }

    return (
        <StorageWrapper helmetTitle={`${getLangText('boughtProductsLabel')} - Selmo`}>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part">
                            <div>
                                <h1 className="title">
                                    {getLangText('boughtProductsLabel')}
                                    {totalItems > 0 &&
                                        <div className="badge">{totalItems}</div>
                                    }
                                </h1>
                                {((totalPrice || quantity) && +userData.id !== WIKA_EWA_FASHION_ID) &&
                                    <div className="flex items-center space-x-2">
                                        <UserPermissionsWrapper
                                            allowedPermissions={[USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY]}>
                                            <>
                                                <div className="total-price extra-small-text">
                                                    {getLangText('totalPriceLabel')}:&nbsp;
                                                    <strong>{getPrice(totalPrice)}</strong>
                                                </div>
                                                {(quantity && totalPrice) &&
                                                    <span className="text-[8px] font-normal text-desc">•</span>
                                                }
                                            </>
                                        </UserPermissionsWrapper>
                                        <UserPermissionsWrapper
                                            allowedPermissions={[USER_PERMISSIONS_PRODUCT_TOTAL_QUANTITY_VISIBILITY, USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY]}>
                                            {quantity &&
                                                <div className="total-price extra-small-text">
                                                        <span
                                                            className="capitalize">{getLangText('piecesGenitiveLabel')}</span>:&nbsp;
                                                    <strong>{quantity}</strong>
                                                </div>
                                            }
                                        </UserPermissionsWrapper>
                                    </div>
                                }
                        </div>
                        <button
                            type="button"
                            className='toggle-filters'
                            onClick={() => setFiltersVisible(!isFiltersVisible)}
                        >
                            <i className='icon-filter'/>
                        </button>
                    </div>
                    {(width > 767 || isFiltersVisible) &&
                        <div className="filters-part mt-sm-3 align-items-end">
                            <StatusFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={ProductsSourceTypes.map((i) => ({...i, value: i.id}))}
                                name="source"
                                selectAllName="sourceSelectAll"
                                defaultSelectedLabel={getLangText('sourceLabel')}
                                optionLabel={(item) => (
                                    <label className="control-label text-nowrap">
                                        <img
                                            className="source-icon inline-block"
                                            src={`/assets/images/svg/product-type/${item.icon}.svg`}
                                            width={14}
                                            alt=""
                                        />
                                        {getLangText(item.lang)}
                                    </label>
                                )}
                            />
                            <StatusFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={ORDER_STATUES}
                                name="statuses"
                                selectAllName="filtersSelectAll"
                                defaultSelectedLabel={getLangText('statusLabel')}
                                className="sm:ml-2"
                            />
                            {!!+userData.facebook_ads_active &&
                                <SingleSelectFiltersStatus
                                    ListActions={ListActions}
                                    FiltersActions={FiltersActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    options={[
                                        {value: 'all', label: 'allLabel'},
                                        {id: 1, value: 1, label: 'Tak'},
                                        {id: 2, value: 2, label: 'Nie'},
                                    ]}
                                    name="selmo_booster"
                                    className="sm:ml-2"
                                    defaultSelectedLabel={getLangText('selmoAdd')}
                                    withSubLabel={true}
                                />
                            }
                            <CustomDropdown
                                ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                                icon="icon-print"
                                className="ml-sm-auto d-md-block d-none mb-1"
                                buttonClassName="mr-sm-3 default-size"
                                tooltipTitle={getLangText('exportLabel')}
                            >
                                <button
                                    type="button"
                                    onClick={() => setOpenExportModal(PDF_EXPORT_MODAL)}
                                    className="custom-option"
                                >
                                    {getLangText('printProductsPDFLabel')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setOpenExportModal(XLS_EXPORT_MODAL)}
                                    className="custom-option"
                                >
                                    {getLangText('printProductsXLSLabel')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setOpenExportModal(PDF_CLIENTS_EXPORT_MODAL)}
                                    className="custom-option"
                                >
                                    {getLangText('printClientsPDFLabel')}
                                </button>
                            </CustomDropdown>
                            <SearchInput
                                placeholder={getLangText('searchPlaceholder')}
                                className="mr-md-2 ml-0"
                                search={search}
                            />
                            <DateRangePicker
                                actions={ListActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                FiltersActions={FiltersActions}
                            />
                        </div>
                    }
                </div>
                <ProductsTable
                    getSortClass={getSortClass}
                    updateSortOptions={updateSortOptions}
                    items={items}
                    isWaiting={isWaiting}
                />
            </div>
            <Pagination
                getLangText={getLangText}
                pages={getPagination()}
                items={items}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                onPrevPage={prevPage}
                onChangePage={changePage}
                onNextPage={nextPage}
                onChangeItemsPerPage={updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left',
                    nextIcon: 'icon-chevron right',
                }}
                showItemsPerPageSelect
            />
        </div>
    {
        openExportModal === PDF_EXPORT_MODAL &&
        <ExportModal
            title={getLangText('exportLabel')}
            subTitle={getLangText('receivedFileInPDFFormatLabel')}
            saveButton={getLangText('exportLabel')}
            rest={`${services.API_EXPORT_STORAGE_ORDERED_PRODUCTS_PDF}${preparedDateParams()}`}
            fileName={getLangText('boughtProductsLabel')}
            show={openExportModal === PDF_EXPORT_MODAL}
            hide={() => setOpenExportModal('')}
        />
    }
    {
        openExportModal === XLS_EXPORT_MODAL &&
        <ExportModal
            title={getLangText('exportLabel')}
            subTitle={getLangText('receivedFileInXLSFormatLabel')}
            saveButton={getLangText('exportLabel')}
            rest={`${services.API_EXPORT_STORAGE_ORDERED_PRODUCTS_XLS}${preparedDateParams()}`}
            fileName={getLangText('boughtProductsLabel')}
            fileType='xlsx'
            show={openExportModal === XLS_EXPORT_MODAL}
            hide={() => setOpenExportModal('')}
        />
    }
    {
        openExportModal === PDF_CLIENTS_EXPORT_MODAL &&
        <ExportModal
            title={getLangText('exportLabel')}
            subTitle={getLangText('receivedFileInPDFFormatLabel')}
            saveButton={getLangText('exportLabel')}
            rest={`${services.API_EXPORT_STORAGE_ORDERED_PRODUCTS_CLIENTS_PDF}${preparedDateParams()}`}
            fileName={getLangText('boughtProductsLabel')}
            show={openExportModal === PDF_CLIENTS_EXPORT_MODAL}
            hide={() => setOpenExportModal('')}
        />
    }
</StorageWrapper>
)
    ;
};

export default ProductsLatestList;
