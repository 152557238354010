import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import Actions from "./Actions";
import {RouterPaths} from "../../../../routes/RouterPath";
import {
    ACTIONS_TO_DO_ONLINE_PAYMENTS, ACTIONS_TO_DO_ORDERS,
} from "../../../shared/enums/TutorialsVideo";
import useCountActionsRequired from "./useCountActionsRequired";

export const selmoActionsRequired = [
    {
        id: 'online_payments',
        title: 'noActiveOnlinePayments',
        desc: 'noActiveOnlinePaymentsSubLabel',
        buttonLink: RouterPaths.SettingsPaymentsOnline,
        buttonTitle: 'activatePayments',
        videoStatsName: ACTIONS_TO_DO_ONLINE_PAYMENTS,
        previewSrc: 'payments-preview',
        actionLabel: 'activateLabel',
        time: 3,
    },
    {
        id: 'orders',
        title: 'noOrdersCreated',
        desc: 'noOrdersCreatedSubLabel1',
        buttonLink: RouterPaths.OrdersList,
        buttonTitle: 'goToOrders',
        videoStatsName: ACTIONS_TO_DO_ORDERS,
        previewSrc: 'orders-preview',
        actionLabel: 'createButton',
        time: 2,
    },
    // {
    //     id: 'sp_shipping_methods',
    //     title: 'noActiveDPD',
    //     desc: 'noActiveDPDSubLabel1',
    //     buttonLink: RouterPaths.SettingsShipping,
    //     buttonTitle: 'addPickupPoints',
    //     videoStatsName: '',
    //     actionLabel: 'activateLabel',
    //     time: 2,
    // },
    {
        id: 'mobile_app_followers',
        title: 'noSelmoApkaSubmitted',
        desc: 'noSelmoApkaSubmittedSubLabel1',
        buttonLink: RouterPaths.Notifications,
        buttonTitle: 'inviteToSelmoApp1',
        videoStatsName: '',
        actionLabel: 'invite',
        time: 2,
    },
]

const ActionsToDo = ({data}) => {
    const {getLangText} = useLang();
    const {isAllActive, chartPath, text} = useCountActionsRequired();

    if (isAllActive) return null;

    return (
        <div className="bg-[#fff] lg:mt-0 mt-6 rounded-big p-[20px] relative border-2 border-[#fff] overflow-hidden">
            <div
                className={`absolute top-0 left-0 right-0 h-[100px] actions-to-do-gradient ${isAllActive ? 'green' : 'red'}`}/>
            <div class="relative">
                <div class="flex items-center">
                    <div>
                        <div className="text-lg font-bold">
                            {getLangText('requiredActions')}
                        </div>
                        <div className="text-desc text-sm font-medium">
                            {getLangText('takeAdvantageOfSelmoCapabilities')}
                        </div>
                    </div>
                    <div className="ml-auto w-[48px] h-[48px] relative rounded-full bg-[#fff] border-[#fff] border-[3px]">
                        {!!text &&
                            <div
                                className="absolute -tracking-[0.8px] font-bold text-sm whitespace-nowrap left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 ">
                                {text}
                            </div>
                        }
                        <img src={chartPath} alt=""/>
                    </div>
                </div>
                <Actions
                    actions={selmoActionsRequired}
                    data={data}
                />
            </div>
        </div>
    );
};

export default ActionsToDo;