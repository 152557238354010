import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reduxThunk from 'redux-thunk';
import {applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import reducers from "./reducers";
import SessionActions from "./components/app/shared/session/SessionActions";
import {legacy_createStore as createStore} from 'redux'
import Cookies from "js-cookie";

// ReactGA.initialize('G-SPQ5HJD10G',{debug: true});

import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://a7393eca951abc8480f85b0f91634889@o4507182783397888.ingest.de.sentry.io/4507182785691728",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [/^https:\/\/app\.selmo\.io\//],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
})) || compose;

const store = createStore(reducers, composeEnhancer(applyMiddleware(reduxThunk)));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

export const errorComposer = (error) => {
    if (error.message === "canceled") {
        return;
    }

    if (error.response?.status === 500 || error.response?.status === 502) {
        store.dispatch(SessionActions.setErrorPage(true))
    }

    if (error.response?.data?.message === 'User not logged in' || error.response?.data?.message === 'User not exist') {
        console.log('should log out')
        store.dispatch(SessionActions.logout())
    }

    if (error.response?.data?.message === 'MAINTENANCE_PAGE') {
        store.dispatch(SessionActions.setMaintenancePage(true))
    }
}

export const responseComposer = (response) => {
    const tutorialStartVariant = response?.data?.item?.tutorialStartVariant;
    const tutorialSocialsVariant = response?.data?.item?.tutorialSocialsVariant;
    if (tutorialStartVariant) {
        Cookies.set('tutorialStartVariant', tutorialStartVariant, {
            secure: process.env.REACT_APP_COOKIES_SECURE,
            expires: 365
        })
    }
    if (tutorialSocialsVariant) {
        Cookies.set('tutorialSocialsVariant', tutorialSocialsVariant, {
            secure: process.env.REACT_APP_COOKIES_SECURE,
            expires: 365
        })
    }
    return response;
}

reportWebVitals();
