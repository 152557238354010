import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import InvoiceDataMarketingModal from "../../../components/InvoiceDataMarketingModal";

const NoAccess = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(null);

    return (
        <div
            className={`animate-[fadeIn_.3s_ease_forwards] z-[99999] rounded-[20px] bg-[#fff] lg:w-[350px] lg:max-w-[350px] shadow-default lg:ml-[130px] fixed lg:right-auto right-6 top-1/2 left-6 lg:left-1/2 lg:-translate-x-1/2 -translate-y-1/2 p-6 sm:p-10`}>
            <div className="relative">
                <div className="relative w-[55px] mx-auto">
                    <img className="w-full" src={`/assets/images/svg/marketing/fb.svg`}
                         alt="icon"/>
                    <img
                        className="absolute w-[20px] -bottom-1 -right-1"
                        src="/assets/images/svg/circle-cross.svg"
                        alt=""
                    />
                </div>
                <div
                    className="text-lg font-bold mt-2 text-center"
                    dangerouslySetInnerHTML={{__html: getLangText('realizedPayment')}}
                />
                <div
                    className="font-medium text-sm whitespace-pre-wrap text-[#6b7280] leading-[18px] mt-1 mb-3 text-center max-w-[530px] mx-auto"
                    dangerouslySetInnerHTML={{__html: getLangText('payToUnLockMarketing')}}
                />
            </div>
            <button
                type="button"
                onClick={() => setShowModal(1)}
                style={{fontSize: '14px', borderRadius: 8, paddingTop: 12, paddingBottom: 12}}
                className={`button medium-size text-center black primary lg:w-auto w-full sm:min-w-[290px]`}
            >
                {getLangText('extendAccess')}
            </button>
            {showModal &&
                <InvoiceDataMarketingModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(null)}
                />
            }
        </div>
    );
};

export default NoAccess;