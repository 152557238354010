import {RouterPaths} from "../../../../../../routes/RouterPath";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {Link, NavLink, useParams} from "react-router-dom";
import React, {useState} from "react";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import ConfirmModal from "../../../../../orders/list/components/export/ConfirmModal";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import AdminBox from "./components/admin/AdminBox";
import {useUnitPrice} from "../../../../../shared/helpers/Price";

const tabs = [
    {id: 0, path: `${RouterPaths.MarketingFacebookAdvertisementsDetails}`, label: 'editionLabel'},
    {id: 1, path: `${RouterPaths.MarketingFacebookAdvertisementsDetails}/statistics`, label: 'salesStatistics'},
]

const FacebookAdvertisementsFormWrapper = ({children, postId = '', isWaiting, values, setValue, fetchData}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const [showModal, setShowModal] = useState(false);
    const {getPrice} = useUnitPrice();

    const [{onSave: changeActivity, isWaiting: isWaitingForChangingActivity}] = useSubmitData(
        {
            rest: `${services.API_MARKETING_ACTIVE_AD}/${values?.id}`,
            values: {
                status: (+values?.status === 1 || +values?.status === 2) ? 3 : 2,
            },
            afterSubmit: () => {
                setShowModal(false)
                setValue('status', ((+values?.status === 1 || +values?.status === 2) ? 3 : 2))
            }
        });

    const getStatus = () => {
        switch (+values.status) {
            case 1:
                return {
                    label: 'active_label',
                    color: '#000',
                }
            case 2:
                return {
                    label: 'processing',
                    color: '#3c61ea',
                }
            case 3:
                return {
                    label: 'not_active_label',
                    color: '#000',
                }
        }
    }

    const restProducts = values?.products?.filter((_, index) => index !== 0);

    return (
        <div className={`loader-parent list-with-tabs separated-box rounded-b-lg`}>
            <AdminBox
                fetchData={fetchData}
                data={values}
            />
            <div className="tabs-header">
                <div
                    className={`p-6 bg-[#fff] mb-3 flex items-center rounded-medium ${(!!values?.products?.length || !!values?.media?.length) ? 'min-h-[115px]' : ''}`}>
                    {isWaiting ?
                        <div className="animate-pulse h-[25px] bg-gray-200 rounded-md dark:bg-gray-700 w-[70px]"/> :
                        <div className="flex items-center grow">
                            <div>
                                <div className="font-bold mb-2 flex items-center">
                                    {!!values?.products?.length ? values?.products[0]?.name : getLangText('ad')}
                                    {!!restProducts?.length &&
                                        <div className="custom-badge">
                                            +{restProducts?.length}
                                        </div>
                                    }
                                </div>
                                <div className="flex items-center space-x-1 space-y-1 flex-wrap">
                                    {values?.products?.length ? restProducts?.map((item) => (
                                            <Link
                                                target="_blank"
                                                to={`${RouterPaths.ProductsList}/edytuj/${item.id}`}
                                                key={item.id}
                                                className="flex items-center border rounded-lg p-1 hover:no-underline"
                                                type="button">
                                                <div className="w-[25px] h-[25px] rounded-sm overflow-hidden">
                                                    {item.photo_small && item.photo_small !== '0' ?
                                                        <ServerImage
                                                            className="w-full h-full object-cover"
                                                            src={item.photo_small}/> :
                                                        <img
                                                            className="w-full h-full object-cover"
                                                            src="/assets/images/default/product.svg"/>
                                                    }
                                                </div>
                                                <div className="text-xs font-bold text-[#6b7280] ml-1">
                                                    {item.name}
                                                </div>
                                                <i className="icon-arrows text-desc text-[6px] mr-2 block -rotate-90 ml-1"/>
                                            </Link>
                                        )) :
                                        <div className='text-desc font-medium text-sm -mt-2'>
                                            {getLangText('completeBasicInformationLabel')}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="ml-auto text-right">
                                {(!!values?.media?.length || !!values.selected_fb_ad_post_id) &&
                                    <button
                                        type="button"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <div className="flex items-center">
                                            <SwitchField
                                                value={(+values.status === 1 || +values.status === 2)}
                                                className="switch-form inline-label big-label blue-version mb-0 ml-auto"
                                                showLabel={false}
                                                id="active"
                                            />
                                            <div
                                                style={{color: getStatus()?.color}}
                                                className={`font-bold text-sm ml-2`}>
                                                {getLangText(getStatus()?.label)}
                                            </div>
                                        </div>
                                    </button>
                                }
                                <div
                                    className="budget-header-label"
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText({
                                            key: 'adBudgetHeader',
                                            data: [getPrice(values.budget_value), +values.budget_type === 1 ? `/ ${getLangText('daily')}` : '']
                                        })
                                    }}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="tabs-wrapper rounded-t-medium bg-[white]">
                    {tabs.map((i) => (
                        <NavLink
                            exact
                            to={`${i.path}/${id}`}
                            key={i.id}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
            </div>
            {children}
            {showModal &&
                <ConfirmModal
                    show={showModal}
                    title={getLangText((+values?.status === 1 || +values?.status === 2) ? 'wantToTurnOfAd' : 'wantToTurnOnAd')}
                    isWaiting={isWaitingForChangingActivity}
                    hide={() => setShowModal(false)}
                    onSubmit={changeActivity}
                    dialogClassName="small-modal"
                    saveButton={getLangText('yesButton')}
                />
            }
        </div>
    );
};

export default FacebookAdvertisementsFormWrapper;
