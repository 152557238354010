import createReducer from '../../../shared/helpers/createReducer';
import DataReducer from '../../../../../../../src_shared/form/data/DataReducer';
import { getReduceMap } from '../../../../../../../src_shared/filters/filterReducer';
import history from '../../../../../../history';
import { parseParams } from '../../../../../../../src_shared/list/ListActions';
import moment from 'moment';
import {
  ALL_ORDERS_VIEW,
  INTERVAL,
} from '../../../shared/enums/OrderViewTypes';
import { ORIGIN_TYPES } from '../../../shared/enums/OriginTypes';
import { ADMIN_ADVERTS } from '../../../shared/enums/AdminAdverts';
import { ADMIN_SIGNUP_STATUSES } from '../../../shared/enums/AdminSignupStatuses';
import { ADMIN_SHOP_STATUSES } from '../../../shared/enums/AdminShopStatus';

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams);

const preparedAdverts = parsedParams['adverts[]'] || [];
const preparedStatuses = parsedParams['status[]'] || [];
const preparedOrigin = parsedParams['origin[]'] || [];
const preparedExtraStatus = parsedParams['extra_status[]'] || [];
const preparedViewType = !!queryParams
  ? parsedParams.dateFrom
    ? INTERVAL
    : ALL_ORDERS_VIEW
  : ALL_ORDERS_VIEW;
const expansion = +parsedParams.expansion || 'all';
const assigned_seller = +parsedParams.assigned_seller || 'all';

const preparedDate = (initialState, paramsDate) => {
  if (!!queryParams) {
    if (parsedParams[paramsDate]) {
      return moment(parsedParams[paramsDate]).toDate();
    } else {
      return null;
    }
  } else {
    return initialState;
  }
};

export const getStateRoot = (state) => state.admin.stats.sales.filters;

export const getInitState = () =>
  DataReducer.getInitState(
    {
      statuses: preparedStatuses || [],
      filtersSelectAll: preparedStatuses.length === ADMIN_SHOP_STATUSES.length,
      adverts: preparedAdverts || [],
      origin: preparedOrigin || [],
      extraStatus: preparedExtraStatus || [],
      extraStatusSelectAll:
        preparedExtraStatus.length === ADMIN_SIGNUP_STATUSES.length,
      originSelectAll: preparedOrigin.length === ORIGIN_TYPES.length,
      advertsSelectAll: preparedAdverts.length === ADMIN_ADVERTS.length,
      fromDate: preparedDate(
        moment(new Date()).subtract(1, 'W').toDate(),
        'dateFrom'
      ),
      toDate: preparedDate(new Date(), 'dateTo'),
      viewType: preparedViewType,
      expansion,
      assigned_seller,
    },
    {
      statuses: [],
      filtersSelectAll: false,
      extraStatus: [],
      extraStatusSelectAll: false,
      origin: [],
      adverts: [],
      originSelectAll: false,
      advertsSelectAll: false,
      fromDate: moment(new Date()).subtract(1, 'W').toDate(),
      toDate: new Date(),
      viewType: INTERVAL,
      expansion: 'all',
      assigned_seller: 'all',
    }
  );

export const prefix = 'ADMIN_STATS_SALES_FILTERS_';

export const getInstance = () =>
  createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
