import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../../shared/session/SessionActions";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import api from "../../../../../services/axios/axios";
import {SHOP_ORIGIN_PL} from "../../../shared/enums/ShopOrigins";

export class SubmittedClientsListActions extends ListActions {

    setWaitForNotificationSent(toggle) {
        return {
            type: `${this.prefix}SET_WAIT_FOR_NOTIFICATION_SENT`,
            toggle,
        }
    }

    setSendBatchWaitForNotificationSent(toggle) {
        return {
            type: `${this.prefix}SET_SEND_BATCH_WAIT_FOR_NOTIFICATION_SENT`,
            toggle,
        }
    }

    setValidationActive(toggle) {
        return {
            type: `${this.prefix}SET_VALIDATION_ACTIVE`,
            toggle,
        }
    }

    setSuccessSendFormView(toggle) {
        return {
            type: `${this.prefix}SET_SUCCESS_FORM_VIEW`,
            toggle,
        }
    }

    afterSent() {
        return (dispatch, getState) => {
            const {origin, id} = getState().session.userData;

            batch(() => {
                if (origin === SHOP_ORIGIN_PL) {
                // if (+id === TEST_ACCOUNT_SHOP_ID) {
                    dispatch(this.loadData());
                    dispatch(this.setSuccessSendFormView(true));
                    return;
                }
                dispatch(this.modal.hideModal())
                dispatch(this.loadData())
                dispatch(showAlert('yuhuLabel', 'successfulNotificationsSent'))
            });
        }
    }

    sendNotifications(values, afterSubmit = null) {
        return async (dispatch) => {
            if (!values.message) {
                dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
                dispatch(this.setValidationActive(true))
                return;
            }
            dispatch(this.setWaitForNotificationSent(true))

            try {
                await api.post(`${selmoUrl}/${services.API_CLIENTS_SEND_NOTIFICATIONS}`, {
                    ...values
                })
                dispatch(this.setSendBatchWaitForNotificationSent(true))
                dispatch(this.setValidationActive(false))
                dispatch(this.afterSent())
                if (afterSubmit) afterSubmit()
            } catch (e) {
                dispatch(this.afterSent())
                if (e.response?.data?.message) {
                    dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
                }
                console.error(e)
            } finally {
                dispatch(this.setWaitForNotificationSent(false))
            }
        }
    }

    loadData(id) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            const {itemsPerPage, sortBy} = this.getStateRoot(getState());

            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ?
                `/${id}${getGETParamsUrl(params)}` :
                getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`)
                const {items, total, waitingForNotifications} = response.data;
                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
                }
                if (!sortBy) {
                    // dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
                }
                batch(() => {
                    dispatch(this.table.updateAllItems(items))
                    dispatch(this.setSendBatchWaitForNotificationSent(waitingForNotifications))
                    dispatch(this.pagination.updateTotalItems(total))
                })
            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }

    updateSendBatchFromPusher = () => {
        return (dispatch, getState) => {
            const {waitingForNotifications} = this.getStateRoot(getState());
            if (waitingForNotifications) {
                dispatch(this.loadData())
            }
        }
    }
}

export const getInstance = () => new SubmittedClientsListActions({
    getStateRoot,
    restService: services.API_CLIENTS_NOTIFICATIONS,
    pathname: RouterPaths.ClientsSubmittedList,
    prefix,
    defaultSortBy: 'date'
});

export default getInstance();
