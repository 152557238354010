import React, {useEffect, useState} from 'react';
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import {batch, useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {createSelector} from "reselect";
import {useInView} from "react-hook-inview";
import useList from "../../../../../../../src_shared/list/useList.js";
import Loader from "../../../../../shared/Loader.jsx";
import SortDropdown from "../components/SortDropdown.jsx";
import SearchInput from "../../../../../shared/components/form/SearchInput.jsx";
import EmptyList from "../../../../../shared/EmptyList.jsx";
import CustomerRow from "../../components/CustomerRow.jsx";
import ProductVariants from "../../productsInBasket/components/ProductVariants.jsx";
import {variants} from "../../../../../../../src_shared/helpers/helper.jsx";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {updateFieldInLiveDetails} from "../../LiveDetailsActions";
import {
	TEST_ACCOUNT_SHOP_ID,
	THE_PROMISE_BOUTIQUE_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";

const ProductsList = ({id}) => {
	const dispatch = useDispatch();
	const [activeClientGroupId, setActiveClientGroupId] = useState(null);
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();
	const {userData} = useSelector((state) => state.session);

	const {
		pagination: {
			totalItems,
		},
		list: {items, isWaiting},
		search,
		sort: {sortBy, callSort}
	} = useList({
		ListActions,
		getStateRoot,
		params: {
			id,
		},
		hasParentId: true,
	})

	const {
		hasMore,
		validationActive,
		waitingForAdding,
	} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {
		liveInfo,
		isInfoLoading,
	} = useSelector((state) => state.lives.details)

	const isLiveFinished = !isInfoLoading && +liveInfo.finished;

	const loadNewPage = () => dispatch(ListActions.loadNewPage(id));
	const [ref, inView] = useInView()

	useEffect(() => {
		if (inView && hasMore && !isWaiting) {
			loadNewPage()
		}
	}, [inView])

	const updateProductOnTop = (item) => {
		const updatedProduct = {
			id: item.product_id,
			name: item.name,
			code: item.code,
			price: item.price,
			photo: item.photo,
			sizes: item.sizes,
			squ: +item.availability < 0 ? 0 : item.availability,
			taxable: true,
		}

		dispatch(updateFieldInLiveDetails('product', updatedProduct))
	}

	const collapsedButtonClick = (item) => {
		const clientGroupId = item.id
		if (clientGroupId === activeClientGroupId) {
			return setActiveClientGroupId(null);
		}
		const clientIds = [
			TEST_ACCOUNT_SHOP_ID,
			THE_PROMISE_BOUTIQUE_SHOP_ID,
		];

		if (clientIds.includes(+userData.id) && !!+liveInfo.shopify) {
			updateProductOnTop(item)
		}
		setActiveClientGroupId(clientGroupId)
	}

	const isClientGroupClass = (id) => {
		const isActive = activeClientGroupId === id ? 'active' : ''
		return `client-group ${isActive}`
	}

	const addNewCustomer = (id, value) => {
		batch(() => {
			dispatch(ListActions.addNewCustomer(id))
			dispatch(ListActions.setCustomerDuplicateId(id, value))
		})
	}

	const preparedProduct = (product) => ({
		...product,
		id: product.product_id,
		sizes: product.sizes,
	})

	const AvailabilityStatus = ({availability}) => {
		const variantsLang = {
			singular: getLangText('pieceLabel'),
			plural: getLangText('piecesLabel'),
			genitive: getLangText('piecesGenitiveLabel')
		}

		const finalLang = `${availability} ${variants(+availability, variantsLang)}`

		if (+availability > 0) {
			return <div className="status-bg green small-status">{finalLang}</div>
		}
		if (+availability < 0) {
			return <div className="status-bg red small-status">{finalLang}</div>
		}
		return <div className="status-bg grey small-status">{finalLang}</div>
	}

	const toggleClientSort = (value) => {
		callSort(value, 'asc', id)
		dispatch(ListActions.resetPageParams());
	}

	const updateFieldInCustomer = (productId, clientId, field, value) => dispatch(ListActions.updateFieldInClient(productId, clientId, field, value))

	return (
		<div className="default-shadow-box">
			<div className="clients-header">
				<div className="title">{getLangText('asideProductsLabel')} <span className="badge">{totalItems}</span></div>
				<SortDropdown
					sortBy={sortBy}
					sortByField="name"
					sortByLang="sortByProductLabel"
					toggleClientSort={toggleClientSort}
				/>
				<SearchInput
					placeholder={getLangText('searchPlaceholder')}
					search={search}
					className="mt-sm-0 mt-3"
				/>
			</div>
			<EmptyList
				items={items}
				isLoading={isWaiting}
				className="small-size"
				imgSrc="/assets/images/empty/products-empty.svg"
				title={getLangText('noProductsLabel')}
			>
				<InfiniteScroll
					dataLength={items.length}
					next={loadNewPage}
					loader={<Loader isLoading={true} className="static-loader"/>}
					hasMore={hasMore}
				>
					<div className="client-group-wrapper">
						{items.map((item) => (
							<div
								key={item.id}
								className={isClientGroupClass(item.id)}
							>
								<div
									className="top-part"
									onClick={() => collapsedButtonClick(item)}
								>
									<div className="avatar-col">
										<figure className="product-avatar">
											{item.photo && item.photo !== '0' ?
												<ServerImage src={item.photo}/> :
												<img src="/assets/images/default/product.svg"/>
											}
										</figure>
									</div>
									<div className="name-with-status">
										<div className="name">
											{item.name}
											{item.code &&
												<div className="client-number">
													{getLangText('codeLabel')}: <strong>{item.code}</strong>
												</div>
											}
										</div>
									</div>
									{item.product_id && !isLiveFinished &&
										<AvailabilityStatus availability={item.availability}/>
									}
									<div className="price">{getPrice(item.price)}</div>
									<div className="buttons-group">
										<div className="button text-only">
											<i className="icon-arrows"/>
										</div>
									</div>
								</div>
								<div className="products-row-wrapper">
									{item.product_id &&
										<div className="current-basket-products">
											<div className="label">{getLangText('variantsLabel')}</div>
											<ProductVariants
												product={preparedProduct(item)}
												customers={item.clients}
												withPrice
												isLiveFinished={isLiveFinished}
											/>
										</div>
									}
									<div className="label mt-2">{getLangText('asideClientsLabel')}</div>
									<div className="inputs-wrapper only-first-row-labels">
										{item.clients.map((client) => (
											<CustomerRow
												key={client.live_products_id}
												customerId={client.live_products_id}
												customers={item.clients}
												customer={client}
												product={preparedProduct(item)}
												updateField={(field, value) => updateFieldInCustomer(item.id, client.live_products_id, field, value)}
												removeCustomer={() => dispatch(ListActions.updateFieldInClient(item.id, client.live_products_id, 'is_deleted', '1'))}
												productId={item.product_id}
												customerDuplicateId={item.customerDuplicatedId}
												setCustomerDuplicatedId={(value) => dispatch(ListActions.setCustomerDuplicateId(item.id, value))}
												hasCustomerError={validationActive}
												isLiveFinished={isLiveFinished}
												liveId={liveInfo?.id}
											/>
										))}
									</div>
									{!isLiveFinished &&
										<div className="buttons-row">
											<button
												type="button"
												className="button text-blue-button add-new-row"
												onClick={() => addNewCustomer(item.id)}
											>
												<i className="icon-plus"/>
												<span className="d-none d-md-block">{getLangText('addNextClientLabel')}</span>
												<span className="d-md-none">{getLangText('addNextClient')}</span>
											</button>
											<button
												type="submit"
												className="button primary the-smallest-size"
												onClick={() => dispatch(ListActions.addCustomersToProduct(item.id))}
											>
												{waitingForAdding &&
													<Loader isLoading={true}/>
												}
												{getLangText('saveChangesButton')}
											</button>
										</div>
									}
								</div>
							</div>
						))}
					</div>
					<div
						style={{height: '1px'}}
						className="for-intersection" ref={ref}
					/>
				</InfiniteScroll>
			</EmptyList>
		</div>
	);
};

export default ProductsList;
