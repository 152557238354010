import React from 'react';
import {CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW} from "../../../shared/enums/CustomerBasketViews";
import {useDispatch, useSelector} from "react-redux";
import BasketPageFormActions from "../../page/FormActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA, ORDER_PAYMENT_BM_TRANSFER, ORDER_PAYMENT_MB_WAY, ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL, ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_PICKUP,
    ORDER_PAYMENT_SHOP_PICKUP,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER
} from "../../../shared/enums/PaymentStatuses";
import {createSelector} from "reselect";
import {useUnitPrice} from "../../../shared/helpers/Price";
import {getStateRoot} from "../formReducer";
import {getStateRoot as getDeliveryAddressStateRoot} from "../../deliveryAddress/formReducer";
import CardDetails from "../../paymentMethods/components/CardDetails";
import FormActions from "../FormActions";
import {
    OUI_OUI_FASHION_SHOP_ID,
    SCRILU_SHOP_ID,
    STYLOWE_BUTY_SHOP_ID, TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../secondStep/SecondStep";
import {SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

const OrderInfo = ({data, setIsPointModalOpen, shopId, setActiveShippingId}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const setDeliveryMethodsModalVisible = (toggle) => dispatch(FormActions.setDeliveryMethodsModalVisible(toggle))

    const shippingAddress = useSelector(createSelector(getDeliveryAddressStateRoot, (stateRoot) => stateRoot));

    const {
        deliveryMethodsVisible,
        data: {
            pointMachineName,
            pointMachineCode,
            pointMachineStreet,
            pointMachineCity,
            pointMachineType
        }
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const payment = data?.overall?.payment_value?.type;

    const setBasketView = (view) => dispatch(BasketPageFormActions.setBasketView(view));

    const openPointMachineModal = () => {
        setActiveShippingId(data?.overall?.shipping_value?.id);
        setIsPointModalOpen(pointMachineType)
    }

    return (
        <section>
            <div className="section-title">
                {getLangText('orderDetailsLabel')}
            </div>
            <div className="data-boxes-wrapper not-separated">
                <div className="content no-hover">
                    <div className="content__top-part align-items-start">
                        <div className="icon-place"><i className="icon-profile"/></div>
                        <div className="flex-grow-1">
                            <div
                                className="label">{shippingAddress.data.first_name} {shippingAddress.data.last_name}</div>
                            <div className="data">
                                {shippingAddress.data.email}
                            </div>
                        </div>
                        <button
                            onClick={() => setBasketView(CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW)}
                            type="button"
                            className="button text-only"
                        >
                            {getLangText('customerBasketChangeButton')}
                        </button>
                    </div>
                </div>
                {data.shipping_cart &&
                    <div className="content no-hover">
                        <div className="content__top-part align-items-start">
                            <div className="icon-place"><i className="icon-home"/></div>
                            <div className="flex-grow-1">
                                <div
                                    className="label">{shippingAddress.data.street} {shippingAddress.data.building_number}</div>
                                {shippingAddress?.data.origin === SHOP_ORIGIN_US ?
                                    <div className="data">
                                        {shippingAddress.data.city}{', '}{shippingAddress.data.state_name} {shippingAddress.data.zip_code}
                                        <div>{shippingAddress.data?.selectedCountry?.name}</div>
                                    </div> :
                                    <div className="data">
                                        {shippingAddress.data.zip_code} {shippingAddress.data.city}{', '}{shippingAddress.data?.selectedCountry?.name}
                                    </div>
                                }
                            </div>
                            <button
                                onClick={() => setBasketView(CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW)}
                                type="button"
                                className="button text-only"
                            >
                                {getLangText('customerBasketChangeButton')}
                            </button>
                        </div>
                    </div>
                }
                {!!data?.overall?.shipping_value?.name && !deliveryMethodsVisible &&
                    <div className="content no-hover">
                        <div className="content__top-part align-items-start">
                            <div className="icon-place">
                                <i className="icon-delivery"/>
                            </div>
                            <div className="flex-grow-1">
                                <div className="label">
                                    {data?.overall?.shipping_value?.name}
                                </div>
                                <div className="data">
                                    {+data.shopInfo?.order_stage > 1 ?
                                        getLangText('paidLabel') :
                                        <>
                                            {!!+data?.overall.discount?.free_shipping || !+data?.overall.shipping_value?.price || (+data?.overall?.products_value >= +data?.shopInfo?.free_shipping_price && data?.shopInfo?.free_shipping_active === '1') ?
                                                <div className="data">{getLangText('freeLabel')}</div> :
                                                <div
                                                    className="data">{getPrice(data?.overall.shipping_value?.price, data?.shopInfo?.currency_shortcut)}</div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {+data.shopInfo?.order_stage <= 1 &&
                                <button
                                    type="button"
                                    className="button text-only"
                                    onClick={() => setDeliveryMethodsModalVisible(true)}
                                >
                                    {getLangText('customerBasketChangeButton')}
                                </button>
                            }
                        </div>
                        {pointMachineCode &&
                            <div className="inpost-results-wrapper">
                                <div className="inpost-row">
                                    <div className="name-wrapper">
                                        {(pointMachineType === DPD_POINT_MODAL || pointMachineType === GLS_POINT_MODAL) ?
                                            <div>
                                                <div className="top-name">
                                                    {pointMachineName}
                                                </div>
                                                <div className="extra-info mt-1">
                                                    {pointMachineStreet}, {pointMachineCity}
                                                </div>
                                                <div className="extra-info mt-1">
                                                    {pointMachineCode}
                                                </div>
                                            </div> :
                                            <div>
                                                <div className="top-name">
                                                    {pointMachineStreet} {pointMachineCode}
                                                </div>
                                                <div className="extra-info">{pointMachineCity}</div>
                                            </div>
                                        }
                                        <div
                                            onClick={openPointMachineModal}
                                            className="button border-button btn-change change-inpost text-nowrap">
                                            {getLangText('customerBasketChangeButton')}
                                        </div>
                                    </div>
                                    <div className="photo">
                                        {pointMachineType === INPOST_POINT_MODAL &&
                                            ((+data?.shopInfo?.id === TIM_SHOP_ID || +data?.shopInfo?.id === TEST_ACCOUNT_SHOP_ID) ?
                                                    <img
                                                        className="h-[34px] rounded-sm"
                                                        src="/assets/images/inpost-tim.jpg"
                                                        alt="inpost"
                                                    /> :
                                                    <img src="/assets/images/inpost.svg" alt=""/>
                                            )}
                                        {pointMachineType === MONDIAL_RELAY_POINT_MODAL &&
                                            <img width={60} src="/assets/images/svg/logomondial-relay.svg" alt=""/>
                                        }
                                        {pointMachineType === ORLEN_POINT_MODAL &&
                                            <img width={30} src="/assets/images/svg/orlen-paczka.svg" alt=""/>
                                        }
                                        {pointMachineType === DPD_POINT_MODAL &&
                                            <img width={90} src="/assets/images/svg/dpd-pick-up-logo.svg" alt=""/>
                                        }
                                        {pointMachineType === GLS_POINT_MODAL &&
                                            <img width={60} src="/assets/images/gls.png" alt="gls"/>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {+data.shopInfo?.order_stage > 1 && !!payment &&
                    <div className="content no-hover">
                        <div className="content__top-part align-items-start">
                            <div className="icon-place">
                                <i className="icon-payment"/>
                            </div>
                            {payment === ORDER_PAYMENT_STRIPE_CARD &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('cardPaymentLabel')}
                                    </div>
                                    <div className="data">
                                        {getLangText('freeLabel')}
                                    </div>
                                    <CardDetails/>
                                </div>
                            }
                            {payment === ORDER_PAYMENT_BLIK &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodBlikLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {payment === ORDER_PAYMENT_BLUEMEDIA &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodOnlineTransferLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {payment === ORDER_PAYMENT_MOBILEPAY &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodMobilePayLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {(payment === ORDER_PAYMENT_TRANSFER || payment === ORDER_PAYMENT_BM_TRANSFER) &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodTransferLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {payment === ORDER_PAYMENT_PICKUP &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {+shopId === STYLOWE_BUTY_SHOP_ID ?
                                            'Za pobraniem' :
                                            getLangText('paymentMethodCashOnDeliveryLabel')
                                        }
                                    </div>

                                    {!+data?.overall.payment_value.price ?
                                        <div className="data">{getLangText('freeLabel')}</div> :
                                        <div className="data">
                                            {/*{getPrice(data?.overall.payment_value.price, data?.shopInfo?.currency_shortcut)} -*/}
                                            {/*płatne tylko raz*/}
                                            {getLangText('paidLabel')}
                                        </div>
                                    }
                                </div>
                            }
                            {payment === ORDER_PAYMENT_SHOP_PICKUP &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodCashInShopLabel')}
                                        {data.overall?.payment_value?.address}
                                    </div>
                                    <div className="data">
                                        {!+data?.overall.payment_value.price ?
                                            <div className="data">{getLangText('freeLabel')}</div> :
                                            <div className="data">
                                                {/*{getPrice(data?.overall.payment_value.price, data?.shopInfo?.currency_shortcut)} -*/}
                                                {/*płatne tylko raz*/}
                                                {getLangText('paidLabel')}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {(payment === ORDER_PAYMENT_PAYPAL || payment === ORDER_PAYMENT_PAYPAL_AUTOMATIC) &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('paymentMethodPaypalLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {payment === ORDER_PAYMENT_MB_WAY &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText('mbwayLabel')}
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                            {payment?.includes(`${ORDER_PAYMENT_TRANSFER}_`) &&
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {data.overall?.payment_value?.name}
                                        {(+shopId === SCRILU_SHOP_ID || +shopId === OUI_OUI_FASHION_SHOP_ID || !!+data.overall?.payment_value?.is_custom) ?
                                            null :
                                            <>
                                                -
                                                <span
                                                    style={{textTransform: 'lowercase'}}>&nbsp;{getLangText('paymentMethodTransferLabel')}</span>
                                            </>
                                        }
                                    </div>
                                    {/*<div className="data">*/}
                                    {/*    {getLangText('freeLabel')}*/}
                                    {/*</div>*/}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </section>
    );
};

export default OrderInfo;
