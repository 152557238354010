import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import Field from "../../../../../../src_shared/components/form/field/Field";
import ListActions from "../../productsList/ListActions";

const ChangePriceModal = ({
                              modalVisible,
                              hideModal,
                              values,
                              title,
                          }) => {
    const {getLangText} = useLang();
    const [price, setPrice] = useState(null);
    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${services.API_BATCH_CHANGE_PRICE}`,
            values: {
                ...values,
                price,
            },
            afterSubmit: () => {
                batch(() => {
                    dispatch(showAlert())
                    dispatch(ListActions.loadData());
                    hideModal();
                })
            }
        });

    return (
        <Modal
            dialogClassName="default-modal w-420"
            // contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('changeProductsPrice')}
                    <div className="text-desc text-sm font-medium">
                        {title}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button self-start"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form className="grow flex flex-col" onSubmit={onSave}>
                <Modal.Body className="pt-3 pb-5 grow">
                    <Field
                        className="floated-small-label"
                        label={getLangText('priceLabel')}
                        value={price}
                        setValue={(field, value) => setPrice(value)}
                        required
                        type="number"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        disabled={!price}
                        className={`button primary ${!!price ? '' : 'not-active'} ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('changePrice')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default ChangePriceModal;
