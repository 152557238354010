import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import {useUnitPrice} from "../../../shared/helpers/Price";
import SelectSizesModal from "./SelectSizeModal";
import api from "../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import Loader from "../../../../../src_shared/components/loader/Loader";
import {batch, useDispatch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import BasketPageFormActions from "../../page/FormActions";

const Upsell = ({data, magicUrl, shopId}) => {
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const [activeProduct, setActiveProduct] = useState({});
    const [isWaiting, setIsWaiting] = useState(null);
    const dispatch = useDispatch();

    const addUpsellProduct = async (product) => {
        setIsWaiting(product.product_id);
        try {
            const {data} = await api.post(`${selmoUrl}/${services.API_CART_UPSELL}/${product.product_id}`, {
                magic_url: magicUrl, shop_id: shopId, product_size_id: product.product_size_id,
            })
            batch(() => {
                dispatch(BasketPageFormActions.data.updateValues({
                    overall: data.item?.overall,
                    productsInfo: data.item?.productsInfo,
                }))
                dispatch(showAlert('yuhuLabel', 'productsHasBeenAddedToOrder'))
            })
            // setAddedProducts((prevState) => [...prevState, product])
            setActiveProduct({})
        } catch (e) {
            console.error('Error send value on server')
        } finally {
            setIsWaiting(null);
        }
    }

    const addedProduct = (product) => {
        return data.productsInfo.find((i) => +i.product_id === +product.id)
    }

    const handleClick = async (product) => {
        // if (!!addedProduct(product)) return;
        if (!!+product.has_sizes) {
            setActiveProduct(product)
        } else {
            await addUpsellProduct({product_id: product.id})
        }
    }


    if (!data.upsell_products?.length) return null;

    return (
        <section>
            <div className="section-title">
                {getLangText('youMightBeInterested')}
            </div>
            <div
                className="section-title__description">{getLangText('addToYourOrder')}</div>
            <div className="space-y-3">
                {data?.upsell_products?.map((product) => (
                    <div
                        key={product.id}
                        className={`border border-[#E5E7EB] rounded-[8px] p-3 flex items-center flex-sm-nowrap flex-wrap ${!!addedProduct(product) ? '' : ''}`}
                    >
                        <div className="flex items-center">
                            <figure className="w-[60px] h-[60px] rounded-[8px] overflow-hidden">
                                {product.photo_small && product.photo_small !== '0' ?
                                    <ServerImage
                                        className="w-full h-full object-cover"
                                        src={product.photo_small}/> :
                                    <img
                                        className="w-full h-full object-cover"
                                        src="/assets/images/default/product.svg"/>
                                }
                            </figure>
                            <div className="ml-2">
                                <div className="font-bold text-sm">{product.name}</div>
                                <div className="flex items-center space-x-1 text-xs">
                                    <div
                                        className="text-[#101827]">{getPrice(product.price, data?.shopInfo?.currency_shortcut)}</div>
                                    {product.old_price &&
                                        <div className="text-[#D80027] line-through">
                                            {getPrice(product.old_price, data?.shopInfo?.currency_shortcut)}
                                        </div>
                                    }
                                    {/*{!!addedProduct(product)?.product_size &&*/}
                                    {/*    <>*/}
                                    {/*        <div className="text-[8px] text-[#101827]">•</div>*/}
                                    {/*        <div className="text-[#101827]">{addedProduct(product)?.product_size}</div>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                        <div className="sm:w-auto w-full sm:mt-0 mt-2.5 ml-auto">
                            {!addedProduct(product) ? (
                                    <button
                                        onClick={() => handleClick(product)}
                                        className={`rounded-[8px] overflow-hidden sm:w-auto w-full relative min-w-[150px] font-semibold py-[12px] min-h-[40px] text-sm leading-[16px] px-[14px] border transition-colors ${!!addedProduct(product) ? '!cursor-default border-[#E5E7EB] text-desc' : 'border-transparent bg-[#000] text-[#fff]'}`}
                                        type="button"
                                    >
                                        {+isWaiting === +product.id &&
                                            <Loader
                                                className="small-loader in-black-button"
                                                isLoading={true}
                                            />
                                        }
                                        {getLangText(!!addedProduct(product) ? 'added' : 'addToBasket')}
                                    </button>
                                ) :
                                <button
                                    onClick={() => handleClick(product)}
                                    type="button"
                                    className="border sm:w-auto w-full relative overflow-hidden rounded-big hover:bg-[#f9f9f9] flex items-center transition-colors">
                                    <div
                                        className="w-[40px] h-[40px] text-xs border-r flex items-center justify-center">
                                        <i className="icon-tick"/>
                                    </div>
                                    <div className="text-sm ml-auto mr-auto text-center font-bold px-[14px]">
                                        {+isWaiting === +product.id &&
                                            <Loader
                                                className="small-loader in-white-button"
                                                isLoading={true}
                                            />
                                        }
                                        {getLangText('addNext')}
                                    </div>
                                </button>
                            }
                            {/*{!!addedProduct(product) &&*/}
                            {/*    <button*/}
                            {/*        onClick={() => handleClick(product)}*/}
                            {/*        type="button"*/}
                            {/*        className="ml-1 sm:mt-0 mt-1.5 text-xs font-bold flex sm:justify-end justify-center items-center">*/}
                            {/*        <i class="icon-plus text-desc text-[8px] mr-1"/>*/}
                            {/*        Dodaj następny*/}
                            {/*    </button>*/}
                            {/*}*/}
                        </div>
                    </div>
                ))}
            </div>
            {activeProduct?.id &&
                <SelectSizesModal
                    isOpen={activeProduct?.id}
                    hideModal={() => setActiveProduct({})}
                    product={activeProduct}
                    currency_shortcut={data?.shopInfo?.currency_shortcut}
                    addUpsellProduct={addUpsellProduct}
                    isWaiting={isWaiting}
                />
            }
        </section>
    );
};

export default Upsell;
