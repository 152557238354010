import React, {useState} from "react";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import ComponentLoader from "../../../../../shared/components/componentLoader/ComponentLoader";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import FacebookAdvertisementsFormWrapper from "../form/FacebookAdvertisementsFormWrapper";
import useList from "../../../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import Model from "../../../../../../../src_shared/modules/model/Model";
import PromotedProducts from "./components/PromotedProducts";
import StatsRow from "../../statistics/components/StatsRow";
import Pagination from "../../../../../../admin/components/app/shared/components/pagination/Pagination";
import Table from "../../statistics/components/Table";
import VideoPreview from "./components/VideoPreview";
import SingleSelectFiltersStatus
    from "../../../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import FiltersActions from "../../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import YES_NO_TYPES from "../../../../../shared/enums/YesNoTypes";
import NewClients from "../../statistics/components/NewClients";

const FacebookMarketingDetailsStats = () => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const {getLangText} = useLang();
    const {id} = useParams();
    const [showNoStatsModal, setShowNoStatsModal] = useState(false);

    const [{values, setValue, isLoading, fetchData}] = useFormLocal({
        model: new Model(),
        rest: `${services.API_MARKETING_AD_STATS_DETAILS}/${id}`,
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
    } = useList({
        ListActions,
        getStateRoot,
        hasParentId: true,
        params: {
            id,
        },
    });

    const getAllData = () => {
        fetchData()
        loadData(id)
    }

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.MarketingFacebookAdvertisements}>
                            {getLangText('advertisements')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.MarketingFacebookAdvertisements}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <FacebookAdvertisementsFormWrapper
                isWaiting={isLoading}
                values={values}
                setValue={setValue}
                fetchData={getAllData}
            >
                <div>

                    <div>
                        <div className="stats-wrapper bg-[#fff] p-6 mb-3 rounded-b-medium">
                            <div class="flex items-center mb-2 sm:min-h-[45px]">
                                <div>
                                    <div className="text-lg font-bold">
                                        {getLangText('statisticsLabel')}
                                    </div>
                                </div>
                                {/*{!!+values.new_clients_total &&*/}
                                {/*    <NewClients*/}
                                {/*        isLoading={isLoading}*/}
                                {/*        values={values}/>*/}
                                {/*}*/}
                                {/*{+userData.id === TEST_ACCOUNT_SHOP_ID &&*/}
                                {/*    <div className="ml-auto">*/}
                                {/*        <DateRangePicker*/}
                                {/*            actions={ListDetailsActions}*/}
                                {/*            getFiltersStateRoot={getFiltersStateRoot}*/}
                                {/*            FiltersActions={FiltersActions}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </div>
                            <StatsRow
                                isLoading={isLoading}
                                data={values}
                            />
                        </div>
                        <div className="">
                            <div className="text-lg font-bold p-6 pb-2 bg-[#fff] rounded-t-medium">
                                {getLangText('promotedProducts')}
                            </div>
                            <PromotedProducts
                                isWaiting={isLoading}
                                items={values.products}
                                getSortClass={() => {
                                }}
                            />
                        </div>
                    </div>
                    {isWaiting ?
                        <ComponentLoader/> :
                        <div className="grid sm:grid-cols-5 gap-5 mt-3">
                            <div className="sm:col-span-3">
                                <div className="p-6 pb-2 bg-[#fff] rounded-t-medium">
                                    <div className="text-lg font-bold mb-2">
                                        {getLangText('adPurchases')}
                                    </div>
                                    <SingleSelectFiltersStatus
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={YES_NO_TYPES}
                                        name="is_new_client"
                                        defaultSelectedLabel={getLangText('newClients')}
                                        withSubLabel={true}
                                        extraProps={{
                                            id,
                                        }}
                                    />
                                </div>
                                <Table
                                    isWaiting={isWaiting}
                                    items={items}
                                    updateSortOptions={updateSortOptions}
                                    getSortClass={getSortClass}
                                    askForRemoveItem={askForRemoveItem}
                                    markedToRemove={markedToRemove}
                                    type="details"
                                />
                                <Pagination
                                    pages={getPagination()}
                                    items={items}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={totalItems}
                                    onPrevPage={prevPage}
                                    onChangePage={changePage}
                                    onNextPage={nextPage}
                                    onChangeItemsPerPage={updateItemsPerPage}
                                    classes={{
                                        prevIcon: 'icon-chevron left', nextIcon: 'icon-chevron right',
                                    }}
                                    showItemsPerPageSelect={false}
                                />
                            </div>
                            {!!values.media?.length &&
                                <div className="sm:col-span-2">
                                    <VideoPreview values={values?.media[0]}/>
                                </div>
                            }
                        </div>
                    }
                </div>
            </FacebookAdvertisementsFormWrapper>
            {/*{showNoStatsModal &&*/}
            {/*    <NoDataModal*/}
            {/*        modalVisible={showNoStatsModal}*/}
            {/*        hideModal={() => setShowNoStatsModal(false)}*/}
            {/*    />*/}
            {/*}*/}
        </>
    );
};

export default FacebookMarketingDetailsStats;
