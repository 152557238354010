import { combineReducers } from 'redux';
import sharedReducer from '../components/app/shared/sharedReducer';
import shopsReducer from '../components/app/shops/shopsReducer';
import usersReducer from '../components/app/users/usersReducer';
import clientsReducer from '../components/app/clients/clientsReducer';
import dashboardReducer from '../components/app/dashboard/dashboardReducer';
import invoicesReducer from '../components/app/invoices/invoicesReducer';
import sessionReducer from '../../app/shared/session/sessionReducer';
import premiumReducer from '../components/app/admin/premiumReducer';
import postsReducer from '../components/app/posts/postsReducer';
import leadsReducer from '../components/app/leads/leadsReducer';
import subscriptionReducer from '../components/app/subscription/subscriptionReducer';
import freshmenReducer from '../components/app/freshmen/freshmenReducer';
import langsReducer from '../components/app/shared/langs/langsReducer';
import salesReducer from '../components/app/sales/salesReducer';
import shopifyClientsReducer from '../components/app/shopifyClients/shopifyClientsReducer';
import statsReducer from '../components/app/stats/statsReducer';
import demoReducer from '../components/app/demo/demoReducer';
import marketingReducer from "../components/app/marketing/marketingReducer";

export default combineReducers({
    shops: shopsReducer,
    premium: premiumReducer,
    posts: postsReducer,
    users: usersReducer,
    clients: clientsReducer,
    dashboard: dashboardReducer,
    invoices: invoicesReducer,
    subscription: subscriptionReducer,
    leads: leadsReducer,
    freshmen: freshmenReducer,
    sales: salesReducer,
    shopifyClients: shopifyClientsReducer,
    session: sessionReducer,
    shared: sharedReducer,
    i18n: langsReducer,
    stats: statsReducer,
    demo: demoReducer,
    marketing: marketingReducer,
});
