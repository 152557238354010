import React, { useMemo } from 'react';
import useLang from '../../../../../../../src_shared/hooks/useLang';
import useForm from '../../../../../../../src_shared/form/useForm';
import FormActions from './FormActions';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';
import { RouterPaths } from '../../../../../../routes/RouterPath';
import MessageContent from './components/messageContent/MessageContent';
import MessageContentWarning from './components/messageContentWarning/MessageContentWarning';
import Receivers from './components/receivers/Receivers';
import MessageBubble from './components/messageBubble/MessageBubble';
import ScheduleShipment from './components/scheduleShipment/ScheduleShipment';
import ComponentLoader from '../../../../../../../src_shared/components/ui/componentLoader/ComponentLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import Loader from '../../../../../shared/Loader';
import BalanceModal from '../../../../../orders/details/selmoPaczka/components/shared/BalanceModal';
import { processMessage } from './smsPriceCalculator';
import cn from 'clsx';
import FormDownload from '../../components/FormDownload';
import CustomDropdown from '../../../../../shared/components/dropdown/CustomDropdown';
import useRemoveData from '../../../../../../../src_shared/hooks/useRemoveData';
import { services } from '../../../../../../RestServices';
import ConfirmRemoveModal from '../../../../../../../src_shared/components/modal/ConfirmRemoveModal';

const formatCost = (cost) => {
    return cost.toLocaleString('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

const PLACEHOLDER = 'Wpisz treść SMS';

const SmsMarketingDetails = () => {
    const { getLangText } = useLang();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.session);
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isWaitingDraft, setIsWaitingDraft] = useState(false);

    const {
        data: { values, setValue, isPreloaded },
        form: { isWaiting, getFieldProps },
        validation,
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    const [
        { openModal, hideModal, confirmModalVisible, isDeleting, removeItem },
    ] = useRemoveData({
        rest: services.API_MARKETING_SMS,
        redirectRoute: RouterPaths.MarketingSmsCampaigns,
        method: 'delete',
    });

    const bothButtonsDisabled = isWaitingDraft || isSendingMessage;

    const { parts, price, limit, length, hasSpecial } = useMemo(
        () => processMessage(values.message),
        [values.message],
    );

    const campaignCost = () => {
        if (!!+values.receivers) {
            return formatCost(+values.receivers * price);
        }

        return formatCost(Math.round(+values.available_receivers / 2) * price);
    };

    const handleSubmitAction = (action) => {
        setValue('submit_action', action);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (values.submit_action === 'save-draft') {
            dispatch(FormActions.saveDraft(values, history, setIsWaitingDraft));
            return;
        }

        dispatch(
            FormActions.sendMessage(
                values,
                history,
                setShowBalanceModal,
                setIsSendingMessage,
            ),
        );
    };

    const hasError = (field) => {
        if (!validation.isActive) {
            return false;
        }

        const errorKey = validation.validateErrors[field](
            values[field],
            values,
        );

        return errorKey ? errorKey : false;
    };

    if (values.notFound) {
        return <Redirect to={RouterPaths.MarketingSmsCampaigns} />;
    }

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.MarketingSmsCampaigns}>SMS</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('createButton')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.MarketingSmsCampaigns}
                    className="button back-to-panels sm:mb-5 sm:mt-0 mt-3.5">
                    <i className="icon-arrow-left" />
                    {getLangText('backToSmsList')}
                </Link>
            </div>
            {isWaiting && !isPreloaded ? (
                <ComponentLoader />
            ) : (
                <>
                    <div className="bg-[#fff] rounded-medium p-6 mb-3 flex justify-between items-center">
                        <div>
                            <div className="text-lg font-bold">
                                {getLangText('createSmsCampaign')}
                            </div>
                            <div className="text-desc text-sm font-medium">
                                {getLangText('increaseReachAndSalesSms')}
                            </div>
                        </div>
                        {values.status === '1' && (
                            <CustomDropdown>
                                <button
                                    type="button"
                                    className="custom-option"
                                    onClick={() => openModal({ id })}>
                                    {getLangText('removeButton')}
                                </button>
                            </CustomDropdown>
                        )}
                    </div>
                    {userData.sms_shop_name_verification === '1' && (
                        <FormDownload />
                    )}
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col lg:flex-row bg-[#fff] rounded-medium">
                        <div className="flex flex-col gap-6 p-6 lg:w-1/2">
                            <div>
                                <MessageContent
                                    placeholder={PLACEHOLDER}
                                    messageLength={length}
                                    messageLimit={limit}
                                    parts={parts}
                                    values={values}
                                    setValue={setValue}
                                    hasError={hasError('message')}
                                    disabled={bothButtonsDisabled}
                                />
                                {hasSpecial && <MessageContentWarning />}
                            </div>
                            <Receivers
                                availableReceivers={
                                    +values?.available_receivers
                                }
                                receivers={+values?.receivers}
                                setValue={setValue}
                                disabled={bothButtonsDisabled}
                            />
                            <ScheduleShipment
                                values={values}
                                setValue={setValue}
                                hasError={hasError}
                                getFieldProps={getFieldProps}
                                disabled={bothButtonsDisabled}
                            />
                            <div className="border-b-[1px] border-[#E5E7EB] border-dashed w-full"></div>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-sm font-medium text-[#4b5563]">
                                        {getLangText('campaignCost')}
                                    </span>
                                    <div className="tooltip-parent">
                                        <i className="icon-info-thin text-[#9CA3AF]"></i>
                                        <div className="tooltip-content">
                                            {getLangText('campaignCostTooltip')}
                                        </div>
                                    </div>
                                </div>
                                <span className="text-[18px] font-bold">
                                    {campaignCost()}
                                </span>
                            </div>
                            <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-between">
                                <button
                                    onClick={() =>
                                        handleSubmitAction('save-draft')
                                    }
                                    className={cn(
                                        'w-full sm:w-1/2 button light-grey medium-size py-[14px] !text-[14px] !font-bold !rounded-[8px] px-2',
                                        bothButtonsDisabled && 'loading-button',
                                    )}
                                    type="submit"
                                    disabled={bothButtonsDisabled}>
                                    <Loader
                                        isLoading={isWaitingDraft}
                                        className="!bg-[#F9FAFB]"
                                    />
                                    {getLangText('saveDraftVersion')}
                                </button>
                                <button
                                    onClick={() =>
                                        handleSubmitAction('send-sms')
                                    }
                                    className={cn(
                                        'w-full sm:w-1/2 button primary medium-size py-[14px] !text-[14px] !font-bold !rounded-[8px] px-2',
                                        bothButtonsDisabled && 'loading-button',
                                    )}
                                    type="submit"
                                    disabled={bothButtonsDisabled}>
                                    <Loader isLoading={isSendingMessage} />
                                    {getLangText('sendSmsMessage')}
                                </button>
                            </div>
                        </div>
                        <div className="w-[1px] bg-[#E5E7EB] hidden lg:block"></div>
                        <div className="p-16 w-1/2 hidden lg:block">
                            <div className="relative">
                                <img
                                    src="/assets/images/svg/marketing/iphone.svg"
                                    alt="iphone"
                                    className="w-full"
                                />
                                <MessageBubble
                                    message={values.message}
                                    placeholder={PLACEHOLDER}
                                />
                            </div>
                        </div>
                    </form>
                </>
            )}
            {showBalanceModal && (
                <BalanceModal
                    show={showBalanceModal}
                    hideModal={() => setShowBalanceModal(false)}
                    extraValues={{
                        sms_id: id,
                    }}
                />
            )}
            {confirmModalVisible && (
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    title={getLangText('wantToRemoveSmsCampaign')}
                    subTitle={getLangText('campaignWillBeRemoved')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('removeButton')}
                    dialogClassName="small-modal w-420"
                />
            )}
        </>
    );
};

export default SmsMarketingDetails;
