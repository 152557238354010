import React from 'react';

const StylesLoader = ({ className = '', isLoading }) => {
    return (
        <div
            style={isLoading ? { display: 'block' } : { display: 'none' }}
            className={`loader-container ${className}`}>
            <div className="circle-loader text-white">
                <span className="circle" />
            </div>
        </div>
    );
};

export default StylesLoader;
