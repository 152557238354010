import React from 'react';
import { useSelector } from 'react-redux';
import { useUnitPrice } from '../../../../components/app/shared/helpers/Price';
import useLang from '../../../hooks/useLang';
import {
    ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_STRIPE_CARD,
} from '../../../../components/app/shared/enums/PaymentStatuses';
import {
    OUI_OUI_FASHION_SHOP_ID,
    SCRILU_SHOP_ID,
    STYLOWE_BUTY_SHOP_ID,
} from '../../../enums/TrustedShopsIds';

const BLIK = 'blik';
const TRANSFER = 'transfer';
const BM_TRANSFER = 'bm_transfer';
const BLUEMEDIA = 'bluemedia';
const PICKUP = 'pickup';
const SHOP_PICKUP = 'shop_pickup';
const PAYPAL = 'paypal';
const PAYPO = 'paypo';

const PaymentTypesSelect = ({
    values,
    setValue,
    updateValues,
    finalPrice,
    currency,
    freeShippingPrice,
    freeShippingActive,
    data,
}) => {
    const { userData } = useSelector((state) => state.session);
    const { getPrice } = useUnitPrice();
    const { getLangText } = useLang();

    console.log(data);

    const safetyFreeShippingPrice =
        userData.free_shipping_price || freeShippingPrice;
    const safetyFreeShippingActive =
        userData.free_shipping_active || freeShippingActive;

    const onTransferAccountChange = (id) => {
        updateValues({
            payment: `${TRANSFER}_${id}`,
            transfer_account_id: id,
        });
    };

    return values.allEmptyPayments ? (
        <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
            <div>
                <i className="icon-info-c" />
            </div>
            <div>
                <div className="title">
                    {getLangText('paymentMethodNoDeliveriesMethodsLabel')}
                </div>
            </div>
        </div>
    ) : (
        <div className="data-boxes-wrapper">
            {values.stripe_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === ORDER_PAYMENT_STRIPE_CARD ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={
                                values.payment === ORDER_PAYMENT_STRIPE_CARD
                            }
                            onChange={() =>
                                setValue('payment', ORDER_PAYMENT_STRIPE_CARD)
                            }
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {getLangText('cardPaymentLabel')}
                            </div>
                            {!!data?.stripe_price ? (
                                <div className="data">
                                    {getPrice(data?.stripe_price, currency)}
                                </div>
                            ) : (
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            )}
                        </div>
                        {/*<figure>*/}
                        {/*	<img src="/assets/images/payments/paypal.png"*/}
                        {/*		 style={{height: '16px'}}/>*/}
                        {/*</figure>*/}
                    </div>
                </div>
            )}
            {!!+values.lusopay_active && (
                <div
                    className={`content payment-content ${values.payment === ORDER_PAYMENT_MB_WAY ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === ORDER_PAYMENT_MB_WAY}
                            onChange={() =>
                                setValue('payment', ORDER_PAYMENT_MB_WAY)
                            }
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {getLangText('mbwayLabel')}
                            </div>
                            <div className="data">
                                {getLangText('customerBasketFreeLabel')}
                            </div>
                        </div>
                        <figure className="self-center">
                            <img
                                src="/assets/images/payments/mb-way.png"
                                style={{ height: '28px' }}
                            />
                        </figure>
                    </div>
                </div>
            )}
            {values.bm_active === '1' && (
                <>
                    <div
                        className={`content payment-content ${values.payment === BLIK ? 'active' : ''}`}>
                        <div className="content__top-part">
                            <input
                                checked={values.payment === BLIK}
                                onChange={() => setValue('payment', BLIK)}
                                type="radio"
                                required
                                name="payment_radio"
                            />
                            <span className="checkmark" />
                            <div className="flex-grow-1">
                                <div className="label">
                                    {getLangText('paymentMethodBlikLabel')}
                                </div>
                                {!!data?.bm_price ? (
                                    <div className="data">
                                        {getPrice(data?.bm_price, currency)}
                                    </div>
                                ) : (
                                    <div className="data">
                                        {getLangText('customerBasketFreeLabel')}
                                    </div>
                                )}
                            </div>
                            <figure>
                                <img src="/assets/images/payments/blik.svg" />
                            </figure>
                        </div>
                    </div>
                    <div
                        className={`content payment-content ${values.payment === BLUEMEDIA ? 'active' : ''}`}>
                        <div className="content__top-part">
                            <input
                                checked={values.payment === BLUEMEDIA}
                                onChange={() => setValue('payment', BLUEMEDIA)}
                                type="radio"
                                required
                                name="payment_radio"
                            />
                            <span className="checkmark" />
                            <div className="flex-grow-1">
                                <div className="label">
                                    {getLangText(
                                        'paymentMethodOnlineTransferLabel',
                                    )}
                                </div>
                                {!!data?.bm_price ? (
                                    <div className="data">
                                        {getPrice(data?.bm_price, currency)}
                                    </div>
                                ) : (
                                    <div className="data">
                                        {getLangText('customerBasketFreeLabel')}
                                    </div>
                                )}
                            </div>
                            <figure>
                                <img
                                    style={{ height: '18px' }}
                                    src="/assets/images/payments/bluemedia.svg"
                                />
                            </figure>
                        </div>
                    </div>
                </>
            )}
            {!!+values.bm_transfer_active && (
                <div
                    className={`content payment-content ${values.payment === BM_TRANSFER ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === BM_TRANSFER}
                            onChange={() => setValue('payment', BM_TRANSFER)}
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div>
                            <div className="label">
                                {getLangText(
                                    'paymentMethodAutomationTransferLabel',
                                )}
                            </div>
                            <div className="data">
                                {getLangText('customerBasketFreeLabel')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!!+values.mobilepay_active && (
                <div
                    className={`content payment-content ${values.payment === ORDER_PAYMENT_MOBILEPAY ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === ORDER_PAYMENT_MOBILEPAY}
                            onChange={() =>
                                setValue('payment', ORDER_PAYMENT_MOBILEPAY)
                            }
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div>
                            <figure>
                                <img
                                    src="/assets/images/payments/mobilepay.svg"
                                    style={{ height: '18px' }}
                                />
                            </figure>
                            <div className="data">
                                {getLangText('customerBasketFreeLabel')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {values.transfer_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === TRANSFER ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === TRANSFER}
                            onChange={() =>
                                updateValues({
                                    payment: TRANSFER,
                                    transfer_account_id: null,
                                })
                            }
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div>
                            <div className="label">
                                {getLangText('paymentMethodTransferLabel')}
                            </div>
                            <div className="data">
                                {getLangText('customerBasketFreeLabel')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!!values.additional_transfer_accounts?.length &&
                values.additional_transfer_accounts?.map((account) => (
                    <div
                        className={`content payment-content ${values.payment === `${TRANSFER}_${account.id}` ? 'active' : ''}`}>
                        <div className="content__top-part">
                            <input
                                checked={
                                    values.payment ===
                                    `${TRANSFER}_${account.id}`
                                }
                                onChange={() =>
                                    onTransferAccountChange(account.id)
                                }
                                type="radio"
                                required
                                name="payment_radio"
                            />
                            <span className="checkmark" />
                            <div>
                                {account.transfer_account_name ? (
                                    <div className="label">
                                        {account.transfer_account_name}
                                        {!+account.is_custom && (
                                            <span
                                                style={{
                                                    textTransform: 'lowercase',
                                                }}>
                                                {' '}
                                                -{' '}
                                                {getLangText(
                                                    'paymentMethodTransferLabel',
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="label">
                                        {getLangText(
                                            'paymentMethodTransferLabel',
                                        )}
                                    </div>
                                )}
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {values.pickup_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === PICKUP ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === PICKUP}
                            onChange={() => setValue('payment', PICKUP)}
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div>
                            <div className="label">
                                {+userData.id === STYLOWE_BUTY_SHOP_ID
                                    ? 'Za pobraniem'
                                    : getLangText(
                                          'paymentMethodCashOnDeliveryLabel',
                                      )}
                            </div>
                            {values.pickup_price === '0' ||
                            (+finalPrice >= +safetyFreeShippingPrice &&
                                safetyFreeShippingActive === '1') ? (
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            ) : (
                                <div className="data">
                                    {getPrice(values.pickup_price, currency)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {values.shop_pickup_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === SHOP_PICKUP ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === SHOP_PICKUP}
                            onChange={() => setValue('payment', SHOP_PICKUP)}
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div>
                            <div className="label">
                                {getLangText('paymentMethodCashInShopLabel')}{' '}
                                {values.shop_pickup_address}
                            </div>
                            <div className="data">
                                {getLangText('customerBasketFreeLabel')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {values.paypal_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === PAYPAL ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === PAYPAL}
                            onChange={() => setValue('payment', PAYPAL)}
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {getLangText('paymentMethodPaypalLabel')}
                            </div>
                            {!!data?.paypal_price ? (
                                <div className="data">
                                    {getPrice(data?.paypal_price, currency)}
                                </div>
                            ) : (
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            )}
                        </div>
                        <figure>
                            <img
                                src="/assets/images/payments/paypal.png"
                                style={{ height: '16px' }}
                            />
                        </figure>
                    </div>
                </div>
            )}
            {values.paypo_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === PAYPO ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={values.payment === PAYPO}
                            onChange={() => setValue('payment', PAYPO)}
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {getLangText({
                                    key: 'paypoLabel',
                                    data: ['PayPo'],
                                })}
                            </div>
                            {!!data?.bm_price ? (
                                <div className="data">
                                    {getPrice(data?.bm_price, currency)}
                                </div>
                            ) : (
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            )}
                        </div>
                        <figure>
                            <img
                                src="/assets/images/payments/paypo.svg"
                                style={{ height: '18px' }}
                            />
                        </figure>
                    </div>
                </div>
            )}
            {values.paypal_automatic_active === '1' && (
                <div
                    className={`content payment-content ${values.payment === ORDER_PAYMENT_PAYPAL_AUTOMATIC ? 'active' : ''}`}>
                    <div className="content__top-part">
                        <input
                            checked={
                                values.payment ===
                                ORDER_PAYMENT_PAYPAL_AUTOMATIC
                            }
                            onChange={() =>
                                setValue(
                                    'payment',
                                    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
                                )
                            }
                            type="radio"
                            required
                            name="payment_radio"
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {getLangText(
                                    'paymentMethodPaypalAutomaticLabel',
                                )}
                            </div>
                            {!!data?.paypal_automatic_price ? (
                                <div className="data">
                                    {getPrice(
                                        data?.paypal_automatic_price,
                                        currency,
                                    )}
                                </div>
                            ) : (
                                <div className="data">
                                    {getLangText('customerBasketFreeLabel')}
                                </div>
                            )}
                        </div>
                        <figure>
                            <img
                                src="/assets/images/payments/paypal.png"
                                style={{ height: '16px' }}
                            />
                        </figure>
                    </div>
                </div>
            )}
            {!!values.stripe?.length &&
                values.stripe?.map(
                    (item) =>
                        !!+item.active && (
                            <div
                                className={`content payment-content ${values.payment === item.type ? 'active' : ''}`}>
                                <div className="content__top-part">
                                    <input
                                        checked={values.payment === item.type}
                                        onChange={() =>
                                            setValue('payment', item.type)
                                        }
                                        type="radio"
                                        required
                                        name="payment_radio"
                                    />
                                    <span className="checkmark" />
                                    <div className="flex-grow-1">
                                        <div className="label">
                                            {item.label}
                                        </div>
                                        {!!data?.stripe_price ? (
                                            <div className="data">
                                                {getPrice(
                                                    data?.stripe_price,
                                                    currency,
                                                )}
                                            </div>
                                        ) : (
                                            <div className="data">
                                                {getLangText(
                                                    'customerBasketFreeLabel',
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ),
                )}
        </div>
    );
};

export default PaymentTypesSelect;
