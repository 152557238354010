import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import {services} from "../../../../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import history from "../../../../../../history";

export class FacebookAdvertisementsStatsListActions extends ListActions {
    getLoadParams(state, id) {
        const params = super.getLoadParams(state);
        const is_new_client = getFiltersStateRoot(state).data.is_new_client

        if (is_new_client && is_new_client !== 'all') {
            params.is_new_client = is_new_client
        }

        return params;
    }
}

export const getInstance = () => new FacebookAdvertisementsStatsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD_STATS,
    pathname: RouterPaths.MarketingFacebookAdvertisementStatistics,
    prefix,
    preventPushParams: true,
});
export default getInstance();
