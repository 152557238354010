import React from 'react';
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import useLang from "../../../../../../src_shared/hooks/useLang";

const Permission = ({permission, updateValues, values, group, selectedGroup, setIsOpen}) => {
    const {getLangText} = useLang();
    const value = selectedGroup?.permissions?.some((selectedPermission) => selectedPermission.id === permission.id);
    const setValue = () => updateValues({
        selectedOptions: values.selectedOptions.map((option) => option.id === group.id ? {
            ...option,
            permissions: value ?
                option.permissions.filter((i) => i.id !== permission.id) :
                [...option.permissions, {id: permission.id}]
        } : option)
    })

    return (
        <CheckboxField
            className="mb-0 big-size"
            LabelComponent={<div>{getLangText(permission.name) || permission.name}</div>}
            setValue={setValue}
            value={value}
        />
    );
};

export default Permission;
