import React from 'react';
import {
    E_MODO_SHOP_ID,
    KROLOWA_PLUS_SIZE_SHOP_ID,
    PEWEXOWY_SHOP_ID, PIANO_FASHION_SHOP_ID, PRELOVED_SHOP_ID, SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import Field from "../../../../../src_shared/components/form/field/Field";

const ExtraCodes = ({userData, getFieldProps, setValue, values}) => {

    return (
        <>
            <div className="flex items-end space-x-2 sm:space-x-4 mt-3">
                {(+userData.id === PEWEXOWY_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === KROLOWA_PLUS_SIZE_SHOP_ID || +userData.id === E_MODO_SHOP_ID) &&
                    <Field
                        {...getFieldProps('manufacturers_code')}
                        setValue={setValue}
                        label="Kod producenta"
                        className="flex-1 mb-0"
                    />}
                {(!+values.has_sizes && (+userData.id === PEWEXOWY_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === PRELOVED_SHOP_ID || +userData.id === E_MODO_SHOP_ID)) &&
                    <Field
                        {...getFieldProps('external_id')}
                        setValue={setValue}
                        label="Kod magazynowy"
                        className="flex-1 mb-0"
                    />
                }
            </div>
        </>
    );
};

export default ExtraCodes;
