import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import NotificationsPage from "../app/notifications/Page";
import NotificationsWrapper from "../app/notifications/Wrapper";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import FacebookNotifications from "../app/notifications/facebookNotifications/FacebookNotifications";
import {RouterPaths} from "./RouterPath";

const NotificationsRoute = ({path}) => {

    return (
        <PermissionRoute
            path={path}
            allowedPermissions={[SELMO_APP_PERMISSION]}
        >
            <NotificationsWrapper>
                <Switch>
                    <Route
                        exact
                        path={path}
                        component={NotificationsPage}
                    />
                    <Route
                        exact
                        path={RouterPaths.NotificationsFacebook}
                        component={FacebookNotifications}
                    />
                    <Redirect to={path}/>
                </Switch>
            </NotificationsWrapper>
        </PermissionRoute>
    );
};

export default NotificationsRoute;
