import React from 'react';
import {useSelector} from "react-redux";
import Landing from "./landing/Landing";
import {HEADER_BOX_MOBILE_NOTIFICATIONS} from "../shared/enums/TutorialsVideo";
import TutorialPageBox from "../../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";

const tabs = [
    {
        id: 1,
        label: 'selmoApp',
        path: RouterPaths.Notifications,
    },
    {
        id: 2,
        label: 'facebookNotifications',
        path: RouterPaths.NotificationsFacebook,
    },
]

const NotificationsWrapper = ({children}) => {
    const {getLangText} = useLang();

    return (
        <>
            <TutorialPageBox
                page='mobile_notifications'
                videoStatsName={HEADER_BOX_MOBILE_NOTIFICATIONS}
                title="asideClientsLabel"
            />
                <div className="tabs-wrapper bg-[#fff] sm:rounded-medium shadow-default mb-3">
                    {tabs.map((i) => (
                        <NavLink
                            to={i.path}
                            exact
                            key={i.id}
                            // className={`${pathname === i.path ? 'active' : ''}`}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
            {children}
        </>
    );
};

export default NotificationsWrapper;
