import React from 'react';
import useLang from "../../../hooks/useLang";

const SwitchBoxes = ({
                         options,
                         value,
                         name,
                         setValue,
                         getError,
                         validationData,
                         validateErrors,
                         ErrorComponent = null,
                         disabled,
                         fieldName,
                         className = '',
                         required = false,
                         withCheckmark = true,
                         label = null,
                         Content,
                     }) => {
    const {getLangText} = useLang();

    const isError = () => {
        const errorKey = getError(value, validationData);
        return errorKey ? errorKey : false;
    }
    const hasError = !!isError() && validateErrors;

    return (
        <>
            {hasError && ErrorComponent &&
                <ErrorComponent isError={isError} getLangText={getLangText}/>
            }
            {label && <div className="form-label">{label}</div>}
            <div className={`data-boxes-wrapper ${className} ${hasError ? 'error-group' : ''}`}>
                {options.map((item) => (
                    <div key={item.id}>
                        <div className={`content ${value === item.id ? 'active' : ''}`}>
                            <div className="content__top-part">
                                <input
                                    id={item.id}
                                    checked={value === item.id}
                                    onChange={() => setValue(name, item.id)}
                                    type="radio"
                                    name={fieldName || name}
                                    disabled={disabled}
                                    required={required}
                                />
                                {withCheckmark &&
                                    <span className="checkmark"/>
                                }
                                {Content(item, getLangText)}
                                {item.iconClassName &&
                                    <div className="icon-place">
                                        <i className={item.iconClassName}/>
                                    </div>
                                }
                                {item.price &&
                                    <div className="right-place">
                                        {item.price}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

SwitchBoxes.defaultProps = {
    ErrorComponent: (isError, getLangText) => (
        <div className="error-text">
            {getLangText(isError())}
        </div>
    ),
    getError: () => {},
    Content: (item, getLangText) => (
        <div className="flex-grow-1">
            <div className="label">
                {getLangText(item.label) || item.label}
            </div>
            <div className="data"
                 dangerouslySetInnerHTML={{__html: item.description && getLangText(item.description) || item.description}}/>
        </div>
    ),
}

export default SwitchBoxes;
