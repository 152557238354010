import {Link} from "react-router-dom";
import React from "react";
import VideoPreview from "../../../../../src_shared/components/ui/photoPreview/VideoPreview";
import usePhotoPreview from "../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import useAssetsLang from "../../../shared/helpers/useAssetsLang";
import useLang from "../../../../../src_shared/hooks/useLang";
import TutorialsVideo from "../../../shared/enums/TutorialsVideo";
import CheckboxField from "../../../../../src_shared/components/form/checkboxField/CheckboxField";
import ToggleContent from "../../../../../src_shared/components/toggle/ToggleContent";
import PhotoPreviewButton from "../../../../../src_shared/components/ui/photoPreview/PhotoPreviewButton";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_PL} from "../../../shared/enums/ShopOrigins";
import {RouterPaths} from "../../../../routes/RouterPath";
import {prepareRealLang} from "../../../../../services/i18n/i18nAPI";

const Action = ({
                    title,
                    actionLabel,
                    desc,
                    checked,
                    buttonTitle,
                    buttonLink,
                    videoStatsName,
                    previewSrc,
                    time,
                    setOpen,
                    open,
                    id
                }) => {
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {getLangText} = useLang();
    const lang = useAssetsLang();
    const {origin} = useSelector((state) => state.session.userData);

    const previewClick = (e) => {
        e.stopPropagation();
        openPreviewModal();
    }

    const getVideoSource = () => {
        const matched = TutorialsVideo.find((i) => i.id === videoStatsName) || {};

        return matched[`src_${lang}`] || null;
    }

    const ToggleHeader = (isVisible) => {
        return (
            <div className="p-[20px] flex items-center grow">
                <div>
                    {checked ?
                        <img
                            width={20}
                            className="min-w-[20px]"
                            src="/assets/images/svg/circle-check.svg" alt=""/> :
                        <img
                            width={20}
                            className="min-w-[20px]"
                            src="/assets/images/svg/cross.svg" alt=""/>
                    }
                </div>
                <div className="ml-2 text-sm font-bold w-full text-left">
                    {getLangText(title)}
                </div>
                <div
                    className={`text-[10px] sm:block hidden rounded-medium leading-[12px] mr-2 font-bold py-1 px-2 ${checked ? 'bg-[#effef4] text-[#0eb981]' : 'bg-[#fef6da] text-[#f59e0c]'}`}>
                    {getLangText(checked ? 'readyLabel' : actionLabel)}
                </div>
                <div
                    className="ml-auto">
                    <i className={`block icon-arrows text-[8px] text-[#9ca3af] transition-all ${isVisible ? 'rotate-180' : ''}`}/>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`flex items-start w-full border transition-all rounded-big shadow-selmo-border`}>
                <div className="grow">
                    <ToggleContent
                        ToggleHeader={ToggleHeader}
                    >
                        <div
                            className="text-left text-desc text-sm sm:pl-[40px] sm:ml-2 px-[20px] -mt-2 pb-[20px] leading-[23px] font-medium sm:flex items-start">
                            <div className="mr-3">
                                <div className="whitespace-pre-wrap"
                                     dangerouslySetInnerHTML={{__html: getLangText(desc)}}/>
                                {id === 'mobile_app_followers' &&
                                    <a
                                        target="_blank"
                                        className="font-bold hover:no-underline hover:text-[#000]"
                                        href={`https://go.selmo.io/pobierz-film?lang=${prepareRealLang(lang)}`}
                                        dangerouslySetInnerHTML={{__html: getLangText('videoAvailableHere')}}
                                    />
                                }
                                <div className="flex items-center sm:space-x-4 mt-3">
                                    <Link
                                        target={"_blank"}
                                        className="button hidden sm:inline-block black small-size"
                                        to={origin === SHOP_ORIGIN_PL ? buttonLink : RouterPaths.SettingsPayments}
                                    >
                                        {getLangText(buttonTitle)}
                                    </Link>
                                    <div className="text-desc text-xs font-medium flex items-center">
                                        <i class="icon-time text-sm mr-1"/>
                                        {time}&nbsp;
                                        {getLangText('minutes')}
                                    </div>
                                </div>
                            </div>
                            {!!getVideoSource() &&
                                <PhotoPreviewButton
                                    onClick={previewClick}
                                    className="sm:ml-auto w-[100px] min-w-[100px] sm:mt-0 mt-3.5"
                                    src={`/assets/images/multiLanguage/${lang}/actionsToDo/${previewSrc}.png`}
                                />
                            }
                            <Link
                                target={"_blank"}
                                className="button sm:hidden black small-size mt-3 sm:w-auto w-full text-center"
                                to={buttonLink}
                            >
                                {getLangText(buttonTitle)}
                            </Link>
                        </div>
                    </ToggleContent>
                </div>
            </div>
            <VideoPreview
                hide={hidePreviewModal}
                show={showPreviewModal}
                videoStatsName={videoStatsName}
                src={getVideoSource()}
                title={title}
            />
        </>
    );
};

export default Action;