import { prefix } from './formReducer';
import { getStateRoot } from './formReducer';
import FormActions from '../../../../../../../src_shared/form/FormActions';
import { services } from '../../../../../../RestServices';
import { RouterPaths } from '../../../../../../routes/RouterPath';
import api from '../../../../../../../services/axios/axios';
import { selmoUrl } from '../../../../../../../src_shared/api/api';
import { batch } from 'react-redux';
import { ERROR_NOT_FOUND } from '../../../../../../../src_shared/enums/ErrorEnums';
import LangsActions from '../../../../../shared/langs/LangsActions';
import { showAlert } from '../../../../../shared/components/alert/AlertActions';
import MarketingSmsCampaignFormModel from '../../../../../../../modules/models/marketing/MarketingSmsCampaignFormModel';

export class SmsCampaignsFormActions extends FormActions {
    loadData(id) {
        return async (dispatch) => {
            dispatch(this.waiting.startWaiting());

            api.defaults.headers.common['lang'] = dispatch(
                LangsActions.getLang(),
            );
            const restUrl = id
                ? `${this.restService[0]}/${id}`
                : this.restService[0];

            try {
                const { data } = await api.get(`${selmoUrl}/${restUrl}`);
                const parsedItem = dispatch(this.parseData(data.item));

                batch(() => {
                    dispatch(this.data.updateValues(parsedItem));
                    dispatch(this.data.updateEmptyValues(parsedItem));
                    dispatch(this.data.setDataPreloaded(true));
                });
            } catch (e) {
                if (
                    e.response?.data?.message === ERROR_NOT_FOUND ||
                    e.response?.status === 500
                ) {
                    dispatch(this.data.setValue('notFound', true));
                }
                dispatch(this.onErrorLoad(e));
                console.error('Error loadData');
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        };
    }

    saveDraft(values, history, setIsWaiting) {
        return async (dispatch, state) => {
            const { id } = values;
            const { emptyData } = getStateRoot(state());

            dispatch(this.validation.activateValidation());

            if (!this.dataModel.validateData(values)) {
                dispatch(
                    showAlert('fillRequireFieldWarning', '', 'alert-fail'),
                );
                console.error('Validation failed!');
                return;
            }

            const filteredData = Object.entries(values).filter(
                ([key]) => key !== 'id',
            );
            const finalData = Object.fromEntries(filteredData);
            const filteredEmptyData = Object.entries(emptyData).filter(
                ([key]) => key !== 'id',
            );
            const finalEmptyData = Object.fromEntries(filteredEmptyData);

            const preparedData = this.dataModel.buildDTO(
                finalData,
                finalEmptyData,
            );

            setIsWaiting(true);

            try {
                await api.put(
                    `${selmoUrl}/${this.restService[0]}/${id}`,
                    preparedData,
                );
                dispatch(this.afterSubmit(id));
                history.push(RouterPaths.MarketingSmsCampaigns);

                batch(() => {
                    dispatch(showAlert());
                    dispatch(this.validation.deactivateValidation());
                    dispatch(this.data.setValue('initialMount', true));
                });
            } catch (e) {
                dispatch(
                    showAlert(
                        e?.response?.data?.message || 'sthWentWrongWarning',
                        '',
                        'alert-fail',
                    ),
                );
                console.error('Error submitEditForm');
            } finally {
                setIsWaiting(false);
            }
        };
    }

    sendMessage(values, history, showBalanceModal, setIsWaiting) {
        return async (dispatch, state) => {
            const { id } = values;
            const { emptyData } = getStateRoot(state());

            dispatch(this.validation.activateValidation());

            if (!this.dataModel.validateData(values)) {
                dispatch(
                    showAlert('fillRequireFieldWarning', '', 'alert-fail'),
                );
                console.error('Validation failed!');
                return;
            }

            const filteredData = Object.entries(values).filter(
                ([key]) => key !== 'id',
            );
            const finalData = Object.fromEntries(filteredData);
            const filteredEmptyData = Object.entries(emptyData).filter(
                ([key]) => key !== 'id',
            );
            const finalEmptyData = Object.fromEntries(filteredEmptyData);

            const preparedData = this.dataModel.buildDTO(
                finalData,
                finalEmptyData,
            );

            setIsWaiting(true);

            try {
                await api.post(
                    `${selmoUrl}/${this.restService[1]}/${id}`,
                    preparedData,
                );

                dispatch(this.afterSubmit(id));
                history.push(RouterPaths.MarketingSmsCampaigns);

                batch(() => {
                    dispatch(
                        showAlert(
                            'yuhuLabel',
                            'successfullySmsSent',
                            'alert-success',
                        ),
                    );
                    dispatch(this.validation.deactivateValidation());
                    dispatch(this.data.setValue('initialMount', true));
                });
            } catch (e) {
                if (e?.response?.data?.message === 'NOT_ENOUGH_BALANCE') {
                    showBalanceModal(true);
                } else {
                    dispatch(
                        showAlert(e?.response?.data?.message, '', 'alert-fail'),
                    );
                }
            } finally {
                setIsWaiting(false);
            }
        };
    }
}

export const getInstance = () =>
    new SmsCampaignsFormActions({
        prefix,
        getStateRoot,
        route: RouterPaths.MarketingSmsCampaignsDetails,
        restService: [
            services.API_MARKETING_SMS,
            services.API_MARKETING_SMS_SEND,
        ],
        model: MarketingSmsCampaignFormModel,
    });

export default getInstance();
