import React from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const BoxWrapper = ({title, desc, icon = null, children}) => {
    const {getLangText} = useLang();

    return (
        <div className="rounded-[8px] border p-[14px] sm:p-6">
            <div className="flex items-center mb-3">
                <div>
                    <div className="font-bold">{getLangText(title)}</div>
                    <div className="text-sm text-desc font-medium">{getLangText(desc)}</div>
                </div>
                {icon &&
                    <i className={`ml-auto text-desc icon-${icon}`}/>
                }
            </div>

            {children}
        </div>
    );
};

export default BoxWrapper;
