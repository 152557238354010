export const ORDER_STATUS_ALL = 0;
export const ORDER_STATUS_CREATED = 1;
export const ORDER_STATUS_NOT_PAID = 5;
export const ORDER_STATUS_TO_SEND = 2;
export const ORDER_STATUS_OPEN_CART = 8;
export const ORDER_STATUS_EDITED = 7;
export const ORDER_STATUS_SENT = 3;
export const ORDER_STATUS_FINISHED = 4;
export const ORDER_PAYMENT_PICKUP = 'pickup';
export const ORDER_PAYMENT_TRANSFER = 'transfer';
export const ORDER_PAYMENT_BLUEMEDIA = 'bluemedia';

export const ORDER_STATUES = [
    {
        value: ORDER_STATUS_CREATED,
        label: 'Utworzone',
        color: 'waiting',
    },
    {
        value: ORDER_STATUS_NOT_PAID,
        label: 'Nieopłacone',
        color: 'not-paid',
    },
    {
        value: ORDER_STATUS_TO_SEND,
        label: 'Do wysłania',
        color: 'to-send',
    },
    {
        value: ORDER_STATUS_OPEN_CART,
        label: 'Otwarta paczka',
        color: 'open-cart',
    },
    {
        value: ORDER_STATUS_EDITED,
        label: 'Edytowane',
        color: 'edited',
    },
    {
        value: ORDER_STATUS_SENT,
        label: 'Wysłane',
        color: 'already-send',
    },
    {
        value: ORDER_STATUS_FINISHED,
        label: 'Zrealizowane',
        color: 'finished',
    },
    {
        value: ORDER_PAYMENT_PICKUP,
        label: 'Płatność przy odbiorze',
        color: 'waiting',
    },
    {
        value: ORDER_PAYMENT_TRANSFER,
        label: 'Przelew standardowy',
        color: 'waiting',
    },
    {
        value: ORDER_PAYMENT_BLUEMEDIA,
        label: 'Przelew internetowy',
        color: 'waiting',
    },
];

export const findOrderStatusById = (id) =>
    ORDER_STATUES.find((status) => status.value === id);
