import {langUrl} from "./i18n";

export const prepareRealLang = (lang) => {
	if (lang === 'us' || lang === 'en') return 'en'
	if (lang === 'es-mx') return 'es'
	return lang;
}

export function fetchTranslations(lang, path = langUrl) {
	return new Promise((resolve) => {
		fetch(path.replace("{lang}", prepareRealLang(lang)))
			.then((response) => response.json())
			.then((data) => resolve(data));
	});
}
