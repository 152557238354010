import { getStateRoot, prefix } from './listReducer';
import { RouterPaths } from '../../../../../../routes/RouterPath';
import { services } from '../../../../../../RestServices';
import ListActions, {
    getGETParamsUrl,
} from '../../../../../../../src_shared/list/ListActions';
import api from '../../../../../../../services/axios/axios';
import { selmoUrl } from '../../../../../../../src_shared/api/api';
import { batch } from 'react-redux';
import SessionActions from '../../../../../shared/session/SessionActions';

export class SmsCampaignsListActions extends ListActions {
    loadData(id, signal) {
        return async (dispatch, getState) => {
            const { firstInit } = getState().session;
            const { itemsPerPage } = this.getStateRoot(getState());

            dispatch(this.waiting.startWaiting());

            if (firstInit) {
                dispatch(this.onFirstInit());
            }

            const params = this.getLoadParams(getState(), id);
            const restUrl = +id
                ? `/${id}${getGETParamsUrl(params)}`
                : getGETParamsUrl(params);

            try {
                const response = await api.get(
                    `${selmoUrl}/${this.restService}${restUrl}`,
                    { signal },
                );

                const { items, total, isFirstStepPage, balance_amount } =
                    response.data;

                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort(
                        (a, b) =>
                            parseFloat(b[this.defaultSortBy]) -
                            parseFloat(a[this.defaultSortBy]),
                    );
                }

                batch(() => {
                    dispatch(
                        SessionActions.updateUserData(
                            'sp_balance',
                            balance_amount,
                        ),
                    );
                    dispatch(this.table.updateAllItems(items));
                    dispatch(this.setFirstStepPage(isFirstStepPage));
                    dispatch(this.pagination.updateTotalItems(total));
                });
            } catch (e) {
                dispatch(this.table.getListError(e));
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }

                dispatch(this.waiting.stopWaiting());
            }
        };
    }
}

export const getInstance = () =>
    new SmsCampaignsListActions({
        getStateRoot,
        restService: services.API_MARKETING_SMS,
        pathname: RouterPaths.MarketingSmsCampaigns,
        prefix,
    });

export default getInstance();
