export const SYSTEM_IOS = 'IOS';
export const SYSTEM_ANDROID = 'ANDROID';
export const SYSTEM_WINDOWS = 'WINDOWS';

export const SYSTEM_TYPES = [
  {
    value: SYSTEM_IOS,
    label: 'iOS',
  },
  {
    value: SYSTEM_ANDROID,
    label: 'Android',
  },
  {
    value: SYSTEM_WINDOWS,
    label: 'Windows',
  },
];
