import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import {services} from "../../../../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../../../../shared/session/SessionActions";
export class FacebookAdvertisementsListActions extends ListActions {

    setFacebookAdId(id) {
        return {
            type: `${this.prefix}SET_FACEBOOK_AD_ID`,
            id,
        };
    }

    loadData(id, signal) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            const {itemsPerPage, sortBy} = this.getStateRoot(getState());
            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ?
                `/${id}${getGETParamsUrl(params)}` :
                getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, {signal})
                const {items, total, isFirstStepPage, facebook_ads_active, fb_ad_account_id} = response.data;
                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
                }

                batch(() => {
                    if (!+facebook_ads_active) {
                        dispatch(SessionActions.updateUserData('facebook_ads_active', '2'))
                    }
                    dispatch(this.setFacebookAdId(fb_ad_account_id))
                    dispatch(this.table.updateAllItems(items))
                    dispatch(this.setFirstStepPage(isFirstStepPage))
                    dispatch(this.pagination.updateTotalItems(total))
                })

            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }

}

export const getInstance = () => new FacebookAdvertisementsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD,
    pathname: RouterPaths.MarketingFacebookAdvertisements,
    prefix,
});
export default getInstance();
