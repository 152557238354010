import React, {useEffect} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import Model from "../../../../../../src_shared/modules/model/Model";
import SwitchBoxes from "../../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const Accounts = () => {
    const {getLangText} = useLang();
    const history = useHistory();
    const [{onSave, isWaiting, getFieldProps, values, setValue}] = useFormLocal({
        model: new Model(),
        rest: services.API_MARKETING_AD_ACCOUNTS,
        initialAutoLoad: false,
        afterSubmit: () => history.push(RouterPaths.MarketingFacebookAdvertisements)
    });

    const [{isLoading, data}] = useFetchData({
        rest: services.API_MARKETING_AD_ACCOUNTS,
        initialData: [],
    })

    useEffect(() => {
        if (data?.length === 1) setValue('id', data[0]?.id);
    }, [data]);

    const Content = (item) => (
        <div className="flex-grow-1 flex items-center">
            <div className="w-[34px] h-[34px] rounded-full flex items-center justify-center border mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
                    <g fill="none" fillRule="evenodd" transform="translate(0 2.5)">
                        <rect
                            width={9}
                            height={9}
                            x={1}
                            y={1}
                            stroke="#000"
                            strokeWidth={2}
                            rx={3}
                        />
                        <rect width={4} height={1.5} x={12} y={1} fill="#000" rx={0.5}/>
                        <rect width={4} height={1.5} x={12} y={5} fill="#000" rx={0.5}/>
                        <rect width={4} height={1.5} x={12} y={9} fill="#000" rx={0.5}/>
                        <path stroke="#000" strokeLinecap="square" d="M2.5 7.5h6M6.5 7.5v1"/>
                    </g>
                </svg>
            </div>
            <div>
                <div className="label">
                    {item.name}
                </div>
                <div className="data">
                    {getLangText({key: 'adAccountId', data: [item.id]})}
                </div>
            </div>

        </div>
    )

    return (
        <div className="bg-[#fff] sm:pt-20 sm:pb-20 p-6 loader-parent">
            <Loader isLoading={isLoading} className="small-loader"/>
            <div className="max-w-[550px] mx-auto">
                <div class="text-center">
                    <div className="text-xl sm:text-2xl font-bold">{getLangText('selectAdAccount')}</div>
                    <div className="text-desc sm:text-[16px] text-sm font-medium">{getLangText('selectAdAccountSubLabel')}</div>
                </div>
                <div className="mt-4">
                    <form onSubmit={onSave}>
                        <SwitchBoxes
                            {...getFieldProps('id')}
                            setValue={setValue}
                            options={data}
                            className="separated small-padd w-full"
                            Content={Content}
                            required={true}
                            // ErrorComponent={() => (
                            // 	<div className="form-info-box alert-style red w-100 mb-2">
                            // 		<div>
                            // 			<i className="icon-info-c"/>
                            // 		</div>
                            // 		<div className="title">
                            // 			{getLangText('fillRequiredFieldWarning')}
                            // 		</div>
                            // 	</div>
                            // )}
                        />
                        <div className="flex justify-end mt-3 sm:grow-0 grow">
                            <button
                                style={{borderRadius: 8, fontSize: 14}}
                                className={`button primary sm:w-auto w-full medium-size ${isWaiting ? 'loading-button' : ''}`}
                                type="submit"
                            >
                                {isWaiting && <Loader isLoading={true} />}
                                {getLangText('connectAccount')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Accounts;
