import {getStateRoot, prefix} from "./listReducer";
import {services} from "../../../../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../../../../shared/session/SessionActions";
import {
    getStateRoot as getFiltersStateRoot
} from "../../filters/filtersReducer";
import {convertDate} from "../../../../../shared/helpers/dateHelpers";
import history from "../../../../../../admin/components/history";
import {RouterPaths} from "../../../../../../routes/RouterPath";

export class FacebookStatisticsDetailsListActions extends ListActions {

    getLoadParams(state) {
        const params = super.getLoadParams(state);
        const { fromDate, toDate } = getFiltersStateRoot(state).data

        if (fromDate) {
            params.dateFrom = convertDate(fromDate);
        }

        if (toDate) {
            params.dateTo = convertDate(toDate);
        }

        history.replace({
            pathname: this.pathname,
            search: getGETParamsUrl(params)
        });

        return params;
    }

    loadData(id, signal) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ?
                `/${id}${getGETParamsUrl(params)}` :
                getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, {signal})
                const {item, total, isFirstStepPage} = response.data;

                batch(() => {
                    dispatch(this.table.updateAllItems(item))
                    dispatch(this.setFirstStepPage(isFirstStepPage))
                    dispatch(this.pagination.updateTotalItems(total))
                })

            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }

}

export const getInstance = () => new FacebookStatisticsDetailsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD_STATS_DETAILS,
    pathname: RouterPaths.MarketingFacebookStatistics,
    prefix,
});
export default getInstance();
