import {selmoActionsRequired} from "./ActionsToDo";
import {useSelector} from "react-redux";

const useCountActionsRequired = () => {
    const {userData} = useSelector((state) => state.session);
    const actionRequiredLength = selmoActionsRequired.filter((action) => !userData.clientPoints[action.id])?.length;
    const isAllActive = actionRequiredLength === 0;
    const chartPath = isAllActive ?  `/assets/images/svg/actionsRequiredCharts/green.png` : `/assets/images/svg/actionsRequiredCharts/${actionRequiredLength}.svg`

    return {
        isAllActive,
        actionRequiredLength,
        chartPath,
        text: isAllActive ? '' : `${actionRequiredLength} / ${selmoActionsRequired.length}`
    }
};

export default useCountActionsRequired;