import React, { useEffect, useMemo, useState } from 'react';
import {
    findOrderPaymentStatusById,
    ORDER_PAYMENT_BANCONTACT,
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_BM_TRANSFER,
    ORDER_PAYMENT_LUSOPAY_MULTIBANCO,
    ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_PICKUP,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER,
    ORDER_PAYMENT_PAYPO,
} from '../../enums/PaymentStatuses';
import useLang from '../../../../../src_shared/hooks/useLang';
import {
    AILEMA_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    CALZADO_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    SHOP_PRZYDA_CI_SIE,
    STYLOWE_BUTY_SHOP_ID,
    TNK_SHOP_ID,
} from '../../../../../src_shared/enums/TrustedShopsIds';
import AdditionalTransferAccounts from './components/AdditionalTransferAccounts';
import CardDetails from './components/CardDetails';
import { useUnitPrice } from '../../helpers/Price';
import { SHOP_ORIGIN_FR } from '../../enums/ShopOrigins';

export const onlinePaymentsIdArray = [
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_BANCONTACT,
    ORDER_PAYMENT_PAYPO,
];

const PaymentMethodsForm = ({
    items,
    shopInfo,
    shopPayment,
    onPaymentChange,
    values,
    updateValues,
    hasError,
    setPaymentMethodsValidation,
    withPopularBox = true,
    className = '',
    paymentValidationActive,
    isOrderPickupSelected = false,
    hiddenPickupMethod = false,
}) => {
    const { getLangText } = useLang();
    const [cardDetailsVisible, setCardDetailsVisible] = useState(false);
    const { getPrice } = useUnitPrice();
    const isPopularBoxActive = useMemo(
        () => items?.some((i) => onlinePaymentsIdArray.includes(i.type)),
        [items],
    );
    const checkIsPaymentOnline = (id) =>
        onlinePaymentsIdArray.includes(id) || id?.includes('stripe_');

    const getPriceForDescription = (item) => {
        if (item.type !== ORDER_PAYMENT_PICKUP) return '';
        return getPrice(item.price, shopInfo?.currency_shortcut);
    };

    const getPaymentCommissionPrice = (id) => {
        if (id !== ORDER_PAYMENT_PICKUP) return '';
        if (id === ORDER_PAYMENT_PAYPAL) {
            return shopPayment?.paypal_commission
                ? getPrice(
                      shopPayment?.paypal_commission,
                      shopInfo?.currency_shortcut,
                  )
                : getLangText('freeLabel');
        } else if (
            id === ORDER_PAYMENT_BLIK ||
            id === ORDER_PAYMENT_BLUEMEDIA ||
            (id === ORDER_PAYMENT_BM_TRANSFER &&
                +shopInfo?.id === AILEMA_SHOP_ID) ||
            id === ORDER_PAYMENT_LUSOPAY_MULTIBANCO
        ) {
            return shopPayment?.bm_commission
                ? getPrice(
                      shopPayment?.bm_commission,
                      shopInfo?.currency_shortcut,
                  )
                : getLangText('freeLabel');
        } else if (id === ORDER_PAYMENT_TRANSFER) return '';
        else return isPopularBoxActive ? '' : getLangText('freeLabel');
    };

    const optionsWhenOrderPickupSelected =
        ((+shopInfo?.id === BUTIK_MADAME_SHOP_ID ||
            +shopInfo?.id === TNK_SHOP_ID) &&
            isOrderPickupSelected) ||
        hiddenPickupMethod
            ? items?.filter((x) => x.type !== ORDER_PAYMENT_PICKUP)
            : items;

    const options = useMemo(
        () =>
            optionsWhenOrderPickupSelected?.map((i) => ({
                ...i,
                description: !!+i.price
                    ? +values.shopInfo?.order_stage > 1
                        ? getLangText('paidLabel')
                        : getPriceForDescription(i)
                    : getPaymentCommissionPrice(i.type),
                label:
                    i.label ||
                    getLangText(findOrderPaymentStatusById(i.type)?.label) ||
                    findOrderPaymentStatusById(i.type)?.label,
                id: i.type,
            })),
        [optionsWhenOrderPickupSelected, shopPayment],
    );

    const onTransferAccountChange = (id) => {
        onPaymentChange({
            payment: `${ORDER_PAYMENT_TRANSFER}_${id}`,
            transfer_account_id: id,
        });
    };

    const getPreparedPaymentLabel = (item) => {
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            +shopInfo?.id === SHOP_BAZAR_SHOP_ID
        ) {
            return getLangText('paymentMethodPaypalWithProvisionLabel');
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            +shopInfo?.id === CALZADO_SHOP_ID
        ) {
            return getLangText('paypalForeignTransfers');
        }
        if (item.id === ORDER_PAYMENT_PAYPAL && +shopInfo?.id === 6369) {
            return 'PayPal - Choisir paiement "ENTRE PROCHE"';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL_AUTOMATIC &&
            +shopInfo?.id === 5479
        ) {
            return 'Paiement PayPal en 4x (á partir de 30E)';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL_AUTOMATIC &&
            shopInfo?.origin === SHOP_ORIGIN_FR
        ) {
            return 'PayPal / Paiement CB / Paiement en 4 fois (à partir de 30E)';
        }
        if (
            item.id === ORDER_PAYMENT_PAYPAL &&
            shopInfo?.origin === SHOP_ORIGIN_FR
        ) {
            return 'PayPal (Entre proches)';
        }
        if (
            item.id === ORDER_PAYMENT_PICKUP &&
            +shopInfo?.id === STYLOWE_BUTY_SHOP_ID
        ) {
            return 'Za pobraniem';
        }
        return getLangText(item.label) || item.label;
    };

    useEffect(() => {
        if (values.payment === ORDER_PAYMENT_STRIPE_CARD) {
            setCardDetailsVisible(true);
        } else if (cardDetailsVisible) {
            setCardDetailsVisible(false);
            setPaymentMethodsValidation(false);
        }
    }, [values.payment]);

    return (
        <>
            {isPopularBoxActive && withPopularBox && (
                <div className="popular-box">
                    <div className="text">
                        <i className="icon-safe" />
                        {getLangText('mostPopularOptionsLabel')}
                    </div>
                </div>
            )}
            {hasError && (
                <div className="form-info-box alert-style red w-100 mb-2">
                    <div>
                        <i className="icon-info-c" />
                    </div>
                    <div className="title">
                        {getLangText('customerBasketPaymentTypeLabel')}
                    </div>
                </div>
            )}
            <div
                className={`data-boxes-wrapper ${className} separated ${hasError ? 'error-group' : ''}`}>
                {!options.length ? (
                    <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                        <div>
                            <i className="icon-info-c" />
                        </div>
                        <div>
                            {+shopInfo?.id === SHOP_PRZYDA_CI_SIE ? (
                                <div className="title">
                                    Nie możemy aktualnie zrealizować płatności.
                                    Prosimy wrócić później.
                                </div>
                            ) : (
                                <div className="title">
                                    {getLangText(
                                        'paymentMethodNoDeliveriesMethodsLabel',
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    options.map((item) => (
                        <React.Fragment key={item.id}>
                            {!!+item.active && (
                                <div
                                    className={`content p-0 payment-content with-left-border ${checkIsPaymentOnline(item.id) ? 'blue-border' : ''} ${values.payment === item.id ? 'active' : ''}`}>
                                    <div
                                        className={`content__top-part ${item.id === ORDER_PAYMENT_STRIPE_CARD ? 'align-items-start' : ''}`}>
                                        <input
                                            checked={values.payment === item.id}
                                            onChange={() =>
                                                onPaymentChange(
                                                    'payment',
                                                    item.id,
                                                )
                                            }
                                            type="radio"
                                            name="payment"
                                            required
                                        />
                                        <span className="checkmark" />
                                        <div className="flex-grow-1">
                                            <div className="label">
                                                {item.id ===
                                                ORDER_PAYMENT_MOBILEPAY ? (
                                                    <figure>
                                                        <img
                                                            src="/assets/images/payments/mobilepay.svg"
                                                            style={{
                                                                height: '18px',
                                                            }}
                                                        />
                                                    </figure>
                                                ) : (
                                                    getPreparedPaymentLabel(
                                                        item,
                                                    )
                                                )}
                                            </div>
                                            <div className="data">
                                                {(item.description &&
                                                    getLangText(
                                                        item.description,
                                                    )) ||
                                                    item.description}
                                                {checkIsPaymentOnline(
                                                    item.id,
                                                ) && (
                                                    <div>
                                                        <div className="popular-text">
                                                            <i className="icon-time" />
                                                            {getLangText(
                                                                'paymentWillArriveImmediatelyLabel',
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {(item.id ===
                                                    ORDER_PAYMENT_TRANSFER ||
                                                    item.id ===
                                                        ORDER_PAYMENT_BM_TRANSFER) &&
                                                    isPopularBoxActive && (
                                                        <div>
                                                            <div className="popular-text grey">
                                                                <i className="icon-time" />
                                                                {getLangText(
                                                                    'paymentWillArriveWithinTwoDaysLabel',
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                {cardDetailsVisible &&
                                                    item.id ===
                                                        ORDER_PAYMENT_STRIPE_CARD && (
                                                        <CardDetails
                                                            values={values}
                                                            updateValues={
                                                                updateValues
                                                            }
                                                            paymentValidationActive={
                                                                paymentValidationActive
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                        {item.id === ORDER_PAYMENT_BLIK && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/blik.svg"
                                                    alt="BLIK"
                                                />
                                            </figure>
                                        )}
                                        {item.id ===
                                            ORDER_PAYMENT_BLUEMEDIA && (
                                            <figure>
                                                <img
                                                    style={{ height: '18px' }}
                                                    src="/assets/images/payments/bluemedia.svg"
                                                    alt="BLUEMEDIA"
                                                />
                                            </figure>
                                        )}
                                        {item.id ===
                                            ORDER_PAYMENT_STRIPE_CARD && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/cards.svg"
                                                    style={{ height: '16px' }}
                                                    alt="CARDS"
                                                />
                                            </figure>
                                        )}
                                        {item.id?.includes(
                                            ORDER_PAYMENT_PAYPAL,
                                        ) && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/paypal.png"
                                                    style={{ height: '16px' }}
                                                    alt="PAYPAL"
                                                />
                                            </figure>
                                        )}
                                        {item.id === ORDER_PAYMENT_MB_WAY && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/mb-way.png"
                                                    style={{ height: '26px' }}
                                                    alt="MB WAY"
                                                />
                                            </figure>
                                        )}
                                        {item.id ===
                                            ORDER_PAYMENT_LUSOPAY_MULTIBANCO && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/multibanco.png"
                                                    style={{ height: '38px' }}
                                                    alt="MULTIBANCO"
                                                />
                                            </figure>
                                        )}
                                        {item.id === ORDER_PAYMENT_TRANSFER && (
                                            <figure>
                                                <img
                                                    src="/assets/images/payments/standard.svg"
                                                    style={{ height: '26px' }}
                                                    alt="TRANSFER"
                                                />
                                            </figure>
                                        )}
                                        {item.id ===
                                            ORDER_PAYMENT_BANCONTACT && (
                                            <figure>
                                                <img
                                                    style={{ height: '30px' }}
                                                    src="/assets/images/payments/bancontact.svg"
                                                    alt="BANCONTACT"
                                                />
                                            </figure>
                                        )}
                                        {!!item.logo && (
                                            <figure>
                                                <img
                                                    className="max-w-[100px]"
                                                    style={{ height: '30px' }}
                                                    src={item.logo}
                                                    alt={item.label}
                                                />
                                            </figure>
                                        )}
                                    </div>
                                </div>
                            )}
                            {item.id === ORDER_PAYMENT_TRANSFER &&
                                !!item.additional_transfer_accounts?.length &&
                                item.additional_transfer_accounts?.map(
                                    (account) => (
                                        <AdditionalTransferAccounts
                                            key={account.id}
                                            account={account}
                                            isPopularBoxActive={
                                                isPopularBoxActive
                                            }
                                            onTransferAccountChange={
                                                onTransferAccountChange
                                            }
                                            shopInfo={shopInfo}
                                            values={values}
                                        />
                                    ),
                                )}
                        </React.Fragment>
                    ))
                )}
            </div>
        </>
    );
};

export default PaymentMethodsForm;
