import React from 'react';
import Chart from 'react-apexcharts';
import EmptyList from '../../../shared/EmptyList';
import { getCurrenciesShortcut } from '../../list/List';
import moment from 'moment';
import useLang from '../../../../../src_shared/hooks/useLang';
import { convertToUserTimeZone } from '../../../shared/helpers/dateHelpers';
import { useUnitPrice } from '../../../shared/helpers/Price';

export const labelStyle = {
  style: {
    fontSize: '12px',
    colors: '#9CA3AF',
    fontFamily: 'Plus Jakarta Sans',
  },
};

export const formattedMonth = (monthNumber) =>
  convertToUserTimeZone().month(monthNumber).format('MMM');
export const formattedNumberMonth = (monthNumber) =>
  convertToUserTimeZone()
    .month(monthNumber - 1)
    .format('MM');
export const formattedYear = (monthNumber) =>
  convertToUserTimeZone().year(monthNumber).format('YY');

const IncomesChart = ({ items, currency }) => {
  const { getLangText } = useLang();
  const { getPrice } = useUnitPrice();

  const options = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: '#9CA3AF',
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    grid: {
      borderColor: '#D1D5DB',
      strokeDashArray: 5,
    },
    xaxis: {
      categories: items?.ordersInfo?.map((i) =>
        i.day
          ? `${i.day}.${formattedNumberMonth(i.month)}`
          : `${formattedMonth(i.month)} ${formattedYear(i.year)}`
      ),
      labels: {
        ...labelStyle,
        hideOverlappingLabels: true,
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    legend: {
      show: false,
    },
    colors: ['#1C4ED8', '#90E8EE'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: '#3287ef',
            opacity: 1,
          },
          {
            offset: 50,
            color: '#1C4ED8',
            opacity: 1,
          },
        ],
      },
    },
    tooltip: {
      custom: function ({ series, dataPointIndex, seriesIndex, w }) {
        return `<div class="chart-tooltip">
						<div class="date-label">
						${w.config.series[0].data[dataPointIndex].x}
						</div>
						<div class="label">${getLangText('incomeLabel')}</div>
						<div class="value">${getPrice(
              series[0][dataPointIndex],
              items?.currency?.shortcut
            )}</div>
					</div>`;
      },
    },
  };

  const series = [
    {
      name: getLangText('incomesLabel'),
      data: items.ordersInfo?.map((i) => ({
        y: Math.round(+i.price),
        x: i.day
          ? `${i.day}.${formattedNumberMonth(i.month)}`
          : `${formattedMonth(i.month)} ${formattedYear(i.year)}`,
      })),
    },
  ];

  return (
    <div className="default-shadow-box mb-3">
      <div className="title title-with-legend">
        {getLangText('incomesLabel')}
        {!!items.ordersInfo?.length && (
          <div className="custom-legends">
            <div>{getLangText('incomesLabel')}</div>
          </div>
        )}
      </div>
      <EmptyList
        className="full-size"
        imgSrc="/assets/images/empty/chart-1.svg"
        items={items.ordersInfo}
        title=""
      >
        <div className="overflow-x-auto horizontal-custom-scroll">
          <div
            style={{ minWidth: items.ordersInfo?.length * 63 }}
            className="chart-wrapper"
          >
            <Chart
              options={options}
              series={series}
              type="line"
              width="100%"
              height={265}
            />
          </div>
        </div>
      </EmptyList>
    </div>
  );
};

export default IncomesChart;
