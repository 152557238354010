import React, {useEffect, useState} from 'react';
import SearchInput from "../../../../../../../shared/components/form/SearchInput";
import Loader from "../../../../../../../shared/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../../../RestServices";
import Post from "./components/Post";
import useScrollList from "./components/useScrollList";
import ValidationBox from "../ValidationBox";
import ServerImage from "../../../../../../../shared/components/serverImage/ServerImage";
import SelectedPost from "./components/SelectedPost";

const PostsLists = ({setValue, values, hasError, isActive}) => {

    const {getLangText} = useLang();

    const [selectedParams, setSelectedParams] = useState({
        superSearch: '',
        limit: 6,
    })

    const {
        getNewPage,
        hasMore,
        isNewPageLoading,
        isLoading,
        data,
        page,
        setPage,
        fetchData,
    } = useScrollList({
        simpleArray: true,
        params: selectedParams,
        initialAutoLoad: false,
        rest: services.API_MARKETING_FACEBOOK_POSTS,
    });

    const search = {
        updateSearchText: (value) => setSelectedParams((prevState) => ({...prevState, superSearch: value})),
        callSearch: fetchData,
        searchText: selectedParams.superSearch,
    }

    useEffect(() => {
        if (!page) {
            fetchData()
        }
    }, [page])

    useEffect(() => {
        // setPage(1)
    }, [selectedParams.superSearch])

    return (
        <div>
            {/*<SearchInput*/}
            {/*    className="full-width-input mb-2"*/}
            {/*    placeholder={getLangText('searchPlaceholder')}*/}
            {/*    search={search}*/}
            {/*/>*/}
            {values?.selected_post?.id ?
                <div>
                    <div className="form-label">
                        {getLangText('selectedPostLabel')}
                    </div>
                    <SelectedPost
                        isActive={isActive}
                        setValue={setValue}
                        item={values.selected_post}
                    />
                </div> :
                <div className="loader-parent">
                    <Loader
                        className="small-loader"
                        isLoading={isLoading}/>
                    {!data?.length ?
                        <div className="flex items-center justify-center pt-12">
                            <div className="text-center max-w-[200px]">
                                <div className="font-bold">{getLangText('noPostToSelectLabel')}</div>
                            </div>
                        </div> :
                        <div className="data-boxes-wrapper small-padd products-boxes">
                            <div
                                className="max-h-[400px] overflow-y-auto"
                                id="scrollableDiv"
                                style={{flex: 1}}
                            >
                                <InfiniteScroll
                                    dataLength={data?.length}
                                    next={getNewPage}
                                    loader={isNewPageLoading && <Loader isLoading={true} className="static-loader"/>}
                                    hasMore={hasMore}
                                    scrollableTarget="scrollableDiv"
                                    className=""
                                >
                                    {data?.map((item) => (
                                        <Post
                                            key={item.id}
                                            item={item}
                                            setValue={(value) => setValue('selected_fb_ad_post_id', value)}
                                            values={values}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        </div>
                    }
                </div>
            }
            <ValidationBox
                className="mt-3"
                visible={hasError}
                title={'completeThisStepToGo'}
            />
        </div>
    );
};

export default PostsLists;
