import React from 'react';
import useLang from '../../../hooks/useLang';

const Group = (props) => {
    const { getLangText } = useLang();

    const {
        validateErrors,
        value,
        validationData,
        label,
        showLabel,
        children,
        getError,
        className,
        addon,
        optional,
        required,
        disabled,
        floatedLabel,
        subLabel,
        style,
        id,
        labelClassName,
        errorText = true,
        dataText = null,
    } = props;

    const isSelectedValue = value?.length;
    const isError = () => {
        const errorKey = getError(value, validationData);
        return errorKey ? errorKey : false;
    };

    const hasError = !!isError() && validateErrors;

    return (
        <div
            data-text={dataText}
            id={id}
            style={style}
            className={`form-group ${disabled ? 'disabled' : ''} ${hasError ? 'error-group' : ''} ${required ? 'required-field' : ''} ${className} ${!!addon ? 'form-group-addon' : ''}`}>
            {!floatedLabel && showLabel && (
                <label className={`control-label ${labelClassName}`}>
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                    {optional && (
                        <span className="optional text-desc">
                            ({getLangText('optionalLabel')})
                        </span>
                    )}
                    {subLabel && (
                        <div
                            className="sub-label"
                            dangerouslySetInnerHTML={{ __html: subLabel }}
                        />
                    )}
                </label>
            )}
            {floatedLabel && (
                <span
                    className={`floated-label ${isSelectedValue ? 'selected' : ''}`}>
                    {label}
                </span>
            )}
            {children}
            {hasError && errorText && (
                <div
                    className="error-text"
                    dangerouslySetInnerHTML={{
                        __html: getLangText(isError()) || isError(),
                    }}
                />
            )}
        </div>
    );
};

Group.defaultProps = {
    showLabel: true,
    label: '',
    activeValidation: false,
    required: false,
    name: '',
    value: '',
    disabled: false,
    optional: false,
    getError: () => {},
    className: '',
};

export default Group;
