import {batch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions.js";
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {CONFIRM_MODAL_TYPES} from "./clients/confirmModals/ConfirmModalTypes.js";
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {selmoUrl} from "../../../../../src_shared/api/api";
import DefinedVariantsFormActions from "./definedVariantsModal/FormActions.js";
import {getStateRoot as getDefinedVariantStateRoot} from "./definedVariantsModal/formReducer.js";
import {services} from "../../../../RestServices.js";
import {preparedProductObject} from "./components/productRow/StorageProductRow.jsx";
import LivesProductsListActions from "./clients/productsList/ListActions.js";
import api from "../../../../../services/axios/axios";

export const updateFieldInLiveDetails = (field, value) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FIELD',
		field,
		value
	};
}

const getLiveDetailsInfoRequested = () => {
	return {
		type: 'GET_LIVE_DETAILS_INFO_REQUESTED'
	};
}

export const updateLivesDetailsInfo = items => {
	return {
		type: 'GET_LIVE_DETAILS_INFO',
		items,
	};
}

const getLiveDetailsInfoError = () => {
	return {
		type: 'GET_LIVE_DETAILS_INFO_ERROR'
	};
}

export const resetLiveInfo = () => {
	return {
		type: 'LIVE_DETAILS_INFO_RESET'
	};
}

export const liveSetLastFetchedCommentId = (id) => {
	return {
		type: 'LIVE_DETAILS_SET_LAST_FETCHED_COMMENT_ID',
		id,
	};
}


export const getLiveDetailsInfo = (id) => {

	return async (dispatch) => {
		dispatch(getLiveDetailsInfoRequested())
		try {
			const response = await api.get(`${selmoUrl}/${services.API_LIVE}?id=${id}`)
			const {data} = await response
			dispatch(updateLivesDetailsInfo(data))
			if (!!data.tags?.sizes || !!data.tags?.colors) {
				const sizes = JSON.parse(data.tags.sizes);
				const colors = JSON.parse(data.tags.colors);

				if (!!sizes.length || !!colors.length) {
					dispatch(DefinedVariantsFormActions.data.updateValues({
						sizes: sizes,
						colors: colors,
						savedTags: '1',
					}))
				}
			}
		} catch (e) {
			dispatch(getLiveDetailsInfoError())
			dispatch(showAlert('sthWentWrongWarning', 'selectedLiveDoesNotExistLabel', 'alert-fail'))
			console.error(e)
		}
	}
}


const getLiveDetailsClientsRequested = () => {
	return {
		type: 'GET_LIVE_DETAILS_CLIENTS_REQUESTED'
	};
}

export const updateLiveDetailsClients = items => {
	return {
		type: 'GET_LIVE_DETAILS_CLIENTS',
		items,
	};
}


const getLiveDetailsClientsError = () => {
	return {
		type: 'GET_LIVE_DETAILS_CLIENTS_ERROR'
	};
}

export const setNextPage = () => {
	return {
		type: 'LIVE_DETAILS_SET_NEW_PAGE'
	};
}

export const resetPage = () => {
	return {
		type: 'LIVE_DETAILS_RESET_PAGE'
	};
}

export const setHasMore = (toggle) => {
	return {
		type: 'LIVE_DETAILS_SET_HAS_MORE',
		toggle,
	};
}

export const resetClientsListParams = (values) => {
	return (dispatch, getState) => {
		Object.keys(values).forEach((key) => {
			dispatch(updateFieldInLiveDetails(key, values[key]))
		})
	}
}

export const resetClientListParams = () => {
	return (dispatch) => {
		batch(() => {
			dispatch(resetPage());
			dispatch(resetClientsListParams({
				isCancelled: null,
				isSummarySent: null,
				isCartStatusSent: null,
				isOrderCreated: null,
			}))
		})
	}
};

const getClientLoadParams = () => {
	return (dispatch, getState) => {

		const livesDetails = getState().lives.details;
		const clientSearch = livesDetails.clientSearch;
		const sortBy = livesDetails.sortBy;
		const clientsPage = livesDetails.clientsPage;
		const isCancelled = livesDetails.isCancelled;
		const isSummarySent = livesDetails.isSummarySent;
		const isCartStatusSent = livesDetails.isCartStatusSent;
		const isOrderCreated = livesDetails.isOrderCreated;

		const params = {
			page: clientsPage,
		};

		if (clientSearch) {
			params.superSearch = clientSearch;
		}

		if (sortBy) {
			params.sortBy = sortBy;
		}

		if (isCancelled) {
			params.is_cancelled = isCancelled;
		}

		if (isSummarySent) {
			params.summary_sent = isSummarySent;
		}

		if (isCartStatusSent) {
			params.cart_status_sent = isCartStatusSent;
		}

		if (isOrderCreated) {
			params.order_created = isOrderCreated;
		}

		return params;
	}
}

export const getLiveDetailsClients = (id) => {
	return async (dispatch) => {
		const params = dispatch(getClientLoadParams());
		try {
			const {data} = await api.get(`${selmoUrl}/${services.API_LIVE_DETAILS_CLIENTS_LIST}/${id}${getGETParamsUrl(params)}`)
			const {items, hasMore, total} = data
			batch(() => {
				dispatch(setHasMore(hasMore))
				dispatch(updateFieldInLiveDetails('totalClients', total))
				dispatch(updateLiveDetailsClients(items))
			})
		} catch (e) {
			dispatch(getLiveDetailsClientsError())
			console.error(e)
		}
	}
}

export const liveDetailsAddNewCustomer = () => {
	return {
		type: 'LIVE_DETAILS_ADD_NEW_CUSTOMER',
	};
}

export const liveDetailsAddSeveralCustomers = () => {
	return {
		type: 'LIVE_DETAILS_ADD_SEVERAL_CUSTOMERS',
	};
}

export const liveDetailsUpdateCustomers = (customers) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_CUSTOMERS',
		customers
	};
}

export const liveDetailsRemoveCustomer = (id) => {
	return {
		type: 'LIVE_DETAILS_REMOVE_CUSTOMER',
		id,
	};
}

export const updateFieldInCustomer = (field, value, id) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FIELD_IN_CUSTOMER',
		field,
		value,
		id,
	}
}

export const updateFieldInProduct = (field, value) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FIELD_IN_PRODUCT',
		field,
		value,
	}
}

export const updateFieldsInProduct = (values) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FIELDS_IN_PRODUCT',
		values,
	}
}


export const updateFieldInCustomersProduct = (field, value, shop_client_id, productId) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FIELD_IN_CUSTOMERS_PRODUCT',
		field,
		value,
		shop_client_id,
		productId
	}
}

export const liveDetailsAddNewProductToCustomer = (shop_client_id) => {
	return {
		type: 'LIVE_DETAILS_ADD_NEW_PRODUCT_TO_CUSTOMER',
		shop_client_id,
	};
}

export const resetAllCustomers = () => {
	return {
		type: 'LIVE_DETAILS_RESET_ALL_CUSTOMERS',
	}
}

export const resetProduct = () => {
	return {
		type: 'LIVE_DETAILS_RESET_PRODUCT',
	}
}

export const addCustomersToProductRequest = (toggle) => {
	return {
		type: 'LIVE_DETAILS_ADD_CUSTOMER_TO_PRODUCT_REQUEST',
		toggle,
	}
}

const afterSubmitProductToCustomers = (id) => {
	return (dispatch, getState) => {
		batch(() => {
			const {savedTags} = getDefinedVariantStateRoot(getState()).data;
			dispatch(resetAllCustomers());
			dispatch(resetProduct())
			dispatch(resetAndUpdateClients(id))
			dispatch(showAlert('yuhuLabel', 'successfulProductAddedLabel'))
			if (!+savedTags) {
				dispatch(DefinedVariantsFormActions.data.restoreEmptyData())
			}
		})
	}
}

export const updateStorageProduct = () => {
	return async (dispatch, getState) => {
		const {product, liveInfo} = getState().lives.details

		if (!product.id) {
			return;
		}

		dispatch(updateFieldInLiveDetails('waitingForStorageProductUpdate', true))

		try {
			const {data} = await api.get(`${selmoUrl}/${services.API_PRODUCTS}/${product.id}?live_id=${liveInfo.id}`)
			dispatch(updateFieldInLiveDetails('product', preparedProductObject(data.item)))

		} catch (e) {
			console.warn('Cannot get product data')
		} finally {
			dispatch(updateFieldInLiveDetails('waitingForStorageProductUpdate', false))
		}
	}
}

export const addCustomersToProduct = () => {
	return async (dispatch, getState) => {
		const livesDetailsRoot = getState().lives.details
		const {product} = livesDetailsRoot;
		const {customers} = livesDetailsRoot;
		const {id} = livesDetailsRoot.liveInfo;
		const {sizes, colors} = getDefinedVariantStateRoot(getState()).data;

		const preparedCustomers = customers.map((i) => ({
			client_name: i.name,
			client_id: i.client_id || null,
			color: i.color,
			quantity: i.quantity,
			size: i.size,
			variant_id: i.variant_id
		}))

		const isEmptyProductName = !product.name;
		const isEmptyProductPrice = !product.price
		const isEmptyVariant = (variantId) => !!product.sizes?.length && !variantId;
		const isEmptySomeCustomerField = customers.some((customer) => !customer.name.length || !customer.quantity || customer.quantity <= 0 || isEmptyVariant(customer.variant_id))
		const isCustomerNotFromSelmoClients = customers.some((customer) => !customer.client_id)

		if (isEmptyProductName) {
			dispatch(updateFieldInProduct('hasError', true))
		}

		if (isEmptyProductPrice) {
			dispatch(updateFieldInProduct('hasPriceError', true))
		}

		if (isEmptySomeCustomerField || isCustomerNotFromSelmoClients) {
			dispatch(updateFieldInLiveDetails('hasCustomerError', true))
		}

		if (isEmptyProductName || isEmptyProductPrice || isEmptySomeCustomerField) {
			dispatch(showAlert('sthWentWrongWarning', 'fillRequireFieldWarning', 'alert-fail'))
			return;
		}

		if (isCustomerNotFromSelmoClients) {
			dispatch(showAlert('sthWentWrongWarning', 'selectClientFromListLabel', 'alert-fail'))
			return;
		}

		try {
			dispatch(addCustomersToProductRequest(true))
			await api.post(`${selmoUrl}/apilive/clients`, {
				live_id: id,
				product,
				customers: preparedCustomers,
				variants: {
					colors,
					sizes,
				},
			})
			dispatch(updateFieldInLiveDetails('hasCustomerError', false))
			dispatch(LivesProductsListActions.loadData(id))
			dispatch(afterSubmitProductToCustomers(id))
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		} finally {
			dispatch(addCustomersToProductRequest(false))
		}
	}
}

export const setMoreActionsClientToggleVisibility = (shop_client_id) => {
	return {
		type: 'MORE_ACTIONS_CLIENT_NAME_TOGGLE_VISIBILITY',
		shop_client_id
	}
}

export const liveDetailsAddProductsToClientRequest = () => {
	return {
		type: 'LIVE_DETAILS_ADD_PRODUCTS_TO_CLIENT_REQUEST',
	}
}

export const liveDetailsAddProductsToClientRequestDone = () => {
	return {
		type: 'LIVE_DETAILS_ADD_PRODUCTS_TO_CLIENT_REQUEST_DONE',
	}
}

const liveDetailsUpdateProductsInClient = (products, shop_client_id) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_PRODUCTS_IN_CLIENT',
		products,
		shop_client_id,
	}
}

export const addProductsToClient = (shop_client_id) => {
	return async (dispatch, getState) => {
		const livesDetailsClientsRoot = getState().lives.details
		const {clients} = livesDetailsClientsRoot;
		const isCancelled = livesDetailsClientsRoot.isCancelled;

		const isEmptyVariant = (product) => !!product.sizes?.length && !product.variant_id;

		const client = clients.find((client) => +client.shop_client_id === +shop_client_id)

		const {live_id, id} = client;

		const preparedProduct = client.products.map((product) => ({
			id: product.client_id ? product.id : null,
			name: product.name,
			quantity: product.quantity,
			price: product.price,
			is_deleted: product.is_deleted,
			is_cancelled: product.is_cancelled,
			variant_id: product.variant_id,
			product_id: product.product_id,
			code: product.code,
			saved_product_id: product.saved_product_id || null,
			taxable: product.taxable,
		}))

		const preparedData = {
			client_id: id,
			live_id,
			products: preparedProduct,
		}

		const isEmptySomeCustomerProductField = client.products.some((product) => !+product.is_deleted && (!product.price || !product.quantity || product.quantity <= 0 || !product.name || isEmptyVariant(product)))

		if (isEmptySomeCustomerProductField) {
			dispatch(updateFieldInLiveDetails('hasProductInCustomerError', true))
			dispatch(showAlert('sthWentWrongWarning', 'fillRequireFieldWarning', 'alert-fail'))
			return;
		}

		dispatch(liveDetailsAddProductsToClientRequest())

		try {
			const response = await api.put(`${selmoUrl}/apilive/client`, {
				...preparedData
			})
			dispatch(showAlert());
			dispatch(updateFieldInLiveDetails('hasProductInCustomerError', false))
			if (isCancelled) {
				dispatch(getLiveDetailsClients(live_id))
				return;
			}
			dispatch(liveDetailsUpdateProductsInClient(response.data.items, shop_client_id))

			if (!response.data.items.length) {
				dispatch(liveDetailsRemoveClientFromState(id))
			}
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		} finally {
			dispatch(liveDetailsAddProductsToClientRequestDone())
		}
	}
}

export const setConfirmModalToggleVisibility = (modalType, data) => {
	return {
		type: 'LIVE_DETAILS_CONFIRM_MODAL_VISIBILITY',
		modalType,
		data,
	};
}

export const setConfirmModalRequestLoading = () => {
	return {
		type: 'LIVE_DETAILS_CONFIRM_MODAL_REQUEST_LOADING',
	};
}

export const updateFieldInConfirmMModal = (field, value) => {
	return {
		type: 'LIVE_DETAILS_UPDATE_FILED_IN_CONFIRM_MODAL',
		field,
		value,
	};
}

export const resetAndUpdateClients = (liveId) => {
	return (dispatch) => {
		dispatch(resetPage())
		dispatch(getLiveDetailsClients(liveId))
	}
}

export const liveDetailsRemoveClientFromState = (id) => {
	return {
		type: 'LIVE_DETAILS_REMOVE_CLIENT_FROM_STATE',
		id,
	};
}

export const removeClient = (clientId, liveId) => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {data} = livesDetailsConfirmModalRoot;
		const client_id = data.id || clientId;
		const live_id = data.live_id || liveId;

		dispatch(setConfirmModalRequestLoading());
		try {
			await api.post(`${selmoUrl}/apilive/clientDelete`, {
				client_id,
				live_id,
			})
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(resetAndUpdateClients(live_id))
				dispatch(showAlert('yuhuLabel', 'successfulClientRemovedLabel'))
			})

		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			})
		}
	}
}

export const removeLive = (history) => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {data} = livesDetailsConfirmModalRoot;
		dispatch(setConfirmModalRequestLoading());

		try {
			await api.post(`${selmoUrl}/apilive/liveDelete`, {
				live_id: data.id
			})
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('yuhuLabel', 'successfulLivedRemovedLabel'))
			})
			history.push(RouterPaths.LiveList)

		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			})
		}
	}
}

export const updateLiveSendBatchFromPusher = (data) => {
	return async (dispatch, getState) => {
		const {liveInfo} = getState().lives.details;
		if (+data.live_id === +liveInfo.id) {
			batch(() => {
				dispatch(resetAndUpdateClients(liveInfo.id))
				dispatch(getLiveDetailsInfo(liveInfo.shop_live_id))
			})
		}
	}
}


export const summaryBatchRequest = (rest) => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {liveInfo} = getState().lives.details;
		const {data, modalType} = livesDetailsConfirmModalRoot;
		dispatch(setConfirmModalRequestLoading());

		try {
			await api.post(`${selmoUrl}${rest}`, {
				live_id: data.id
			})
			batch(() => {
				dispatch(resetAndUpdateClients(data.id))
				dispatch(setConfirmModalToggleVisibility(null))
				if (modalType === CONFIRM_MODAL_TYPES.SEND_ALL_BASKET_STATES) {
					dispatch(showAlert('yuhuLabel', 'successfulBasketStatesSentLabel'))
					dispatch(updateLivesDetailsInfo({...liveInfo, waitingForBasket: true}))
				}
				if (modalType === CONFIRM_MODAL_TYPES.SEND_ALL_SUMMARY) {
					dispatch(showAlert('yuhuLabel', 'successfulSummariesSentLabel'))
					dispatch(updateLivesDetailsInfo({...liveInfo, waitingForSummary: true}))
				}
			})
		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('upsLabel', e.response.data.message, 'alert-fail'))
			})
		}
	}
}


export const createOrder = () => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {data} = livesDetailsConfirmModalRoot;
		dispatch(setConfirmModalRequestLoading());

		try {
			await api.post(`${selmoUrl}/apilive/orderCreate`, {
				live_id: data.live_id,
				client_id: data.id,
			})
			batch(() => {
				dispatch(resetAndUpdateClients(data.live_id))
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('yuhuLabel', 'successfulOrderCreatedLabel'))

			})

		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			})
		}
	}
}

export const sendMessage = () => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {data, modalType} = livesDetailsConfirmModalRoot;
		dispatch(setConfirmModalRequestLoading());

		try {
			await api.post(`${selmoUrl}/apilive/message`, {
				recipient: data.facebook_client_id,
				message: data.basketState,
				with_link: data.orderLink,
				order_id: data.order_created,
				live_id: data.live_id,
				type: modalType,
				client_id: data.id
			})
			batch(() => {
				dispatch(resetAndUpdateClients(data.live_id))
				dispatch(setConfirmModalToggleVisibility(null))
				if (modalType === CONFIRM_MODAL_TYPES.SUMMARY) {
					dispatch(showAlert('yuhuLabel', 'successfulSummarySentLabel'))
				}
				if (modalType === CONFIRM_MODAL_TYPES.BASKET_STATE) {
					dispatch(showAlert('yuhuLabel', 'successfulBasketStateSentLabel'))
				}
			})

		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			})
		}
	}
}

export const getSummaryText = (rest) => {
	return async (dispatch, getState) => {
		const livesDetailsConfirmModalRoot = getState().lives.details.clientsConfirmModal;
		const {data} = livesDetailsConfirmModalRoot;

		try {
			const response = await api.get(`${selmoUrl}/apilive/${rest}?live_id=${data.live_id}&client_id=${data.id}`)
			dispatch(updateFieldInConfirmMModal('basketState', response.data))

		} catch (e) {
			batch(() => {
				dispatch(setConfirmModalToggleVisibility(null))
			})
		}
	}
}

const getLiveDetailsInfoTotalPriceRequest = () => {
	return {
		type: 'GET_LIVE_DETAILS_INFO_TOTAL_PRICE_REQUEST'
	};
};

export const liveDetailsGetTotalOrderPrice = (id) => {
	return async (dispatch) => {
		dispatch(getLiveDetailsInfoRequested())
		try {
			const response = await api.post(`${selmoUrl}/apilive/totalPrice`,
				{live_id: id})
			const {data} = await response
			dispatch(updateLivesDetailsInfo(data))
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', 'failedGetPriceLabel', 'alert-fail'))
			console.error(e)
		}
	}
}


