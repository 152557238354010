import React, {useRef, useState} from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../../../../../src_shared/components/loader/Loader";
import ValidationBox from "../ValidationBox";
import * as tus from 'tus-js-client'
import Cookies from "js-cookie";
import BoxWrapper from "../BoxWrapper";
import usePhotoPreview from "../../../../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../../../../../../src_shared/components/ui/photoPreview/VideoPreview";
import api from "../../../../../../../../../services/axios/axios";
import PostsLists from "../postsLists/PostsLists";
import {MARKETING_INCREASE_SALE_VIEW} from "../../../list/components/SelectGoalModal";

export const EXISTING_AD = 1;
export const NEW_AD = 2;

const tabs = [
    {id: EXISTING_AD, label: 'selectExistingPost'},
    {id: NEW_AD, label: 'createNewAd'},
]

const VideoImageComponent = ({
                                 values,
                                 updateValues,
                                 restService,
                                 photoService,
                                 hasError,
                                 setIsVideoUploading,
                                 isVideoUploading,
                                 setValue,
                                 getError,
                                 id,
                                 isActive,
                             }) => {
    const {getLangText} = useLang();
    const [isToBig, setIsToBig] = useState(false);
    const [isInvalidFormat, setIsInvalidFormat] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isError, setIsError] = useState(false);
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const [isLoadingForImage, setIsLoadingForImage] = useState(null);

    const isUploading = (isVideoUploading || !!+values.is_being_transmitted || (!values.media?.length && isLoadingForImage))

    const dropRef = useRef(null);
    const fileInputRef = useRef(null);

    const validVideoExtensions = [
        '.mp4', '.mov', '.webm', '.flv', '.avi',
        '.mkv', '.wmv', '.mpeg', '.3gp'
    ];

    const validImageExtensions = [
        '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.heic'
    ];

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.add('bg-[#F9FAFB]');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
    };

    const handleDrop = async (e, item = {}) => {
        e.preventDefault();
        e.stopPropagation();
        dropRef?.current?.classList?.remove('bg-[#F9FAFB]');
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            await handleFiles(files, item);
            e.dataTransfer?.clearData();
        }
    };

    const handleFileChange = async (e, item = {}) => {
        setIsToBig(false);
        setIsInvalidFormat(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            await handleFiles(files, item);
        }
    };

    const handleFiles = async (files, item) => {
        const images = [];
        for (const file of files) {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (validVideoExtensions.includes(fileExtension)) {
                if ((file.size / 1000000) > 2000) {
                    setIsToBig(true);
                    return;
                }
                await uploadToCloudflareServer(file, item);
                return;
            } else if (validImageExtensions.includes(fileExtension)) {
                if ((file.size / 1000000) > 20) { // Limit for images (20MB)
                    setIsToBig(true);
                    return;
                }
                images.push(file);
            } else {
                setIsInvalidFormat(true);
                return;
            }
        }
        if (images.length > 0) {
            await uploadImages(images, item);
        }
    };

    const sendVideoUrl = async (url, item) => {

        const newMediaItem = {
            is_being_transmitted: true,
            media_url: url,
            media_poster: '',
            media_type: 'video',
            is_error: false,
            id: item.id || new Date().getTime(),
        }

        updateValues({
            media: [newMediaItem],
            is_being_transmitted: true,
        })
        setIsVideoUploading(false);
    };

    const uploadToCloudflareServer = async (file, item) => {
        setIsVideoUploading(true);
        setIsToBig(false);
        setIsInvalidFormat(false);


        const endpoint = item.id ? `https://api2.selmo.io/${restService}/${id}?media_id=${item.id}` : `https://api2.selmo.io/${restService}/${id}`;

        try {
            const upload = new tus.Upload(file, {
                endpoint,
                chunkSize: 50 * 1024 * 1024,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    name: file.name,
                    filename: file.name,
                    filetype: file.type,
                },
                onError: function (error) {
                    console.log('Failed because: ' + error);
                    setIsError(true);
                },
                onBeforeRequest: function (req) {
                    if (req.getURL() === endpoint) {
                        req.setHeader('Authorization', 'Bearer ' + Cookies.get('token'));
                    }
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    setProgress(percentage)
                },
                onSuccess: function () {
                    sendVideoUrl(upload.url, item)
                },
            });

            upload.findPreviousUploads().then(function (previousUploads) {
                // if (previousUploads.length) {
                //     console.log(previousUploads)
                //     upload.resumeFromPreviousUpload(previousUploads[0]);
                // }
                upload.start();
            });
        } catch (e) {
            console.log('cos nie tak')
        }
    };

    const uploadImages = async (files, item = {}) => {
        setIsLoadingForImage(item?.id || true);
        const endpoint = item.id ? `https://api2.selmo.io/${photoService}/${item.id}` : `https://api2.selmo.io/${photoService}/${id}`;
        const method = item.id ? 'put' : 'post';
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        try {
            const {data} = await api[method](endpoint, formData)
            const updatedMedia = !!values.media?.length ? values.media?.map((item) => {
                const matchingItem = data?.result.find((newItem) => +newItem?.id === +item.id);
                return matchingItem ? {...item, media_url: matchingItem?.media_url, media_type: 'image'} : item;
            }) : data?.result;

            updateValues({
                media: updatedMedia,
            })

        } catch (e) {
            console.error('Failed to upload images');
            setIsError(true);
        } finally {
            setIsLoadingForImage(null)
        }
    };

    const uploadingContent = (item) => (
        <div className="flex items-start bg-[#F5FCFF] border border-[#5FA5FA] px-6 py-3 rounded-[5px]">
            <Loader
                className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                isLoading
            />
            {isLoadingForImage ?
                <div>
                    <div className="font-bold text-sm">
                        {getLangText('imageIsBeingSent')}
                    </div>
                    <div
                        className="text-desc text-xs whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{__html: getLangText('itMakeTakeAWhileDoNotClosePage')}}
                    />
                </div> :
                <div>
                    <div className="font-bold text-sm">
                        {getLangText(!!+item.is_being_transmitted ? 'theVideoIsBeingProcessed' : 'videoIsBeingSent')}
                    </div>
                    <div className="text-desc text-xs whitespace-pre-wrap"
                         dangerouslySetInnerHTML={{__html: getLangText(!!+item.is_being_transmitted ? 'sthWentWrongBatch' : 'itMakeTakeAWhileDoNotClosePage')}}/>
                    {(!!progress && !+item.is_being_transmitted) &&
                        <div className="w-full h-1.5 bg-gray-200 rounded-lg mt-2">
                            <div
                                className="h-1.5 bg-[#5fa5fa] rounded-lg transition-all"
                                style={{width: `${progress}%`}}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )


    return (
        <BoxWrapper
            title="selectPostToPromote"
            desc="makeSureHaveProductsInStock"
        >
            {+values.ad_target === MARKETING_INCREASE_SALE_VIEW && isActive &&
                <div className="grid grid-cols-2 bg-[#f3f4f6] rounded-medium p-1 mt-3 mb-3">
                    {tabs.map((item) => (
                        <div
                            className={+item.id === +values.post_type ? 'shadow-default-small rounded-medium overflow-hidden' : ''}>
                            <button
                                onClick={() => setValue('post_type', item.id)}
                                className={`text w-full text-xs py-2 transition-all font-bold ${+item.id === +values.post_type ? 'bg-[#fff] text-[#101827]' : 'text-[#6b7280]'}`}
                                type="button"
                                key={item.id}
                            >
                                {getLangText(item.label)}
                            </button>
                        </div>
                    ))}
                </div>
            }
            {+values.post_type === NEW_AD ?
                <div>
                    {isUploading ?
                        uploadingContent(values) :
                        !!values.media?.length ?
                            <div className="space-y-2">
                                {values.media?.map((item) => (
                                    !!+item.is_being_transmitted ?
                                        uploadingContent(item) :
                                        <div
                                            key={item.id}
                                            className="bg-[#F9FAFB] p-3 border border-[#DFE1E4] overflow-hidden rounded-medium"
                                        >
                                            <div className="flex items-center flex-sm-nowrap flex-wrap">
                                                <div className="flex items-center overflow-hidden">
                                                    <button
                                                        className="relative image-loader-wrapper"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openPreviewModal(item.media_url)
                                                        }}>
                                                        <img
                                                            className="h-[50px] min-w-[50px] w-[50px] rounded-lg object-cover"
                                                            src={item.media_type === 'video' ? item.media_poster : item.media_url}
                                                            alt=""
                                                        />
                                                        {+isLoadingForImage === +item.id &&
                                                            <Loader className="small-loader" isLoading={true}/>
                                                        }
                                                    </button>
                                                    <div className="ml-2 mr-2 overflow-hidden">
                                                        <div
                                                            className="text-sm font-bold">{getLangText(item.media_type === 'video' ? 'addedAndProcessedVideo' : 'addedPhoto')}</div>
                                                        <a
                                                            target="_blank"
                                                            className="text-desc text-xs hover:text-[#000] transition-all block overflow-hidden text-ellipsis whitespace-nowrap"
                                                            href={item.media_url}
                                                        >
                                                            {item.media_url}
                                                        </a>
                                                    </div>
                                                </div>
                                                {+values.status === 3 &&
                                                    <div
                                                        className="sm:ml-auto sm:w-auto w-full relative group cursor-pointer sm:mt-0 mt-3">
                                                        <input
                                                            type="file"
                                                            accept="video/*,image/*"
                                                            onChange={(e) => handleFileChange(e, item)}
                                                            className="absolute opacity-0 top-0 left-0 right-0 bottom-0 w-full h-full cursor-pointer z-10 text-[0px]"
                                                            id="fileUpload"
                                                            multiple={false}
                                                            ref={fileInputRef}
                                                        />
                                                        <div
                                                            className="button border-button sm:w-auto w-full left-icon mb-0 whitespace-nowrap group-hover:bg-[#F9FAFB] group-hover:text-[#4B5563] transition-all duration-200"
                                                        >
                                                            <i className="icon-upload mr-[5px] -top-[0.5px]"/>
                                                            {getLangText(item.media_type === 'video' ? 'changeVideo' : 'changePhotoButton')}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                ))}
                            </div>
                            :
                            <div
                                ref={dropRef}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                onClick={() => fileInputRef?.current?.click()}
                                className={`w-full transition-background duration-300 overflow-hidden ${!!values.media?.length ? '' : 'hover:bg-[#F9FAFB] cursor-pointer rounded-[5px]  border border-dashed'}`}
                            >
                                <div className="text-center py-16">
                                    <div
                                        className="w-[30px] mx-auto h-[30px] rounded-[5px] bg-[#E5E7EB] flex items-center justify-center text-[#230C34] mb-2">
                                        <i className="icon-upload"/>
                                    </div>
                                    <div className="font-bold sm:text-lg">{getLangText('selectVideoToUpload')}</div>
                                    <div
                                        className="font-medium text-sm text-desc">{getLangText('orDragAndDropHere')}</div>
                                    <div className="mt-3">
                                        <input
                                            type="file"
                                            accept="video/*,image/*"
                                            onChange={handleFileChange}
                                            className="hidden"
                                            id="fileUpload"
                                            multiple={false}
                                            ref={fileInputRef}
                                        />
                                        <div
                                            style={{fontSize: 14}}
                                            className="button primary small-size mb-0"
                                        >
                                            {getLangText('selectVideo')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {isToBig &&
                        <div className="bg-[#FEF2F2] rounded-[5px] flex p-2 text-[#981B1B] mt-2">
                            <div>
                                <i className="icon-alert-c"/>
                            </div>
                            <div className="ml-2">
                                <div className="font-bold text-sm">
                                    {getLangText('maxVideo200')}
                                </div>
                                <a
                                    href="https://www.freeconvert.com/video-compressor"
                                    target="_blank"
                                    className="font-medium text-sm underline"
                                >
                                    {getLangText('useConverterOnline')}
                                </a>
                            </div>
                        </div>
                    }
                    {isInvalidFormat &&
                        <div className="bg-[#FEF2F2] rounded-[5px] flex p-2 text-[#981B1B] mt-2">
                            <div>
                                <i className="icon-alert-c"/>
                            </div>
                            <div>
                                <div className="font-bold text-sm ml-2">
                                    {getLangText('invalidFileType')}
                                </div>
                                <div className="font-medium text-xs ml-2">
                                    {getLangText({key: 'acceptableFormats', data: [validVideoExtensions.join(', ')]})}
                                </div>
                                <div className="font-medium text-xs ml-2">
                                    {getLangText({key: 'acceptableFormats', data: [validImageExtensions.join(', ')]})}
                                </div>
                            </div>
                        </div>
                    }
                    <ValidationBox
                        visible={hasError}
                        title={isVideoUploading ? 'theVideoIsBeingUploadedSavingWillBeAvailableOnceItIsFinished' : 'completeThisStepToGo'}
                    />
                    <VideoPreview
                        subTitle="previewButton"
                        hide={hidePreviewModal}
                        show={showPreviewModal}
                    />
                </div> :
                <div>
                    <PostsLists
                        setValue={setValue}
                        values={values}
                        updateValues={updateValues}
                        hasError={getError('selected_fb_ad_post_id')}
                        isActive={isActive}
                    />
                </div>
            }
        </BoxWrapper>
    );
};

VideoImageComponent.defaultProps = {
    restService: 'api/media',
    field: 'file',
}

export default VideoImageComponent;
