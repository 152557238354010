import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import InvoiceDataMarketingModal from "../../../marketing/components/InvoiceDataMarketingModal";

const NotPaidMarketing = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(null);

    return (
        <div className="bg-[#f75f5e] flex text-[#fff] items-center font-bold text-sm justify-center h-[40px] px-6">
            {getLangText('notPaidMarketing')}
            <button
                type="button"
                onClick={() => setShowModal(1)}
                className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] ml-2 text-[#f75f5e]`}
            >
                {getLangText('pay')}
            </button>
            {showModal &&
                <InvoiceDataMarketingModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(null)}
                />
            }
        </div>
    );
};

export default NotPaidMarketing;
