const SearchInput = ({ placeholder, onChange, value }) => {
    return (
        <div className="mt-3 rounded-[8px] bg-[#F3F4F6] flex items-center h-[44px]">
            <div className="flex items-center justify-center ml-[13px]">
                <i className="icon-magnifier text-[#D0D5DC] text-[15px]" />
            </div>
            <input
                type="text"
                placeholder={placeholder}
                className="w-full h-full py-[7px] px-[12px] bg-transparent outline-none placeholder:text-[#9CA3AF] placeholder:text-[13px] text-[13px]"
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

export default SearchInput;
