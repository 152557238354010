export const NO_SELLER = 'NO_SELLER';
export const DAMIAN = 1;
export const WOJTEK = 2;
export const PRZEMEK = 3;
export const EMILY = 4;
export const LOUIS = 6;
export const RODRIGO = 7;
export const DOUGLAS = 10;
export const ERYK = 19;
export const MIKE = 21;
export const SIMON = 23;
export const MARIA = 24;
export const Monika = 22;
export const Michael = 25;
export const Agatha = 26;
export const Dominique = 27;
export const EWELINA = 29;
export const LEO = 30;

export const ADMIN_SELLERS = [
    {
        value: NO_SELLER,
        label: 'Brak sprzedawcy',
        color: 'null',
    },
    {
        value: DAMIAN,
        label: 'Damian',
        color: 'orange',
    },
    {
        value: WOJTEK,
        label: 'Wojtek',
        color: 'green',
    },
    {
        value: PRZEMEK,
        label: 'Przemek',
        color: 'purple',
    },
    {
        value: EMILY,
        label: 'Emily',
        color: 'pink',
    },
    {
        value: LOUIS,
        label: 'Louis',
        color: 'blue',
    },
    {
        value: LEO,
        label: 'Leo',
        color: 'red',
    },
    {
        value: RODRIGO,
        label: 'Rodrigo',
        color: 'yellow',
    },
    {
        value: DOUGLAS,
        label: 'Douglas',
        color: 'orange',
    },
    {
        value: MIKE,
        label: 'Michał',
        color: 'yellow',
    },
    {
        value: Monika,
        label: 'Monika',
        color: 'blue',
    },
    {
        value: Michael,
        label: 'Michael',
        color: 'green',
    },
    {
        value: Agatha,
        label: 'Agatha',
        color: 'lblue',
    },
    {
        value: EWELINA,
        label: 'Ewelina',
        color: 'pink',
    },
];

export const findSellerById = (value) =>
    ADMIN_SELLERS.find((i) => i.value === +value);
