import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

const openCartStatusValidator = (value, data) => {
	if (!data.shipping_cart) {
		return false;
	}
	if (!data.openCartEnabled) {
		return false;
	}
	return ValidationError.notEmpty(value);
}

const packageTypeValidator = (value, data) => {
	if (!data.shipping_cart) {
		return false;
	}
	if (!data.packagesTypes.length) {
		return false;
	}
	return ValidationError.notEmpty(value);
}

const shippingValidator = (value, data) => {
	if (!data.shipping_cart) {
		return false;
	}
	if (data.orderStage > 1) {
		return false;
	}
	return ValidationError.notEmpty(value);
}

const paymentValidator = (value, data) => {
	if (data.orderStage > 1 || data.stripe_tax_enabled) {
		return false;
	}
	return ValidationError.notEmpty(value);
}

export class BasketDeliveryMethodModel extends Model {
	getModel() {
		return {
			shippingAddress: {},
			packagesTypes: [],
			shipping: null,
			open_cart_status: null,
			package_type_id: null,
			payment: null,
			shopInfo: {},
			payment_methods: [],
			agreement: '0',
			card_details: {
				card_number: '',
				expiration_date: '',
				cvc: '',
			}
		};
	}

	getValidators() {
		return {
			shipping: shippingValidator,
			open_cart_status: openCartStatusValidator,
			package_type_id: packageTypeValidator,
			payment: paymentValidator,
			agreement: ValidationError.notFalse,
		};
	}

	buildDTO(data) {
		return {
			shipping: data.shipping,
			open_cart_status: data.open_cart_status,
			package_type_id: data.package_type_id,
			payment: data.payment,
		}
	}
}

export default new BasketDeliveryMethodModel();
