import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";

const Upsell = ({getFieldProps, setValue}) => {
    const {getLangText} = useLang();

    return (
        <section className="default-shadow-box big-padd mb-3">
            <div className="left-side">
                <h2 className="font-bold mb-3.5">{getLangText('upsell')}</h2>
            </div>
            <div className="form-col flex">
                <SwitchField
                    {...getFieldProps('upsell')}
                    setValue={setValue}
                    label={getLangText('upsell')}
                    subLabel={getLangText('upsellSubLabel')}
                    id="upsell"
                    className="switch-form align-items-start inline-label big-label light-version mb-0"
                />
            </div>
        </section>
    );
};

export default Upsell;
