import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import ClientAvatar from "../../../live/shared/Avatar";
import AssignEmployeeModal from "./components/AssignEmployeeModal";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";

const AssignEmployee = ({defaultValues, fetchData}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
    }] = useSubmitData({
        rest: `${services.API_ORDER_EMPLOYEES}/${defaultValues.id}`,
        initialAutoLoad: false,
        values: {id: null},
        afterSubmit: () => {
            fetchData();
            dispatch(showAlert());
            setShowConfirmModal(false)
        },
    });

    return (
        <div className="summary-section mb-3">
            <div className="flex justify-between mb-3">
                <div>
                    <div className="font-bold">
                        {getLangText('assignPersonFromTeam')}
                    </div>
                    <div className="text-[13px] leading-[17px] font-medium text-desc">
                        {getLangText('assignPersonFromTeamToThisOrder')}
                    </div>
                </div>
                <img className="w-[20px] ml-auto inline-block" src="/assets/images/svg/pro.svg" alt=""/>
            </div>
            <div className="border rounded-big p-[12px]">
                {defaultValues?.assigned_employee?.id ?
                    <div className="flex items-center">
                        <ClientAvatar
                            className="w-38 border rounded-full"
                            photo={defaultValues?.assigned_employee?.photo}
                            fbLogo={false}
                            newIcon
                        />
                        <div className="font-bold text-sm">{defaultValues?.assigned_employee?.name}</div>
                        <button
                            type="button"
                            className="button border-button small-size ml-auto"
                            onClick={() => setShowConfirmModal(true)}

                        >
                            {getLangText('removeButton')}
                        </button>
                    </div> :
                    <div className="flex items-center">
                        <ClientAvatar
                            className="w-38 border rounded-full"
                            photo={null}
                            fbLogo={false}
                            newIcon
                        />
                        <div className="font-bold text-sm">{getLangText('unassigned')}</div>
                        <button
                            type="button"
                            className="button border-button small-size ml-auto"
                            onClick={() => setShowModal(true)}

                        >
                            {getLangText('assignButton')}
                        </button>
                    </div>
                }
            </div>
            {showModal &&
                <AssignEmployeeModal
                    show={showModal}
                    hide={() => setShowModal(false)}
                    defaultValues={defaultValues}
                    fetchData={fetchData}
                />
            }
            {showConfirmModal &&
                <ConfirmModal
                    dialogClassName="w-380 small-modal"
                    title={getLangText('wantToRemoveEmployeeFromOrder')}
                    saveButton={getLangText('yesButton')}
                    show={showConfirmModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowConfirmModal(false)}
                />
            }
        </div>
    );
};

export default AssignEmployee;