import Composition from '../../../../../shared/helpers/Composition';
import createReducer from '../../../../../shared/helpers/createReducer';
import ListReducer from '../../../../../../../src_shared/list/ListReducer';

export const getStateRoot = (state) =>
    state.marketing.smsMarketing.campaigns.list;
export const prefix = 'MARKETING_SMS_CAMPAIGNS_LIST_';

const getInitState = () =>
    ListReducer.getInitState({
        waiting: true,
        isActive: true,
    });

const getReduceMap = () => new Composition(ListReducer.getReduceMap());

export const getInstance = () =>
    createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
