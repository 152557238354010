import React from 'react';
import useLang from '../../../src_shared/hooks/useLang';
import { NavLink, useLocation } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPath';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import FormDownload from './sms/submitted/components/FormDownload';
import TutorialPageBox from '../../../src_shared/components/ui/TutorialPageBox/TutorialPageBox';
import { HEADER_BOX_MARKETING_FACEBOOK } from '../shared/enums/TutorialsVideo';
import { SHOP_ORIGIN_PL } from '../shared/enums/ShopOrigins';

const getStatusLabel = (status) => {
    if (status === 1 || status === 3) {
        return 'active';
    }

    return 'activateNow';
};

export const marketingTabOptions = ({ origin, statuses }) => {
    return [
        {
            id: 1,
            status: getStatusLabel(statuses.facebook),
            title: 'facebookAdds',
            desc: 'increaseSale',
            route: RouterPaths.MarketingFacebook,
            icon: 'fb',
        },
        {
            id: 2,
            status:
                origin === SHOP_ORIGIN_PL
                    ? getStatusLabel(statuses.sms)
                    : 'soon',
            title: 'SMS',
            route: RouterPaths.MarketingSms,
            icon: 'sms',
            soonTitle: 'marketingSMSTitle',
            soonDesc: 'marketingSMSDesc',
        },
        {
            id: 3,
            status: 'soon',
            title: 'selmoAppAdds',
            route: RouterPaths.MarketingSelmoApp,
            icon: 'app',
            soonTitle: 'marketingSelmoAppTitle',
            soonDesc: 'marketingSelmoAppDesc',
        },
        {
            id: 4,
            status: 'soon',
            title: 'E-mail',
            route: RouterPaths.MarketingEmail,
            icon: 'email',
            soonTitle: 'marketingEmailTitle',
            soonDesc: 'marketingEmailDesc',
        },
    ];
};

const Marketing = ({ children }) => {
    const { getLangText } = useLang();
    const { pathname } = useLocation();
    const { userData } = useSelector((state) => state.session);
    const isSmsRoute = pathname.includes(RouterPaths.MarketingSms);
    const isFacebookRoute = pathname.includes(RouterPaths.MarketingFacebook);

    const statuses = {
        facebook: userData.facebook_ads_active,
        sms: userData.sms_active,
        // app: +userData.app_active,
        // email: +userData.email_active,
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getLangText('advertisements')} - Selmo</title>
            </Helmet>
            <div className="bg-[#fff] sm:rounded-medium shadow-default overflow-hidden sm:p-6 mb-3">
                <div className="text-lg font-bold sm:mb-[16px] mb-[10px] sm:p-[0px] p-6 pb-0">
                    Selmo Marketing
                </div>
                <div className="flex overflow-auto sm:grid sm:grid-cols-4 sm:px-0 px-6 sm:pb-[0px] pb-6 gap-3">
                    {marketingTabOptions({
                        origin: userData.origin,
                        statuses,
                    }).map((option) => (
                        <NavLink
                            activeClassName="border-[#3c61ea] bg-[#f7f9fe]"
                            to={option.route}
                            className={`text-left hover:no-underline hover:text-[#000] border sm:min-w-[auto] min-w-[250px] rounded-[8px] px-[16px] py-[13px] flex items-center ${pathname.includes(option.route) ? 'border-[#3c61ea] bg-[#f7f9fe]' : 'border-[#e5e7eb] '}`}>
                            <div>
                                <div className="text-sm font-bold">
                                    {getLangText(option.title) || option.title}
                                </div>
                                <div className="text-[#9ca3af] text-xs font-medium">
                                    {option.status === 'active' ||
                                    option.status === 'activateNow' ? (
                                        <div className="flex items-center">
                                            <span className="w-[3px] mr-1 h-[3px] block bg-[#0eb981] rounded-full" />
                                            <span className="text-[#0eb981] ">
                                                {getLangText(option.status)}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <i className="icon-safe mr-1" />
                                            <span>
                                                {getLangText(option.status)}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <img
                                className="sm:w-[44px] ml-auto"
                                src={`/assets/images/svg/marketing/${option.icon}.svg`}
                                alt="icon"
                            />
                        </NavLink>
                    ))}
                </div>
            </div>
            {isSmsRoute && userData.sms_shop_name_verification === '1' && (
                <FormDownload />
            )}
            {isFacebookRoute && (
                <TutorialPageBox
                    videoStatsName={HEADER_BOX_MARKETING_FACEBOOK}
                    page="marketing_fb"
                    title="facebookAdds"
                />
            )}
            {children}
        </>
    );
};

export default Marketing;
