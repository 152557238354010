import Model from '../../../../src_shared/modules/model/Model';
import ValidationError from '../../../../src_shared/form/validation/ValidationError';
import { v4 as uuidv4 } from 'uuid';
import Validation from '../../../../src_shared/form/validation/Validation';

const nipValidator = (value, data) => {
    if (data.address.want_invoice === '1') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validateNip,
        )(value, data);
    }

    return ValidationError.skip();
};

const emailValidator = (value, data) => {
    return ValidationError.combine(
        ValidationError.notEmpty,
        ValidationError.validateEmail,
    )(value, data);
};

class OrderCreateModel extends Model {
    getModel() {
        return {
            products: [
                {
                    id: uuidv4(),
                    name: '',
                    price: '',
                    qty: '1',
                },
            ],
            address: {
                email: '',
                country: '',
                first_name: '',
                last_name: '',
                street: '',
                building_number: '',
                city: '',
                zip_code: '',
                phone: '',
                extra_description: '',
                want_invoice: '0',
                nip: '',
            },
        };
    }

    getValidators() {
        return {
            products: {
                name: ValidationError.notEmpty,
                qty: ValidationError.greaterThan(0),
                price: ValidationError.greaterThan(0),
            },
            address: {
                email: emailValidator,
                country: ValidationError.notEmpty,
                first_name: ValidationError.notEmpty,
                last_name: ValidationError.notEmpty,
                street: ValidationError.notEmpty,
                building_number: ValidationError.notEmpty,
                city: ValidationError.notEmpty,
                zip_code: ValidationError.notEmpty,
                phone: ValidationError.notEmpty,
                extra_description: ValidationError.skip,
                want_invoice: ValidationError.skip,
                nip: nipValidator,
            },
        };
    }

    buildDTO(data) {
        const { want_invoice, ...addressWithoutInvoice } = data.address;

        return {
            products: data.products.map((product) => ({
                name: product.name.trim(),
                qty: product.qty,
                price: product.price,
            })),
            address: addressWithoutInvoice,
        };
    }

    validateProducts(products) {
        for (const product of products) {
            if (
                !Validation.runValidation(
                    product,
                    this.getValidators().products,
                )
            ) {
                return false;
            }
        }

        return true;
    }

    validateAddress(address) {
        return Validation.runValidation({ address }, this.getValidators());
    }
}

export default new OrderCreateModel();
