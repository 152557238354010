import React, {useEffect, useState} from 'react';
import ConfirmModal from "../../../app/orders/list/components/export/ConfirmModal";
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";
import Group from "../../../../src_shared/components/form/group/Group";
import AsyncSearchSelectField
    from "../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import {services} from "../../../RestServices";
import ZipCodeField from "../../../app/orders/details/deliveryAddress/components/ZipCodeInputs";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import api from "../../../../services/axios/axios";
import Loader from "../../../app/shared/Loader";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../src_shared/modules/model/Model";

const BillingDataModal = ({show, hide, defaultValues, fetchData}) => {
    const {getLangText} = useLang();
    const [invoiceDataWaiting, setInvoiceDataWaiting] = useState(false);
    const dispatch = useDispatch();
    const [errorNip, setErrorNip] = useState(false);

    const afterSubmit = async () => {
        hide();
        await fetchData()
    }

    const [{onSave, isWaiting, getFieldProps, values, setValue, updateValues}] = useFormLocal({
        rest: services.API_SETTINGS_INVOICE_DATA,
        model: new Model(),
        method: 'put',
        initialAutoLoad: false,
        afterSubmit,
    });

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    const checkInvoiceData = async () => {
        if (+values.country_id === 103 && !values.nip?.match(/^[0-9]{10}$/g)) {
            setErrorNip(true)
            dispatch(showAlert('sthWentWrongWarning', 'checkNipValidLabel', 'alert-fail'))
            return;
        }
        setInvoiceDataWaiting(true)
        try {
            const {data} = await api.post(`/${services.API_GUS_DATA}`, {nip: values.nip})
            updateValues(data.item)
            setErrorNip(false)
        } catch (e) {
            setErrorNip(true)
            dispatch(showAlert('sthWentWrongWarning', 'checkNipValidLabel', 'alert-fail'))
        } finally {
            setInvoiceDataWaiting(false)
        }
    }

    useEffect(() => {
        if (show) {
            updateValues(defaultValues);
        }
    }, [show])

    // const updateCountryName = (option) => {
    //     updateValues({
    //         country: {
    //             id: option.id,
    //             name: option.name
    //         }
    //     })
    // }

    // const getNotValidNipError = notValidNip ? () => 'enterTheCorrectNipLabel' : getFieldProps('nip').getError;
    // const setNipValue = (e) => {
    //     setValue('nip', e.target.value)
    //     dispatch(PaymentFormActions.setNotValidNip(false))
    //     dispatch(PaymentFormActions.validation.deactivateValidation())
    // }

    return (
        <ConfirmModal
            title={getLangText('billingDataLabel')}
            dialogClassName=""
            show={show}
            isWaiting={isWaiting}
            submitButtonClass="primary min-w-[120px] px-3"
            onSubmit={onSave}
            hide={hide}
            withModalFooter={false}
        >
            <form onSubmit={onSave} id="settings-billing-data-form">
                <div className="loader-parent">
                    <Loader isLoading={invoiceDataWaiting}/>
                    <Field
                        {...getFieldProps(values.normal_name ? 'normal_name' : 'name')}
                        label={getLangText(values.normal_name ? 'nameLabel' : 'companyNameLabel')}
                        extraProps={{
                            required: true,
                        }}
                    />
                    <AsyncSearchSelectField
                        {...getFieldProps('country_id')}
                        className="default-size"
                        label={getLangText('countryLabel')}
                        rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
                        formatOptionLabel={formatOptionLabel}
                        defaultValue={{name: values.country_name, id: values.country_id}}
                        searchOnFocus
                        extraProps={{
                            required: true,
                        }}
                    />
                    {values.normal_address ?
                        <Field
                            {...getFieldProps('normal_address')}
                            label={getLangText('streetNumberLabel')}
                            extraProps={{
                                required: true,
                            }}
                        />
                        :
                        <>
                            <Group
                                {...getFieldProps('nip')}
                                label={getLangText('nipLabel')}
                                className="relative"
                                // validateErrors={getFieldProps('nip').validateErrors || errorNip}
                                // getError={getNotValidNipError}
                            >
                                <input
                                    {...getFieldProps('nip')}
                                    className="form-control"
                                    onChange={(e) => setValue('nip', e.target.value)}
                                    required={true}
                                />
                                {+values.country_id === 103 &&
                                    <button
                                        type="button"
                                        onClick={checkInvoiceData}
                                        className="button border-button small-size copy-btn copied left-icon absolute right-[7px] bottom-[7px]">
                                        <i className="icon-download"/>
                                        {getLangText('loadDataLabel')}
                                    </button>
                                }
                            </Group>
                            <Field
                                {...getFieldProps('address')}
                                label={getLangText('streetNumberLabel')}
                                extraProps={{
                                    required: true,
                                }}
                            />
                            <div className="row small-padd-row">
                                <div className="col-md-6">
                                    <ZipCodeField
                                        {...getFieldProps('zip_code')}
                                        label={getLangText('zipCodeLabel')}
                                        extraProps={{
                                            required: true,
                                        }}
                                        isPoland={+values.country_id === 103}
                                        maxLength={+values.country_id === 103 ? 6 : 99}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        {...getFieldProps('city')}
                                        label={getLangText('cityLabel')}
                                        extraProps={{
                                            required: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className="bg-[#f9f9f9] py-[20px] px-[25px] -mb-[20px] flex justify-end -mx-[25px] space-x-4">
                        <button
                            onClick={hide}
                            type="button"
                            className="button border-button min-w-[100px] bg-white"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        <button
                            type="submit"
                            className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader isLoading/>}
                            {getLangText('saveButton')}
                        </button>
                    </div>
                </div>
            </form>
        </ConfirmModal>
    );
};

export default BillingDataModal;
