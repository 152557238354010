import { useState, useRef } from 'react';
import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import AreaButton from './components/AreaButton/AreaButton';
import { ChevronDownIcon } from 'lucide-react';
import TemplatesDropdown from '../../../../../../../shared/templatesDropdown/TemplatesDropdown';
import AddLinkModal from '../addLinkModal/AddLinkModal';
import { useClickOutside } from '../../../../../../../../../src_shared/hooks/useClickOutside';

const MOBILE_URL = 'https://mobile.selmo.io';

const MessageContent = ({
    placeholder,
    messageLength,
    messageLimit,
    parts,
    values,
    setValue,
    hasError,
    disabled,
}) => {
    const { getLangText } = useLang();
    const [showTemplatesDropdown, setShowTemplatesDropdown] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const textAreaRef = useRef(null);
    const templatesDropdownRef = useRef(null);

    useClickOutside(templatesDropdownRef, () =>
        setShowTemplatesDropdown(false),
    );

    const handleChange = (e) => {
        const newMessage = e.target.value;
        setValue('message', newMessage);

        const productLink = `${MOBILE_URL}/products/${values.product_id}`;
        if (values.product_id && !newMessage.includes(productLink)) {
            setValue('product_id', null);
        }

        const liveLink = `${MOBILE_URL}/lives/${values.live_id}`;
        if (values.live_id && !newMessage.includes(liveLink)) {
            setValue('live_id', null);
        }
    };

    return (
        <div className="flex flex-col gap-2">
            <p className="font-bold">{getLangText('messageContentLabel')}</p>
            <div
                className={`relative flex flex-col gap-2 border-[1px] border-[${hasError ? 'red' : '#E5E7EB'}] rounded-[8px] p-3.5 ${disabled ? 'opacity-50' : ''}`}>
                <textarea
                    ref={textAreaRef}
                    value={values.message}
                    rows={6}
                    className="resize-none text-sm focus:outline-none leading-[22px] font-medium"
                    maxlength={messageLimit + 1}
                    onChange={handleChange}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                <div className="flex flex-col-reverse sm:flex-row gap-2 justify-between sm:items-center">
                    <div className="flex gap-2 items-center">
                        <div ref={templatesDropdownRef} className="relative">
                            <AreaButton
                                onClick={() =>
                                    setShowTemplatesDropdown(
                                        (prevState) => !prevState,
                                    )
                                }
                                title={getLangText('selectTemplateLabel')}
                                icon={
                                    <ChevronDownIcon
                                        color="#9CA3AF"
                                        size={14}
                                        strokeWidth={2.5}
                                    />
                                }
                            />
                            {showTemplatesDropdown && (
                                <TemplatesDropdown
                                    hide={() => setShowTemplatesDropdown(false)}
                                    setInputValue={(value) =>
                                        setValue('message', value)
                                    }
                                    textAreaRef={textAreaRef}
                                    type="sms"
                                />
                            )}
                        </div>
                        <AreaButton
                            onClick={() => setShowLinkModal(true)}
                            title={getLangText('addLink')}
                            icon={
                                <i className="icon-plus text-[9px] text-[#9CA3AF] font-bold" />
                            }
                        />
                    </div>
                    <div className="text-[13px] flex gap-1 items-center">
                        <span className="text-black font-bold">{parts}</span>
                        <span className="text-[#6b7280] font-medium">SMS,</span>
                        <span className="text-black font-bold">
                            {messageLimit - messageLength}
                        </span>
                        <span className="text-[#6b7280] font-medium">
                            znaków
                        </span>
                    </div>
                </div>
            </div>
            {hasError && (
                <p className="text-[12px] text-[#ef4444] -mt-[2px] font-medium">
                    {getLangText(hasError)}
                </p>
            )}
            {showLinkModal && (
                <AddLinkModal
                    modalVisible={showLinkModal}
                    hideModal={() => setShowLinkModal(false)}
                    setValue={setValue}
                    values={values}
                />
            )}
        </div>
    );
};

export default MessageContent;
