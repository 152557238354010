import React from 'react';
import ServerImage from '../../../../../../../../shared/components/serverImage/ServerImage';
import { convertToUserTimeZone } from '../../../../../../../../shared/helpers/dateHelpers';
import useLang from '../../../../../../../../../../src_shared/hooks/useLang';

const SelectedPost = ({ item, setValue, isActive }) => {
    const { getLangText } = useLang();

    return (
        <div
            className={`content border rounded-medium payment-content w-full text-left p-2`}>
            <div className="content__top-part d-flex items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                    <figure className="w-[50px] min-w-[50px] h-[50px] rounded-medium overflow-hidden mr-2">
                        {item.full_picture && (
                            <ServerImage
                                style={{ width: '100%', height: '100%' }}
                                className="w-full h-full object-cover"
                                src={item.full_picture}
                            />
                        )}
                    </figure>
                    <div className="mr-3">
                        <a
                            href={`https://www.facebook.com/${item.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="leading-[18px] text-sm font-bold line-clamp-2">
                            {item.message}
                        </a>
                        <div className="text-xs font-medium text-desc mt-1">
                            <span>
                                {convertToUserTimeZone(
                                    item.created_time,
                                ).format('DD.MM.YYYY')}
                            </span>
                            &nbsp;
                            <span>
                                {convertToUserTimeZone(
                                    item.created_time,
                                ).format('HH:mm')}
                            </span>
                        </div>
                    </div>
                </div>
                {isActive && (
                    <button
                        type="button"
                        onClick={() => setValue('selected_post', {})}
                        className={`text-right whitespace-nowrap sm:min-w-[106px] inline-flex items-center text-xs h-[31px] transition-none font-bold button border-button`}>
                        {getLangText('customerBasketChangeButton')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default SelectedPost;
