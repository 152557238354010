import ListReducer from '../../../../../../../src_shared/list/ListReducer';
import createReducer from '../../../shared/helpers/createReducer';
import Composition from '../../../shared/helpers/Composition';

export const getStateRoot = (state) => state.admin.stats.sales.table;
export const prefix = 'ADMIN_STATS_SALES_TABLE_';

const getInitState = () =>
  ListReducer.getInitState({
    sortBy: 'date',
    sortDir: 'desc',
    items: [],
  });

const getReduceMap = () => new Composition(ListReducer.getReduceMap());

export const getInstance = () =>
  createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
