import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";

import {selmoUrl} from "../../../../src_shared/api/api";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {getStateRoot as getPaymentMethodsState} from '../paymentMethods/formReducer';
import {getStateRoot as getBasketPageStateRot} from '../page/formReducer';
import BasketPageFormActions from "../page/FormActions";
import {batch} from "react-redux";
import {services} from "../../../RestServices";
import {
    ORDER_PAYMENT_BM_TRANSFER, ORDER_PAYMENT_LUSOPAY_MULTIBANCO,
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_PICKUP,
    ORDER_PAYMENT_SHOP_PICKUP,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER
} from "../../shared/enums/PaymentStatuses";
import {CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW} from "../../shared/enums/CustomerBasketViews";
import BasketPaymentMethodsFormActions from "../paymentMethods/FormActions";
import {
    validateCreditCardNumber, validateCVC,
    validateExpirationDate
} from "../../../../src_shared/form/validation/ValidationError";
import api from "../../../../services/axios/axios";
import {cloneDeep} from "lodash";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {PRODUCT_SOURCE_SHOP} from "../../shared/enums/ProductSourceType";


export class BasketDeliveryMethodsFormActions extends FormActions {

    setPaymentsMethodsVisible(toggle) {
        return {
            type: `${this.prefix}SET_PAYMENT_METHODS_VISIBLE`, toggle,
        }
    }

    setDeliveryMethodsModalVisible(toggle) {
        return {
            type: `${this.prefix}SET_DELIVERY_METHODS_MODAL_VISIBLE`, toggle,
        }
    }

    setDeliveryMethodsVisible(toggle) {
        return {
            type: `${this.prefix}SET_DELIVERY_METHODS_VISIBLE`,
            toggle,
        }
    }

    setWaitingForValueOnServer(toggle) {
        return {
            type: `${this.prefix}SET_WAITING_FOR_VALUE_ON_SERVER`,
            toggle,
        }
    }


    updateShippingMethods(deliveries) {
        return async (dispatch, getState) => {
            const {shipping} = getStateRoot(getState()).data
            const shippingExist = deliveries?.some((i) => +i.id === +shipping);
            dispatch(this.data.setValue('deliveries', deliveries))

            if (!shippingExist) {
                batch(() => {
                    dispatch(this.data.setValue('shipping', null))
                    dispatch(this.setDeliveryMethodsVisible(true))
                })
            }
        }
    }

    getDeliveryOptions(rest, field, magicUrl, shopId) {
        return async (dispatch, getState) => {
            dispatch(this.waiting.startWaiting());
            const {shipping, package_type_id} = getStateRoot(getState()).data

            try {
                const {data} = await api.get(`${selmoUrl}/${rest}?magic_url=${magicUrl}&shop_id=${shopId}`)
                const parsedItem = dispatch(this.parseData(data.item));
                dispatch(this.data.setValue(field, parsedItem))

                if (field === 'shippingAddress') {
                    dispatch(this.data.setValue('agreement', data.item.agreement));
                }
                if (field === 'deliveries') {
                    const shippingExist = data?.item?.some((i) => +i.id === +shipping);
                    if (!shippingExist) {
                        batch(() => {
                            dispatch(this.data.setValue('shipping', null))
                            dispatch(this.setDeliveryMethodsVisible(true))
                        })
                    }
                }

                if (field === 'packagesTypes') {
                    const packedTypeExist = data?.item?.some((i) => +i.id === +package_type_id);
                    if (!packedTypeExist) {
                        dispatch(this.data.setValue('package_type_id', null))
                    }
                }
            } catch (e) {
                console.error('Error getDeliveryOptions1')
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        }
    }

    setValueOnServer(rest, field, value, magicUrl, shopId) {
        return async (dispatch, getState) => {
            const {deliveryMethodsModalVisible, openCartStatus, data} = getStateRoot(getState());
            const {data: basketData} = getBasketPageStateRot(getState());
            const {payment} = getPaymentMethodsState(getState()).data;
            const hiddenPickupMethod = +!!value && !+basketData.shopInfo.open_cart_pickup;

            dispatch(this.setWaitingForValueOnServer(true))
            const preparedValue = typeof value === 'object' ? value : {[field]: value}
            const defaultValue = cloneDeep(data);

            dispatch(this.data.updateValues(preparedValue))
            try {
                const {data} = await api.put(`${selmoUrl}/${rest}`, {
                    ...preparedValue, magic_url: magicUrl, shop_id: shopId,
                })
                batch(() => {
                    if (field === 'shipping') {
                        dispatch(BasketPageFormActions.getAndUpdatePrices(magicUrl, shopId));
                    }
                    if (deliveryMethodsModalVisible) {
                        dispatch(this.setDeliveryMethodsModalVisible(false));
                    }
                    if (field === 'open_cart_status' && (+shopId === TEST_ACCOUNT_SHOP_ID || +shopId === BUTIK_MADAME_SHOP_ID)) {
                        const baseTotalPrice = basketData.overall.total_price.toString().replace(',', '.');
                        if (data?.item?.open_cart_surcharge_added) {
                            dispatch(BasketPageFormActions.data.updateValues({
                                ...basketData,
                                overall: {
                                    ...basketData.overall,
                                    open_cart_surcharges: 2,
                                    total_price: +baseTotalPrice + 2,
                                }
                            }));
                        }
                    }
                    if (field === 'open_cart_status' && data.orderStage < 2) {
                        dispatch(BasketPaymentMethodsFormActions.loadData(magicUrl, shopId, openCartStatus));
                    }
                    if (field === 'open_cart_status' && payment === ORDER_PAYMENT_PICKUP && hiddenPickupMethod) {
                        dispatch(BasketPaymentMethodsFormActions.setValueOnServer('payment', null, magicUrl, shopId))
                    }
                })
            } catch (e) {
                dispatch(this.data.updateValues(defaultValue))
                console.error('Error send value on server')
            } finally {
                dispatch(this.setWaitingForValueOnServer(false))
            }
        }
    }

    waitingForSubmit(toggle) {
        return {
            type: `${this.prefix}WAITING_FOR_SUBMIT`, toggle,
        }
    }

    setShowAutomaticTransfersModal(toggle) {
        return {
            type: `${this.prefix}SET_SHOW_AUTOMATIC_TRANSFER_MODAL`
            , toggle,
        }
    }


    submitEditForm(values, history, checkPayment = true) {
        return async (dispatch, getState) => {
            const {payment, card_details} = getPaymentMethodsState(getState()).data;
            const {overall, shopPayment, shopInfo, productsInfo} = getBasketPageStateRot(getState()).data;
            const {
                magic_url,
                shop_id,
                shopName,
                agreement,
                selmo_marketing_agreement,
                shop_marketing_agreement,
            } = values;

            dispatch(this.validation.activateValidation());

            const preparedValues = {
                ...values,
                magic_url,
                shop_id,
                payment
            }

            if (!this.dataModel.validateData(preparedValues)) {
                dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
                dispatch(BasketPaymentMethodsFormActions.setPaymentMethodsValidation(true));
                console.error('Validation failed!');
                return;
            }

            if (overall?.payment_value?.type === ORDER_PAYMENT_STRIPE_CARD && (!validateCreditCardNumber(card_details.card_number) || !validateExpirationDate(card_details.expiration_date) || !validateCVC(card_details.cvc))) {
                dispatch(showAlert('incorrectCardDataLabel', '', 'alert-fail'))
                dispatch(BasketPaymentMethodsFormActions.setPaymentMethodsValidation(true));
                console.error('Validation failed!');
                return;
            }

            if (payment === ORDER_PAYMENT_BM_TRANSFER && checkPayment) {
                dispatch(this.setShowAutomaticTransfersModal(true))
                return;
            }

            dispatch(this.waitingForSubmit(true));

            const preparedCardDetails = {
                ...card_details,
                card_number: card_details.card_number.replace(/\D+/g, ''),
            }

            const isProductFromShop = productsInfo.some((i) => i.type === PRODUCT_SOURCE_SHOP);

            if (!!+shopInfo?.stripe_tax_enabled) {
                try {
                    const {data} = await api.post(`${selmoUrl}/${services.API_CART_START_STRIPE_TAX}`, {
                        magic_url,
                        shop_id,
                        agreement,
                        selmo_marketing_agreement,
                        shop_marketing_agreement,
                        new_payments_view: overall?.new_payments_view
                    })

                    window.location.href = data.item;

                } catch (e) {
                    dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
                    console.error('Error submitEditForm')
                } finally {
                    dispatch(this.waitingForSubmit(false));
                }
            } else {
                try {
                    const {data} = await api.post(`${selmoUrl}/${this.restService}`, {
                        magic_url,
                        shop_id,
                        agreement,
                        selmo_marketing_agreement,
                        shop_marketing_agreement,
                        card_details: preparedCardDetails,
                        payment: values.payment || null,
                        new_payments_view: overall?.new_payments_view
                    })

                    if (isProductFromShop && !!shopInfo?.integration_facebook_pixel) {
                        import("react-facebook-pixel")
                            .then((x) => x.default)
                            .then((ReactPixel) => {
                                ReactPixel.init(shopInfo?.integration_facebook_pixel)
                                ReactPixel.trackSingle(shopInfo?.integration_facebook_pixel, 'Purchase', {
                                    currency: shopInfo?.currency_short_name?.toUpperCase(),
                                    value: overall?.total_price?.toString().replace(',', '.')
                                })
                            });
                    }

                    if (overall?.payment_value?.type === ORDER_PAYMENT_PAYPAL && !!shopPayment.paypal_link) {
                        window.location.href = `${shopPayment.paypal_link}/${overall.total_price}${shopInfo.currency_short_name}`;
                        return;
                    }

                    const redirectToPayment = (overall?.payment_value?.type === ORDER_PAYMENT_TRANSFER || overall?.payment_value?.type === ORDER_PAYMENT_PAYPAL || overall?.payment_value?.type?.includes(`${ORDER_PAYMENT_TRANSFER}_`) || overall?.payment_value?.type === ORDER_PAYMENT_LUSOPAY_MULTIBANCO)

                    if (redirectToPayment) {
                        dispatch(showAlert('yuhuLabel', 'payForOrderLabel'))
                        history.push(`/koszyk/${shopName}/${magic_url}/platnosc/`)
                        return;
                    }

                    if (overall?.payment_value?.type === ORDER_PAYMENT_SHOP_PICKUP || overall?.payment_value?.type === ORDER_PAYMENT_PICKUP) {
                        batch(() => {
                            dispatch(showAlert('yuhuLabel', 'informationHasBeenSubmittedLabel'))
                            dispatch(BasketPageFormActions.setBasketView(CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW))
                            dispatch(BasketPageFormActions.loadData(magic_url, shop_id))
                        })
                        return;
                    }

                    window.location.href = data.item;

                } catch (e) {
                    dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
                    console.error('Error submitEditForm')
                } finally {
                    dispatch(this.waitingForSubmit(false));
                    dispatch(this.setShowAutomaticTransfersModal(false))
                }
            }
        }
    }

}

export const getInstance = () => new BasketDeliveryMethodsFormActions({
    prefix, getStateRoot,
    model: BasketDeliveryMethodModel,
    restService: services.API_CART_ORDER_PAYMENT,
});

export default getInstance();

