import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import Modal from "react-bootstrap/Modal";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import Loader from "../../../../../src_shared/components/loader/Loader";

const SelectSizesModal = ({product, isOpen, hideModal, isWaiting, currency_shortcut, addUpsellProduct}) => {

    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();


    const [{isLoading, data: products}] = useFetchData({
        rest: `${services.API_CART_PRODUCTS_SIZES}/${product?.id}`,
        initialData: [],
    });
    const productSizeDisabled = (size) => !+size.squ;

    return (
        <Modal
            className="higher-index"
            show={isOpen}
            dialogClassName="default-modal small-modal w-400"
            onHide={hideModal}
        >
            <Modal.Header>
                <div className="font-bold text-sm">{getLangText('selectVariantLabel')}</div>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="mb-3">
                <div className="select-size-wrapper">
                    <div className="flex items-center mb-3">
                        <figure className="w-[54px] h-[54px] rounded-[8px] overflow-hidden mr-2">
                            {!product?.photo_small || product?.photo_small === '0' ?
                                <img
                                    src="/assets/images/default/product.svg"
                                    alt={product?.name}
                                    className="img-preview"/>
                                :
                                <ServerImage
                                    className="w-full h-full object-cover"
                                    src={product?.photo_small}
                                    alt={product?.name}
                                />
                            }
                        </figure>
                        <div>
                            <div className="text-xs font-bold">{product?.name}</div>
                            <div className="flex items-center space-x-1 text-xs">
                                <div
                                    className="text-[#101827]">{getPrice(product.price, currency_shortcut)}</div>
                                {product.old_price &&
                                    <div className="text-[#D80027] line-through">
                                        {getPrice(product.old_price, currency_shortcut)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <Loader
                            className="small-loader"
                            isLoading={isLoading}
                        />
                        <div className="sizes-wrapper upsell-sizes-wrapper">
                            {products?.map((size) => (
                                <div
                                    key={size.id}
                                    className="tooltip-parent"
                                >
                                    {productSizeDisabled(size) &&
                                        <div className="tooltip-content">
                                            {getLangText('noVariantAvailableLabel')}
                                        </div>
                                    }
                                    <button
                                        type="button"
                                        onClick={() => addUpsellProduct({
                                            product_id: product.id,
                                            product_size_id: size.id,
                                            product_size_name: size.size,
                                        })}
                                        key={size?.id}
                                        disabled={productSizeDisabled(size)}
                                        className={`size-box hover:border-[#E5E7EB] hover:bg-[#FAFBFF] relative`}
                                    >
                                        {isWaiting === size.id &&
                                            <Loader
                                                className="small-loader in-white-button"
                                                isLoading={true}
                                            />
                                        }
                                        <div>{size?.size}</div>
                                        {/*<div className="price">{getPrice(size?.price)}</div>*/}
                                        <div className="text-[#6B7280] font-bold text-xs ml-auto">
                                            {getLangText('selectButton')}
                                            <i className="icon-arrows inline-block text-[8px] -rotate-90 ml-1 relative -top-[1px]"/>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SelectSizesModal;
