import React, {useEffect} from 'react';
import SelectField from "../../../../../../../../../src_shared/components/form/selectField/SelectField";
import useFormLocal from "../../../../../../../../../src_shared/hooks/useFormLocal";
import SelmoPaczkaPointActivationModal
    from "../../../../../../../../../modules/models/selmoPaczka/SelmoPaczkaPointActivationModal";
import {services} from "../../../../../../../../RestServices";
import Loader from "../../../../../../../../../src_shared/components/loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import Model from "../../../../../../../../../src_shared/modules/model/Model";
import {useParams} from "react-router-dom";
import {showAlert} from "../../../../../../../shared/components/alert/AlertActions";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../../../src_shared/components/form/field/Field";

const STATUSES =
    [
        {value: '1', label: 'Aktywna'},
        {value: '2', label: 'Przetwarzanie'},
        {value: '3', label: 'Nieaktywna'},
    ]

const AdminBox = ({data, fetchData}) => {
    const {userData} = useSelector((state) => state.session);
    const {id} = useParams();
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const [{getFieldProps, onSave, isWaiting, values, setValue, updateValues}] = useFormLocal({
        model: new Model(),
        rest: `${services.API_MARKETING_AD_STATUS}/${id}`,
        initialAutoLoad: false,
        afterSubmit: () => {
            fetchData()
            dispatch(showAlert())
        }
    });

    useEffect(() => {
        updateValues({
            status: data.status,
            budget_spent: data.budget_spent,
        })
    }, [data.status, data.budget_spent])

    if (+userData.selmo_boss !== 69) return null

    return (
        <div className="p-6 bg-[#fff] rounded-medium mb-3">
            <div className="font-bold text-lg mb-3">Admin BOX</div>
            <div className="flex items-end space-x-2">
                <SelectField
                    {...getFieldProps('status')}
                    value={values.status || null}
                    label="Wybierz status"
                    options={STATUSES}
                    className="default-size mb-0"
                    placeholder="Wybierz status"
                    extraProps={{
                        isSearchable: false,
                    }}
                />
                <Field
                    {...getFieldProps('budget_spent')}
                    label="Budżet"
                    className="mb-0"
                    setValue={setValue}
                />
                <button
                    type="button"
                    onClick={onSave}
                    style={{paddingTop: 14, paddingBottom: 14}}
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading={true} />}
                    {getLangText('saveButton')}
                </button>
            </div>
        </div>
    );
};

export default AdminBox;
