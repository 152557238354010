import useLang from '../../../../../../../../../../../src_shared/hooks/useLang';

const LinkInput = ({ placeholder, onChange, value, hasError }) => {
    const { getLangText } = useLang();

    return (
        <div>
            <div
                className={`mt-3 rounded-[8px] bg-[#F3F4F6] ${
                    hasError ? 'border-[#ef4444] border-[1px]' : ''
                } flex items-center h-[44px]`}>
                <input
                    type="text"
                    placeholder={placeholder}
                    className="w-full h-full py-[7px] px-[12px] bg-transparent outline-none placeholder:text-[#9CA3AF] placeholder:text-[13px] text-[13px]"
                    onChange={onChange}
                    value={value}
                />
            </div>
            {hasError && (
                <p className="text-[12px] text-[#ef4444] mt-1">
                    {getLangText('customLinkError')}
                </p>
            )}
        </div>
    );
};

export default LinkInput;
