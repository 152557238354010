import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useLang from '../../../../../src_shared/hooks/useLang';
import { RouterPaths } from '../../../../routes/RouterPath';
import { Helmet } from 'react-helmet';
import NoAccess from './shared/NoAccess';
import { useSelector } from 'react-redux';

const tabs = [
    {
        id: 1,
        label: 'firstSteps',
        path: RouterPaths.MarketingFacebookFirstSteps,
    },
    {
        id: 2,
        label: 'advertisements',
        path: RouterPaths.MarketingFacebookAdvertisements,
    },
    {
        id: 3,
        label: 'statisticsLabel',
        path: RouterPaths.MarketingFacebookStatistics,
    },
];

const SubmittedWrapper = ({ children }) => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);
    const { pathname } = useLocation();

    // if (+userData.facebook_ads_active === 2) return <NoAccess />

    return (
        <div className="animate-[fadeIn_.3s_ease_forwards]">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getLangText('facebookAdds')} - Selmo</title>
            </Helmet>
            <div className={``}>
                <div className="tabs-wrapper bg-[#fff] sm:rounded-t-medium shadow-default">
                    {tabs.map((i) => (
                        <NavLink
                            to={i.path}
                            key={i.id}
                            // className={`${pathname === i.path ? 'active' : ''}`}
                            activeClassName="active">
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
                <div className="relative">
                    {children}
                    {+userData.facebook_ads_active === 2 && (
                        <>
                            <div
                                className={`transition-all absolute top-0 left-0 right-0 bg-[#fff] opacity-70 z-10 ${pathname.includes(RouterPaths.MarketingFacebookFirstSteps) ? 'bottom-0' : 'lg:bottom-[92px] bottom-0'}`}
                            />
                            <NoAccess />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubmittedWrapper;
