import React, {useEffect, useMemo, useRef} from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import SettingsGeneralModel from "../../../modules/models/settings/SettingsGeneralModel";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import ImageUpload from "../../../src_shared/components/form/imageUpload/ImageUpload";
import Field from "../../../src_shared/components/form/field/Field";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import {useDispatch, useSelector} from "react-redux";
import SelectField from "../../../src_shared/components/form/selectField/SelectField";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link, useLocation} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {showAlert} from "../../app/shared/components/alert/AlertActions";
import {Helmet} from "react-helmet";
import useDataApi from "../../../src_shared/hooks/useDataApi";
import SessionActions from "../../app/shared/session/SessionActions";
import {
    LA_BELLA_SHOP_ID, NINA_MOLETKY_SHOP_ID, OLI_ELEGANCJA,
    TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID,
    TOMI_STYLE_SHOP_ID,
    VANITY_SHOP_ID
} from "../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_DA} from "../../app/shared/enums/ShopOrigins";
import useScrollToFoundSection from "../../../src_shared/hooks/useScrollToFoundSection";

const General = () => {
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const removeFromTimeInputRef = useRef(null);
    useScrollToFoundSection();
    const afterGetShopInfoData = (response) => dispatch(SessionActions.login(response))

    const [{
        isLoading: waitingForSession,
        fetchData: getShopInfoData
    }] = useDataApi(`${services.API_USER_SESSION}?lang=${lang}`, [], [], false, {}, afterGetShopInfoData)
    const afterSubmit = async () => {
        dispatch(showAlert())
        updateValues(SettingsGeneralModel.getModel())
        await fetchData();
        await getShopInfoData();
    };

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        updateValues,
        fetchData,
        setValue,
    }] = useFormLocal({
        rest: services.API_SETTINGS_GENERAL,
        model: SettingsGeneralModel,
        afterSubmit,
    });

    const currenciesOptions = useMemo(() => values.currencies.map((i) => ({
        label: i.name,
        value: i.id,
    })), [values.currencies])

    const onSwitchChange = (field, value) => {
        if (!!+value) {
            setTimeout(() => {
                removeFromTimeInputRef.current?.focus();
            }, 200)
        }
        setValue(field, value)
    }


    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('mainSettingsLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('mainSettingsLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/settings/overview.svg" alt="overview"/>
                        {getLangText('settings_general_title')}
                    </h1>
                    <div className="description">{getLangText('settings_general_title_description')}</div>
                </div>
                <div className="settings-form">
                    <section id="logo">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_logo')}</h2>
                        </div>
                        <div className="form-col">
                            <ImageUpload
                                defaultImage="shop.svg"
                                values={values}
                                updateValues={updateValues}
                                imagePath="settings/general"
                                field="logo"
                            />
                        </div>
                    </section>
                    <section id="name">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_name_and_description')}</h2>
                        </div>
                        <div className="form-col">
                            <Field
                                {...getFieldProps('name')}
                                label={getLangText('input_label_shop_name')}
                            />
                        </div>
                    </section>
                    <section id="counting">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_counting')}</h2>
                        </div>
                        <div className="form-col">
                            <Field
                                {...getFieldProps('time_to_pay')}
                                className="hidden-arrows"
                                style={{maxWidth: '170px'}}
                                label={getLangText('input_label_time_to_pay')}
                                addon={getLangText('input_addon_hours')}
                                type="number"
                                extraProps={{
                                    min: 0,
                                }}
                            />
                            <SwitchField
                                {...getFieldProps('time_to_pay_active')}
                                label={getLangText('settings_general_counting_on_title')}
                                subLabel={getLangText('settings_general_counting_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"
                            />
                        </div>
                    </section>
                    <section id="free-shipping">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_free_shipping')}</h2>
                        </div>
                        <div className="form-col">
                            <Field
                                {...getFieldProps('free_shipping_price')}
                                className="hidden-arrows"
                                style={{maxWidth: '170px'}}
                                label={getLangText('input_label_min_order_value')}
                                addon={userData.currency_info?.text}
                                type="number"
                                extraProps={{
                                    min: 0,
                                }}
                            />
                            <SwitchField
                                {...getFieldProps('free_shipping_active')}
                                label={getLangText('settings_general_free_shipping_on_title')}
                                subLabel={getLangText('settings_general_free_shipping_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

                            />
                        </div>
                    </section>
                    <section id="currency">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_currency')}</h2>
                        </div>
                        <div className="form-col">
                            <div className="row">
                                <div className="col-md-5">
                                    <SelectField
                                        {...getFieldProps('currency')}
                                        label={getLangText('input_label_currency')}
                                        className="default-size mb-0"
                                        name="currency"
                                        options={currenciesOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="payment-status">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_payment_status')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('show_payment_status')}
                                label={getLangText('settings_general_payment_status_on_title')}
                                subLabel={getLangText('settings_general_payment_status_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

                            />
                        </div>
                    </section>
                    <section id="balance">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_clients_balance')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('show_payment_received')}
                                label={getLangText('settings_general_clients_balance_on_title')}
                                subLabel={getLangText('settings_general_clients_balance_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

                            />
                        </div>
                    </section>
                    <section id="open-cart">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_open_package')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('open_cart_enabled')}
                                label={getLangText('settings_general_open_package')}
                                subLabel={getLangText('settings_general_open_package_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

                            />
                            <SwitchField
                                {...getFieldProps('edit_open_cart_enabled')}
                                label={getLangText('settings_general_close_open_package_by_client_on_title')}
                                subLabel={getLangText('settings_general_close_open_package_by_client_on_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"

                            />
                            <SwitchField
                                {...getFieldProps('open_cart_pickup')}
                                label={getLangText('settings_general_pickup')}
                                subLabel={getLangText('settings_general_pickup_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"

                            />
                        </div>
                    </section>
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === VANITY_SHOP_ID || userData.origin === SHOP_ORIGIN_DA || +userData.id === LA_BELLA_SHOP_ID || +userData.id === TOMI_STYLE_SHOP_ID || +userData.id === 499 || +userData.id === TIM_SHOP_ID || +userData.id === NINA_MOLETKY_SHOP_ID || +userData.id === OLI_ELEGANCJA || !!+userData.selmo_pro_active) &&
                        <section>
                            <div className="left-side">
                                <h2 className="label">{getLangText('removeProductsFromCartLabel')}</h2>
                            </div>
                            <div className="form-col">
                                <SwitchField
                                    {...getFieldProps('edit_cart_enabled')}
                                    label={getLangText('removeProductsFromCartLabel')}
                                    subLabel={getLangText('removeProductsFromCartSubLabel')}
                                    className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"
                                />
                                <div className="mt-4">
                                    <SwitchField
                                        {...getFieldProps('edit_cart_enabled_time')}
                                        setValue={onSwitchChange}
                                        label={getLangText('removeProductForSpecificTime')}
                                        subLabel={getLangText('removeProductForSpecificTimeSubLabel')}
                                        className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"
                                    />
                                    {!!+values.edit_cart_enabled_time &&
                                        <Field
                                            {...getFieldProps('edit_cart_enabled_time_value')}
                                            className="hidden-arrows ml-[44px] mt-2"
                                            style={{maxWidth: '170px',}}
                                            showLabel={false}
                                            addon={getLangText('minutesLabel')}
                                            type="number"
                                            extraProps={{
                                                ref: removeFromTimeInputRef,
                                                min: 0,
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </section>
                    }
                    <section id="prducts">
                        <div className="left-side">
                            <h2 className="label">{getLangText('asideProductsLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('purchase_price_enabled')}
                                label={getLangText('setPurchasePriceVisible')}
                                subLabel={getLangText('setPurchasePriceVisibleSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0"
                            />
                            <SwitchField
                                {...getFieldProps('category_on_product_add')}
                                label={getLangText('setCategoryWhenAddingNewProduct')}
                                subLabel={getLangText('setCategoryWhenAddingNewProductSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"
                            />
                            <SwitchField
                                {...getFieldProps('description_on_product_add')}
                                label={getLangText('setDescriptionWhenAddingNewProduct')}
                                subLabel={getLangText('setDescriptionWhenAddingNewProductSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"
                            />
                        </div>
                    </section>
                    <section id="additional-info">
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_general_additional_info')}</h2>
                        </div>
                        <div className="form-col">
                            <SwitchField
                                {...getFieldProps('extra_input_enabled')}
                                label={getLangText('settings_general_additional_info')}
                                subLabel={getLangText('settings_general_additional_info_description')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

                            />
                            <SwitchField
                                {...getFieldProps('company_invoice_enabled')}
                                label={getLangText('invoiceForClientsLabel')}
                                subLabel={getLangText('invoiceForClientsSubLabel')}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"
                            />
                        </div>
                    </section>
                    <div className="button-place">
                        <button
                            type="button"
                            onClick={onSave}
                            className={`button primary ${(isWaiting || waitingForSession) ? 'loading-button' : ''}`}
                        >
                            <Loader isLoading={isWaiting || waitingForSession}/>
                            {getLangText('saveButton')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default General;
