import React, { useRef, useState } from 'react';
import useLang from '../../../../src_shared/hooks/useLang';
import EditDropdown from './EditDropdown';
import useRemoveData from '../../../../src_shared/hooks/useRemoveData';
import { services } from '../../../RestServices';
import ConfirmModal from '../../live/details/default/summary/export/ConfirmModal';

const TemplatesMessages = ({
    data,
    setShowModal,
    setInputValue,
    hideDropdown,
    fetchData,
    textAreaRef,
    type = 'messenger',
}) => {
    const { getLangText } = useLang();
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const scrollContainer = useRef(null);
    const menuRef = useRef(null);

    const onMessageClick = (message) => {
        setInputValue(message);
        hideDropdown();
        textAreaRef?.current?.focus();
    };

    const [
        {
            openModal: openRemoveModal,
            hideModal: hideRemoveModal,
            confirmModalVisible,
            isDeleting,
            removeItem,
            itemToRemove,
        },
    ] = useRemoveData({
        rest: services.API_MESSENGER_TEMPLATE,
        params: {
            type,
        },
        afterSubmit: () => fetchData(),
    });

    const handleModifyTemplate = (id, action) => (e) => {
        e.stopPropagation();

        switch (action) {
            case 'edit':
                setShowModal(id);
                break;
            case 'remove':
                openRemoveModal(id);
                break;
            default:
                throw new Error('Invalid action');
        }
    };

    const getTemplateName = (id) => {
        const template = data.find((item) => item.id === id);
        return template?.name;
    };

    return (
        <div
            ref={scrollContainer}
            className="space-y-5 p-2 max-h-[400px] overflow-y-auto vertical-custom-scroll">
            {!!data.length ? (
                data?.map((item) => (
                    <button
                        key={item.id}
                        type="button"
                        className="w-full rounded-[8px] hover:bg-[#F6F7F9] p-[5px] flex z-10"
                        onClick={() => onMessageClick(item.text)}>
                        <div className="flex">
                            <div className="flex shadow-selmo-border self-start items-center justify-center w-[35px] h-[35px] rounded-[6px] bg-white border-[1px] border-[#E5E7EB]">
                                <img
                                    src="/assets/images/svg/marketing/sms-inside.svg"
                                    alt="Chat icon"
                                    className="w-[15px]"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col items-start ml-2 w-full">
                            <p className="font-bold text-[12px]">{item.name}</p>
                            <p className="text-[12px] text-[#6b7280] text-left">
                                {item.text.trim()}
                            </p>
                        </div>
                        <div className="flex items-center justify-start ml-2 z-20">
                            <EditDropdown
                                visible={visibleDropdown === item.id}
                                setVisible={setVisibleDropdown}
                                id={item.id}
                                menuRef={menuRef}
                                offsetY={15}
                                className="self-start -translate-y-[5px] -translate-x-[5px]">
                                <div ref={menuRef}>
                                    <button
                                        type="button"
                                        className="custom-option"
                                        onClick={handleModifyTemplate(
                                            item.id,
                                            'edit',
                                        )}>
                                        {getLangText('editButton')}
                                    </button>
                                    <button
                                        type="button"
                                        className="custom-option"
                                        onClick={handleModifyTemplate(
                                            item.id,
                                            'remove',
                                        )}>
                                        {getLangText('removeButton')}
                                    </button>
                                </div>
                            </EditDropdown>
                        </div>
                    </button>
                ))
            ) : (
                <div className="text-center p-3">
                    <div className="font-bold">
                        {getLangText('noSavedTemplates')}
                    </div>
                    <button
                        type="button"
                        onClick={() => setShowModal(0)}
                        className="button text-only with-icon lgrey add-order-table">
                        <i className="icon-plus" />
                        {getLangText('settings_package_types_text_1')}
                    </button>
                </div>
            )}
            {confirmModalVisible && (
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('delete_modal_accept')}
                    SubTitle={() => (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLangText({
                                    key: 'wantToRemoveTemplateLabel',
                                    data: [getTemplateName(itemToRemove)],
                                }),
                            }}
                        />
                    )}
                    saveButton={getLangText('removeButton')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={() => removeItem(itemToRemove)}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            )}
        </div>
    );
};

export default TemplatesMessages;
