import React, { useRef } from 'react';
import useDropdown from '../../../../src_shared/hooks/useDropdown';
import Portal from './Portal';

const DropdownWithPortal = ({
    children,
    icon = 'icon-dots',
    ButtonToggle = null,
    tooltipTitle,
    className = '',
    placement = '',
    onClick = () => {},
    buttonClassName,
    offsetY = 0,
    menuRef,
}) => {
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    const hideDropdown = () => setOpen(false);
    const { open, setOpen } = useDropdown(buttonRef, dropdownRef, hideDropdown);

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    return (
        <>
            <div
                onClick={onClick}
                ref={dropdownRef}
                className={`react-dropdown position-relative ${className} ${placement}`}>
                <button
                    type="button"
                    className={`dropdown-toggle ${buttonClassName} ${open ? 'show' : ''} ${tooltipTitle ? 'tooltip-parent' : ''}`}
                    onClick={handleClick}
                    ref={buttonRef}>
                    <i className={icon} />
                </button>
                {open && (
                    <Portal
                        offsetY={offsetY}
                        basedOn={buttonRef}
                        menuRef={menuRef}
                        open={open}
                        rightPosition>
                        {children}
                    </Portal>
                )}
            </div>
        </>
    );
};

export default DropdownWithPortal;
