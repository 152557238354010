import React from 'react';
import {useUnitPrice} from "../../../shared/helpers/Price";
import useLang from "../../../../../src_shared/hooks/useLang";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../secondStep/SecondStep";
import SelectedPointMachineBox from "./SelectedPointMachineBox";
import DpdPickupLogos from "./DpdPickupLogos";
import {TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const DeliveryType = ({
                          values,
                          updateValues,
                          setValue,
                          showSelectedPoint = true,
                          data,
                          getError,
                          validateErrors,
                          setIsPointModalOpen,
                          setActiveShippingId,
                          currency = null,
                          className = '',
                      }) => {

    const {getPrice} = useUnitPrice();
    const getPriceWithCurrency = (price) => getPrice(price, currency || data?.shopInfo?.currency_shortcut);

    const {getLangText} = useLang();

    const onInputChange = (delivery) => {
        if (!!+delivery.is_inpost || !!+delivery.is_mondial || !!+delivery.is_orlen || !!+delivery.is_dpd || !!+delivery.is_gls) {
            onInputClick(delivery);
            return;
        }
        setValue('shipping', delivery.id)
        updateValues({
            shipping: delivery.id,
            pointMachineCode: null,
        })
    }

    const onInputClick = (delivery) => {
        setActiveShippingId(delivery.id)
        if (!!+delivery.is_inpost) {
            setIsPointModalOpen(INPOST_POINT_MODAL);
        }
        if (!!+delivery.is_mondial) {
            setIsPointModalOpen(MONDIAL_RELAY_POINT_MODAL);
        }
        if (!!+delivery.is_orlen) {
            setIsPointModalOpen(ORLEN_POINT_MODAL);
        }
        if (!!+delivery.is_dpd) {
            setIsPointModalOpen(DPD_POINT_MODAL);
        }
        if (!!+delivery.is_gls) {
            setIsPointModalOpen(GLS_POINT_MODAL);
        }
    }

    const isError = () => {
        const errorKey = getError(values.shipping, values);
        return errorKey ? errorKey : false;
    }

    const hasError = !!isError() && validateErrors;

    return (
        <>
            {!!values.deliveries?.length ?
                <>
                    {hasError &&
                        <div className="form-info-box alert-style red w-100 mb-2">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div className="title">
                                {getLangText('customerBasketDeliveryTypeLabel')}
                            </div>
                        </div>
                    }
                    <section>
                        <div className={`data-boxes-wrapper separated ${className} ${hasError ? 'error-group' : ''}`}>
                            {values.deliveries?.map((delivery) => (
                                <div
                                    key={delivery.id}
                                    className={`content p-0 payment-content ${values.shipping === delivery.id ? 'active' : ''}`}
                                >
                                    <div className="content__top-part">
                                        <input
                                            checked={values.shipping === delivery.id}
                                            onChange={() => onInputChange(delivery)}
                                            onClick={() => onInputClick(delivery)}
                                            type="radio"
                                            name="delivery_radio"
                                        />
                                        <span className="checkmark"/>
                                        <div className="flex-grow-1">
                                            <div className="label">{delivery.name}</div>
                                            {(+data?.shopInfo?.id === TIM_SHOP_ID || +data?.shopInfo?.id === TEST_ACCOUNT_SHOP_ID) && !!+delivery.is_inpost &&
                                                <div className="text-desc text-xs font-medium">
                                                    {getLangText('deliveryOneTwoBusinessDays')}
                                                </div>
                                            }
                                            {+values.shopInfo?.order_stage > 1 ?
                                                <div className="data">{getLangText('paidLabel')}</div> :
                                                <>
                                                    {!!+data?.overall?.discount?.free_shipping || !+delivery.price || (+data?.overall?.products_value >= +data?.shopInfo?.free_shipping_price && !!+data?.shopInfo?.free_shipping_active) ?
                                                        <div
                                                            className="data">{getLangText('freeLabel')}</div> :
                                                        <div
                                                            className="data">{getPriceWithCurrency(delivery.price)}</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {!!+delivery.is_inpost &&
                                            <div className="button-place ml-auto flex items-center mt-0">
                                                {(+data?.shopInfo?.id === TIM_SHOP_ID || +data?.shopInfo?.id === TEST_ACCOUNT_SHOP_ID) ?
                                                    <img
                                                        style={{height: 35}}
                                                        className="sm:mr-2 rounded-sm"
                                                        src="/assets/images/inpost-tim.jpg"
                                                        alt="inpost"
                                                    /> : <img
                                                        style={{height: 30}}
                                                        className="sm:mr-2"
                                                        src="/assets/images/inpost.svg"
                                                        alt="inpost"
                                                    />
                                                }
                                                <div className="sm:block hidden">
                                                    <div className="button border-button btn-change">
                                                        {getLangText('selectPoint')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!!+delivery.is_mondial &&
                                            <div className="button-place ml-auto flex items-center mt-0">
                                                <img
                                                    style={{height: 22}}
                                                    className="sm:mr-2"
                                                    src="/assets/images/svg/logomondial-relay.svg"
                                                    alt="dpd"
                                                />
                                                <div className="sm:block hidden">
                                                    <div className="button border-button btn-change">
                                                        {getLangText('selectPoint')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!!+delivery.is_orlen &&
                                            <div className="button-place ml-auto flex items-center mt-0">
                                                <img
                                                    style={{height: 32}}
                                                    className="sm:mr-2"
                                                    src="/assets/images/svg/orlen-paczka.svg"
                                                    alt="dpd"
                                                />
                                                <div className="sm:block hidden">
                                                    <div className="button border-button btn-change">
                                                        {getLangText('selectPoint')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!!+delivery.is_dpd &&
                                            <div className="button-place ml-auto flex items-center mt-0">
                                                <img
                                                    className="sm:mr-2 w-[120px] sm:w-[100px]"
                                                    src="/assets/images/svg/dpd-pick-up-logo.svg"
                                                    alt="dpd"
                                                />
                                                <div className="sm:block hidden">
                                                    <div className="button border-button btn-change">
                                                        {getLangText('selectPointOrMachine')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!!+delivery.is_gls &&
                                            <div className="button-place ml-auto flex items-center mt-0">
                                                <img
                                                    style={{height: 18}}
                                                    className="sm:mr-2"
                                                    src="/assets/images/gls.png"
                                                    alt="dpd"
                                                />
                                                <div className="sm:block hidden">
                                                    <div className="button border-button btn-change">
                                                        {getLangText('selectPoint')}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {!!+delivery.is_dpd &&
                                        <DpdPickupLogos/>
                                    }
                                    {(!!+delivery.is_inpost || !!+delivery.is_mondial || !!+delivery.is_orlen || !!+delivery.is_dpd || !!+delivery.is_gls) &&
                                        <div className="mx-[15px] sm:mx-[20px] mb-[12px] sm:mt-0 -mt-2 sm:hidden">
                                            <button
                                                type="button"
                                                onClick={() => onInputClick(delivery)}
                                                style={{width: '100%', paddingTop: 8, paddingBottom: 8}}
                                                className="button border-button btn-change"
                                            >
                                                {getLangText(!!+delivery.is_dpd ? 'selectPointOrMachine' : 'selectPoint')}
                                            </button>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </section>
                    <section className="mb-0">
                        {!!values.shipping && values.pointMachineCode && showSelectedPoint &&
                            <SelectedPointMachineBox
                                values={values}
                                setActiveShippingId={setActiveShippingId}
                                setIsPointModalOpen={setIsPointModalOpen}
                            />
                        }
                    </section>
                </>
                :
                <div className="form-info-box align-items-center w-100 mb-0 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('customerBasketNoDeliveryMethodsLabel')}</div>
                    </div>
                </div>
            }
        </>
    );
};

export default DeliveryType;
