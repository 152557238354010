import Model from '../../../src_shared/modules/model/Model';
import ValidationError from '../../../src_shared/form/validation/ValidationError';

class SettingsPostaBezHranicModel extends Model {
    getModel() {
        return {
            user_id: '',
            api_key: '',
            active: '1',
        };
    }

    getValidators() {
        return {
            user_id: ValidationError.notEmpty,
            api_key: ValidationError.notEmpty,
            active: ValidationError.skip,
        };
    }
}

export default new SettingsPostaBezHranicModel();
