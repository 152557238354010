import Composition from "../../../../../shared/helpers/Composition";
import createReducer from "../../../../../shared/helpers/createReducer";
import ListReducer from "../../../../../../../src_shared/list/ListReducer";

export const getStateRoot = (state) => state.marketing.facebookMarketing.statisticsDetails;
export const prefix = 'MARKETING_FACEBOOK_STATISTICS_DETAILS_LIST_';

const getInitState = () => ListReducer.getInitState({
	waiting: true,
	items: {},
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

