import {combineReducers} from "redux";
import filtersReducer from "./filters/filtersReducer";
import marketingFacebookClientsReducer from "./clients/marketingFacebookClientsReducer";
import marketingFacebookAdsReducer from "./ads/marketingFacebookAdsReducer";

export default combineReducers({
	ads: marketingFacebookAdsReducer,
	clients: marketingFacebookClientsReducer,
	filters: filtersReducer,
});
