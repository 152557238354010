import useLang from '../../../../../../../../../src_shared/hooks/useLang';

const MessageContentWarning = () => {
    const { getLangText } = useLang();

    return (
        <div className="flex gap-2 bg-[#FEF2F2] rounded-[8px] p-3.5 mt-3">
            <div>
                <i className="icon-info-c text-[#F87171] text-[20px]" />
            </div>
            <div>
                <p className="text-[#981B1B] text-sm font-bold">
                    {getLangText('messageContainsSpecialChars')}
                </p>
                <p className="text-[#C64846] text-[14px] mt-1 font-medium">
                    {getLangText('diacriticalMarks')}
                </p>
            </div>
        </div>
    );
};

export default MessageContentWarning;
