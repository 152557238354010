import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import useLang from "../../../../../src_shared/hooks/useLang";
import useCountActionsRequired from "../../../dashboard/components/actionsToDo/useCountActionsRequired";

const RequiredActionsAside = ({closeMobileMenu, subMenuOpen}) => {
    const {getLangText} = useLang();
    const {isAllActive, chartPath, text} = useCountActionsRequired();

    if (isAllActive) return null

    return (
        <Link
            onClick={closeMobileMenu}
            to={RouterPaths.Dashboard}
            // style={{padding: 4, paddingLeft: 7}}
            className={`bg-[#fff] rounded-[5px] flex items-center p-[2px] pl-[6px] hover:no-underline transition-all border mt-3 ${subMenuOpen ? 'delay-200 border-[#DFE1E4]' : 'border-transparent'}`}
        >
            <div
                className="w-[35px] h-[35px] relative">
                <div
                    className="absolute font-bold text-xs -tracking-[0.8px] text-[#000] whitespace-nowrap left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 ">
                    {text}
                </div>
                <img
                    src={chartPath}
                    style={{display: 'block'}}
                    className="w-full h-full"
                    alt=""/>
            </div>
            <div className="font-bold ml-2">
                <div className="text-[#000] text-sm">
                    {getLangText('requiredActions')}
                </div>
                <div className="text-[11px] text-desc font-medium flex items-center">
                    &nbsp;{getLangText('increaseCapabilitiesOfSelmo')}
                </div>
            </div>
        </Link>
    );
};

export default RequiredActionsAside;