import React, { useRef, useState } from 'react';
import TemplatesDropdown from '../../../../shared/templatesDropdown/TemplatesDropdown';
import { useClickOutside } from '../../../../../../src_shared/hooks/useClickOutside';

const MessageTemplates = ({ setInputValue, textAreaRef }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useClickOutside(dropdownRef, () => setShowDropdown(false));

    return (
        <div ref={dropdownRef} className="relative">
            <button
                type="button"
                className="icon-btn mr-2"
                onClick={() => setShowDropdown((prevState) => !prevState)}>
                <i className="icon-chat1" />
            </button>
            {showDropdown && (
                <TemplatesDropdown
                    setInputValue={setInputValue}
                    show={showDropdown}
                    hide={() => setShowDropdown(false)}
                    textAreaRef={textAreaRef}
                    type="messenger"
                />
            )}
        </div>
    );
};

export default MessageTemplates;
