import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import useAssetsLang from "../../../../shared/helpers/useAssetsLang";

const DoItOnMobilePhone = ({setShowMobilePermissionsModal}) => {
    const {getLangText} = useLang();
    const lang = useAssetsLang();
    return (
        <div className="p-3 rounded-[10px] bg-[#F6FCFF] border border-[#D7EAFE] flex items-center mb-3">
            <div className="">
                <div className="text-lg text-[#101827] font-bold">{getLangText('addPostsInSelmoApp')}</div>
                <div className="text-sm text-[#4B5563] font-medium">{getLangText('sendVideoDirectlyFromMobilePhone')}</div>
                <button
                    onClick={() => setShowMobilePermissionsModal(true)}
                    type="button"
                    className="button border-button left-icon small-size mt-2">
                    <span className="text-xs">{getLangText('findOutMoreButton')}</span>
                </button>
            </div>
            <div className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-3.5">
                <button
                    type="button"
                    onClick={() => setShowMobilePermissionsModal(true)}
                    className={`relative block h-[100px] w-full sm:w-[100px] cursor-pointer rounded-[10px] overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.3)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full`}>
                    <img
                        className="h-full w-full sm:w-auto object-cover"
                        src={`/assets/images/multiLanguage/${lang}/salesPosts/mobile-mini.webp`}
                        alt="preview"
                    />
                    <div
                        className="absolute z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[30px] h-[30px] bg-[white] rounded-full flex items-center justify-center">
                        <img
                            style={{width: '10px'}}
                            className="pointer-events-none  group-hover:scale-[115%] transition-all"
                            src="/assets/images/svg/play-black.svg"
                            alt="play"
                        />
                    </div>
                </button>

            </div>
        </div>
    );
};

export default DoItOnMobilePhone;
