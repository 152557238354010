import {combineReducers} from "redux";
import marketingFacebookAdvertisementsReducer from "./advertisements/marketingFacebookAdvertisementsReducer";
import statisticsListReducer from "./statistics/listReducer";
import statisticsDetailsListReducer from "./statistics/details/listReducer";
import filtersReducer from "./filters/filtersReducer";


export default combineReducers({
	advertisements: marketingFacebookAdvertisementsReducer,
	statistics: statisticsListReducer,
	statisticsDetails: statisticsDetailsListReducer,
	filters: filtersReducer,
});
