import React, {useEffect, useMemo, useState} from "react";
import Action from "./Action";
import {useSelector} from "react-redux";

const Actions = ({data, actions}) => {
    const [open, setOpen] = useState(null)
    const {userData} = useSelector((state) => state.session);

    useEffect(() => {
        if (data?.clientPoints) {
            setOpen(actions.find((i) => !data.clientPoints[i.id])?.id || null)
        }

    }, [data.clientPoints])

    return (
        <div className="mt-3.5 space-y-2">
            {actions.map((step) => (
                <Action
                    key={step.id}
                    title={step.title}
                    desc={step.desc}
                    buttonLink={step.buttonLink}
                    buttonTitle={step.buttonTitle}
                    checked={userData.clientPoints[step.id]}
                    videoStatsName={step.videoStatsName}
                    previewSrc={step.previewSrc}
                    setOpen={setOpen}
                    open={open}
                    id={step.id}
                    actionLabel={step.actionLabel}
                    time={step.time}
                />
            ))}
        </div>
    );
};

export default Actions;