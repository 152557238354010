import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import SetUnreadButton from "../setUnreadButton";
import SetConversationStatusButton from "../setConversationStatusButton";
import OrderModal from "../../../orderModal/OrderModal";
import AddOrderModal from "../../../addOrderModal/addOrderModal";
import CustomDropdown from "../../../../live/shared/Dropdown";
import {USER_PERMISSIONS_MESSENGER_ORDERS_MENAGE} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const MobileChatHeaderDropdown = ({activeConversation, hideRightSide, messageScrollRef, lastId, setStatusVisible, userData}) => {
    const {getLangText} = useLang();
    const [open, setOpen] = useState(false)

    return (
        <CustomDropdown
            visible={open}
            setVisible={setOpen}
        >
            <SetUnreadButton activeConversation={activeConversation}/>
            {activeConversation.conversation_status === '4' ?
                <SetConversationStatusButton
                    tooltipLang={getLangText('moveToMainFolderLabel')}
                    icon="icon-cross"
                    statusNumber="0"
                    withRedirect
                    setVisibleRightSide={hideRightSide}
                /> :
                <SetConversationStatusButton
                    tooltipLang={getLangText('moveToReadyFolderLabel')}
                    icon="icon-tick"
                    statusNumber="4"
                    withRedirect
                    setVisibleRightSide={hideRightSide}
                />
            }
            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_MESSENGER_ORDERS_MENAGE]}>
                {userData.ecommerce_orders !== 'SHOPIFY' &&
                    <OrderModal activeConversation={activeConversation}/>
                }
                <AddOrderModal
                    setStatusVisible={setStatusVisible}
                    lastId={lastId}
                    activeConversation={activeConversation}
                    messageScrollRef={messageScrollRef}
                />
            </UserPermissionsWrapper>
        </CustomDropdown>
    )
};

export default MobileChatHeaderDropdown;
