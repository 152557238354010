import React, { useEffect, useState } from 'react';
import useForm from '../../../../../src_shared/form/useForm';
import FormActions from './FormActions.js';
import Loader from '../../../shared/Loader';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { RouterPaths } from '../../../../routes/RouterPath';
import DeliveryAddressForm from '../deliveryAddress/Form';
import DeliveryAddressFormActions from '../deliveryAddress/FormActions';
import DeliveryTypeFormActions from '../deliveryType/FormActions';
import PaymentTypeFormActions from '../paymentType/FormActions';
import CurrencyTypeFormActions from '../currencyType/FormActions';
import PackagingTypeFormActions from '../packagingType/FormActions';
import EditProductsFormActions from '../editProducts/FormActions';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateRoot as getDeliveryAddressStateRoot } from '../deliveryAddress/formReducer';
import { getStateRoot as getDeliveryTypeStateRoot } from '../deliveryType/formReducer';
import { getStateRoot as getPaymentTypeStateRoot } from '../paymentType/formReducer';
import { getStateRoot as getCurrencyTypeStateRoot } from '../currencyType/formReducer';
import { getStateRoot as getPackagingTypeStateRoot } from '../packagingType/formReducer';
import DeliveryTypeForm from '../deliveryType/Form';
import PaymentTypeForm from '../paymentType/Form';
import RemoveOrder from '../removeOrder/RemoveOrder';
import InpostLabelFrom from '../inpostLabel/Form';
import SendEmailForm from '../sendEmailForm/SendEmailForm';
import Products from '../products/Products';
import FurgonetkaLabelFrom from '../furgonetkaLabel/Form';
import OrderHeader from '../components/orderHeader/OrderHeader';
import ClientInfo from '../components/clientInfo/ClientInfo';
import ClientLink from '../components/clientLink/ClientLink';
import {
    getCorrectOrderPaymentName,
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_BM_TRANSFER,
    ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_PAYPAL_AUTOMATIC,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER,
    ORDER_PAYMENT_PAYPO,
} from '../../../shared/enums/PaymentStatuses';
import FakturowniaLabelForm from '../fakturowniaLabel/Form';
import moment from 'moment';
import CurrencyTypeForm from '../currencyType/Form';
import { useUnitPrice } from '../../../shared/helpers/Price';
import OrderNotFound from './OrderNotFound';
import PackagingTypeForm from '../packagingType/Form';
import SendVoiceMessage from '../sendVoiceMessage/SendVoiceMessage';
import MobileToggle from '../components/MobileToggle';
import PermissionsWrapper from '../../../../routes/PermissionsWrapper';
import { FAKTUROWNIA_PERMISSION } from '../../../shared/enums/SelmoPermissions';
import FunctionalityModal from '../../../payments/functionalityModal/Form';
import DiscountCode, {
    getDiscountCodeValue,
} from '../../../shared/components/discountCode/DiscountCodeInput';
import useLang from '../../../../../src_shared/hooks/useLang';
import {
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_SK,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_DA,
    SHOP_ORIGIN_US,
    SHOP_ORIGIN_CZ,
} from '../../../shared/enums/ShopOrigins';
import ShippyproLabelForm from '../shippyproLabel/Form';
import {
    ANGEL_GLADYS_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID,
    MODANOEMI_SHOP_ID,
    MODANOEMI_V2_SHOP_ID,
    OLI_ELEGANCJA,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID,
    WIKA_EWA_FASHION_ID,
} from '../../../../../src_shared/enums/TrustedShopsIds';
import PaxyLabel from '../paxyLabel/Form';
import MondialLabel from '../mondialLabel/Form';
import OrlenLabel from '../orlenLabel/Form';
import SelectedPointMachineBox from './components/SelectedPointMachineBox';
import ShelfInfo from '../shelfInfo/ShelfInfo';
import OpenCartSurcharges from './components/OpenCartSurcharges';
import ShipmondoLabel from '../shipmondoLabel/Form';
import DpdLabel from '../dpdLabel/Form';
import OrderHistory from '../orderHistory/OrderHistory';
import GlsLabel from '../glsLabel/Form';
import { USER_PERMISSIONS_ORDERS_STATISTICS_VISIBILITY } from '../../../shared/enums/UserPermissionsTypes';
import UserPermissionsWrapper from '../../../../routes/UserPermissionsWrapper';
import MrAutomateStopped from './components/MrAutomateStopped';
import SelmoPaczka from '../selmoPaczka/SelmoPaczka';
import { convertToUserTimeZone } from '../../../shared/helpers/dateHelpers';
import RefundPaymentButton from './components/RefundPaymentButton';
import StopAutomation from '../stopAutomation/StopAutomation';
import GlsFranceLabel from '../glsFranceLabel/Form';
import AssignEmployee from '../assignEmployee/AssignEmployee';
import ColissimoLabel from '../colissimoLabel/Form';
import UspsLabel from '../uspsLabel/Form';
import PostaBezHranicLabel from '../postaBezHranicLabel/Form';

const OrderForm = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { getLangText } = useLang();
    const [showFakturowniaModalVisible, setShowFakturowniaModalVisible] =
        useState(false);
    const { userData } = useSelector((state) => state.session);
    const canAddProducts =
        +userData.id !== CHIQUE_SHOP_ID &&
        +userData.id !== WIKA_EWA_FASHION_ID &&
        +userData.id !== BUTIK_MADAME_SHOP_ID;
    const timKurierBoxId = 19812;

    const {
        data: { values, setValue },
        form: { isWaiting, deleteWithoutAsk, loadData },
        modal,
    } = useForm({
        FormActions,
        params,
    });

    const showDeliveryAddressModal = () => {
        batch(() => {
            dispatch(
                DeliveryAddressFormActions.data.updateValues({
                    id: values.id,
                    first_name: values.shippingData.first_name,
                    last_name: values.shippingData.last_name,
                    email: values.shippingData.email,
                    street: values.shippingData.street,
                    building_number: values.shippingData.building_number,
                    country: values.shippingData.country,
                    country_name: values.shippingData.country_name,
                    phone: values.shippingData.phone,
                    zip_code: values.shippingData.zip_code,
                    city: values.shippingData.city,
                    extra_description: values.shippingData.extra_description,
                    nip: values.shippingData.nip,
                    want_invoice: !!values.shippingData.nip,
                    only_digital: values.only_digital,
                    origin: userData.origin,
                    state: values.shippingData.state,
                    state_name: values.shippingData.state_name,
                }),
            );
            dispatch(DeliveryAddressFormActions.modal.showModal());
        });
    };
    const showDeliveryTypeModal = () => {
        batch(() => {
            dispatch(
                DeliveryTypeFormActions.data.updateValues({
                    shipping: values.shippingData.shipping,
                    pointMachineCode: values.pointMachineCode,
                    pointMachineStreet: values.pointMachineStreet,
                    pointMachineCity: values.pointMachineCity,
                    pointMachineType: values.pointMachineType,
                    pointMachineName: values.pointMachineName,
                }),
            );
            dispatch(DeliveryTypeFormActions.modal.showModal());
        });
    };
    const showPaymentTypeModal = () => {
        batch(() => {
            dispatch(
                PaymentTypeFormActions.data.updateValues({
                    id: values.id,
                    transfer_account_id:
                        values.shippingData.transfer_account_id,
                    payment: values.shippingData.payment,
                }),
            );
            dispatch(PaymentTypeFormActions.modal.showModal());
        });
    };

    const showCurrencyTypeModal = () => {
        batch(() => {
            dispatch(
                CurrencyTypeFormActions.data.updateValues({
                    id: values.id,
                    currency: values.currency.id,
                }),
            );
            dispatch(CurrencyTypeFormActions.modal.showModal());
        });
    };

    const showPackagingTypeModal = () => {
        batch(() => {
            dispatch(
                PackagingTypeFormActions.data.updateValues({
                    id: values.id,
                    package_type_id: values.chosen_package_type.id,
                }),
            );
            dispatch(PackagingTypeFormActions.modal.showModal());
        });
    };

    const [
        deliveryAddressModalVisible,
        deliveryTypeModalVisible,
        paymentTypeModalVisible,
        currencyTypeModalVisible,
        packagingTypeModalVisible,
    ] = useSelector(
        createSelector(
            getDeliveryAddressStateRoot,
            getDeliveryTypeStateRoot,
            getPaymentTypeStateRoot,
            getCurrencyTypeStateRoot,
            getPackagingTypeStateRoot,
            (x1, x2, x3, x4, x5) => [
                x1.modalVisible,
                x2.modalVisible,
                x3.modalVisible,
                x4.modalVisible,
                x5.modalVisible,
            ],
        ),
    );

    const orderPaymentMethod =
        values.shippingData.payment &&
        (getLangText(
            getCorrectOrderPaymentName(values?.shippingData?.payment),
        ) ||
            values.shippingData.payment_name);

    const { getPrice } = useUnitPrice();
    const getOrderPrice = (amountPrice) =>
        getPrice(amountPrice, values?.currency?.text);

    const NoPermissionFakturownia = () => (
        <section className="summary-section mb-3">
            <div className="section-header d-block mb-3">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h2>
                            {userData.origin === SHOP_ORIGIN_FR &&
                            +userData.id !== ANGEL_GLADYS_SHOP_ID ? (
                                <a
                                    href="https://vosfactures.fr/?partner=kaaubv"
                                    target="_blank"
                                    rel="nofollow noreferrer">
                                    vosfactures.fr
                                </a>
                            ) : (
                                <a
                                    href="https://fakturownia.pl/?partner=kaaubv"
                                    target="_blank"
                                    rel="nofollow noreferrer">
                                    Fakturownia.pl
                                </a>
                            )}
                        </h2>
                    </div>
                    <div>
                        {userData.origin === SHOP_ORIGIN_FR &&
                        +userData.id !== ANGEL_GLADYS_SHOP_ID ? (
                            <a
                                href="https://vosfactures.fr/?partner=kaaubv"
                                target="_blank"
                                rel="nofollow noreferrer">
                                <img
                                    style={{ height: '24px' }}
                                    src="/assets/images/fakturownia-logo-fr.png"
                                    alt="VosFactures"
                                />
                            </a>
                        ) : (
                            <a
                                rel="nofollow noreferrer"
                                href="https://fakturownia.pl/?partner=kaaubv"
                                target="_blank">
                                <img
                                    style={{ height: '24px' }}
                                    src="/assets/images/fakturownia-logo.svg"
                                    alt="Fakturownia"
                                />
                            </a>
                        )}
                    </div>
                </div>
                <div className="section-header__description">
                    {getLangText('automaticallySalesDocumentLabel')}
                </div>
            </div>
            {!!userData.user_info.premium_date ? (
                <button
                    className="button primary small-size w-100"
                    type="button"
                    onClick={() => setShowFakturowniaModalVisible(true)}>
                    {getLangText('purchaseAccessLabel')}
                </button>
            ) : (
                <a
                    className="button primary small-size w-100 text-center"
                    href={RouterPaths.Payments}>
                    {getLangText('purchaseAccessLabel')}
                </a>
            )}
        </section>
    );

    const isFreeShipping =
        +values.all_products_price >= +userData.free_shipping_price &&
        userData.free_shipping_active === '1' &&
        values.is_open_cart !== '1';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${getLangText('customerBasketOrderLabel')} ${values.shop_order_id} - Selmo`}</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.OrdersList}>
                            {getLangText('customerBasketOrderLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>
                            {getLangText('customerBasketOrderLabel')}{' '}
                            {values.shop_order_id}
                        </div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.OrdersList}
                    className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
                    <i className="icon-arrow-left" />
                    {getLangText('backToOrdersLabel')}
                </Link>
            </div>
            {values.notFound ? (
                <OrderNotFound />
            ) : (
                <div className={`loader-parent`}>
                    <Loader className="sticky-content" isLoading={isWaiting} />
                    <div className="order-details-wrapper">
                        <div>
                            <div className="details-section">
                                <OrderHeader
                                    setValue={setValue}
                                    values={values}
                                    userData={userData}
                                    fetchData={() =>
                                        loadData(values.shop_order_id)
                                    }
                                />
                                {values.shippingData.payment ===
                                    ORDER_PAYMENT_TRANSFER && (
                                    <div className="form-info-box orange w-100">
                                        <div>
                                            <i className="icon-info-c" />
                                        </div>
                                        <div>
                                            <div className="title">
                                                {getLangText(
                                                    'standardPaymentTransferLabel',
                                                )}
                                            </div>
                                            <div className="desc">
                                                {getLangText(
                                                    'changeStatusIfFoundsOnAccountLabel',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {values.shippingData.payment ===
                                    ORDER_PAYMENT_BM_TRANSFER &&
                                    !values.productsPayment.length && (
                                        <div className="form-info-box orange w-100">
                                            <div>
                                                <i className="icon-info-c" />
                                            </div>
                                            <div>
                                                <div className="title">
                                                    {getLangText(
                                                        'standardAutomationPaymentTransferLabel',
                                                    )}
                                                </div>
                                                <div className="desc">
                                                    {getLangText(
                                                        'statusWillChangeAutomatically',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {values.shippingData.payment ===
                                    ORDER_PAYMENT_PAYPAL && (
                                    <div className="form-info-box orange w-100">
                                        <div>
                                            <i className="icon-info-c" />
                                        </div>
                                        <div>
                                            <div className="title">
                                                {getLangText(
                                                    'paypalPaymentLabel',
                                                )}
                                            </div>
                                            <div className="desc">
                                                {getLangText(
                                                    'changeStatusIfFoundsOnAccountLabel',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(values.shippingData.payment ===
                                    ORDER_PAYMENT_BLIK ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_PAYPO ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_BM_TRANSFER ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_BLUEMEDIA ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_STRIPE_CARD ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_MB_WAY ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_MOBILEPAY ||
                                    values.shippingData.payment ===
                                        ORDER_PAYMENT_PAYPAL_AUTOMATIC ||
                                    values.shippingData.payment?.includes(
                                        'stripe_',
                                    )) &&
                                    !!values.productsPayment.length && (
                                        <div className="transactions-history mt-lg-3 mt-2">
                                            <div>
                                                <i className="icon-tick-c" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="title">
                                                    {values.shippingData
                                                        .payment ===
                                                    ORDER_PAYMENT_BM_TRANSFER
                                                        ? getLangText(
                                                              'paidByAutomaticTransferLabel',
                                                          )
                                                        : values.shippingData
                                                                .payment ===
                                                            ORDER_PAYMENT_STRIPE_CARD
                                                          ? getLangText(
                                                                'cardPaymentLabel',
                                                            )
                                                          : getLangText(
                                                                'paidByTransferOnlineLabel',
                                                            )}
                                                </div>
                                                <div className="histories">
                                                    {values.productsPayment.map(
                                                        (i) => (
                                                            <div
                                                                className="group"
                                                                key={i.date}>
                                                                <span>
                                                                    {convertToUserTimeZone(
                                                                        i.date,
                                                                    ).format(
                                                                        'DD.MM.YYYY',
                                                                    )}
                                                                </span>
                                                                <span className="grow">
                                                                    {convertToUserTimeZone(
                                                                        i.date,
                                                                    ).format(
                                                                        'HH:mm:ss',
                                                                    )}
                                                                </span>
                                                                {(values
                                                                    .shippingData
                                                                    .payment ===
                                                                    ORDER_PAYMENT_STRIPE_CARD ||
                                                                    values.shippingData?.payment?.includes(
                                                                        'stripe_',
                                                                    )) && (
                                                                    <RefundPaymentButton
                                                                        loadData={() =>
                                                                            loadData(
                                                                                values.shop_order_id,
                                                                            )
                                                                        }
                                                                        id={
                                                                            i.id
                                                                        }
                                                                    />
                                                                )}
                                                                <strong className="ml-0">
                                                                    {getOrderPrice(
                                                                        i.amount,
                                                                    )}
                                                                </strong>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                                <div className="total">
                                                    <div className="label">
                                                        {getLangText(
                                                            'totalLabel',
                                                        )}
                                                    </div>
                                                    <strong>
                                                        {getOrderPrice(
                                                            values.productsPaymentTotal,
                                                        )}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {!!+values.mr_automate_paused && (
                                    <MrAutomateStopped
                                        values={values}
                                        id={values.id}
                                        loadData={() =>
                                            loadData(values.shop_order_id)
                                        }
                                    />
                                )}
                                <div className="products-wrapper">
                                    <section className="mb-lg-3">
                                        <ClientLink values={values} />
                                        <MobileToggle
                                            title={getLangText(
                                                'customerBasketDeliveryLabel',
                                            )}
                                            onEditClick={
                                                showDeliveryAddressModal
                                            }>
                                            <div className="order-summary__prices">
                                                <div className="section-header">
                                                    <h2>
                                                        {getLangText(
                                                            'deliveryAddressLabel',
                                                        )}
                                                    </h2>
                                                    <button
                                                        className="button border-button big-width w-xs-100"
                                                        onClick={
                                                            showDeliveryAddressModal
                                                        }>
                                                        <span className="d-lg-block d-none">
                                                            {getLangText(
                                                                'editDeliveryDataLabel',
                                                            )}
                                                        </span>
                                                        <span className="d-lg-none d-block">
                                                            {getLangText(
                                                                'editButton',
                                                            )}
                                                        </span>
                                                    </button>
                                                    {deliveryAddressModalVisible && (
                                                        <DeliveryAddressForm
                                                            data={values}
                                                        />
                                                    )}
                                                </div>
                                                {values.shippingData.email ? (
                                                    <div className="section-value">
                                                        <div>
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .first_name
                                                            }{' '}
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .last_name
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .email
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .street
                                                            }{' '}
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .building_number
                                                            }
                                                        </div>
                                                        {userData.origin ===
                                                        SHOP_ORIGIN_US ? (
                                                            <div>
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .city
                                                                }
                                                                ,{' '}
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .state_name
                                                                }{' '}
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .zip_code
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .zip_code
                                                                }{' '}
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .city
                                                                }
                                                            </div>
                                                        )}
                                                        {values.shippingData
                                                            .country &&
                                                            values.shippingData
                                                                .country_name}
                                                        <div className="mt-2">
                                                            {
                                                                values
                                                                    .shippingData
                                                                    .phone
                                                            }
                                                        </div>
                                                        {!!+values.extra_input_enabled &&
                                                            values.shippingData
                                                                .extra_description && (
                                                                <div className="mt-2">
                                                                    {
                                                                        values
                                                                            .shippingData
                                                                            .extra_description
                                                                    }
                                                                </div>
                                                            )}
                                                        {!!values.shippingData
                                                            .nip && (
                                                            <div className="mt-2">
                                                                NIP:{' '}
                                                                {
                                                                    values
                                                                        .shippingData
                                                                        .nip
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                                                        <div>
                                                            <i className="icon-info-c" />
                                                        </div>
                                                        <div>
                                                            <div className="title">
                                                                {getLangText(
                                                                    'noDeliveryDataLabel',
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {(+values.shop_id === 5 ||
                                                +values.shop_id === 322 ||
                                                +values.shop_id === 470) && (
                                                <div className="order-summary__prices">
                                                    <div className="section-header">
                                                        <h2>
                                                            {getLangText(
                                                                'packedTypeLabel',
                                                            )}
                                                        </h2>
                                                        <button
                                                            onClick={
                                                                showPackagingTypeModal
                                                            }
                                                            className="button border-button big-width w-xs-100">
                                                            <span className="d-lg-block d-none">
                                                                {getLangText(
                                                                    'editPackedTypeLabel',
                                                                )}
                                                            </span>
                                                            <span className="d-lg-none d-block">
                                                                {getLangText(
                                                                    'editButton',
                                                                )}
                                                            </span>
                                                        </button>
                                                        {packagingTypeModalVisible && (
                                                            <PackagingTypeForm />
                                                        )}
                                                    </div>
                                                    {values.chosen_package_type
                                                        .id ? (
                                                        <div className="section-value">
                                                            <div>
                                                                {
                                                                    values
                                                                        .chosen_package_type
                                                                        .name
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                                                            <div>
                                                                <i className="icon-info-c" />
                                                            </div>
                                                            <div>
                                                                <div className="title">
                                                                    {getLangText(
                                                                        'noSelectedPackedTypeLabel',
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {!values.only_digital && (
                                                <div className="order-summary__prices">
                                                    <div className="section-header">
                                                        <h2>
                                                            {getLangText(
                                                                'deliveryMethodLabel',
                                                            )}
                                                        </h2>
                                                        <button
                                                            onClick={
                                                                showDeliveryTypeModal
                                                            }
                                                            className="button border-button big-width w-xs-100">
                                                            <span className="d-lg-block d-none">
                                                                {getLangText(
                                                                    'editDeliveryMethodLabel',
                                                                )}
                                                            </span>
                                                            <span className="d-lg-none d-block">
                                                                {getLangText(
                                                                    'editButton',
                                                                )}
                                                            </span>
                                                        </button>
                                                        {deliveryTypeModalVisible && (
                                                            <DeliveryTypeForm
                                                                getPrice={
                                                                    getOrderPrice
                                                                }
                                                                data={values}
                                                            />
                                                        )}
                                                    </div>
                                                    {values.shippingData
                                                        .shipping_name ? (
                                                        <>
                                                            <div className="section-value">
                                                                {!!+values
                                                                    ?.discount_code_info
                                                                    .free_shipping ||
                                                                (+values.all_products_price >=
                                                                    +userData.free_shipping_price &&
                                                                    userData.free_shipping_active ===
                                                                        '1' &&
                                                                    ((values.is_open_cart ===
                                                                        '1' &&
                                                                        +values
                                                                            .orderProductsExtra[0]
                                                                            ?.totalPrice >=
                                                                            +userData.free_shipping_price) ||
                                                                        values.is_open_cart !==
                                                                            '1')) ? (
                                                                    <>
                                                                        <div>
                                                                            {
                                                                                values
                                                                                    .shippingData
                                                                                    .shipping_name
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {getLangText(
                                                                                'customerBasketFreeLabel',
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            {
                                                                                values
                                                                                    .shippingData
                                                                                    .shipping_name
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {getOrderPrice(
                                                                                values
                                                                                    .shippingData
                                                                                    .shipping_price,
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <SelectedPointMachineBox
                                                                values={values}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                                                            <div>
                                                                <i className="icon-info-c" />
                                                            </div>
                                                            <div>
                                                                <div className="title">
                                                                    {getLangText(
                                                                        'noSelectedDeliveryMethodLabel',
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <div className="order-summary__prices">
                                                <div className="section-header">
                                                    <h2>
                                                        {getLangText(
                                                            'paymentMethodLabel',
                                                        )}
                                                    </h2>
                                                    <button
                                                        onClick={
                                                            showPaymentTypeModal
                                                        }
                                                        className="button border-button big-width w-xs-100">
                                                        <span className="d-lg-block d-none">
                                                            {getLangText(
                                                                'editPaymentMethodLabel',
                                                            )}
                                                        </span>
                                                        <span className="d-lg-none d-block">
                                                            {getLangText(
                                                                'editButton',
                                                            )}
                                                        </span>
                                                    </button>
                                                    {paymentTypeModalVisible && (
                                                        <PaymentTypeForm
                                                            getPrice={
                                                                getOrderPrice
                                                            }
                                                            data={values}
                                                        />
                                                    )}
                                                </div>
                                                {orderPaymentMethod &&
                                                (values.shippingData
                                                    .shipping_name ||
                                                    values.only_digital) ? (
                                                    <div className="section-value">
                                                        <div>
                                                            {orderPaymentMethod}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                                                        <div>
                                                            <i className="icon-info-c" />
                                                        </div>
                                                        <div>
                                                            <div className="title">
                                                                {getLangText(
                                                                    'noSelectedPaymentMethod',
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {!+values.bm_active &&
                                                    userData.origin ===
                                                        SHOP_ORIGIN_PL && (
                                                        <a
                                                            href={
                                                                RouterPaths.SettingsPaymentsOnlineLanding
                                                            }
                                                            target="_blank"
                                                            className="flex items-center bg-[#F0FAFF] rounded-[8px] p-2 mt-3 hover:no-underline group transition-all hover:border-[#def4ff] border border-transparent"
                                                            rel="noreferrer">
                                                            <div className="w-[56px] h-[56px] rounded-[8px] flex items-center justify-center bg-[#fff] mr-[12px] shadow-default">
                                                                <img
                                                                    alt="blik"
                                                                    className="m-auto"
                                                                    style={{
                                                                        height: '20px',
                                                                    }}
                                                                    src="/assets/images/payments/blik.svg"
                                                                />
                                                            </div>
                                                            <div className="text-sm text-[#3A5089]">
                                                                <div className="font-bold text-[#0D3364]">
                                                                    {getLangText(
                                                                        'getPaymentInstantly',
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className="font-medium"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: getLangText(
                                                                            'activeAutoPayToChangeStatusAutomatically',
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="button primary small-size ml-auto group-hover:bg-[#1c4ed8]">
                                                                {getLangText(
                                                                    'activateLabel',
                                                                )}
                                                            </div>
                                                        </a>
                                                    )}
                                            </div>
                                            <div className="order-summary__prices">
                                                <div className="section-header">
                                                    <h2>
                                                        {getLangText(
                                                            'orderCurrencyLabel',
                                                        )}
                                                    </h2>
                                                    <button
                                                        onClick={
                                                            showCurrencyTypeModal
                                                        }
                                                        className="button border-button big-width w-xs-100">
                                                        <span className="d-lg-block d-none">
                                                            {getLangText(
                                                                'editOrderCurrencyLabel',
                                                            )}
                                                        </span>
                                                        <span className="d-lg-none d-block">
                                                            {getLangText(
                                                                'editButton',
                                                            )}
                                                        </span>
                                                    </button>
                                                    {currencyTypeModalVisible && (
                                                        <CurrencyTypeForm />
                                                    )}
                                                </div>
                                                <div className="section-value">
                                                    <div>
                                                        {values.currency.name}
                                                    </div>
                                                </div>
                                            </div>
                                            {!!userData.discount_codes_enabled && (
                                                <DiscountCode
                                                    isFreeShipping={
                                                        isFreeShipping
                                                    }
                                                    getOrderPrice={
                                                        getOrderPrice
                                                    }
                                                    data={values}
                                                    loadData={loadData}
                                                />
                                            )}
                                        </MobileToggle>
                                    </section>
                                    <MobileToggle
                                        title={getLangText(
                                            'asideProductsLabel',
                                        )}
                                        className="product-mobile-toggle-header">
                                        <button
                                            type="button"
                                            className="button border-button big-width w-lg-auto w-100 d-lg-none mt-3"
                                            onClick={() =>
                                                dispatch(
                                                    EditProductsFormActions.showModal(
                                                        values.shop_order_id,
                                                    ),
                                                )
                                            }>
                                            {getLangText('editProductsLabel')}
                                        </button>
                                        {!!+userData.selmo_pro_active &&
                                            canAddProducts && (
                                                <Link
                                                    to={`${RouterPaths.StorageScanner}/order/${values.id}?clickAction=1&defaultView=2`}
                                                    className="button border-button big-width w-lg-auto w-100 d-lg-none mt-2 left-icon"
                                                    type="button">
                                                    <i className="icon-qr-code" />
                                                    {getLangText('addProducts')}
                                                </Link>
                                            )}
                                        <Products
                                            getTotalPrice={getOrderPrice}
                                            values={values}
                                        />

                                        <div className="order-summary__prices pt-lg-4 pt-0">
                                            <div className="price-row">
                                                <div className="name">
                                                    {getLangText(
                                                        'orderPriceLabel',
                                                    )}
                                                </div>
                                                <div className="value">
                                                    {getOrderPrice(
                                                        values.all_products_price,
                                                    )}
                                                </div>
                                            </div>
                                            {values.shippingData
                                                .shipping_name && (
                                                <div className="price-row shipping-row">
                                                    <div className="name shipping-name">
                                                        {
                                                            values.shippingData
                                                                .shipping_name
                                                        }
                                                    </div>
                                                    {+values.all_products_price >=
                                                        +userData.free_shipping_price &&
                                                    userData.free_shipping_active ===
                                                        '1' &&
                                                    ((values.is_open_cart ===
                                                        '1' &&
                                                        +values
                                                            .orderProductsExtra[0]
                                                            ?.totalPrice >=
                                                            +userData.free_shipping_price) ||
                                                        values.is_open_cart !==
                                                            '1') ? (
                                                        <div className="value shipping-price">
                                                            {getLangText(
                                                                'customerBasketFreeLabel',
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="value shipping-price">
                                                            {getOrderPrice(
                                                                values
                                                                    .shippingData
                                                                    .shipping_price,
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {values.shippingData.payment ===
                                                'pickup' &&
                                                values.shippingData
                                                    .pickup_price > 0 && (
                                                    <div className="price-row shipping-row">
                                                        <div className="name">
                                                            {getLangText(
                                                                'paymentMethodCashOnDeliveryLabel',
                                                            )}
                                                        </div>
                                                        {+values.all_products_price >=
                                                            +userData.free_shipping_price &&
                                                        userData.free_shipping_active ===
                                                            '1' ? (
                                                            <div className="value">
                                                                {getLangText(
                                                                    'customerBasketFreeLabel',
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="value">
                                                                {getOrderPrice(
                                                                    values
                                                                        .shippingData
                                                                        .pickup_price,
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            {values.discount_code_info?.id && (
                                                <div className="price-row">
                                                    <div className="name">
                                                        {getLangText(
                                                            'discountCodeLabel',
                                                        )}
                                                        :
                                                    </div>
                                                    <div className="value">
                                                        {getDiscountCodeValue(
                                                            values,
                                                            getPrice,
                                                            getLangText,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {!!values.payment_commission && (
                                                <div className="price-row">
                                                    <div className="name">
                                                        {getLangText(
                                                            'commissionLabel',
                                                        )}
                                                        :
                                                    </div>
                                                    <div className="value">
                                                        {getOrderPrice(
                                                            values.payment_commission,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {!!+values.payment_service_fee && (
                                                <div className="price-row">
                                                    <div className="name">
                                                        {getLangText(
                                                            +userData?.id ===
                                                                2267
                                                                ? 'saleTax'
                                                                : 'servicePayment',
                                                        )}
                                                        :
                                                    </div>
                                                    <div className="value">
                                                        {getOrderPrice(
                                                            values.payment_service_fee,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {!!values.open_cart_surcharges_logs
                                                ?.length && (
                                                <OpenCartSurcharges
                                                    loadData={() =>
                                                        loadData(
                                                            values.shop_order_id,
                                                        )
                                                    }
                                                    items={
                                                        values.open_cart_surcharges_logs
                                                    }
                                                    getOrderPrice={
                                                        getOrderPrice
                                                    }
                                                    values={values}
                                                />
                                            )}
                                        </div>
                                        <div className="order-summary__final-price">
                                            <div className="price-row">
                                                <div className="name">
                                                    {getLangText('totalLabel')}:
                                                </div>
                                                <div className="value final-price">
                                                    {getOrderPrice(
                                                        values.total_price,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </MobileToggle>
                                </div>
                            </div>
                            <RemoveOrder
                                values={values}
                                showModal={modal.showModal}
                                modalVisible={modal.modalVisible}
                                hideModal={modal.hideModal}
                                removeOrder={deleteWithoutAsk}
                            />
                        </div>
                        <div>
                            <ClientInfo
                                fetchData={() => loadData(values.shop_order_id)}
                                data={values}
                            />
                            {(+userData.id === TEST_ACCOUNT_SHOP_ID ||
                                +userData.id === 499) && (
                                <AssignEmployee
                                    defaultValues={values}
                                    fetchData={() =>
                                        loadData(values.shop_order_id)
                                    }
                                />
                            )}
                            {!!+userData.selmo_pro_active && (
                                <StopAutomation
                                    values={values}
                                    fetchData={() =>
                                        loadData(values.shop_order_id)
                                    }
                                />
                            )}
                            {((userData.origin === SHOP_ORIGIN_PL &&
                                +userData.id !== TIM_SHOP_ID) ||
                                (+userData.id === TIM_SHOP_ID &&
                                    +values.shippingData.shipping !==
                                        timKurierBoxId)) && (
                                <SelmoPaczka
                                    fetchData={() =>
                                        loadData(values.shop_order_id)
                                    }
                                    data={values}
                                />
                            )}
                            {!!+userData.depot_system &&
                                +userData.id !== CHIQUE_SHOP_ID && (
                                    <ShelfInfo
                                        fetchData={loadData}
                                        values={values}
                                    />
                                )}
                            {(((userData.origin === SHOP_ORIGIN_PL ||
                                +userData.id === MODANOEMI_SHOP_ID ||
                                +userData.id === MODANOEMI_V2_SHOP_ID ||
                                +userData.id === OLI_ELEGANCJA ||
                                +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                +userData.id !== TIM_SHOP_ID) ||
                                (+userData.id === TIM_SHOP_ID &&
                                    +values.shippingData.shipping !==
                                        timKurierBoxId)) && (
                                <>
                                    <InpostLabelFrom data={values} />
                                    <FurgonetkaLabelFrom data={values} />
                                </>
                            )}
                            {(userData.origin === SHOP_ORIGIN_PL ||
                                userData.origin === SHOP_ORIGIN_FR ||
                                userData.origin === SHOP_ORIGIN_SK ||
                                +userData.id === ANGEL_GLADYS_SHOP_ID) && (
                                <PermissionsWrapper
                                    NoPermissionComponent={
                                        NoPermissionFakturownia
                                    }
                                    allowedPermissions={[
                                        FAKTUROWNIA_PERMISSION,
                                    ]}>
                                    <FakturowniaLabelForm data={values} />
                                </PermissionsWrapper>
                            )}
                            <MondialLabel data={values} />
                            <OrlenLabel data={values} />
                            {userData.origin === SHOP_ORIGIN_DA && (
                                <ShipmondoLabel data={values} />
                            )}
                            <DpdLabel data={values} />
                            <GlsLabel data={values} />
                            <GlsFranceLabel data={values} />
                            <ColissimoLabel data={values} />
                            <UspsLabel data={values} />
                            <ShippyproLabelForm data={values} />
                            <PaxyLabel loadData={loadData} data={values} />
                            <PostaBezHranicLabel data={values} />
                            <SendEmailForm values={values} />
                            <SendVoiceMessage
                                className="mt-3"
                                values={values}
                            />
                            <UserPermissionsWrapper
                                allowedPermissions={[
                                    USER_PERMISSIONS_ORDERS_STATISTICS_VISIBILITY,
                                ]}>
                                <OrderHistory values={values} />
                            </UserPermissionsWrapper>
                            <RemoveOrder
                                values={values}
                                showModal={modal.showModal}
                                modalVisible={modal.modalVisible}
                                hideModal={modal.hideModal}
                                removeOrder={deleteWithoutAsk}
                                mobile
                            />
                        </div>
                    </div>
                </div>
            )}
            {showFakturowniaModalVisible && (
                <FunctionalityModal
                    desc={getLangText('automaticallySalesDocumentLabel')}
                    title={getLangText('buyAccessToFakturownia')}
                    functionalityName={FAKTUROWNIA_PERMISSION}
                    openModal={showFakturowniaModalVisible}
                    setModalOpen={setShowFakturowniaModalVisible}
                />
            )}
        </>
    );
};

export default OrderForm;
