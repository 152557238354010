import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HelpRoute from '../routes/HelpRoute';
import { RouterPaths } from '../routes/RouterPath';
import ProductsRoute from '../routes/ProductsRoute';
import OrdersRoute from '../routes/OrdersRoute';
import LiveRoute from '../routes/LivesRoute';
import PartnersRoute from '../routes/PartnersRoute';
import MessengerRoute from '../routes/MessengerRoute';
import ClientsRoute from '../routes/ClientsRoute';
import StatsRoute from '../routes/StatsRoute';
import ShopSettingsRoute from '../routes/shopSettingsRoute/ShopSettingsRoute';
import NewsRoute from '../routes/NewsRoute';
import Layout from './Layout';
import useIsPremium from './shared/hooks/useIsPremium';
import Dashboard from './dashboard/Dashboard';
import NotificationsRoute from '../routes/NotificationsRoute';
import AutomationRoute from '../routes/AutomationRoute';
import StorageRoute from '../routes/StorageRoute';
import {
    USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY,
    USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY,
    USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY,
    USER_PERMISSIONS_LIVE_MENU_VISIBILITY,
    USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY,
    USER_PERMISSIONS_ORDERS_MENU_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
    USER_PERMISSIONS_SHOP_MENU_VISIBILITY,
    USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY,
} from './shared/enums/UserPermissionsTypes';
import UserPermissionRoute from '../routes/UserPermissionRoute';
import SelmoPro from './selmoPro/SelmoPro';
import MrAutomate from './mrAutomate/page/MrAutomate';
import LiveOverlayRoute from '../routes/LivesOverlayRoute';
import SelmoProRoute from '../routes/SelmoProRoute';
import SalesPostRoute from '../routes/SalesPostRoute';
import Vacation from './vacation/Vacation';
import SelmoPaczkaRoute from '../routes/SelmoPaczkaRoute';
import AnalyticsRoute from '../routes/AnalyticsRoute';
import { SELMO_APP_PERMISSION } from './shared/enums/SelmoPermissions';
import PermissionRoute from '../routes/PermissionRoute';
import StatsNewRoute from '../routes/StatsNewRoute';
import DMListRoute from '../routes/DMListRoute';
import SelmoProLandingRoute from '../routes/SelmoProLandingRoute';
import DpdPromo from './dpdPromo/DpdPromo';
import BlackFriday from './blackFriday/BlackFriday';
import Marketing from './marketing/Marketing';
import MarketingRoute from '../routes/MarketingRoute';
import MarketingDetailsRoute from '../routes/MarketingDetailsRoute';
import SystemNotificationsRoute from '../routes/SystemNotificationsRoute';
import SmsMarketingDetailsRoute from '../routes/SmsMarketingDetailsRoute';

const SelmoApp = () => {
    const { isPremium } = useIsPremium();

    if (!isPremium) {
        return <Redirect to={RouterPaths.Payments} />;
    }

    return (
        <Layout>
            <Switch>
                <HelpRoute path={RouterPaths.Help} />
                <UserPermissionRoute
                    path={RouterPaths.ProductsList}
                    allowedPermissions={[
                        USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
                    ]}>
                    <ProductsRoute path={RouterPaths.ProductsList} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.OrdersList}
                    allowedPermissions={[
                        USER_PERMISSIONS_ORDERS_MENU_VISIBILITY,
                    ]}>
                    <OrdersRoute path={RouterPaths.OrdersList} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.LiveList}
                    allowedPermissions={[
                        USER_PERMISSIONS_LIVE_MENU_VISIBILITY,
                    ]}>
                    <LiveRoute path={RouterPaths.LiveList} />
                </UserPermissionRoute>
                <LiveOverlayRoute path={RouterPaths.LiveOverlay} />
                <SalesPostRoute path={RouterPaths.SalesPost} />
                <PartnersRoute path={RouterPaths.PartnersProgram} />
                <UserPermissionRoute
                    path={RouterPaths.Messenger}
                    allowedPermissions={[
                        USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY,
                    ]}>
                    <MessengerRoute path={RouterPaths.Messenger} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.SystemNotification}
                    allowedPermissions={[
                        USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY,
                    ]}>
                    <SystemNotificationsRoute />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.ClientsList}
                    allowedPermissions={[
                        USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY,
                    ]}>
                    <ClientsRoute path={RouterPaths.ClientsList} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.Notifications}
                    allowedPermissions={[
                        USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY,
                    ]}>
                    <NotificationsRoute path={RouterPaths.Notifications} />
                </UserPermissionRoute>
                <StatsRoute path={RouterPaths.Stats} />
                <StatsNewRoute path={RouterPaths.StatsNew} />
                <DMListRoute path={RouterPaths.DMList} />
                <UserPermissionRoute
                    path={RouterPaths.Analytics}
                    allowedPermissions={[
                        USER_PERMISSIONS_SHOP_MENU_VISIBILITY,
                    ]}>
                    <AnalyticsRoute path={RouterPaths.Analytics} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.Shop}
                    allowedPermissions={[
                        USER_PERMISSIONS_SHOP_MENU_VISIBILITY,
                    ]}>
                    <ShopSettingsRoute path={RouterPaths.Shop} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.Automation}
                    allowedPermissions={[
                        USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY,
                    ]}>
                    <AutomationRoute path={RouterPaths.Automation} />
                </UserPermissionRoute>
                <UserPermissionRoute
                    path={RouterPaths.MrAutomate}
                    allowedPermissions={[
                        USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY,
                    ]}>
                    <PermissionRoute
                        path={RouterPaths.MrAutomate}
                        allowedPermissions={[SELMO_APP_PERMISSION]}>
                        <SelmoProRoute
                            path={RouterPaths.MrAutomate}
                            component={MrAutomate}
                            extact
                        />
                    </PermissionRoute>
                </UserPermissionRoute>
                <StorageRoute path={RouterPaths.Storage} />
                <SelmoProLandingRoute path={RouterPaths.SelmoPro} />
                <SelmoPaczkaRoute path={RouterPaths.SelmoPaczka} />
                <Route component={Vacation} path={RouterPaths.Vacation} />
                <Route
                    path={RouterPaths.BlackFriday}
                    render={() => <Redirect to={RouterPaths.Dashboard} />}
                />
                <NewsRoute exact path={RouterPaths.News} />
                <Route component={DpdPromo} path={RouterPaths.DPDPromo} />
                <MarketingDetailsRoute
                    path={RouterPaths.MarketingFacebookAdvertisementsDetails}
                />
                <SmsMarketingDetailsRoute
                    path={RouterPaths.MarketingSmsCampaignsDetails}
                />
                <MarketingRoute path={RouterPaths.Marketing} />
                <Route component={Dashboard} path={RouterPaths.Dashboard} />
                <Redirect to={RouterPaths.Dashboard} />
            </Switch>
        </Layout>
    );
};

export default SelmoApp;
