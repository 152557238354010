import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../../../../messenger/loader/Loader';
import useLang from '../../../../../../../src_shared/hooks/useLang';
import useFormLocal from '../../../../../../../src_shared/hooks/useFormLocal';
import { services } from '../../../../../../RestServices';
import Field from '../../../../../../../src_shared/components/form/field/Field';
import Model from '../../../../../../../src_shared/modules/model/Model';
import { useSelector } from 'react-redux';

const proposedOptions = [
    { id: 1, amount: 100 },
    { id: 2, amount: 200 },
    { id: 3, amount: 500 },
    { id: 4, amount: 1000 },
];

const USE_FUNDS_OPTIONS = [
    {
        label: 'sendParcelsWithSelmoPaczka',
        icon: 'selmo-box',
    },
    {
        label: 'sendSmsCampaigns',
        icon: 'selmo-sms',
    },
];

const BalanceModal = ({ show, hideModal, extraValues = null }) => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);
    const [selectedProposedAmount, setSelectedProposedAmount] = useState(null);

    const minValue = extraValues ? extraValues.amount : 10;

    const [{ getFieldProps, onSave, isWaiting, updateValues, setValue }] =
        useFormLocal({
            model: new Model(),
            rest: services.API_PACKAGE_BALANCE_TRANSACTION,
            initialAutoLoad: false,
            afterSubmit: (response) => {
                window.location.href = response;
            },
        });

    useEffect(() => {
        if (extraValues) {
            updateValues({
                ...extraValues,
                path: window.location.pathname,
            });
        } else {
            setValue('path', window.location.pathname);
        }
    }, [extraValues]);

    const onAmountChange = (field, value) => {
        setValue(field, value);
        setSelectedProposedAmount(null);
    };

    return (
        <Modal
            show={show}
            dialogClassName="default-modal big-modal w-[650px]"
            contentClassName="!rounded-2xl"
            className="higher-index"
            backdropClassName="higher-index"
            onHide={hideModal}>
            <Modal.Body className="relative p-0 !rounded-2xl overflow-hidden">
                <button
                    onClick={hideModal}
                    type="button"
                    className="button text-right text-[#6b7280] text-[11px] absolute top-5 right-5">
                    <i className="icon-cross" />
                </button>
                <div className="flex">
                    <div className="flex flex-col w-[60%] p-[30px] py-[45px]">
                        <div className="flex justify-between items-start gap-2">
                            <div>
                                <div className="font-bold text-lg">
                                    {getLangText('balanceState')}:&nbsp;
                                    {userData.sp_balance}&nbsp;zł
                                </div>
                                <div className="text-desc font-medium text-sm -mt-1">
                                    {getLangText('increaseBalanceForShipping')}
                                </div>
                            </div>
                            <img
                                src="/assets/images/selmo-logo.svg"
                                alt="Selmo"
                                className="w-[80px] mt-[5px]"
                            />
                        </div>
                        <div className="font-bold text-sm mb-2 mt-3">
                            {getLangText('enterDepositAmount')}
                        </div>
                        <form onSubmit={onSave}>
                            <div className="flex items-center">
                                <div className="relative grow">
                                    <Field
                                        {...getFieldProps('amount')}
                                        setValue={onAmountChange}
                                        type="number"
                                        placeholder={getLangText('enterAmount')}
                                        className={`mb-0 grow big-size hidden-arrows smaller`}
                                        showLabel={false}
                                        extraProps={{
                                            required: true,
                                            autoFocus: true,
                                            min: minValue,
                                            step: 0.01,
                                        }}
                                    />
                                    <div className="text-[#9CA3AF] text-xs font-medium absolute top-1/2 -translate-y-1/2 right-2">
                                        {getLangText('min')}. {minValue} zł
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="text-[#101827] font-medium mt-3 text-sm mb-2">
                                    {getLangText('orSelectFromProposed')}
                                </div>
                                <div className="grid grid-cols-4 gap-3">
                                    {proposedOptions.map((option) => (
                                        <button
                                            type="button"
                                            style={{
                                                boxShadow:
                                                    selectedProposedAmount ===
                                                    option.id
                                                        ? '0 0 0 1px #3C61EA'
                                                        : '0 0 0 1px #eff0f1',
                                            }}
                                            onClick={() => {
                                                setSelectedProposedAmount(
                                                    option.id,
                                                );
                                                setValue(
                                                    'amount',
                                                    option.amount,
                                                );
                                            }}
                                            key={option.id}
                                            className={`border text-sm rounded-[5px] py-[10px] transition-all duration-200 w-full font-medium ${selectedProposedAmount === option.id ? 'text-[#000] border-[#3C61EA] bg-[#f7f9fe]' : 'text-[#6B7280]'}`}>
                                            {option.amount} zł
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    paddingTop: 18,
                                    paddingBottom: 18,
                                }}
                                className="button primary mt-3 align-self-stretch w-full whitespace-nowrap bigger-radius">
                                {isWaiting && <Loader />}
                                {getLangText('deposit')}
                            </button>
                        </form>
                    </div>
                    <div className="flex flex-col py-[51px] pl-[25px] w-[40%] bg-[#F6FCFF] border-l-[1px] border-[#D7EAFE]">
                        <div className="text-desc font-medium text-sm">
                            {getLangText('whatWillYouUseTheFundsFor')}
                        </div>
                        <div className="flex flex-col gap-3.5 mt-3">
                            {USE_FUNDS_OPTIONS.map((option) => (
                                <div
                                    key={option.label}
                                    className="flex items-center gap-2.5">
                                    <div className="shadow-selmo-border w-12 h-12 rounded-[8px] border-[1px] border-[#E5E7EB] flex items-center justify-center bg-white">
                                        <img
                                            src={`/assets/images/svg/${option.icon}.svg`}
                                            alt={option.label}
                                            className="w-12 h-12"
                                        />
                                    </div>
                                    <div
                                        className="text-sm font-medium whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{
                                            __html: getLangText(option.label),
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BalanceModal;
