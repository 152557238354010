import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PT} from "../../../app/shared/enums/ShopOrigins";
import useAssetsLang from "../../../app/shared/helpers/useAssetsLang";

const NotConnected = ({data}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const lang = useAssetsLang();

    return (
        <div className="settings-page sub-settings-page help-contact-box partners-page mb-sm-4 instagram-connected-page"
             style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <div className="text-center not-connected-view">
                <div>
                    <img width="100" src="/assets/images/svg/selmoxinstagram.svg" alt="selmoxinstagram" />
                </div>
                <div className="title">
                    {getLangText('connectPageInstagramLabel')}
                </div>
                <p>
                    {getLangText('automateYourSalesLabel')}
                </p>
            </div>
            <div className="partners-page-container">
                <div className="border-step-box">
                    <div className="step-header">
                        <div className="step-circle"><span>1</span></div>
                        <div>
                            <div className="title">
                                <strong>{getLangText('instagramTutorialStepOne1')}</strong>
                                {getLangText('instagramTutorialStepOne2')}
                            </div>
                        </div>
                    </div>
                    <div className="step-body">
                        <img
                            src={`/assets/images/tutorial/${lang}/selmo-instagram-tutorial-01.${(userData.origin === SHOP_ORIGIN_PT || userData.origin === SHOP_ORIGIN_FR) ? 'webp' : 'jpeg'}?v=1`}
                            className="tutorial-picture" />
                    </div>
                </div>
                <div className="border-step-box">
                    <div className="step-header">
                        <div className="step-circle"><span>2</span></div>
                        <div>
                            <div className="title"><strong>{getLangText('instagramTutorialStepTwo')}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="step-body">
                        <a href="https://www.facebook.com/settings?tab=linked_instagram" target="_blank"
                           className="button magic-link">
                            {getLangText('connectedAccountsLabel')}
                        </a>
                        <img
                            src={`/assets/images/tutorial/${lang}/selmo-instagram-tutorial-02.${(userData.origin === SHOP_ORIGIN_PT || userData.origin === SHOP_ORIGIN_FR) ? 'webp' : 'jpeg'}?v=1`}
                            className="tutorial-picture" />
                    </div>
                    <div className="step-body">
                        <img
                            src={`/assets/images/tutorial/${lang}/selmo-instagram-tutorial-03.${(userData.origin === SHOP_ORIGIN_PT || userData.origin === SHOP_ORIGIN_FR) ? 'webp' : 'jpeg'}?v=1`}
                            className="tutorial-picture" />
                    </div>
                </div>
                <div className="border-step-box">
                    <div className="step-header">
                        <div className="step-circle"><span>3</span></div>
                        <div>
                            <div className="title"><strong>{getLangText('instagramTutorialStepThree')}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="step-body">
                        <div className="text-center not-connected-view extra">
                            <div>
                                <img width="100" src="/assets/images/svg/selmoxinstagram.svg"
                                     alt="selmoxinstagram" />
                            </div>
                            <div className="title">
                                {getLangText('connectPageInstagramLabel')}
                            </div>
                            <p>
                                {getLangText('finishConfigurationLabel')}
                            </p>
                            <a href={data.instagramLoginUrl} className="button primary small-size">
                                {getLangText('settingsConnectToInstagram')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

);
};

export default NotConnected;
