import Validation from '../../form/validation/Validation';

export const convertObjectProperties = (source, converters, defaultValues) => {
    const result = { ...source };

    Object.keys(converters).forEach((property) => {
        const converter = converters[property];
        const data = result[property];

        if (data) {
            result[property] = converter(data);
        }
    });

    Object.keys(defaultValues).forEach((property) => {
        const defaultValue = defaultValues[property];
        const value = result[property];

        if (!value) {
            result[property] = defaultValue;
        }
    });

    return result;
};

export class Model {
    constructor() {
        this.idx = 0;
    }

    getModel() {
        return {};
    }

    getValidators() {
        return {};
    }

    createNewId() {
        return `temp-${this.idx++}`;
    }

    create(data) {
        const newModel = this.getModel().merge(data);

        if (!data.id) {
            return newModel.set('id', this.createNewId());
        }

        return newModel;
    }

    validateData(data) {
        return Validation.runValidation(data, this.getValidators());
    }

    parseDTO(data) {
        return data;
    }

    buildDTO(data) {
        return data;
    }
}

export default Model;
