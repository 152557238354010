import React, {memo} from 'react';
import ServerImage from "../../shared/components/serverImage/ServerImage";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";
import useLang from "../../../../src_shared/hooks/useLang";

const ClientAvatar = ({photo, type, fbLogo = true, className = '', appStatus = null, newIcon = false}) => {
    const {getLangText} = useLang();

    const getAppStatusTooltipText = () => {
        if (appStatus === 'connected') {
            return 'thisClientFollowYou'
        }
        if (appStatus === 'not_connected') {
            return 'thisClientDoesNotFollowYou'
        }
        return 'invitationToSelmoAppWasSent'
    }

    const getAppStatusIcon = () => {
        if (appStatus === 'connected') {
            return (
                <i
                    className={`icon-tick-c text-[#3CD9A0] text-[12px] ${fbLogo ? '' : 'border-2 border-[#fff] rounded-full'}`}/>
            )

        }
        if (appStatus === 'not_connected') {
            return (
                <i
                    className={`icon-cross-c text-[#FF3456] text-[12px] ${fbLogo ? '' : 'border-2 border-[#fff] rounded-full'}`}/>
            )
        }
        return (
            <i
                className={`icon-info-c text-[#FBCC14] text-[12px] ${fbLogo ? '' : 'border-2 border-[#fff] rounded-full'}`}/>
        )
    }

    return (
        <figure className={`user-avatar ${className}`}>
            {photo && photo !== '0' ?
                <div className="fb-pic w-100 h-100">
                    <ServerImage src={photo}/>
                </div> :
                newIcon ?
                <img src="/assets/images/default/avatar.svg"/>:
                <img src="/assets/images/default/fb-default.jpeg"/>
            }
            {appStatus &&
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id="followed">
                            {getLangText(getAppStatusTooltipText())}
                        </Tooltip>
                    }
                >
                    <div
                        className={`app-status bg-[#fff] rounded-full absolute -bottom-[2px]  w-[12px] h-[12px] flex items-center justify-center ${fbLogo ? 'with-social -right-[6px]' : '-right-[4px]'}`}>
                        {getAppStatusIcon()}
                    </div>
                </OverlayTrigger>
            }
            {fbLogo &&
                <>
                    {type === 'facebook' && <img className={`msg-logo ${appStatus ? 'row-icons' : ''}`}
                                                 src="/assets/images/svg/messenger-icon.svg"/>}
                    {type === 'instagram' && <img className={`msg-logo ${appStatus ? 'row-icons' : ''}`}
                                                  src="/assets/images/svg/instagram-icon.svg"/>}
                </>
            }
        </figure>
    );
};

export default memo(ClientAvatar);
