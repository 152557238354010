import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";

export const MarketingPermissionsRoute = ({component: Component, ...rest}) => {

	const {userData, waitingForResponse} = useSelector((state) => state.session);

	const noPermissions = +userData.facebook_ads_active === 2

	if (noPermissions && !waitingForResponse) {
		return <Redirect to={RouterPaths.MarketingFacebook}/>;
	}

	return (
		<Route
			component={Component}
			{...rest}
		/>
	);
};

MarketingPermissionsRoute.propTypes = {
	component: PropTypes.elementType,
};

MarketingPermissionsRoute.defaultProps = {
	component: undefined,
};

export default MarketingPermissionsRoute;
