export const  NOTIFICATIONS_TYPE_PANEL = 'SELMO_PANEL';
export const  NOTIFICATIONS_TYPE_SELMO_MOBILE_APP = 'SELMO_MOBILE_APP';
export const  NOTIFICATIONS_TYPE_SELMO_PRO = 'SELMO_PRO';
export const  NOTIFICATIONS_TYPE_SELMO_MARKETING = 'SELMO_MARKETING';
export const  NOTIFICATIONS_TYPE_SELMO_PACZKA = 'SELMO_PACZKA';

const NOTIFICATIONS_TYPES = [
    {value: NOTIFICATIONS_TYPE_PANEL, label: 'selmoPanel', logo: 'selmo'},
    {value: NOTIFICATIONS_TYPE_SELMO_MOBILE_APP, label: 'selmoApp', logo: 'selmo'},
    {value: NOTIFICATIONS_TYPE_SELMO_PRO, label: 'Selmo Pro', logo: 'selmo-pro'},
    {value: NOTIFICATIONS_TYPE_SELMO_MARKETING, label: 'selmoMarketing', logo: 'marketing'},
    {value: NOTIFICATIONS_TYPE_SELMO_PACZKA, label: 'Selmo Paczka', logo: 'paczka'},
];

export const findNotificationTypeById = (id) => NOTIFICATIONS_TYPES.find((item) => item.value === id) || {}

export default NOTIFICATIONS_TYPES;
