import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import Loader from "../../app/shared/Loader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import Field from "../../../src_shared/components/form/field/Field";
import {Helmet} from "react-helmet";
import Model from "../../../src_shared/modules/model/Model";

const ChangeFanpage = () => {
    const {getLangText} = useLang();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
    }] = useFormLocal({
        rest: services.API_SETTINGS_CHANGE_FANPAGE,
        model: new Model(),
    });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_nav_message_templates')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>Change Fanpage</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page loader-parent">
                <Loader isLoading={isLoading}/>
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/settings/delivery.svg" alt="delivery"/>
                        Change Fanpage
                        {/*{getLangText('settings_nav_message_templates')}*/}
                    </h1>
                    {/*<div className="description">{getLangText('settings_nav_message_templates_description')}</div>*/}
                </div>
                <div className="settings-form">
                    <section>
                        <div className="left-side">
                            <h2 className="label">Change Fanpage</h2>
                        </div>
                        <div className="form-col">
                            <Field
                                {...getFieldProps('email')}
                                label={getLangText('emailLabel')}
                            />
                            <div className="d-flex w-100 mt-3">
                                <button
                                    type="submit"
                                    onClick={onSave}
                                    className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default ChangeFanpage;
