import React, {useEffect, useRef, useState} from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {updateStorageProduct,} from "../LiveDetailsActions";
import {useDispatch, useSelector} from "react-redux";
import NumberInput from "../../../../shared/components/form/NumberInput.jsx";
import ClientInput from "./ClientInput";
import {createSelector} from "reselect";
import {getStateRoot} from "../definedVariantsModal/formReducer";
import useOutsideClick from "../../../../messenger/hooks/useOutsideClick/useOutsideClick";
import VariantsSelect from "../../../shared/productSearch/VariantsSelect.jsx";
import optimizedMemo from "../../../../../../src_shared/helpers/optimizedMemo";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";
import {components} from "react-select";
import {useUnitPrice} from "../../../../shared/helpers/Price";

const CustomerRow = ({
                         customer,
                         customers,
                         product,
                         updateField,
                         removeCustomer,
                         productId,
                         customerDuplicateId,
                         setCustomerDuplicatedId,
                         hasCustomerError,
                         isLiveFinished,
                         customerId,
                         liveId,
                         lastCartNumber,
                         index,
                     }) => {

    const dispatch = useDispatch();
    const sizesHintRef = useRef();
    const colorsHintRef = useRef();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const {sizes, colors} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot.data));
    const [sizeHintsVisible, setSizeHintsVisible] = useState(false);
    const [colorHintsVisible, setColorHintsVisible] = useState(false);
    const [filteredArray, setFilteredArray] = useState({
        colors, sizes,
    })
    const ValueContainer = ({children, ...props}) => {
        const {getValue, hasValue} = props;
        if (!hasValue) {
            return (
                <components.ValueContainer {...props}>
                    <span className="select-variant-label">{getLangText('selectVariantLabel')}</span>
                    <span className="hidden-value-container">
					{children}
				</span>
                </components.ValueContainer>
            );
        }
        return (
            <components.ValueContainer className="custom-value-container" {...props}>
                <div className="floated-small-label">{getLangText('variantLabel')}</div>
                <div className="custom-value">{getValue()[0].size} • {getPrice(getValue()[0].price)}</div>
                <span className="hidden-value-container">
					{children}
				</span>
            </components.ValueContainer>
        );
    }

    const updateStorageProductOnNameChange = () => dispatch(updateStorageProduct())

    useEffect(() => {
        setFilteredArray({
            colors, sizes,
        })
    }, [sizes, colors])

    const onInputChangeWithHints = (field, value, array, arrayName) => {
        updateField(field, value)
        const preparedFilteredArray = array.filter(i => i.text.toLowerCase().search(value.toLowerCase()) !== -1);
        setFilteredArray({
            ...filteredArray, [arrayName]: preparedFilteredArray,
        })
    }

    const onHintSelect = (field, value) => {
        updateField(field, value)
        setSizeHintsVisible(false)
        setColorHintsVisible(false)
    }

    useOutsideClick(sizesHintRef, () => {
        if (sizeHintsVisible) {
            setSizeHintsVisible(false);
        }
    });

    useOutsideClick(colorsHintRef, () => {
        if (colorHintsVisible) {
            setColorHintsVisible(false);
        }
    });

    const quantityValidation = (value) => !value || value <= 0;

    const getVariantsCount = () => {
        const selectedCustomerWithTheSameSize = customers.filter((i) => i.shop_client_id && i.variant_id === customer.variant_id);
        const selectedCustomers = customers.filter((i) => i.shop_client_id);

        const totalCreatedOrderProductCount = product?.sizes?.find((size) => size.id === customer.variant_id)?.sold_qty || product.sold_qty || 0;
        const preparedArray = !!product.sizes.length ? selectedCustomerWithTheSameSize.slice(0, selectedCustomerWithTheSameSize.indexOf(customer) + 1) : selectedCustomers.slice(0, selectedCustomers.indexOf(customer) + 1);

        return +totalCreatedOrderProductCount + preparedArray.reduce((prev, curr) => {
            return +prev + +curr.quantity
        }, 0);
    }

    const getNotAvailabilityClass = () => {
        const selectedVariant = !!product.sizes.length ? product.sizes.find((i) => i.id === customer.variant_id) : product;
        const selectedClient = customer.shop_client_id;

        if (!productId || !selectedVariant || !selectedClient || isLiveFinished) {
            return '';
        }

        if (+selectedVariant.squ === +getVariantsCount()) {
            return 'last-item';
        }

        if (+selectedVariant.squ < +getVariantsCount()) {
            return 'not-available';
        }

        return ''
    }

    const isDuplicated = () => {
        if (!!isLiveFinished || !customerDuplicateId) {
            return false;
        }
        const isCustomerDuplicated = +customer.shop_client_id === +customerDuplicateId;
        const duplicatedCustomers = customers.filter((i) => i.shop_client_id === customerDuplicateId)
        if (!product.sizes?.length) {
            return isCustomerDuplicated;
        } else {
            return isCustomerDuplicated && duplicatedCustomers.filter((i) => i.variant_id === customer.variant_id).length > 1;
        }
    }

    const inputsRowClasses = () => {
        const isDeleted = +customer.is_deleted ? 'd-none' : '';
        const duplicated = isDuplicated() ? 'duplicated' : '';
        const variantSelected = !!product.sizes.length ? 'with-variant' : '';
        const hasFutureCartNumber = !!customer.future_cart_number ? 'blue ' : '';
        return `inputs-row form-row small-inputs flex-nowrap ${duplicated} ${getNotAvailabilityClass()} ${variantSelected} ${isDeleted} ${hasFutureCartNumber}`
    }

    useEffect(() => {
        if (customer.shop_client_id) {
            const filteredCustomers = customers.filter((i) => i.is_deleted !== '1' && i.shop_client_id === customer.shop_client_id)
            const isDuplicated = filteredCustomers.length > 1
            if (isDuplicated) {
                setCustomerDuplicatedId(filteredCustomers[0].shop_client_id);
            }
        }
        return () => setCustomerDuplicatedId(null)
    }, [customers])

    return (
        <div className={inputsRowClasses()}>
            <div className="product-input-col with-avatar">
                <ClientInput
                    updateField={updateField}
                    customer={customer}
                    hasCustomerError={hasCustomerError}
                    customers={customers}
                    updateProductOnNameChange={updateStorageProductOnNameChange}
                    isLiveFinished={isLiveFinished}
                    customerId={customerId}
                    liveId={liveId}
                    lastCartNumber={lastCartNumber}
                    index={index}
                />
            </div>
            {productId ?
                <VariantsSelect
                    product={product}
                    variantName={customer.size_name}
                    variantPrice={customer.size_price}
                    variantSqu={customer.squ}
                    validateErrors={hasCustomerError}
                    variantId={customer.variant_id}
                    updateField={updateField}
                    disabled={isLiveFinished}
                    ValueContainer={ValueContainer}
                /> :
                <>
                    <div>
                        <div className="form-group" ref={colorsHintRef}>
                            <label className="control-label">{getLangText('colorLabel')}</label>
                            <input
                                onChange={(e) => onInputChangeWithHints('color', e.target.value, colors, 'colors')}
                                type="text"
                                value={customer.color}
                                tabIndex={customer.tabIndex}
                                onFocus={() => setColorHintsVisible(true)}
                                disabled={isLiveFinished}
                                className="form-control"
                                name={`variant_color_${convertToUserTimeZone().unix()}`}
                                autoComplete={false}
                                required
                            />
                            <div className="custom-dropdown">
                                {colorHintsVisible && filteredArray.colors.map((color) => (<div
                                    onClick={() => onHintSelect('color', color.text)}
                                    className="select-custom-option"
                                    key={color.id}
                                >
                                    {color.text}
                                </div>))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="form-group" ref={sizesHintRef}>
                            <label className="control-label">{getLangText('sizeLabel')}</label>
                            <input
                                onChange={(e) => onInputChangeWithHints('size', e.target.value, sizes, 'sizes')}
                                type="text"
                                className="form-control"
                                value={customer.size}
                                tabIndex={customer.tabIndex}
                                onFocus={() => setSizeHintsVisible(true)}
                                disabled={isLiveFinished}
                                name={`variant_size${convertToUserTimeZone().unix()}`}
                                autoComplete={false}
                                required
                            />
                            <div className="custom-dropdown">
                                {sizeHintsVisible && filteredArray.sizes.map((size) => (<div
                                    onClick={() => onHintSelect('size', size.text)}
                                    className="select-custom-option"
                                    key={size.id}
                                >
                                    {size.text}
                                </div>))}
                            </div>
                        </div>
                    </div>
                </>}
            <div className="quantity-col">
                <div
                    className={`form-group ${hasCustomerError && quantityValidation(customer.quantity) ? 'error-group' : ''}`}>
                    <label className="control-label">{getLangText('quantityLabel')}</label>
                    <NumberInput
                        onChange={(e) => updateField('quantity', e.target.value)}
                        value={customer.quantity}
                        tabIndex={customer.tabIndex}
                        name="quantity"
                        disabled={isLiveFinished}
                        min={1}
                    />
                </div>
            </div>
            {!isLiveFinished && <div className="button-col">
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id={`tooltip-remove-products`}>
                        {getLangText('clientsRemoveClientTitle')}
                    </Tooltip>}
                >
                    <button
                        tabIndex={-1}
                        className="button remove-row-button"
                        onClick={removeCustomer}
                    >
                        <i className="icon-cross"/>
                    </button>
                </OverlayTrigger>
            </div>}
        </div>
    );
};

export default optimizedMemo(CustomerRow);
