import React, {useEffect} from 'react';
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import {createNewId} from "../../../../../../live/details/default/liveDetailsReducer";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const MessageRow = ({values, setNestedValue, setValue}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const {lang} = useSelector((state) => state.i18n);

    const removeVariant = (id) => {
        if (values.reply_comment_messages?.length === 1) {
            setValue('reply_comment_messages', [{id: createNewId(), message: ''}]);
            return;
        }
        const filtered = values.reply_comment_messages.filter((i) => i.id !== id)
        setValue('reply_comment_messages', filtered);
    };

    useEffect(() => {
         if (!+id) {
             const defaultMessages = [
                 {id: createNewId(), message: getLangText('sentInPrivateMessageLabel')},
                 {id: createNewId()+1, message: getLangText('lookInYourMessageBoxLabel')},
                 {id: createNewId()+2, message: getLangText('checkPrivateMessageLabel')},
             ];

             setValue('reply_comment_messages', defaultMessages);
         }
    }, [id, lang])

    return (
        values.reply_comment_messages.map((item, index) => (
            <div
                key={item.id}
                className="flex items-center mb-3">
                <Field
                    key={item.id}
                    name="message"
                    value={item.message}
                    className="grow mb-0"
                    label={getLangText({key: 'answerVariantLabel', data: [index + 1]})}
                    setValue={(field, value) => setNestedValue('reply_comment_messages', item.id, field, value)}
                    extraProps={{
                        // autoFocus: true
                    }}
                />
                <button
                    tabIndex="-1"
                    type="button"
                    className="icon-button text-desc text-[10px] mt-[18px] ml-2"
                    onClick={() => removeVariant(item.id)}
                >
                    <i className="icon-cross"/>
                </button>
            </div>
        ))
    );
};

export default MessageRow;
