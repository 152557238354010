import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterPaths } from './RouterPath';
import { Helmet } from 'react-helmet';
import SmsMarketingDetails from '../app/marketing/sms/submitted/campaigns/form/Form';
import SmsPermissionRoute from './SmsPermissionRoute';

const SmsMarketingDetailsRoute = ({ path }) => {
    return (
        <Route path={path}>
            <SmsPermissionRoute>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SMS - Selmo</title>
                </Helmet>
                <Switch>
                    <Route
                        component={SmsMarketingDetails}
                        exact
                        path={RouterPaths.MarketingSmsCampaign}
                    />
                </Switch>
            </SmsPermissionRoute>
        </Route>
    );
};

export default SmsMarketingDetailsRoute;
