import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import useLang from '../../../../src_shared/hooks/useLang';
import useFormLocal from '../../../../src_shared/hooks/useFormLocal';
import { services } from '../../../RestServices';
import SummerPromoModel from '../../../../modules/models/summerPromo/SummerPromo';
import Loader from '../../../../src_shared/components/loader/Loader';
import { facebookMarketingPaymentMethods } from './AddvertsInfoModal';
import MarketingPurchaseModel from '../../../../modules/models/marketing/MarketingPurchaseModel';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../shared/components/alert/AlertActions';
import Field from '../../../../src_shared/components/form/field/Field';
import { SHOP_ORIGIN_PL } from '../../shared/enums/ShopOrigins';
import SwitchField from '../../../../src_shared/components/form/switchField/SwitchField';

const facebookOptions = [
    'marketingModalBenefitsExpert',
    'marketingModalBenefitsStats',
    'marketingModalBenefitsPanel',
];

const smsOptions = [
    'immediateShippingStrong',
    'intuitiveSmsEditorStrong',
    'fullControlOverExpenses',
];

const optionsFinished = [
    'marketingModalBenefitsExpertShort',
    'marketingModalBenefitsStatsShort',
    'marketingModalBenefitsPanelShort',
];

const InvoiceDataMarketingModal = ({
    modalVisible,
    hideModal,
    extend = true,
    isFinished,
    setData,
    type = 'facebook',
}) => {
    const { getLangText } = useLang();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.session);
    const [{ isLoading, values, onSave, setValue, isWaiting, getFieldProps }] =
        useFormLocal({
            rest: `${services.API_MARKETING_SUBMIT}/${modalVisible}${
                extend ? '?extend=1' : ''
            }`,
            model: extend ? SummerPromoModel : MarketingPurchaseModel,
            initialAutoLoad: extend,
            afterSubmit: (response) => {
                if (isFinished) {
                    setData((prevState) => ({ ...prevState, submitted: true }));
                    hideModal();
                    dispatch(
                        showAlert('yuhuLabel', 'helpModalButtonDescription'),
                    );
                } else {
                    window.location.href = response.url;
                }
            },
        });

    useEffect(() => {
        if (isFinished && !isLoading) {
            setValue('finished', true);

            if (userData.origin !== SHOP_ORIGIN_PL) {
                setValue('selected_payment', 'card');
            }
        }
    }, [isFinished, isLoading, setValue, userData.origin]);

    const getOptions = (type) => {
        if (type === 'facebook') {
            return facebookOptions;
        }

        return smsOptions;
    };

    const getMonthlyPrice = () => {
        if (type === 'facebook') {
            if (userData.origin === SHOP_ORIGIN_PL) {
                return '4 999 zł';
            }

            return '1099 €';
        }

        return !!+values.with_name ? '198 zł' : '99 zł';
    };

    return (
        <Modal
            dialogClassName={`default-modal ${
                type === 'facebook' ? 'w-850' : 'w-900'
            }`}
            contentClassName="rounded-xl p-0"
            show={modalVisible}
            onHide={hideModal}>
            <form onSubmit={onSave}>
                <div className="grid sm:grid-cols-5">
                    <div className="sm:col-span-3">
                        <div className="p-6 sm:px-12 sm:py-16">
                            <div className="text-[#5fa5fa] font-bold text-sm mb-2 uppercase tracking-[1px]">
                                {getLangText(
                                    type === 'facebook'
                                        ? 'selmoFacebookAd'
                                        : 'marketingSMSTitle',
                                )}
                            </div>
                            <div
                                class={`light-grey-subtitle tracking-[-1px] font-bold ${
                                    isFinished
                                        ? 'text-[22px] sm:mt-0 mt-3 sm:text-[32px] leading-[1.28] sm:whitespace-pre-wrap'
                                        : 'text-[24px] sm:text-[36px] leading-[1.31] whitespace-pre-wrap'
                                }`}
                                dangerouslySetInnerHTML={{
                                    __html: getLangText(
                                        type === 'sms'
                                            ? 'sendSmsMessagesToYourClients'
                                            : isFinished
                                              ? 'increaseReachAndSales'
                                              : 'increaseReachAndSalesWithSelmoMarketing',
                                    ),
                                }}
                            />
                            {isFinished ? (
                                <div className="mt-6">
                                    <div className="mb-2 text-sm text-[#101827] font-medium">
                                        {getLangText('enterContactDetails')}
                                    </div>
                                    <Field
                                        {...getFieldProps('phone')}
                                        label={getLangText('phoneLabel')}
                                        className="big-size mb-3"
                                        extraProps={{ required: true }}
                                        floatedLabel
                                    />
                                    <Field
                                        {...getFieldProps('email')}
                                        label={getLangText('emailLabel')}
                                        className="big-size mb-0"
                                        extraProps={{ required: true }}
                                        floatedLabel
                                        type="email"
                                    />
                                </div>
                            ) : (
                                <div className="mt-6">
                                    <div className="text-[#101827] font-bold text-sm leading-[18px] mb-[12px]">
                                        {getLangText('whatReceived')}
                                    </div>
                                    <div className="space-y-3">
                                        {getOptions(type).map((option) => (
                                            <div className="flex items-center text-sm text-[#101827] font-medium">
                                                <img
                                                    className="min-w-[20px] w-[20px] mr-2"
                                                    src="/assets/images/svg/tick-green.png"
                                                    alt=""
                                                />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: getLangText(
                                                            option,
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {type === 'sms' && (
                                        <div className="flex items-center justify-between rounded-[8px] border-[1px] border-[#E5E7EB] p-3.5 mt-6">
                                            <div className="flex items-center gap-1">
                                                <SwitchField
                                                    {...getFieldProps(
                                                        'with_name',
                                                    )}
                                                    className="switch-form inline-label big-label mb-0 self-start"
                                                    id="with_name"
                                                    setValue={setValue}
                                                    label={getLangText(
                                                        'shopNameAsSender',
                                                    )}
                                                />
                                                {/* <div className="tooltip-parent">
                                                    <i className="icon-info-thin text-[#9CA3AF]"></i>
                                                    <div className="tooltip-content">
                                                        {getLangText(
                                                            'campaignCostTooltip',
                                                        )}
                                                    </div>
                                                </div> */}
                                            </div>
                                            <span className="text-[#6b7280] text-sm font-medium">
                                                + 99 zł
                                            </span>
                                        </div>
                                    )}
                                    {userData.origin === SHOP_ORIGIN_PL && (
                                        <div className="mt-6">
                                            <div className="text-[#101827] font-bold text-sm leading-[18px] mb-[12px]">
                                                {getLangText(
                                                    'paymentsSelectPaymentsMethodsLabel',
                                                )}
                                            </div>
                                            <div className="grid sm:grid-cols-2 gap-3">
                                                {facebookMarketingPaymentMethods.map(
                                                    (item) => (
                                                        <button
                                                            type="button"
                                                            key={item.id}
                                                            onClick={() =>
                                                                setValue(
                                                                    'selected_payment',
                                                                    item.id,
                                                                )
                                                            }
                                                            className={`border rounded-[8px] p-3 block text-left transition-all ${
                                                                item.id ===
                                                                values.selected_payment
                                                                    ? 'border-[#3c61ea] bg-[#f7f9fe]'
                                                                    : 'border-[#e5e7eb]'
                                                            }`}>
                                                            <img
                                                                className="w-[80px] mx-auto"
                                                                src={`/assets/images/payments/${item.icon}.svg`}
                                                                alt=""
                                                            />
                                                            <div className="font-bold text-sm text-center mt-2">
                                                                {getLangText(
                                                                    item.label,
                                                                )}
                                                            </div>
                                                        </button>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <button
                                onClick={hideModal}
                                type="button"
                                className="button w-[32px] h-[32px] bg-[#e5e7eb] rounded-full items-center justify-center absolute text-[#6B7280] text-[8px] right-5 top-5 flex sm:hidden">
                                <i className="icon-cross" />
                            </button>
                            <button
                                type="submit"
                                style={{ borderRadius: 12 }}
                                className={`button primary medium-size leading-[18px] text-center ${
                                    isFinished ? 'mt-3' : 'mt-4'
                                } w-full bg-[#000] text-[white] font-bold py-[14px] sm:py-[16px] px-[13px] rounded-[8px] sm:order-2 transition-all ${
                                    isWaiting ? 'loading-button' : ''
                                }`}>
                                {isWaiting && <Loader isLoading />}
                                {getLangText(
                                    isFinished ? 'signIn' : 'goToPayment',
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="sm:col-span-2 relative">
                        <div
                            className={`bg-[#F9FAFB] h-full sm:rounded-r-[16px] p-6 sm:pb-[0px] flex flex-col ${
                                isFinished ? 'sm:pt-24' : 'sm:pt-16'
                            }`}>
                            <button
                                onClick={hideModal}
                                type="button"
                                className="button w-[32px] h-[32px] bg-[#e5e7eb] rounded-full items-center justify-center absolute text-[#6B7280] text-[8px] right-5 top-5 sm:flex hidden">
                                <i className="icon-cross" />
                            </button>
                            <div className="">
                                <div className="text-[#4b5563] font-medium">
                                    {getLangText('pricingLabel')}
                                </div>
                            </div>
                            <div
                                className={`mt-2 mb-4 ${
                                    isFinished ? 'space-y-2' : 'space-y-4'
                                }`}>
                                <div className="text-[#6b7280] font-medium">
                                    <span className="text-[28px] text-[#101827] font-semibold">
                                        {getMonthlyPrice()}
                                    </span>{' '}
                                    / {getLangText('monthly')}
                                </div>
                                {!extend && type === 'facebook' && (
                                    <div className="text-[#6b7280] font-medium">
                                        <span className="text-[#101827] font-semibold">
                                            {userData.origin === SHOP_ORIGIN_PL
                                                ? '+ 1 999 zł'
                                                : '+ 576 €'}
                                        </span>{' '}
                                        {getLangText('oneTimeActivationFee')}
                                    </div>
                                )}
                                {!extend && type === 'sms' && (
                                    <p
                                        className="font-medium"
                                        dangerouslySetInnerHTML={{
                                            __html: getLangText(
                                                'plusForOnlyOneSms',
                                            ),
                                        }}
                                    />
                                )}
                                {/*{!isFinished && (*/}
                                {/*  <div className="text-[#6b7280] font-medium">*/}
                                {/*<span*/}
                                {/*    className="text-[#101827] font-semibold">+ 10%</span> {getLangText('fromMarketingBudget')}*/}
                                {/*</div>*/}
                                {/*)}*/}
                                {isFinished && (
                                    <div class="pt-7">
                                        <div className="text-[#4b5563] font-medium mb-2">
                                            {getLangText('whatReceived')}
                                        </div>
                                        <div className="space-y-1">
                                            {optionsFinished.map((option) => (
                                                <div className="flex items-center text-sm text-[#101827] font-medium">
                                                    <i class="icon-tick-c text-[#5FA5FA] text-xl mr-1.5" />
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: getLangText(
                                                                option,
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default InvoiceDataMarketingModal;
