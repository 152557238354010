import React, {useEffect} from 'react';
import Loader from "../../../../src_shared/components/loader/Loader";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {Link, useParams} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import {useIntercom} from "react-use-intercom";
import {useDispatch, useSelector} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";
import ServerImage from "../../shared/components/serverImage/ServerImage";
import {RouterPaths} from "../../../routes/RouterPath";
import {findNotificationTypeById} from "../../shared/enums/NotificationTypes";
import TimeAgo from "../../../../src_shared/helpers/TimeAgo";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";
import {prepareRealLang} from "../../../../services/i18n/i18nAPI";

const NotificationsBox = ({setRightSideVisible}) => {

        const {id} = useParams()
        const {getLangText} = useLang();
        const {showNewMessage} = useIntercom();
        const dispatch = useDispatch();
        const {lang} = useSelector((state) => state.i18n);

        const afterSubmit = (response) => {
            dispatch(SessionActions.updateUserData('not_read_notifications', response.not_read_notifications))
        }

        const [{isLoading, fetchData, data, setIsLoading}] = useFetchData({
            rest: `${services.API_NOTIFICATIONS_LIST}/${id}`,
            initialAutoLoad: false,
            afterSubmit,
        })

        const handleMobileBack = () => {
            // setIsLoading(true)
            setRightSideVisible(false)
        }

        useEffect(() => {
            fetchData()
        }, [id]);

        return (
            <div className={`right-panel loader-parent overflow-y-auto`}>
                <button
                    type="button"
                    onClick={handleMobileBack}
                    className="button back-to-panels mb-3 d-lg-none text-left"
                >
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </button>
                <Loader isLoading={isLoading}/>
                <div className="lg:px-16 lg:py-12">
                    <div className="flex items-center">
                        <div className="min-w-[24px] w-[24px] h-[24px] rounded-full overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/assets/images/svg/systemNotifications/${findNotificationTypeById(data.type)?.logo}.svg`}
                                alt=""
                            />
                        </div>
                        <div className="ml-2 grow">
                            <div className="text-xs flex items-center font-bold text-[#101827]">
                                {getLangText(findNotificationTypeById(data.type)?.label) || findNotificationTypeById(data.type)?.label}
                                <div className="text-[#6b7280] font-medium ml-auto">
                                    <TimeAgo
                                        datetime={convertToUserTimeZone(data.date).format('YYYY-MM-DD HH:mm')}
                                        locale={prepareRealLang(lang)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-bold grow text-sm mb-2 mt-3">{data.title}</div>
                    <div
                        className="font-medium text-sm notification-description text-[#4b5563]"
                        dangerouslySetInnerHTML={{__html: data.description}}
                    />
                    {data.photo && data.photo !== '0' &&
                        (data.link ?
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={data.link}
                                    className="d-block w-100 my-3"
                                >
                                    <figure className="my-0 rounded-large overflow-hidden">
                                        <ServerImage
                                            className="w-full h-full"
                                            src={data.photo}/>
                                    </figure>
                                </a> :
                                <figure className="my-3 rounded-large overflow-hidden">
                                    <ServerImage
                                        className="w-full h-full"
                                        src={data.photo}/>
                                </figure>
                        )
                    }
                    <div
                        className="md:flex border-t pt-7 mt-7 justify-end items-center sm:space-y-0 space-y-3 sm:space-x-3">
                        <button
                            type="button"
                            onClick={() => showNewMessage(`${getLangText('topic')}: ${data.title}`)}
                            style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12, fontSize: 12}}
                            className="button border-button sm:w-auto w-full"
                        >
                            {getLangText('response')}
                        </button>
                        {!!data.link &&
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={data.link}
                                style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12}}
                                className="button primary small-size sm:w-auto w-full text-center"
                            >
                                {data.link_text}
                            </a>
                        }
                    </div>
                </div>
            </div>
        );
    }
;

export default NotificationsBox;
