import React, {useState} from 'react';
import SearchInput from "../../../shared/components/form/SearchInput";
import SearchPeopleDropdown from "../../form/components/videoComponent/SearchPeopleDropdown";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import PersonItem from "../../form/components/videoComponent/PersonItem";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";

const MobileAppPermission = () => {

    const {getLangText} = useLang();
    const [searchText, setSearchText] = useState('');
    const updateUsers = async (res, data) => {
        setUsers((prevState) => prevState.filter((i) => i.app_client_id !== data.app_user_id))
        await fetchConnectedUsers();
    }

    const updateConnectedUsers = async (res, data) => {
        setConnectedUsers((prevState) => prevState.filter((i) => i.app_client_id !== data.app_user_id))
    }

    const [{
        data: connectedUsers,
        isLoading: isLoadingConnectedUsers,
        fetchData: fetchConnectedUsers,
        setData: setConnectedUsers
    }] = useFetchData({
        rest: services.API_VIDEO_CONNECTED_USERS,
        initialData: [],
    })

    const [{
        data: usersData,
        isLoading: isLoadingUsers,
        fetchData: fetchUsers,
        setData: setUsers,
        setIsLoading: setIsLoadingUsers
    }] = useFetchData({
        rest: services.API_VIDEO_APP_USERS,
        initialData: [],
        initialAutoLoad: false
    })

    const search = {
        updateSearchText: (text) => {
            setIsLoadingUsers(true)
            setSearchText(text);
        },
        searchText,
        callSearch: fetchUsers,
    }

    return (
        <div>
            <div className="font-bold mb-2">{getLangText('grantPermission')}</div>
            <SearchInput
                className="mb-0 full-width-input"
                placeholder={getLangText('textNameAndSurnameLabel')}
                search={search}
            />
            <div className="my-2">
                <SearchPeopleDropdown
                    isLoadingUsers={isLoadingUsers}
                    usersData={usersData}
                    searchText={searchText}
                    updateConnectedUsers={updateConnectedUsers}
                    updateUsers={updateUsers}
                />
            </div>
            <div className="form-info-box small-line-height w-100">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">{getLangText('searchPersonAndGrantPermissions')}</div>
                    <div className="desc">{getLangText('personHaveToFollowYourShop')}</div>
                </div>
            </div>
            <div className="border-t pt-3 mt-3">
                <div className="font-bold mb-2">{getLangText('accessGranted')}</div>
                {isLoadingConnectedUsers ?
                    <ComponentLoader style={{minHeight: '200px'}}/> :
                    !!connectedUsers.length ?
                        <div className="max-h-[250px] overflow-y-auto -mx-[8px]">
                            {connectedUsers.map((item) => (
                                <PersonItem
                                    item={item}
                                    key={item.app_client_id}
                                    updateConnectedUsers={updateConnectedUsers}
                                    updateUsers={updateUsers}
                                />
                            ))}
                        </div>
                        :
                        <div className="form-info-box red small-line-height w-full">
                            <i class="icon-cross-c" />
                            <div className="title">{getLangText('noGrantedPermission')}</div>
                        </div>
                }
            </div>
        </div>
    );
};

export default MobileAppPermission;