import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { RouterPaths } from './RouterPath';
import { useSelector } from 'react-redux';
import { SHOP_ORIGIN_PL } from '../app/shared/enums/ShopOrigins';

export const SmsPermissionRoute = ({
    component: Component,
    allowField = '',
    ...rest
}) => {
    const { userData, waitingForResponse } = useSelector(
        (state) => state.session,
    );

    const isAllowed =
        userData.origin === SHOP_ORIGIN_PL ||
        (userData.sms_active === 1 && !waitingForResponse);

    if (!isAllowed) {
        return <Redirect to={RouterPaths.MarketingSms} />;
    }

    return <Route component={Component} {...rest} />;
};

SmsPermissionRoute.propTypes = {
    component: PropTypes.elementType,
};

SmsPermissionRoute.defaultProps = {
    component: undefined,
};

export default SmsPermissionRoute;
