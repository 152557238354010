import {useState} from 'react';
import {useLocation} from "react-router-dom";
import {parseParams} from "../../../list/ListActions";
import moment from "moment";
import {convertToUserTimeZone} from "../../../../components/app/shared/helpers/dateHelpers";

const useDateRangePicker = () => {
    const {search} = useLocation();
    const query = parseParams(search);

    const [selectionRange, setSelectionRange] = useState({
        startDate: query.dateFrom ? convertToUserTimeZone(query.dateFrom).toDate() : null,
        endDate: convertToUserTimeZone(query.dateTo || new Date()).toDate(),
        key: 'selection',
    });

    return {
        selectionRange,
        setSelectionRange,
    }
};

export default useDateRangePicker;
