export const USER_PERMISSION_STATS_MENU_VISIBILITY = 'STATS_MENU_VISIBILITY'
export const USER_PERMISSIONS_STATS_MR_AUTOMATE_VISIBILITY = 'STATS_MR_AUTOMATE_VISIBILITY'
export const USER_PERMISSIONS_TEAM_ADD_MEMBER = 'TEAM_ADD_MEMBER'
export const USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS = 'TEAM_EDIT_PERMISSIONS'
export const USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS = 'TEAM_STATISTICS_VISIBILITY'
export const USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY = 'PRODUCTS_PRICE_OVERALL'
export const USER_PERMISSIONS_PRODUCTS_ADD = 'PRODUCTS_ADD'
export const USER_PERMISSIONS_PRODUCTS_EDIT = 'PRODUCTS_EDIT'
export const USER_PERMISSIONS_PRODUCT_PURCHASE_PRICE = 'PRODUCTS_PURCHASE_PRICE'
export const USER_PERMISSIONS_PRODUCT_TOTAL_QUANTITY_VISIBILITY = 'PRODUCTS_TOTAL_QUANTITY_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY = 'PRODUCTS_MENU_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_STATISTICS_VISIBILITY = 'PRODUCTS_STATISTICS_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_MENU_COLLECTIONS_VISIBILITY = 'PRODUCTS_MENU_COLLECTIONS_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_MENU_CATEGORIES_VISIBILITY = 'PRODUCTS_MENU_CATEGORIES_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_MENU_VIDEO_VISIBILITY = 'PRODUCTS_MENU_VIDEO_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_MENU_MANUFACTURERS_VISIBILITY = 'PRODUCTS_MENU_MANUFACTURERS_VISIBILITY'
export const USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY = 'CLIENTS_MENU_VISIBILITY'
export const USER_PERMISSIONS_LIVE_MENU_VISIBILITY = 'LIVE_MENU_VISIBILITY'
export const USER_PERMISSIONS_LIVE_PRICE_OVERALL = 'LIVE_PRICE_OVERALL'
export const USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY = 'AUTOMATION_MENU_VISIBILITY'
export const USER_PERMISSIONS_APP_NOTIFICATIONS_MENU_VISIBILITY = 'APP_NOTIFICATIONS_MENU_VISIBILITY'
export const USER_PERMISSIONS_SHOP_MENU_VISIBILITY = 'SHOP_MENU_VISIBILITY'
export const USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY = 'SETTINGS_MENU_VISIBILITY'
export const USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY = 'MESSENGER_MENU_VISIBILITY'
export const USER_PERMISSIONS_MESSENGER_ORDERS_MENAGE = 'MESSENGER_ORDERS_MANAGE'
export const USER_PERMISSIONS_ORDERS_MENU_VISIBILITY = 'ORDERS_MENU_VISIBILITY'
export const USER_PERMISSIONS_ORDERS_STATISTICS_VISIBILITY = 'ORDERS_STATISTICS_VISIBILITY'
export const USER_PERMISSIONS_ORDERS_FAKTUROWNIA_INVOICE_VISIBILITY = 'ORDERS_FAKTUROWNIA_INVOICE_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY = 'PRODUCTS_BOUGHT_TAB_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY = 'PRODUCTS_RESERVE_LIST_TAB_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_CURRENT_TAB_VISIBILITY = 'PRODUCTS_CURRENT_TAB_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_GALLERY_TAB_VISIBILITY = 'PRODUCTS_GALLERY_TAB_VISIBILITY'
export const USER_PERMISSIONS_PRODUCTS_EXPENDITURE_TAB_VISIBILITY = 'PRODUCTS_EXPENDITURE_TAB_VISIBILITY'
export const USER_PERMISSIONS_LIVE_OVERLAY_MENU_VISIBILITY = 'LIVE_OVERLAY_MENU_VISIBILITY'
export const USER_PERMISSIONS_SALES_POSTS_MENU_VISIBILITY = 'SALES_POSTS_MENU_VISIBILITY'
