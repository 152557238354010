import React, {useCallback, useEffect, useRef} from 'react';
import Dropdown from "./components/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {headerNotificationsSetDropdownVisibility} from "./HeaderNotificationsActions";
import {Link, NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const NotificationsPanelButton = ({userData}) => {
    const buttonRef = useRef();
    const dropdownRef = useRef(null)
    const dispatch = useDispatch();
    const {isOpen, items} = useSelector((state) => state.shared.headerNotifications);
    const {pathname} = useLocation();

    const handleClickOutside = useCallback((e) => {
        if (isOpen
            && buttonRef
            && dropdownRef
            && buttonRef.current !== e.target
            && dropdownRef.current !== e.target
            && !buttonRef.current?.contains(e.target)
            && !dropdownRef.current?.contains(e.target)) {
            dispatch(headerNotificationsSetDropdownVisibility(false))
        }
    }, [isOpen]);

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    if (!userData.last_system_notification_id) return null;

    return (
        <div
            ref={dropdownRef}
            className="relative mr-2"
        >
            <Link
                activeClassName="bg-grey-200"
                to={`${RouterPaths.SystemNotifications}/${userData.last_system_notification_id}`}
                className={`rounded-2xl hover:text-[#000] hover:no-underline w-[30px] h-[30px] flex items-center justify-center font-semibold text-[#4B5563] hover:bg-gray-200 transition-all text-xs ${pathname.includes(RouterPaths.SystemNotifications) ? 'bg-gray-200 text-[#000]' : ''}`}
            >
                <i className="icon-notify text-[16px] -mt-0.5"/>
                {+userData.not_read_notifications > 0 &&
                    <div
                        className="min-w-[14px] h-[14px] px-[2px] rounded-[7px] border border-[#EFF2F6] flex items-center justify-center absolute top-0.5 right-0.5 bg-[#f75f5f] text-[#fff] font-bold text-[8px]">
                        {userData.not_read_notifications}
                    </div>
                }
            </Link>
            {isOpen &&
                <Dropdown
                    items={items}
                    close={() => dispatch(headerNotificationsSetDropdownVisibility(false))}
                />
            }
        </div>
    );
};

export default NotificationsPanelButton;
