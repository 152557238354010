import React, {useRef, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";

const RealDeviceScanner = ({afterScan}) => {

    const { getLangText } = useLang();
    const scannerInputRef = useRef();
    const [isWaitingForScan, setIsWaitingForScan] = useState(false);
    const [isFocused, setIsFocused] = useState(true);

    const updateOverlayFromScanner = async (e) => {
        setIsWaitingForScan(true);
        try {
            const inputValue = e.target.value;

            const jsonMatch = inputValue.match(/\{.*?\}/);

            if (jsonMatch) {
                const obj = JSON.parse(jsonMatch[0]);
                await afterScan(obj);
                // await onSave(e, { selectedProduct: { id: obj.product_id } });
            } else {
                throw new Error(
                    'Nie znaleziono poprawnego JSON-a w wartości inputa.',
                );
            }

            e.target.value = '';
        } catch (error) {
            console.error('Błąd podczas przetwarzania skanera:', error.message);
            setIsWaitingForScan(false);
        }
    };
    return (
        <div className="flex items-center w-full p-3 bg-[#fff] rounded-big">
            <div className="flex items-center w-full justify-between">
                <div className="flex items-center">
                    <i className="icon-qr-code text-[23px] translate-y-[1px] text-[#9ca3af]"/>
                    <div className="font-bold ml-2 flex flex-col">
                        <span>{getLangText('scanner')}</span>
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => setShowScannerModal(true)}*/}
                        {/*    className="text-desc text-xs underline font-normal">*/}
                        {/*    {getLangText('howItWorks')}*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="flex items-center">
                    <div
                        className={`text-sm font-bold ${isFocused ? 'text-[#0eb981]' : 'text-red-700'}`}>
                        {getLangText(
                            isFocused
                                ? 'activeLabel'
                                : 'notActiveLabel',
                        )}
                    </div>
                    {!isFocused && (
                        <button
                            onClick={() => {
                                scannerInputRef?.current?.focus();
                            }}
                            type="button"
                            disabled={isFocused}
                            className="button primary black small-size ml-2">
                            {getLangText('turnOn')}
                        </button>
                    )}
                </div>
            </div>
            <input
                ref={scannerInputRef}
                className="absolute opacity-0 pointer-events-none h-0 w-0"
                onChange={updateOverlayFromScanner}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                type="text"
                autoFocus
            />
        </div>
    );
};

export default RealDeviceScanner;