import React, {useState} from 'react';
import Faq from "../../../notifications/landing/components/Faq/Faq";

const questions = [
    {
        title: 'salesPostFaqLabel1',
        desc: 'salesPostFaqSubLabel1',
        id: 0
    },
    {
        title: 'salesPostFaqLabel2',
        desc: 'salesPostFaqSubLabel2',
        id: 1
    },
    {
        title: 'salesPostFaqLabel3',
        desc: 'salesPostFaqSubLabel3',
        id: 2
    },
    {
        title: 'salesPostFaqLabel4',
        desc: 'salesPostFaqSubLabel4',
        id: 3
    },
    {
        title: 'salesPostFaqLabel5',
        desc: 'salesPostFaqSubLabel5',
        id: 4
    },
    {
        title: 'salesPostFaqLabel6',
        desc: 'salesPostFaqSubLabel6',
        id: 5
    },
    {
        title: 'salesPostFaqLabel7',
        desc: 'salesPostFaqSubLabel17',
        id: 6
    },
]
const FaqSection = () => {
    const [isActive, setActive] = useState(0);

    return (
        <Faq
            smallSize
            questions={questions}
            isActive={isActive}
            setActive={setActive}
        />
    );
};

export default FaqSection;