import React from 'react';
import { NavLink } from 'react-router-dom';
import useLang from '../../../../../src_shared/hooks/useLang';
import { RouterPaths } from '../../../../routes/RouterPath';
import { Helmet } from 'react-helmet';

const tabs = [
    {
        id: 1,
        label: 'marketingSMSTitle',
        path: RouterPaths.MarketingSmsCampaigns,
    },
    {
        id: 2,
        label: 'marketingSMSReceivers',
        path: RouterPaths.MarketingSmsReceivers,
    },
];

const SubmittedWrapper = ({ children }) => {
    const { getLangText } = useLang();

    return (
        <div className="animate-[fadeIn_.3s_ease_forwards]">
            <Helmet>
                <meta charSet="utf-8" />
                <title>SMS - Selmo</title>
            </Helmet>
            <div className="tabs-wrapper bg-[#fff] sm:rounded-t-medium shadow-default">
                {tabs.map((i) => (
                    <NavLink to={i.path} key={i.id} activeClassName="active">
                        {getLangText(i.label)}
                    </NavLink>
                ))}
            </div>
            <div className="relative">{children}</div>
        </div>
    );
};

export default SubmittedWrapper;
