import {combineReducers} from "redux";
import filtersReducer from "./filters/filtersReducer";
import marketingClientsReducer from "./clients/marketingSmsClientsReducer";
import marketingAdsReducer from "./ads/marketingSmsAdsReducer";

export default combineReducers({
	ads: marketingAdsReducer,
	clients: marketingClientsReducer,
	filters: filtersReducer,
});
