import {getStateRoot, prefix} from "./listReducer";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {services} from "../../../../../RestServices";
import ListActions from "../../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";

export class FacebookStatisticsListActions extends ListActions {
    getLoadParams(state, id) {
        const params = super.getLoadParams(state);
        const is_new_client = getFiltersStateRoot(state).data.is_new_client

        if (is_new_client && is_new_client !== 'all') {
            params.is_new_client = is_new_client
        }

        return params;
    }

}

export const getInstance = () => new FacebookStatisticsListActions({
    getStateRoot,
    restService: services.API_MARKETING_AD_STATS,
    pathname: RouterPaths.MarketingFacebookStatistics,
    prefix,
    preventPushParams: true,
});
export default getInstance();
