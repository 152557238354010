import React from 'react';
import BoxWrapper from "../BoxWrapper";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";

const TargetGroupInfo = () => {
    const {getLangText} = useLang();

    return (
        <BoxWrapper
            title="targetGroupNwClients"
            desc="whoWillYouReach"
            icon="clients"
        >
            <div className="form-info-box w-100 items-center small-line-height">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div className="font-medium text-sm text-[#0e276d]"
                     dangerouslySetInnerHTML={{__html: getLangText('addWillGainNewFollowers')}}
                />
            </div>
        </BoxWrapper>
    );
};

export default TargetGroupInfo;
