import { combineReducers } from 'redux';
import filtersReducer from './filters/filtersReducer';
import marketingSmsCampaignsReducer from './campaigns/marketingSmsCampaignsReducer';
import marketingSmsReceiversReducer from './receivers/marketingSmsReceiversReducer';

export default combineReducers({
    filters: filtersReducer,
    campaigns: marketingSmsCampaignsReducer,
    receivers: marketingSmsReceiversReducer,
});
