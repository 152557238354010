import ListReducer from '../../../../../../../src_shared/list/ListReducer';
import createReducer from '../../../shared/helpers/createReducer';
import Composition from '../../../shared/helpers/Composition';

export const getStateRoot = (state) => state.admin.stats.overall.charts;
export const prefix = 'ADMIN_STATS_OVERALL_CHARTS_';

const getInitState = () =>
  ListReducer.getInitState({
    items: {
      onlineUsers: null,
      last24hSales: {
        counter: null,
        sum_price: null,
      },
      ordersTotal: [],
      mobileSignups: [],
      mobileViews: [],
      mobileSessions: [],
    },
  });

const getReduceMap = () => new Composition(ListReducer.getReduceMap());

export const getInstance = () =>
  createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
