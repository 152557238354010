import React from 'react';
import Loader from "../../../app/shared/Loader";
import {RouterPaths} from "../../../routes/RouterPath";
import {Link} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL} from "../../../app/shared/enums/ShopOrigins";

const ShippingList = ({shippingList = [], isLoading}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const preparedType = (item) => {
        if (!!+item.is_inpost) {
            return (
                <img
                    className="w-[35px]"
                    src="/assets/images/inpost.svg" alt=""/>
            )
        }
        if (!!+item.is_orlen) {
            return (
                <img
                    className="w-[35px]"
                    src="/assets/images/svg/orlen-paczka.svg" alt=""/>
            )
        }
        if (!!+item.is_mondial) {
            return (
                <img
                    className="w-[40px]"
                    src="/assets/images/svg/logomondial-relay.svg" alt=""/>
            )
        }
        if (!!+item.is_dpd) {
            return (
                <img
                    className="w-[40px]"
                    src="/assets/images/svg/dpd-logo.svg" alt=""/>
            )
        }
        if (!!+item.is_gls) {
            return (
                <img
                    className="w-[40px]"
                    src="/assets/images/gls.png" alt=""/>
            )
        }
        return <div className="text-xs text-desc">{getLangText('lackLabel')}</div>
    }

    return (
        <div className="form-col loader-parent">
            <Loader isLoading={isLoading}/>
            {shippingList.length ?
                <div className="div-table">
                    <div className="table-head">
                        <div className="table-row">
                            <div className="name">{getLangText('name')}</div>
                            <div className="small-col">{getLangText('input_label_price')}</div>
                            <div className="small-col">
                                {getLangText('typeLabel')}
                            </div>
                            <div className="small-col">{getLangText('active_label')}</div>
                            <div className="action-button-col"/>
                        </div>
                    </div>
                    <div className="table-body">
                        {shippingList?.map((item) => (
                            <Link
                                key={item.id}
                                to={`${RouterPaths.SettingsShippingEdit}/${item.id}/`}
                                className="table-row"
                            >
                                <div className="name">
                                    {item.name}
                                    {item.country &&
                                        <div style={{fontSize: '10px', color: '#6B7280'}}>
                                            {item.country_name}
                                        </div>
                                    }
                                </div>
                                <div className="small-col">
                                    {`${item.price} ${userData.currency_info?.text}`}
                                </div>
                                <div className="small-col">
                                    {preparedType(item)}
                                </div>
                                <div className="small-col icon-status">
                                    {!!+item.active ?
                                        <i className="icon-tick"/>
                                        :
                                        <i className="icon-cross"/>
                                    }
                                </div>
                                <div className="action-button-col">
                                    <div className="button border-button no-hover small-size">
                                        {getLangText('edit')}
                                        <i className="icon-arrow"/>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div> :
                <div className="form-info-box w-100">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div
                            className="title">{getLangText('settings_payments_transfer_no_additional_accounts')}</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ShippingList;
