import moment from 'moment';
import useLang from '../../../../../../../../../../../src_shared/hooks/useLang';

const Row = ({
    type,
    title,
    image,
    date,
    isLive,
    code,
    onSelect,
    isSelected = false,
}) => {
    const { getLangText } = useLang();

    const renderSubTitle = () => {
        if (type === 'product') {
            return (
                <p className="text-[12px] text-[#6B7280]">
                    <span className="font-medium">
                        {getLangText('codeLabel')}:{' '}
                    </span>
                    <span className="font-bold">{code}</span>
                </p>
            );
        }

        return (
            <p className="text-[12px] text-[#6B7280] font-medium">
                <span className="font-medium">
                    {moment(date).format('DD.MM.YYYY, HH:mm')}
                </span>
                <span className="mx-1">•</span>
                <span className={isLive ? 'text-[#FF3456]' : 'text-[#6B7280]'}>
                    {getLangText(isLive ? 'live' : 'liveEnded').toLowerCase()}
                </span>
            </p>
        );
    };

    const renderButtonText = () => {
        if (type === 'product') {
            return getLangText(
                isSelected ? 'selectedProductLabel' : 'selectProductLabel',
            );
        }

        return getLangText(
            isSelected ? 'selectedLiveLabel' : 'selectLiveLabel',
        );
    };

    return (
        <div
            className={`flex items-center gap-2 h-[64px] border-[1px] ${
                isSelected
                    ? 'border-[#3C61EA] bg-[#F7F9FE]'
                    : 'border-[#E5E7EB]'
            } rounded-[8px] p-[12px] transition-[border-color,background-color] duration-300 ease-in-out`}>
            <img
                src={
                    image ??
                    `/assets/images/svg/${type === 'product' ? 'products' : 'broadcast'}-icon.svg`
                }
                alt={title}
                className="w-8 h-8 rounded-sm"
            />
            <div className="flex-1 min-w-0">
                <p className="text-sm font-bold text-[#101010] overflow-hidden text-ellipsis whitespace-nowrap">
                    {title}
                </p>
                {renderSubTitle()}
            </div>
            <button
                type="button"
                className={`button ${
                    isSelected ? 'primary p-0' : 'border-button'
                } h-[31px] w-[135px] !font-bold transition-all duration-300 ease-in-out`}
                onClick={onSelect}>
                {renderButtonText()}
            </button>
        </div>
    );
};

export default Row;
