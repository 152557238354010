import React from "react";
import {RouterPaths} from "../../../../routes/RouterPath";
import useTableRowClick from "../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../shared/EmptyList";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import ImageWithFallback from "./ImageWithFallback";
import {useUnitPrice} from "../../../shared/helpers/Price";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(RouterPaths.LiveOverlay);
    const {getPrice} = useUnitPrice();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table smaller-padd table-fixed">
                        <thead>
                        <tr>
                            <th className="w-[350px]">
                                {getLangText('nameLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
                                    <span name="date">
                                        {getLangText('dateLabel')}
                                    </span>
                            </th>
                            <th
                                name="is_mobile_app_live"
                                className={`text-right whitespace-nowrap`}
                            >
                                {getLangText('platformLabel')}
                            </th>
                            <th
                                name="add_to_cart_total"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('add_to_cart_total')}`}
                            >
                                    <span name="add_to_cart_total">
                                        {getLangText('incomeLabel')}
                                    </span>
                            </th>
                            <th
                                name="reserve_list_total"
                                onClick={updateSortOptions}
                                className={`text-right whitespace-nowrap ${getSortClass('reserve_list_total')}`}
                            >
                                    <span name="reserve_list_total">
                                        {getLangText('reserveListLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className={!!+item.live_broadcast ? 'bg-blue-50' : ''}
                                {...onTableRowClick(item.id)}>
                                <td
                                    style={{color: 'black'}}
                                    className="w-100 text-black sm:mb-0 mb-1"
                                >
                                    <div class={`flex ${!!+item.live_broadcast ? '' : 'items-center'}`}>
                                        <figure
                                            className="w-[32px] h-[32px] min-w-[32px] flex items-center bg-[#f9f9f9] rounded-medium overflow-hidden mr-2.5">
                                            {item.poster_url && item.poster_url !== '0' ?
                                                <ImageWithFallback
                                                    src={item.poster_url}
                                                    alt={item.title}
                                                    className="w-full h-full object-cover"
                                                    fallbackSrc="/assets/images/empty/live-placeholder.svg"
                                                /> :
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src="/assets/images/empty/live-placeholder.svg" alt=""/>
                                            }
                                        </figure>
                                        <div>
                                            {item.title || getLangText('noNameLabel')}
                                            {!!+item.live_broadcast &&
                                                <div className="flex items-center">
                                                    <div className="relative flex h-2.5 w-2.5">
                                                        <div
                                                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"/>
                                                        <div
                                                            className="relative inline-flex rounded-full h-2.5 w-2.5 bg-blue-500"/>
                                                    </div>
                                                    <div className="font-bold text-xs text-blue-500 ml-1">
                                                        {getLangText('live')}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td
                                    data-thead={`${getLangText('createdLabel')}`}
                                    className="text-sm-right date w-lg-auto w-100 with-mobile-thead mb-lg-0 text-nowrap"
                                >
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                                <td className="text-right">
                                    <div class="flex space-x-1 sm:justify-end">
                                        <img
                                            className="inline-block w-[18px]"
                                            src={`${!!item.facebook_live_id ? '/assets/images/svg/fb-circle-blue.svg' : '/assets/images/svg/fb-circle-grey.svg'}`}
                                            alt=""/>
                                        <img
                                            className="rounded-full w-[18px] overflow-hidden inline-block"
                                            src={`${!!+item.is_mobile_app_live ? '/assets/images/svg/selmo-circle-blue.svg' : '/assets/images/svg/selmo-circle-grey.svg'}`}
                                            alt=""/>
                                    </div>
                                </td>
                                <td
                                    data-thead={`${getLangText('incomeLabel')}:`}
                                    className="text-right whitespace-nowrap with-mobile-thead inline-thead">
                                    {getPrice(item?.add_to_cart_total)}
                                </td>
                                <td
                                    data-thead={`${getLangText('reserveListLabel')}:`}
                                    className="text-right whitespace-nowrap with-mobile-thead inline-thead">
                                    {getPrice(item?.reserve_list_total)}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
