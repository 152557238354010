import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const SeeReviews = ({reviewsSectionRef}) => {
    const {getLangText} = useLang();

    const handleScrollToSection = () => {
        reviewsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="flex items-center justify-center mt-6">
            <img
                className="w-[81px]"
                src="/assets/images/marketing/shops.webp" alt=""/>
            <div className="ml-[12px]">
                <img
                    className="h-[15px] mb-[2px]"
                    src="/assets/images/marketing/stars.svg" alt=""/>
                <button
                    class="text-sm font-medium text-[#1f2937]"
                    type="button"
                    onClick={handleScrollToSection}
                >
                    <span className="underline">{getLangText('seeReviews')}</span>
                    <i class="icon-arrow-down text-[#9ca3af] text-[8px] ml-1" />
                </button>
            </div>
        </div>
    );
};

export default SeeReviews;