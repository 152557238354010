import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterPaths } from './RouterPath';
import FacebookMarketingDetails from '../app/marketing/facebook/submitted/advertisements/form/Form';
import FacebookMarketingDetailsStats from '../app/marketing/facebook/submitted/advertisements/stats/FacebookMarketingDetailsStats';
import { AdsPermissionRoute } from './AdsPermissionRoute';
import { Helmet } from 'react-helmet';
import useLang from '../../src_shared/hooks/useLang';
import MarketingPermissionsRoute from './MarketingPermissionsRoute';

const MarketingDetailsRoute = ({ path }) => {
    const { getLangText } = useLang();

    return (
        <Route path={path}>
            <AdsPermissionRoute>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{getLangText('facebookAdds')} - Selmo</title>
                </Helmet>
                <Switch>
                    <MarketingPermissionsRoute
                        component={FacebookMarketingDetailsStats}
                        exact
                        path={
                            RouterPaths.MarketingFacebookAdvertisementStatistics
                        }
                    />
                    <MarketingPermissionsRoute
                        component={FacebookMarketingDetails}
                        exact
                        path={RouterPaths.MarketingFacebookAdvertisement}
                    />
                </Switch>
            </AdsPermissionRoute>
        </Route>
    );
};

export default MarketingDetailsRoute;
