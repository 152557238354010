const ImageWithFallback = ({ src, alt, fallbackSrc, className }) => {
    return (
        <img
            src={src}
            className={className}
            alt={alt}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackSrc;
            }}
        />
    );
};

export default ImageWithFallback;