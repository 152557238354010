import Model, {
    convertObjectProperties,
} from '../../../src_shared/modules/model/Model';
import ValidationError from '../../../src_shared/form/validation/ValidationError';
import moment from 'moment';
import {
    convertToUserTimeZone,
    convertToWarsawTimeZone,
} from '../../../components/app/shared/helpers/dateHelpers';
class MarketingSmsCampaignFormModel extends Model {
    getModel() {
        return {
            message: '',
            send_later_active: '0',
            send_later_date: null,
            product_id: null,
            live_id: null,
            url: null,
            available_receivers: null,
            receivers: '0',
            submit_action: 'save-draft',
        };
    }

    getValidators() {
        return {
            message: (value, data) =>
                data.submit_action === 'send-sms'
                    ? ValidationError.notEmpty(value)
                    : ValidationError.skip(),
            send_later_date: (value, data) =>
                !!+data.send_later_active
                    ? ValidationError.notEmpty(value)
                    : ValidationError.skip(),
            send_later_time: (value, data) =>
                !!+data.send_later_active
                    ? ValidationError.notEmpty(value)
                    : ValidationError.skip(),
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                send_later_date: (send_later_date) =>
                    send_later_date
                        ? convertToUserTimeZone(send_later_date).toDate()
                        : convertToUserTimeZone().toDate(),
                receivers: (receivers) =>
                    +receivers > 0
                        ? receivers
                        : String(Math.round(data.available_receivers / 2)),
            },
            {
                message: '',
                send_later_active: '0',
                send_later_date: convertToUserTimeZone().toDate(),
                send_later_time: !!+data.send_later_active
                    ? convertToUserTimeZone(data.send_later_date).format(
                          'HH:mm',
                      )
                    : convertToUserTimeZone().format('HH:mm'),
                url: null,
                available_receivers: null,
                receivers: '0',
            },
        );
    }

    buildDTO(data) {
        const preparedDateTime = (date, time) => {
            const preparedDate = moment(date).format('YYYY-MM-DD');
            return convertToWarsawTimeZone({ date: preparedDate, time }).format(
                'YYYY-MM-DD HH:mm',
            );
        };

        return {
            message: data.message,
            product_id: data.product_id,
            live_id: data.live_id,
            send_later_active: data.send_later_active,
            send_later_date: !!+data.send_later_active
                ? preparedDateTime(data.send_later_date, data.send_later_time)
                : null,
            url: data.url,
            receivers: data.receivers,
        };
    }
}

export default new MarketingSmsCampaignFormModel();
