import React, {useState} from 'react';
import Loader from "../../../shared/Loader";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import {services} from "../../../../RestServices";
import AssignToPlace from "../../../storage/scanner/assignToPlace/AssignToPlace";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    WIKA_EWA_FASHION_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {useSelector} from "react-redux";

const ShelfInfo = ({values, fetchData}) => {

    const {print, waiting: waitingForQRCode} = usePrintFile({
        rest: `${services.API_ORDER_QR_CODE}/${values.id}`,
    })
    const {userData} = useSelector((state) => state.session);
    const canAddProducts = (+userData.id !== CHIQUE_SHOP_ID && +userData.id !== WIKA_EWA_FASHION_ID && +userData.id !== BUTIK_MADAME_SHOP_ID)
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="summary-section mb-3">
            <div className="flex items-center justify-between">
                <div className="font-bold">
                    {getLangText('warehouseLocationLabel')}
                </div>
                <button
                    className={`button border-button small-size left-icon ${waitingForQRCode ? 'loading-button' : ''}`}
                    onClick={print}
                    type="button"
                >
                    {waitingForQRCode && <Loader isLoading/>}
                    <i className="icon-qr-code"/>
                    QR kod
                </button>
            </div>
            {/*{values.shippingData.shelf_name ?*/}
            {/*    values.shippingData.shelf_name :*/}
            {/*    getLangText('lackLabel')*/}
            {/*}*/}
            <div className="flex items-center justify-center my-3">
                {values.shippingData.shelf_name ?
                    <div
                        className="text-[#6b7280] inline-flex justify-center mx-auto text-[38px] leading-[38px] font-bold shadow-default-small p-2 rounded-medium border">
                        {values.shippingData.shelf_name}
                    </div> :
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="flex items-center space-x-1">
                        <div className="flex items-center justify-center shadow-default-small p-2 rounded-medium border text-[#d1d5db] text-xs w-[45px] h-[55px]">
                            <i className="icon-plus"/>
                        </div>
                        <div className="flex items-center justify-center shadow-default-small p-2 rounded-medium border text-[#d1d5db] text-xs w-[45px] h-[55px]">
                            <i className="icon-plus"/>
                        </div>
                        <div className="flex items-center justify-center shadow-default-small p-2 rounded-medium border text-[#d1d5db] text-xs w-[45px] h-[55px]">
                            <i className="icon-plus"/>
                        </div>
                    </button>
                }
            </div>
            <div className="space-y-2 flex flex-col mt-2">
                <button
                    className="button border-button big-width"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    {getLangText('assignPlace')}
                </button>
                <Link
                    to={`${RouterPaths.StorageScanner}/order/${values.id}?clickAction=1`}
                    className="button border-button big-width left-icon"
                    type="button"
                >
                    <i className="icon-unpacked text-sm"/>
                    {getLangText('completeOrderLabel')}
                </Link>
                {canAddProducts &&
                    <Link
                        to={`${RouterPaths.StorageScanner}/order/${values.id}?clickAction=1&defaultView=2`}
                        className="button border-button big-width left-icon"
                        type="button"
                    >
                        <i className="icon-qr-code"/>
                        {getLangText('addProducts')}
                    </Link>
                }
            </div>
            {
                showModal &&
                <AssignToPlace
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    rest={services.API_WAREHOUSE_ORDER}
                    fetchData={() => fetchData(values.shop_order_id)}
                    orderId={values.id}
                />
            }
        </div>
    )
        ;
};

export default ShelfInfo;
