import {useSelector} from "react-redux";
import {SHOP_ORIGIN_US} from "../enums/ShopOrigins";

export const useUnitPrice = () => {
    const { userData } = useSelector((state) => state.session);

    const formatNumberWithSpaces = (number) => {
        return new Intl.NumberFormat('pl-PL', {
            useGrouping: true,
            minimumFractionDigits: 0,
        }).format(number);
    };

    const parsePrice = (price) => {
        const cleanedPrice = price
            ?.toString()
            .replace(/ /g, '')
            .replace(',', '.');

        const parsedPrice = parseFloat(cleanedPrice);

        return isNaN(parsedPrice) ? 0 : parsedPrice;
    };

    const getPrice = (priceAmount = 0, unit = userData?.currency_info?.text) => {
        const numericPrice = parsePrice(priceAmount);
        const formattedPrice = formatNumberWithSpaces(numericPrice);

        if (userData.origin === SHOP_ORIGIN_US) {
            return `${unit}${formattedPrice.replace(',', '.')}`;
        }

        return `${formattedPrice.replace('.', ',')} ${unit}`;
    };

    return {
        getPrice,
    };
};
