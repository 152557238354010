import createReducer from "../../../shared/helpers/createReducer";
import history from "../../../../history";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../shared/enums/OrderViewTypes";
import {parseParams} from "../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../src_shared/filters/filterReducer";
import {ADMIN_SHOP_STATUSES} from "../../../shared/enums/AdminShopStatus";
import {ADMIN_SHOP_SOURCES} from "../../../shared/enums/AdminShopSource";
import {ADMIN_SELLERS} from "../../../shared/enums/AdminSellers";
import {ORIGIN_TYPES} from "../../../shared/enums/OriginTypes";
import {ADMIN_ADVERTS} from "../../../shared/enums/AdminAdverts";
import {ADMIN_SIGNUP_STATUSES} from "../../../shared/enums/AdminSignupStatuses";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedSource = parsedParams['source[]'] || [];
const preparedAdverts = parsedParams['adverts[]'] || [];
const preparedStatuses = parsedParams['status[]'] || [];
const preparedOrigin = parsedParams['origin[]'] || [];
const preparedExtraStatus = parsedParams['extra_status[]'] || [];
const preparedSellers = parsedParams['seller[]']?.map((i) => i) || [];
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : ALL_ORDERS_VIEW;
const active = parsedParams.active || '1'

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.admin.marketing.sms.filters;

export const getInitState = () => DataReducer.getInitState({
	statuses: preparedStatuses || [],
	filtersSelectAll: preparedStatuses.length === ADMIN_SHOP_STATUSES.length,
	source: preparedSource || [],
	adverts: preparedAdverts || [],
	seller: preparedSellers || [],
	origin: preparedOrigin || [],
	extraStatus: preparedExtraStatus || [],
	extraStatusSelectAll: preparedExtraStatus.length === ADMIN_SIGNUP_STATUSES.length,
	originSelectAll: preparedOrigin.length === ORIGIN_TYPES.length,
	sourceSelectAll: preparedSource.length === ADMIN_SHOP_SOURCES.length,
	sellerSelectAll: preparedSellers.length === ADMIN_SELLERS.length,
	advertsSelectAll: preparedAdverts.length === ADMIN_ADVERTS.length,
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
	active,
}, {
	statuses: [],
	filtersSelectAll: false,
	extraStatus: [],
	extraStatusSelectAll: false,
	source: [],
	origin: [],
	adverts: [],
	seller: [],
	sourceSelectAll: false,
	originSelectAll: false,
	advertsSelectAll: false,
	sellerSelectAll: false,
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: new Date(),
	viewType: INTERVAL,
	active: '1',
})

export const prefix = 'ADMIN_MARKETING_SMS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
