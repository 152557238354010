import SessionActions from '../../../components/app/shared/session/SessionActions';

export const createActions = (prefix) => ({
    getListRequested() {
        return {
            type: `${prefix}GET_LIST_REQUESTED`,
        };
    },

    getListItems(items) {
        return {
            type: `${prefix}GET_LIST_ITEMS`,
            items,
        };
    },

    getListError(e) {
        return (dispatch) => {
            if (e.message === 'canceled') {
                return;
            }
        };
    },

    updateSearch: (searchText) => ({
        type: `${prefix}TABLE_UPDATE_SEARCH`,
        searchText,
    }),
    addItem: (item) => ({
        type: `${prefix}TABLE_ADD_ITEM`,
        item,
    }),
    addItemToBegin: (item) => ({
        type: `${prefix}TABLE_ADD_TO_BEGIN`,
        item,
    }),
    updateItem: (id, field, value) => ({
        type: `${prefix}TABLE_UPDATE_ITEM`,
        id,
        field,
        value,
    }),
    updateAllItems: (items) => ({
        type: `${prefix}TABLE_UPDATE_ALL_ITEMS`,
        items,
    }),
    markItemToRemove: (itemId) => ({
        type: `${prefix}TABLE_MARK_ITEM_TO_REMOVE`,
        itemId,
    }),
    removeItem: (itemId) => ({
        type: `${prefix}TABLE_REMOVE_ITEM`,
        itemId,
    }),
    removeAll: () => ({
        type: `${prefix}TABLE_REMOVE_ALL`,
    }),
    updateSort: (sortBy, sortDir) => {
        console.log(prefix);
        return {
            type: `${prefix}TABLE_UPDATE_SORT`,
            sortBy,
            sortDir,
        };
    },
    setDataPreloaded: (preloaded) => ({
        type: `${prefix}TABLE_SET_PRELOADED`,
        preloaded,
    }),
});

export default {
    createActions,
};
