import React from 'react';
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import {resetAndUpdateClients} from "../../LiveDetailsActions";
import ConfirmModal from "../../../../../orders/list/components/export/ConfirmModal";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const RestartBroadcast = ({shopLiveId, liveId}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = React.useState(false);
    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_LIVE_RESTART_CART}/${shopLiveId}`,
        afterSubmit: () => {
            dispatch(showAlert())
            dispatch(resetAndUpdateClients(liveId));
        },
    });

    return (
        <>
            <button
                type="button"
                className="button border-button"
                onClick={() => setShowModal(true)}
            >
                Restart this broadcast
                <i className="icon-refresh"/>
            </button>
            {showModal &&
                <ConfirmModal
                    title="Are you sure you want to reset this broadcasts?"
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default RestartBroadcast;
