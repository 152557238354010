import React, { useEffect, useState } from 'react';
import useLang from '../../../../src_shared/hooks/useLang';
import useCSSClass from '../../messenger/hooks/useCSSClass/useCSSClass';
import Loader from '../../../../src_shared/components/loader/Loader';
import Field from '../../../../src_shared/components/form/field/Field';
import useFormLocal from '../../../../src_shared/hooks/useFormLocal';
import { services } from '../../../RestServices';
import { marketingTabOptions } from '../Marketing';
import InvoiceData from '../../payments/invoiceData/InvoiceData';
import SummerPromoModel from '../../../../modules/models/summerPromo/SummerPromo';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

export const facebookMarketingPaymentMethods = [
    {
        id: 'p24',
        label: 'settings_payments_fast_payment_online',
        icon: 'przelewy24',
    },
    {
        id: 'card',
        label: 'paymentCard',
        icon: 'cards',
    },
];

const AdvertsInfoModal = ({
    show,
    hideModal,
    isFinished,
    isLoading,
    fixed = true,
}) => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);
    const active = true;
    const finished = active && isFinished;
    const [successReserveList, setSuccessReserveList] = useState(false);

    const statuses = {
        facebook: userData.facebook_ads_active,
        sms: userData.sms_active,
        // app: +userData.app_active,
        // email: +userData.email_active,
    };

    const getData = () =>
        marketingTabOptions({ origin: userData.origin, statuses }).find(
            (item) => item.id === show,
        );

    const [
        {
            isLoading: isLoadingForData,
            updateValues,
            values,
            getFieldProps,
            onSave,
            setValue,
            isWaiting,
        },
    ] = useFormLocal({
        rest: `${services.API_MARKETING_SUBMIT}/${show}${fixed ? '' : '?extend=1'}`,
        model: SummerPromoModel,
        afterSubmit: (response) => {
            if (finished) {
                setSuccessReserveList(true);
            } else {
                window.location.href = response.url;
            }
        },
        initialAutoLoad: active,
    });

    useEffect(() => {
        if (isFinished && !isLoadingForData) {
            setValue('finished', true);
        }
    }, [isFinished, isLoadingForData]);

    return (
        <Modal
            dialogClassName={`default-modal small-modal`}
            contentClassName="rounded-xl overflow-hidden p-7 pt-12"
            show={show}
            onHide={hideModal}
            centered={true}>
            {fixed && (
                <button
                    onClick={hideModal}
                    type="button"
                    className="absolute right-3 top-3 w-[28px] h-[28px] text-[#101827] bg-[#f3f4f6] flex items-center justify-center text-[8px] rounded-full">
                    <i className="icon-cross" />
                </button>
            )}
            <div className="relative">
                <Loader
                    isLoading={isLoading || isLoadingForData}
                    style={{ minHeight: '200px' }}
                />
                {successReserveList ? (
                    <div className="flex items-start">
                        <div className="mr-2 -mt-1">
                            <i className="icon-tick-c text-[#5FA5FA] text-xl" />
                        </div>
                        <div>
                            <div className="text-[#101827] font-bold">
                                {getLangText('helpModalSuccessTitle')}
                            </div>
                            <div className="text-desc text-sm font-medium">
                                {getLangText(
                                    'marketingFinishRegistrationDescription',
                                )}
                            </div>
                        </div>
                    </div>
                ) : finished || active ? (
                    <div className={fixed ? 'px-0.5' : 'px-2'}>
                        {finished && (
                            <div className="relative w-[44px] mx-auto">
                                <img
                                    className="w-full"
                                    src={`/assets/images/svg/marketing/fb.svg`}
                                    alt="icon"
                                />
                                <img
                                    className="absolute w-[20px] -bottom-1 -right-1"
                                    src="/assets/images/svg/circle-cross.svg"
                                    alt=""
                                />
                            </div>
                        )}
                        {fixed && (
                            <>
                                <div
                                    className={`text-lg font-bold mt-2 ${finished ? 'text-center' : ''}`}
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText(
                                            finished
                                                ? 'applicationStopped'
                                                : getData()?.title,
                                        ),
                                    }}
                                />
                                <div
                                    className={`font-medium text-sm text-desc mb-4 ${finished ? 'text-center' : ''}`}
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText(
                                            finished
                                                ? 'registrationClosedTitle'
                                                : getData()?.desc,
                                        ),
                                    }}
                                />
                            </>
                        )}
                        {finished ? (
                            <Field
                                {...getFieldProps('phone')}
                                setValue={setValue}
                                label={getLangText('typePhoneNumberLabel')}
                                floatedLabel
                                className="big-size"
                            />
                        ) : (
                            <InvoiceData
                                withLabel={false}
                                getFieldProps={getFieldProps}
                                setValue={setValue}
                                updateValues={updateValues}
                                values={values}
                                inputClassName="default-size big-size"
                                setValueAndUpdatePrice={setValue}
                                selectId="custom-dropdown-default"
                            />
                        )}
                        {!finished && (
                            <div className="mb-4">
                                <div className="mb-4 mt-4">
                                    <div className="or-separator text-center">
                                        <div
                                            style={{ color: '#4b5563' }}
                                            className="text-[#4b5563]">
                                            {getLangText('paymentMethodLabel')}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-2 gap-3">
                                    {facebookMarketingPaymentMethods.map(
                                        (item) => (
                                            <button
                                                type="button"
                                                key={item.id}
                                                onClick={() =>
                                                    setValue(
                                                        'selected_payment',
                                                        item.id,
                                                    )
                                                }
                                                className={`border rounded-[8px] p-3 block text-left transition-all ${item.id === values.selected_payment ? 'border-[#3c61ea] bg-[#f7f9fe]' : 'border-[#e5e7eb]'}`}>
                                                <img
                                                    className="w-[80px] mx-auto"
                                                    src={`/assets/images/payments/${item.icon}.svg`}
                                                    alt=""
                                                />
                                                <div className="font-bold text-sm text-center mt-2">
                                                    {getLangText(item.label)}
                                                </div>
                                            </button>
                                        ),
                                    )}
                                </div>
                            </div>
                        )}
                        <button
                            onClick={onSave}
                            type="button"
                            style={{
                                borderRadius: 8,
                                paddingTop: 17,
                                paddingBottom: 17,
                            }}
                            className={`button primary w-full black ${isWaiting ? 'loading-button' : ''}`}>
                            {isWaiting && <Loader isLoading />}
                            {getLangText(
                                finished
                                    ? 'notifyAboutAvailability'
                                    : fixed
                                      ? 'applyToParticipate'
                                      : 'extendAccess',
                            )}
                        </button>
                        {isFinished && (
                            <button
                                onClick={hideModal}
                                type="button"
                                style={{
                                    borderRadius: 8,
                                    paddingTop: 17,
                                    paddingBottom: 17,
                                    fontSize: 14,
                                }}
                                className={`button border-button w-full mt-2`}>
                                {getLangText('closeButton')}
                            </button>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="p-2 rounded-[5px] inline-flex items-center text-xs font-bold text-[#3b82f6] ml-auto bg-[#F0FAFF] mb-2">
                            {getLangText('soonRegistration')}
                        </div>
                        <div
                            className="text-[26px] leading-[33px] font-bold whitespace-pre-wrap mb-3"
                            dangerouslySetInnerHTML={{
                                __html: getLangText(getData()?.soonTitle),
                            }}
                        />
                        <div className="text-desc text-sm leading-[26px] font-medium">
                            {getLangText(getData()?.soonDesc)}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AdvertsInfoModal;
