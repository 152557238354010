import React, {useEffect, useState} from 'react';
import CheckboxField from "../../../../../src_shared/components/form/checkboxField/CheckboxField";
import RegulationModal from "../../deliveryAddress/components/RegulationModal";
import {services} from "../../../../RestServices";
import {useModal} from "../../../shared/hooks/useModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getBasketPageStateRoot} from "../../page/formReducer";
import CollapseAgreement from "./CollapseAgreement";

const AgreementCheckbox = ({getFieldProps, setValue, className, data, values}) => {
        const {getLangText} = useLang();
        const {shopName} = useParams();
        const shopId = shopName?.split('-')[shopName.split('-').length - 1];
        const {shopInfo} = useSelector(createSelector(getBasketPageStateRoot, (stateRoot) => stateRoot.data));
        const [isAllSelected, setIsAllSelected] = useState(false);
        const agreementVisible = !!+data.shopInfo?.agreements_enabled;

        const [openRegulationsModal, setOpenRegulationsModal] = useModal(false);
        const [openShopRegulationsModal, setOpenShopRegulationsModal] = useModal(false);
        const [openPolicyModal, setOpenPolicyModal] = useModal(false);

        const regulationModalButton = () => {
            return (
                shopInfo.has_terms ?
                    <button
                        style={{position: "relative", zIndex: '1', textDecoration: 'underline'}}
                        type="button"
                        onClick={() => setOpenShopRegulationsModal(true)}
                    >
                        {getLangText('customerBasketAgreementLabel2')}
                    </button> :
                    <button
                        style={{position: "relative", zIndex: '1', textDecoration: 'underline'}}
                        type="button"
                        onClick={() => setOpenRegulationsModal(true)}
                    >
                        {getLangText('customerBasketAgreementLabel2')}
                    </button>
            )
        }

        const policyModalButton = () => (
            <button
                style={{position: "relative", zIndex: '1', textDecoration: 'underline'}}
                type="button"
                onClick={() => setOpenPolicyModal(true)}
            >
                {getLangText('customerBasketAgreementLabel4')}
            </button>
        )

        const checkboxLabel = () => {
            if (shopInfo.has_policy) {
                return (
                    <>
                        {getLangText('customerBasketAgreementLabel1')}
                        &nbsp;
                        {regulationModalButton()}
                        &nbsp;
                        {getLangText('customerBasketAgreementLabel3')}
                        &nbsp;
                        {policyModalButton()}*
                    </>
                )
            }
            return (
                <>
                    {getLangText('customerBasketAgreementLabel1')}
                    &nbsp;
                    {regulationModalButton()}*
                </>
            )
        }

        const handleAllAgreements = (field, value) => {
            setIsAllSelected(value)
            const agreementObject = {
                agreement: value,
                selmo_marketing_agreement: value,
                shop_marketing_agreement: value,
            }
            setValue('', agreementObject)
        }

        useEffect(() => {
            if (!!+values.agreement && !!+values.selmo_marketing_agreement && !!+values.shop_marketing_agreement) {
                setIsAllSelected(true)
            } else {
                setIsAllSelected(false);
            }
        }, [values.agreement, values.selmo_marketing_agreement, values.shop_marketing_agreement])

        return (
            <div>
                {agreementVisible &&
                    <div className="border-b-[#F3F4F6] pb-[20px] border-b mb-4">
                        <CheckboxField
                            value={isAllSelected}
                            setValue={handleAllAgreements}
                            className="big-label mb-0"
                            label={getLangText('selectAllAgreements')}
                        />
                    </div>
                }
                <CheckboxField
                    {...getFieldProps('agreement')}
                    LabelComponent={checkboxLabel()}
                    setValue={setValue}
                    className={className}
                />
                {agreementVisible &&
                    <>
                        <CollapseAgreement
                            desc="selmoMarketingAgreementExpanded">
                            <CheckboxField
                                {...getFieldProps('selmo_marketing_agreement')}
                                label={getLangText('selmoMarketingAgreement')}
                                setValue={setValue}
                                className={className}
                            />
                        </CollapseAgreement>
                        <CollapseAgreement desc={{key: 'shopMarketingAgreementExpanded', data: [data?.shopInfo?.name]}}>
                            <CheckboxField
                                {...getFieldProps('shop_marketing_agreement')}
                                label={getLangText({key: 'shopMarketingAgreement', data: [data?.shopInfo?.name]})}
                                setValue={setValue}
                                className={className}
                            />
                        </CollapseAgreement>
                    </>
                }
                {openRegulationsModal &&
                    <RegulationModal
                        modalVisible={openRegulationsModal}
                        hideModal={() => setOpenRegulationsModal(false)}
                        rest={services.API_CART_TERMS}
                        shopId={shopId}
                        title={getLangText('customerBasketRegulationsModalTitle')}
                    />
                }
                {openShopRegulationsModal &&
                    <RegulationModal
                        modalVisible={openShopRegulationsModal}
                        hideModal={() => setOpenShopRegulationsModal(false)}
                        rest={services.API_CART_SHOP_TERMS}
                        shopId={shopId}
                        title={`${getLangText('customerBasketShopRegulationsModalTitle')}${shopInfo?.name}`}
                    />
                }
                {openPolicyModal &&
                    <RegulationModal
                        modalVisible={openPolicyModal}
                        hideModal={() => setOpenPolicyModal(false)}
                        rest={services.API_CART_SHOP_POLICY}
                        shopId={shopId}
                        title={`${getLangText('customerBasketShopPolicyModalTitle')}${shopInfo?.name}`}
                    />
                }
            </div>
        );
    }
;

export default AgreementCheckbox;
