import history from '../../../../../history';
import { parseParams } from '../../../../../../src_shared/list/ListActions';
import { INTERVAL } from '../../../../shared/enums/OrderViewTypes';
import moment from 'moment/moment';
import DataReducer from '../../../../../../src_shared/form/data/DataReducer';
import createReducer from '../../../../shared/helpers/createReducer';
import { getReduceMap } from '../../../../../../src_shared/filters/filterReducer';

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams);

const preparedIsRealized = parsedParams.isRealized || '0';
const preparedIsNewClient = parsedParams.is_new_client || 'all';

export const getStateRoot = (state) => state.marketing.smsMarketing.filters;

export const getInitState = () =>
    DataReducer.getInitState(
        {
            fromDate: moment(new Date()).subtract(7, 'd').toDate(),
            toDate: moment().toDate(),
            viewType: INTERVAL,
            isRealized: preparedIsRealized,
            is_new_client: preparedIsNewClient,
        },
        {
            fromDate: moment(new Date()).subtract(7, 'd').toDate(),
            toDate: moment().toDate(),
            viewType: INTERVAL,
            isRealized: '0',
            is_new_client: 'all',
        },
    );

export const prefix = 'MARKETING_SMS_STATISTICS_FILTERS_';

export const getInstance = () =>
    createReducer(getInitState(), getReduceMap(), prefix);

export default getInstance();
