import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {getCurrenciesShortcut} from "../../../../../stats/list/List";
import {createSelector} from "reselect";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import {
    MARKETING_MORE_FAN_PAGE_LIKES_VIEW
} from "../../advertisements/list/components/SelectGoalModal";
import {useUnitPrice} from "../../../../../shared/helpers/Price";

const stats = [
    {id: 'ads_sale', label: 'salesValue', icon: 'icon3', currency: true},
    {id: 'new_clients_total', label: 'newClients', icon: 'user', currency: false},
    {id: 'budget_spent', label: 'budgetSpent', icon: 'icon1', currency: true},
]

const increaseLikesStats = [
    {id: 'observations_number', label: 'followersCount', icon: 'icon3', currency: false},
    {id: 'observations_cost', label: 'pricePerFollow', icon: 'icon2', currency: true},
    {id: 'budget_spent', label: 'budgetSpent', icon: 'icon1', currency: true},
]
const statsExtra = [
    // {id: 'spend', label: 'spend1', icon: 'icon3', currency: true},
    {id: 'impressions', label: 'impressions', icon: 'icon2', currency: false},
    {id: 'clicks', label: 'clicks', icon: 'icon1', currency: false},
    {id: 'reach', label: 'reach', icon: 'icon1', currency: false},
    {id: 'ctr', label: 'ctr', icon: '', currency: false},
    {id: 'cpm', label: 'cpm', icon: '', currency: true},
]

const StatsRow = ({data, isLoading}) => {
        const {getLangText} = useLang();
        const {userData} = useSelector((state) => state.session);
        const {getPrice} = useUnitPrice();

        const IconWrapper = ({children, bg = '#f0faff'}) => (
            <div
                style={{background: bg}}
                class="w-[29px] min-w-[29px] flex items-center justify-center h-[29px] bg-[#] rounded-full">
                {children}
            </div>
        )

        const {
            fromDate,
            toDate,
        } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

        const preparedStats = +data.ad_target === MARKETING_MORE_FAN_PAGE_LIKES_VIEW ? increaseLikesStats : stats;

        return (
            +userData.id !== 40 ?
                <div className="stats-row in-marketing md:grid-cols-4 gap-2">
                    {preparedStats.map((item, index) => (
                        <div>
                            <div className="label">
                                {getLangText(item.label)}
                                <IconWrapper>
                                    <img
                                        className="text-xs"
                                        style={{marginRight: 0, width: 12}}
                                        src={`/assets/images/svg/facebookMarketing/${item.icon}.svg`} alt=""/>
                                </IconWrapper>
                            </div>
                            {isLoading ?
                                <div
                                    className="animate-pulse mt-auto h-[36px] bg-gray-200 rounded-big dark:bg-gray-700 w-[70px]"/> :
                                <div className="value">
                                    {item.id === 'new_clients_total' && !!+data[item.id] && '+'}
                                    {item.currency ? getPrice(data[item.id]) : data[item.id]}
                                </div>
                            }
                        </div>
                    ))}
                    <div
                        style={{borderColor: '#bfddfd'}}
                        className="bg-[#f0faff]">
                        <div className="label tooltip-parent">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: getLangText('roasInfo')
                                }}
                                style={{whiteSpace: 'wrap', paddingTop: 8, paddingBottom: 8}}
                                class="tooltip-content max-w-[260px] min-w-[260px]"/>
                            <span>
                                    {getLangText('roas')} (ROAS)
                                    <i class="icon-info-thin ml-1 mt-0.5"/>
                                </span>
                            <IconWrapper bg="#d9f2ff">
                                <img
                                    style={{marginRight: 0, width: 12}}
                                    src="/assets/images/svg/facebookMarketing/icon4.svg" alt=""/>
                            </IconWrapper>
                        </div>
                        {isLoading ?
                            <div
                                className="animate-pulse mt-auto h-[36px] bg-gray-200 rounded-big dark:bg-gray-700 w-[70px]"/> :
                            <div className="value flex items-center">
                                {+data.roi_value > 1 ? `${data.roi_value}x` : '-'}
                                {+data.roi_value > 1 &&
                                    <i className="icon-arrow-up ml-2 mt-1 text-[#0eb981] text-[18px]"/>
                                }
                            </div>
                        }
                    </div>
                </div> :
                <div className="stats-row in-marketing md:grid-cols-4 gap-2">
                    <div
                        style={{borderColor: '#bfddfd'}}
                        className="bg-[#f0faff]">
                        <div className="label">
                            {getLangText('spend1')}
                            <IconWrapper bg="#d9f2ff">
                                <img
                                    style={{marginRight: 0, width: 12}}
                                    src="/assets/images/svg/facebookMarketing/icon4.svg" alt=""/>
                            </IconWrapper>
                        </div>
                        {isLoading ?
                            <div
                                className="animate-pulse mt-auto h-[36px] bg-gray-200 rounded-big dark:bg-gray-700 w-[70px]"/> :
                            <div className="value">
                                {getPrice(data.spend)}
                            </div>
                        }
                    </div>
                    {statsExtra.map((item, index) => (
                        <div>
                            <div className="label">
                                {getLangText(item.label)}
                                {item.icon &&
                                    <IconWrapper>
                                        <img
                                            className="text-xs"
                                            style={{marginRight: 0, width: 12}}
                                            src={`/assets/images/svg/facebookMarketing/${item.icon}.svg`} alt=""/>
                                    </IconWrapper>
                                }
                            </div>
                            {isLoading ?
                                <div
                                    className="animate-pulse mt-auto h-[36px] bg-gray-200 rounded-big dark:bg-gray-700 w-[70px]"/> :
                                <div className="value">
                                    {item.currency ? getPrice(data[item.id]) : data[item.id]}
                                </div>
                            }
                        </div>
                    ))}
                </div>
        );
    }
;

export default StatsRow;
