import {TEST_ACCOUNT_SHOP_ID, THE_PROMISE_BOUTIQUE_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

export const createNewId = () => new Date().getTime();

export const newCustomer = {
    id: createNewId(),
    name: '',
    color: '',
    size: '',
    quantity: 1,
    tabIndex: 1,
    variant_id: null,
    is_deleted: '0',
}

const newCustomerProduct = {
    id: createNewId(),
    name: '',
    price: '',
    quantity: 1,
    tabIndex: 1,
    is_deleted: '0',
    is_cancelled: '0',
    variant_id: null,
}

const product = {
    name: '',
    code: '',
    price: '',
    sizes: [],
    id: null,
}

const initialState = {
    isInfoLoading: true,
    isInfoError: false,
    waitingForStorageProductUpdate: false,
    liveInfo: {
        date: null,
        finished: 0,
        storage_products: '0',
        waitingForSummary: false,
        waitingForBasket: false,
        waitingForOrders: false,
    },
    lastFetchedCommentId: null,
    historyProductHints: [],
    storageProducts: [],
    isTotalPriceLoading: false,
    isCustomersLoading: false,
    isClientsLoading: true,
    clientsPage: 1,
    isCancelled: null,
    isSummarySent: null,
    sortBy: null,
    isCartStatusSent: null,
    isOrderCreated: null,
    hasMoreClients: false,
    isClientsError: false,
    isClientGroupLoading: false,
    clients: [],
    clientSearch: '',
    totalClients: 0,
    clientsRowsToAdd: 1,
    customers: [newCustomer],
    product,
    moreActionsClientDropdown: null,
    clientsConfirmModal: {
        modalType: false,
        isLoading: false,
        data: {
            basketState: '',
            orderLink: true,
        },
        title: '',
        desc: ''
    },
    customerDuplicateId: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIVE_DETAILS_UPDATE_FIELD':
            return {...state, [action.field]: action.value};
        case 'GET_LIVE_DETAILS_INFO_REQUESTED':
            return {...state, isInfoLoading: true};
        case 'GET_LIVE_DETAILS_INFO':
            return {
                ...state,
                isInfoLoading: false,
                isTotalPriceLoading: false,
                liveInfo: action.items,
                isInfoError: false
            };
        case 'GET_LIVE_DETAILS_INFO_TOTAL_PRICE_REQUEST':
            return {...state, isTotalPriceLoading: true};
        case 'LIVE_DETAILS_INFO_RESET':
            return {...state, isInfoLoading: false, liveInfo: initialState.liveInfo, isInfoError: false};
        case 'GET_LIVE_DETAILS_INFO_ERROR':
            return {...state, isInfoLoading: false, isInfoError: true}
        case 'GET_LIVE_DETAILS_CLIENTS_REQUESTED':
            return {...state, isClientsLoading: true};
        case 'LIVE_DETAILS_SET_LAST_FETCHED_COMMENT_ID':
            return {...state, lastFetchedCommentId: action.id};
        case 'LIVE_DETAILS_ADD_CUSTOMER_TO_PRODUCT_REQUEST':
            return {...state, isCustomersLoading: action.toggle};
        case 'LIVE_DETAILS_SET_NEW_PAGE':
            return {...state, clientsPage: state.clientsPage + 1};
        case 'LIVE_DETAILS_RESET_PAGE':
            return {
                ...state,
                clientsPage: initialState.clientsPage,
                isClientsLoading: true,
                hasMoreClients: false,
                clients: []
            };
        case 'LIVE_DETAILS_SET_HAS_MORE':
            return {...state, hasMoreClients: action.toggle};
        case 'GET_LIVE_DETAILS_CLIENTS':
            const preparedClients = () => {
                if (state.clientsPage === 1) {
                    return (
                        action.items.map((client) => ({
                            ...client,
                            facebook_id: client.facebook_client_id,
                            products: client.products.map((item) => ({
                                ...item,
                                tabIndex: 1,
                                sizes: item.sizes || []
                            }))
                        }))
                    )
                }
                return (
                    state.clients.concat(action.items.map((client) => ({
                        ...client,
                        facebook_id: client.facebook_client_id,
                        products: client.products.map((item) => ({
                            ...item,
                            tabIndex: 1,
                            sizes: item.sizes || []
                        }))
                    })))
                )
            }

            return {
                ...state, isClientsLoading: false,
                clients: preparedClients(),
                isClientsError: false
            };
        case 'GET_LIVE_DETAILS_CLIENTS_ERROR':
            return {...state, isClientsLoading: false, isClientsError: true}
        case 'LIVE_DETAILS_ADD_NEW_CUSTOMER':
            return {
                ...state,
                customers: [...state.customers, {
                    ...newCustomer,
                    id: createNewId(),
                    tabIndex: state.customers[state.customers.length - 1]?.tabIndex + 1
                }]
            }
        case 'LIVE_DETAILS_ADD_SEVERAL_CUSTOMERS':

            const {clientsRowsToAdd} = state;

            const rows = Array(+clientsRowsToAdd).fill({...newCustomer});
            const preparedRows = rows.map((i, key) => ({
                ...i,
                id: key + createNewId(),
            }))

            return {
                ...state,
                customers: [
                    ...state.customers,
                    ...preparedRows,
                ],
                clientsRowsToAdd: 1,
            }
        case 'LIVE_DETAILS_UPDATE_CUSTOMERS':
            return {...state, customers: action.customers}
        case 'LIVE_DETAILS_REMOVE_CUSTOMER':
            const filteredCustomers = state.customers.filter((i) => i.id !== action.id);
            if (state.customers.length === 1) {
                return {...state, customers: [newCustomer]}
            }
            return {...state, customers: filteredCustomers}
        case 'LIVE_DETAILS_REMOVE_CLIENT_FROM_STATE':
            const filteredClients = state.clients.filter((i) => i.id !== action.id);
            return {...state, clients: filteredClients}
        case 'LIVE_DETAILS_UPDATE_FIELD_IN_CUSTOMER':
            return {
                ...state,
                customers: state.customers.map((item) => item.id === action.id ? {
                    ...item,
                    [action.field]: action.value
                } : item)
            }
        case 'LIVE_DETAILS_UPDATE_FIELD_IN_CUSTOMERS_PRODUCT':
            return {
                ...state,
                clients: state.clients.map((client) => +client.shop_client_id === +action.shop_client_id ? {
                    ...client,
                    products: client.products.map((item) => item.id === action.productId ? {
                        ...item,
                        [action.field]: action.value
                    } : item)
                } : client)
            }
        case 'LIVE_DETAILS_UPDATE_PRODUCTS_IN_CLIENT':
            return {
                ...state,
                clients: state.clients.map((client) => +client.shop_client_id === +action.shop_client_id ? {
                    ...client,
                    products: action.products,
                } : client)
            }
        case 'LIVE_DETAILS_REMOVE_CUSTOMER_PRODUCT':
            const productsClient = state.clients.find((client) => +client.shop_client_id === +action.shop_client_id);
            if (productsClient.products.length === 1) {
                return {...state}
            }
            return {
                ...state,
                clients: state.clients.map((client) => +client.shop_client_id === +action.shop_client_id ? {
                    ...client,
                    products: client.products.filter((item) => item.id !== action.productId)
                } : client)
            }
        case 'LIVE_DETAILS_ADD_NEW_PRODUCT_TO_CUSTOMER':
            const getPreparedProduct = (client)  => {
                const {shop_id, shopify} = state.liveInfo;
                const clientIds = [
                    TEST_ACCOUNT_SHOP_ID,
                    THE_PROMISE_BOUTIQUE_SHOP_ID,
                ];

                const preparedProduct = {
                    ...newCustomerProduct,
                    id: createNewId(),
                    tabIndex: client.products[client.products.length - 1]?.tabIndex + 1
                }

                if (clientIds.includes(+shop_id) && !!+shopify) {
                    return {
                        ...preparedProduct,
                        taxable: true,
                    }
                }

                return preparedProduct
            }

            return {
                ...state,
                clients: state.clients.map((client) => +client.shop_client_id === +action.shop_client_id ? {
                    ...client,
                    products: [...client.products, getPreparedProduct(client)]
                } : client)
            }
        case 'LIVE_DETAILS_UPDATE_FIELD_IN_PRODUCT':
            return {
                ...state,
                product: {...state.product, [action.field]: action.value}
            }
        case 'LIVE_DETAILS_UPDATE_FIELDS_IN_PRODUCT':
            return {
                ...state,
                product: action.values,
            }
        case 'LIVE_DETAILS_RESET_ALL_CUSTOMERS':
            return {...state, customers: [newCustomer]}
        case 'LIVE_DETAILS_RESET_PRODUCT':
            return {...state, product}
        case 'LIVE_DETAILS_ADD_PRODUCTS_TO_CLIENT_REQUEST':
            return {...state, isClientGroupLoading: true}
        case 'LIVE_DETAILS_ADD_PRODUCTS_TO_CLIENT_REQUEST_DONE':
            return {...state, isClientGroupLoading: false}
        case 'MORE_ACTIONS_CLIENT_NAME_TOGGLE_VISIBILITY':
            return {...state, moreActionsClientDropdown: action.shop_client_id}
        case 'LIVE_DETAILS_CONFIRM_MODAL_VISIBILITY':
            const {data, modalType} = action;
            //check?
            return {
                ...state, clientsConfirmModal:
                    {
                        ...state.clientsConfirmModal,
                        isLoading: false,
                        modalType: modalType,
                        data: {
                            ...initialState.clientsConfirmModal.data,
                            ...data,
                        }
                    }
            }
        case 'LIVE_DETAILS_CONFIRM_MODAL_REQUEST_LOADING': {
            return {
                ...state, clientsConfirmModal: {
                    ...state.clientsConfirmModal,
                    isLoading: true,
                }
            }
        }
        case 'LIVE_DETAILS_UPDATE_FILED_IN_CONFIRM_MODAL':
            return {
                ...state, clientsConfirmModal:
                    {
                        ...state.clientsConfirmModal,
                        data: {
                            ...state.clientsConfirmModal.data,
                            [action.field]: action.value
                        }
                    }
            }
        default:
            return state;
    }
}
