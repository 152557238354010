import React from 'react';
import useLang from '../../../../../../src_shared/hooks/useLang';
import useList from '../../../../../../src_shared/list/useList';
import ListActions from './ListActions';
import FiltersActions from '../filters/FiltersActions';
import ListDetailsActions from './details/ListActions';
import { getStateRoot } from './listReducer';
import { getStateRoot as getDetailsStateRoot } from './details/listReducer';
import { getStateRoot as getFiltersStateRoot } from '../filters/filtersReducer';
import { useSelector } from 'react-redux';
import Table from './components/Table';
import StatsRow from './components/StatsRow';
import Pagination from '../../../../../admin/components/app/shared/components/pagination/Pagination';
import SingleSelectFiltersStatus from '../../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus';
import YES_NO_TYPES from '../../../../shared/enums/YesNoTypes';
import NewClients from './components/NewClients';
import DateRangePicker from '../../../../shared/components/form/DateRangePicker';

const Statistics = () => {
    const { getLangText } = useLang();
    const { userData } = useSelector((state) => state.session);

    // const [{data, isLoading}] = useFetchData({
    //     rest: services.API_MARKETING_AD_STATS_DETAILS,
    //     // initialAutoLoad: false,
    //     // afterSubmit,
    // });

    const {
        list: { items: data, isWaiting: isLoading, loadData },
    } = useList({
        ListActions: ListDetailsActions,
        getStateRoot: getDetailsStateRoot,
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage,
        },
        list: { items, isWaiting },
        search,
        sort: { updateSortOptions, getSortClass },
        remove: {
            askForRemoveItem,
            isModalVisible,
            cancelItemRemoval,
            markedToRemove,
        },
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <div className="">
            <div className="stats-wrapper bg-[#fff] p-6">
                <div class="flex items-center mb-2 sm:min-h-[45px]">
                    <div>
                        <div className="text-lg font-bold">
                            {getLangText('statisticsLabel')}
                        </div>
                        {+userData.id === 40 && (
                            <div className="text-desc font-medium">
                                Last 7 days account stats
                            </div>
                        )}
                    </div>
                    {/*{!!+data.new_clients_total &&*/}
                    {/*    <NewClients*/}
                    {/*        isLoading={isLoading}*/}
                    {/*        values={data} */}
                    {/*    />*/}
                    {/*}*/}

                    <div className="ml-auto">
                        <DateRangePicker
                            actions={ListDetailsActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            FiltersActions={FiltersActions}
                        />
                    </div>
                </div>
                <StatsRow isLoading={isLoading} data={data} />
            </div>
            <div>
                <div className="bg-[#fff] px-6 pb-3">
                    <div className="text-lg font-bold mb-2">
                        {getLangText('lastSold')}
                    </div>
                    <SingleSelectFiltersStatus
                        ListActions={ListActions}
                        FiltersActions={FiltersActions}
                        getFiltersStateRoot={getFiltersStateRoot}
                        options={YES_NO_TYPES}
                        name="is_new_client"
                        defaultSelectedLabel={getLangText('newClients')}
                        withSubLabel={true}
                    />
                </div>
                <Table
                    isWaiting={isWaiting}
                    items={items}
                    updateSortOptions={updateSortOptions}
                    getSortClass={getSortClass}
                    askForRemoveItem={askForRemoveItem}
                    markedToRemove={markedToRemove}
                />
                <Pagination
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect={false}
                />
            </div>
        </div>
    );
};

export default Statistics;
