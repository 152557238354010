import useLang from "../../../../src_shared/hooks/useLang";
import React, {useState} from "react";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import NotificationsModel from "../../../../modules/models/notifications/NotificationsModel";
import WaitingForSend from "../../../../src_shared/components/ui/waitingForSend/WaitingForSend";
import SentHistory from "../components/SentHistory";
import {facebookNotificationsSteps} from "../landing/Landing";
import SendNotificationsModal
    from "../../clients/lists/submittedClientsList/components/sendNotificationsModal/SendNotificationsModal";
import PromoNotificationBox from "../../clients/lists/submittedClientsList/components/PromoNotificationBox";

const FacebookNotifications = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    const [{isLoading, values, fetchData, setValue}] = useFormLocal({
        rest: services.API_MOBILE_MESSENGER_NOTIFICATIONS,
        model: NotificationsModel,
    });

    return (
        <>
            <div className="md:grid md:grid-cols-5 gap-4">
                <div className="md:col-span-3">
                    <div className="default-shadow-box bigger-y-padd mb-3">
                        <h2 className="font-bold">{getLangText('facebookNotifications')}</h2>
                        <div
                            className="font-medium text-desc text-sm mb-3.5">{getLangText('sendNotificationOnceIn24HoursLabel')}</div>
                        <div className="border flex items-center p-3 rounded-[5px] w-full">
                            <div className="grow">
                                <div
                                    className="text-sm text-desc font-medium">{getLangText('submittedCustomersLabel')}</div>
                                <div className="text-[30px] font-bold">
                                    {+values.total || '-'}
                                </div>
                            </div>
                            {!values.waitingForSend &&
                                <button
                                    type="button"
                                    onClick={() => setShowModal(true)}
                                    className="button primary small-size px-3"
                                >
                                    <i className="icon-notify mr-1"/>
                                    <span className="text-xs">{getLangText('sendNotificationsLabel')}</span>
                                </button>
                            }
                        </div>
                        {values.waitingForSend &&
                            <WaitingForSend
                                className="mt-2"
                                small
                                title="notificationsAreBeingSentLabel"
                                field="waitingForSendFacebookNotifications"
                                fetchData={fetchData}
                                rest={services.API_CLIENTS_SEND_NOTIFICATIONS_STATUS}
                                setValue={setValue}
                            />
                        }
                    </div>
                    {isLoading ?
                        <div className="animate-pulse">
                            <div className="h-[290px] bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3 p-3">
                                <div
                                    className="h-[19px] bg-gray-300 rounded-md dark:bg-gray-800 w-[250px] mb-3.5"/>
                                <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                                <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                                <div className="h-[60px] bg-gray-300 rounded-md dark:bg-gray-800 w-full mb-2"/>
                            </div>
                        </div> :
                        <SentHistory history={values.history}/>
                    }
                    <div className="default-shadow-box mb-3">
                        <h2 className="font-bold mb-3.5">{getLangText('whatHaveToDoYourClientsLabel')}</h2>
                        <div className="space-y-3">
                            {facebookNotificationsSteps.map((step) => (
                                <div
                                    key={step.id}
                                    className="p-[14px] rounded-[10px] flex items-center space-x-3 bg-[#F9FAFB]">
                                    <div
                                        className="sm:min-w-[32px] sm:text-[16px] text-sm min-w-[22px] h-[22px] sm:h-[32px] bg-[#E5E7EB] rounded-[5px] flex items-center justify-center font-bold">
                                    {step.id}
                                    </div>
                                    <div
                                        className="text-sm sm:whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{__html: getLangText(step.title)}}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="md:col-span-2 sm:mt-0 mt-[16px]">
                    <PromoNotificationBox block/>
                </div>
            </div>
            {showModal &&
                <SendNotificationsModal
                    afterSend={() => setValue('waitingForSend', true)}
                    isModalVisible={showModal}
                    cancelItemRemoval={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default FacebookNotifications;
