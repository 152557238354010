import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import moment from "../../messenger/helpers/moment";
import ServerImage from "../../shared/components/serverImage/ServerImage";
import Post from "../../news/components/Post";
import {useSelector} from "react-redux";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";
import AppReelsPost from "./AppReelsPost";
import VacationPromo from "./VacationPromo";

const BlogPost = ({item, className = ''}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <div className={className}>
            <div className="news-row">
                {/*<VacationPromo />*/}
                {/*{userData.origin === SHOP_ORIGIN_PL &&*/}
                {/*<AppReelsPost />*/}
                {/*}*/}
                <Post item={item}/>
            </div>
        </div>
    );
};

export default BlogPost;
