import React, {useEffect, useRef, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Redirect} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";
import Faq from "../../notifications/landing/components/Faq/Faq";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import InvoiceDataMarketingModal from "../components/InvoiceDataMarketingModal";
import Reviews from "./ladningComponents/Reviews";
import SeeReviews from "./ladningComponents/SeeReviews";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";
import useAssetsLang from "../../shared/helpers/useAssetsLang";

const questions = [
    {
        title: 'facebookMarketingFaq1',
        desc: 'facebookMarketingFaqSubLabel1',
        id: 0
    },
    {
        title: 'facebookMarketingFaq2',
        desc: 'facebookMarketingFaqSubLabel2',
        id: 1
    },
    {
        title: 'facebookMarketingFaq3',
        desc: 'facebookMarketingFaqSubLabel3',
        id: 2
    },
    {
        title: 'facebookMarketingFaq4',
        desc: 'facebookMarketingFaqSubLabel4',
        id: 3
    },
    {
        title: 'facebookMarketingFaq5',
        desc: 'facebookMarketingFaqSubLabel5',
        id: 4
    },
    {
        title: 'facebookMarketingFaq6',
        desc: 'facebookMarketingFaqSubLabel6',
        id: 5
    },
    {
        title: 'facebookMarketingFaq7',
        desc: 'facebookMarketingFaqSubLabel7',
        id: 6
    },
]

const videos = [
    {
        id: 0,
        label: 'whatIsThis',
        url_pl: 'https://www.youtube.com/embed/unczpON8Tx8?si=f49PcWgxL5n2xdad&autoplay=1&cc_load_policy=1',
        url_fr: 'https://www.youtube.com/embed/W0wgvX_dIX4?si=rcfqOIcK8riJvBtf&autoplay=1&cc_load_policy=1',
        time: 2,
        preview: 'cover-1',
    },
    {
        id: 1,
        label: 'paymentsHowItWorksLabel',
        url_pl: 'https://www.youtube.com/embed/hNcZQIBjzwo?si=4LHbVgB4buthJ6vv&autoplay=1&cc_load_policy=1',
        url_fr: 'https://www.youtube.com/embed/x6PQvwkGKDg?si=G_ChipuR4nEu1vao&autoplay=1&cc_load_policy=1',
        time: 2,
        preview: 'cover-2',
    },
]

const FacebookMarketing = () => {
    const {getLangText} = useLang();
    const reviewsSectionRef = useRef(null);
    const [showModal, setShowModal] = useState(null);
    const [isActive, setActive] = useState(0);
    const activeView = 1;
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const lang = useAssetsLang();
    const [selectedLabel, setSelectedLabel] = useState(null);
    const {userData} = useSelector((state) => state.session);

    const [{isLoading, data, setData}] = useFetchData({
        rest: `${services.API_MARKETING_LANDING}/${activeView}`,
    })

    const isSubmitted = !!+data.submitted || false;
    const isPaid = !!+data.paid || false;
    const isFinished = !!+data.finished || false;

    // useEffect(() => {
    //     if (isFinished && !isSubmitted) {
    //         setShowModal(activeView)
    //     }
    // }, [isFinished, isSubmitted])

    if (isPaid) {
        return <Redirect to={RouterPaths.MarketingFacebookFirstSteps}/>
    }

    if (isLoading) return <ComponentLoader style={{borderRadius: 12, overflow: 'hidden'}}/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('facebookAdds')} - Selmo</title>
            </Helmet>
            <div className="animate-[fadeIn_.3s_ease_forwards]">
                <div
                    className={`bg-[#fff] relative sm:rounded-[12px] shadow-default sm:py-16 py-12 overflow-hidden ${!!showModal ? '' : ''}`}>
                    <div class="max-w-[870px] mx-auto space-y-16 px-6">
                        <div class="text-center">
                            <div
                                className="text-[#5fa5fa] flex items-center text-xs justify-center tracking-[1px] uppercase font-bold">
                                {getLangText('facebookAdds')}
                            </div>
                            <div
                                class="light-grey-subtitle my-3 text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{__html: getLangText('getNewClientsBySelmoFacebookAd')}}/>
                            <button
                                type="button"
                                disabled={isLoading || isSubmitted}
                                onClick={() => setShowModal(activeView)}
                                style={{borderRadius: 12, paddingTop: 19, paddingBottom: 19}}
                                className={`button sm:w-auto w-full sm:min-w-[200px] medium-size text-center ${isSubmitted ? 'border-button pointer-events-none' : 'primary'}`}
                            >
                                {getLangText(isFinished ? (isSubmitted ? 'successfulSubmittedToWaitingList' : 'checkIfYouQualify') : 'startLabel')}
                            </button>
                            <SeeReviews reviewsSectionRef={reviewsSectionRef}/>
                        </div>
                        {(userData.origin === SHOP_ORIGIN_PL || userData.origin === SHOP_ORIGIN_FR) &&
                            <div class="grid sm:grid-cols-2 gap-6">
                                {videos.map((video) => (
                                    <button
                                        type="button"
                                        className="text-left"
                                        onClick={() => {
                                            openPreviewModal(video[`url_${lang}`])
                                            setSelectedLabel(video.label)
                                        }}
                                    >
                                        <div class="relative mb-3">
                                            <div
                                                className="absolute top-0 right-0 bottom-0 left-0 bg-[#000] opacity-30 rounded-[12px]"/>
                                            <img
                                                className="absolute sm:w-[100px] sm:h-[100px] w-[84px] h-[84px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                                                src="/assets/images/svg/play-white.svg" alt="play" width={100}
                                                height={100}
                                            />
                                            <img
                                                className="rounded-[12px]"
                                                src={`/assets/images/multiLanguage/${lang}/marketing/${video.preview}.png`} alt=""/>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="font-bold text-xl">
                                                {getLangText(video.label)}
                                            </div>
                                            <div
                                                className="ml-2 mt-0.5 text-desc text-xs font-medium flex items-center mr-2">
                                                <i class="icon-time text-sm mr-1"/>
                                                {video.time}&nbsp;
                                                {getLangText('minutes')}
                                            </div>
                                            <div className="button border-button small-size bg-[white] ml-auto">
                                                {getLangText('watchVideoLabel')}
                                                <i className="icon-arrows mt-0.5 ml-1 text-[10px] inline-block -rotate-[90deg] hover:no-underline"/>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        }
                        {(userData.origin === SHOP_ORIGIN_PL || userData.origin === SHOP_ORIGIN_FR) &&
                            <div className="">
                                <div
                                    class="light-grey-subtitle my-3 text-[24px] sm:text-[36px] text-center leading-[1.31] tracking-[-1px] font-bold sm:whitespace-normal whitespace-pre-wrap"
                                    dangerouslySetInnerHTML={{__html: getLangText('thisWhatYourPanelWillLookLike')}}/>
                                <img
                                    className="rounded-[16px]"
                                    src={`/assets/images/multiLanguage/${lang}/marketing/panel.webp`} alt=""/>
                                <div className="text-right text-[#4b5563] text-sm">
                                    {getLangText('statsFromClient')}
                                </div>
                            </div>
                        }
                    </div>
                    <Reviews reviewsSectionRef={reviewsSectionRef}/>
                    <div class="max-w-[870px] mx-auto space-y-16 px-6">
                        <div className="bg-[#f0faff] text-center rounded-[16px] py-12 sm:py-16 px-6">
                            <div
                                class="light-grey-subtitle my-3 text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold sm:whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{__html: getLangText('increaseSalesAndGetNewClients')}}/>
                            <button
                                type="button"
                                disabled={isLoading || isSubmitted}
                                onClick={() => setShowModal(activeView)}
                                style={{borderRadius: 12, paddingTop: 19, paddingBottom: 19}}
                                className={`button sm:w-auto w-full sm:min-w-[200px] medium-size text-center ${isSubmitted ? 'border-button pointer-events-none' : 'primary'}`}
                            >
                                {getLangText(isFinished ? (isSubmitted ? 'successfulSubmittedToWaitingList' : 'checkIfYouQualify') : 'startLabel')}
                            </button>
                        </div>
                        <div className="max-w-[580px] mx-auto">
                            <div
                                class="light-grey-subtitle my-3 text-[24px] sm:text-[36px] leading-[1.31] tracking-[-1px] font-bold sm:whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{__html: getLangText('faqPageTitleSpan')}}/>
                            <Faq
                                setActive={setActive}
                                isActive={isActive}
                                questions={questions}
                                smallSize={true}
                            />
                        </div>
                        {showPreviewModal &&
                            <VideoPreview
                                hide={hidePreviewModal}
                                show={showPreviewModal}
                                bigSize={true}
                                subTitle={selectedLabel}
                            />
                        }
                    </div>
                </div>
                {showModal &&
                    <InvoiceDataMarketingModal
                        modalVisible={showModal}
                        isFinished={isFinished}
                        hideModal={() => setShowModal(null)}
                        extend={false}
                        setData={setData}
                    />
                }
            </div>
        </>
    );
};

export default FacebookMarketing;
