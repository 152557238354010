import React from 'react';
import CodeField from "../../../../src_shared/components/form/codeField/CodeField";
import Field from "../../../../src_shared/components/form/field/Field";
import {useSelector} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";
import PriceAndCountInput from "./components/PriceAndCountInput";
import Categories from "./components/Categories";
import Variants from "./components/variants/Variants";
import PostText from "./components/PostText";
import ExtraCodes from "./components/ExtraCodes";
import {PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE} from "../modalForm/ModalForm";
import RightSide from "../form/components/RightSide";
import {
    BUTIK_MADAME_SHOP_ID, CHEZ_DIDINE_SHOP_ID, KOKARDECZKA_SHOP_ID,
    PIANO_FASHION_SHOP_ID, SWIAT_SLODYCZY_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID, VANITY_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import ShelfInfo from "../form/edit/components/sheflnfo/ShelfInfo";
import MobileApp from "./components/MobileApp";
import DigitalFile from "./components/DigitalFile";
import PurchasePriceAndWeight from "./components/variants/PurchasePriceAndWeight";
import ManufacturerSelect from "../form/edit/components/ManufacturerSelect";
import VatRate from "./components/VatRate";
import Description from "./components/Description";
import Upsell from "./components/Upsell";
import {SHOP_ORIGIN_PL, SHOP_ORIGIN_US} from "../../shared/enums/ShopOrigins";

const SharedEditProductForm = ({
                                   FormActions,
                                   getFieldProps,
                                   setValue,
                                   values,
                                   setNestedValue,
                                   inModal,
                                   waitingForCode,
                                   validation,
                                   updateValues,
                                   type,
                                   defaultValues = {},
                                   isWaiting,
                               }) => {

    const {userData} = useSelector((state) => state.session);

    const {getLangText} = useLang();
    const {
        product_code,
        currency_info,
        category_on_product_add,
        description_on_product_add
    } = useSelector((state) => state.session.userData);

    const isDescriptionVisible = !inModal || (inModal && !!+description_on_product_add)
    const isCategoryVisible = !inModal || (inModal && !!+category_on_product_add)

    return (
        <>
            <section className={!inModal ? 'default-shadow-box big-padd mb-3' : ''}>
                {!inModal &&
                    <div className="left-side">
                        <h2 className="font-bold mb-3.5">{getLangText('basicProductDataLabel')}</h2>
                    </div>
                }
                <div className="form-col">
                    <Field
                        {...getFieldProps('name')}
                        setValue={setValue}
                        label={getLangText('productNameLabel')}
                        placeholder={getLangText('enterProductNameLabel')}
                    />
                    {isDescriptionVisible &&
                        <Description
                            getFieldProps={getFieldProps}
                            values={values}
                            setValue={setValue}
                        />
                    }
                    <PriceAndCountInput
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        values={values}
                        currency_info={currency_info}
                        inModal={inModal}
                    />
                    <PurchasePriceAndWeight
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        values={values}
                        currency_info={currency_info}
                        inModal={inModal}
                    />
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SWIAT_SLODYCZY_SHOP_ID || userData.origin === SHOP_ORIGIN_US || +userData.id === KOKARDECZKA_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID || +userData.id === CHEZ_DIDINE_SHOP_ID) &&
                        <VatRate
                            getFieldProps={getFieldProps}
                            setValue={setValue}
                            values={values}
                            inModal={inModal}
                        />
                    }
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                        <ManufacturerSelect
                            getFieldProps={getFieldProps}
                            setValue={setValue}
                            values={values}
                            inModal={inModal}
                        />
                    }
                    {+userData.id === VANITY_SHOP_ID &&
                        <Field
                            {...getFieldProps('size_tag')}
                            setValue={setValue}
                            className="mt-3"
                            label={getLangText('sizeLabel')}
                            placeholder={getLangText('enterSizeToDisplayInShop')}
                        />
                    }
                    {(!inModal && !!+userData.instagram_automation) &&
                        <div className="mt-4">
                            <DigitalFile
                                inModal={inModal}
                                getFieldProps={getFieldProps}
                                setValue={setValue}
                                values={values}
                                updateValues={updateValues}
                            />
                        </div>
                    }
                </div>
            </section>
            {!inModal &&
                <RightSide
                    values={values}
                    updateValues={updateValues}
                    isWaiting={isWaiting}
                />
            }
            <section
                className={!inModal ? 'default-shadow-box big-padd  mb-3' : type !== PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE ? 'mb-3' : ''}>
                <PostText
                    product_code={product_code}
                    values={values}
                    inModal={inModal}
                />
                <div className="form-col">
                    {type !== PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE &&
                        <CodeField
                            {...getFieldProps('selmo_code')}
                            setValue={setValue}
                            label={getLangText('enterProductCodeLabel')}
                            inModal={inModal}
                            placeholder={getLangText('enterProductCodeLabel')}
                            waiting={waitingForCode}
                            values={values}
                            className={inModal ? 'mb-0' : ''}
                        />
                    }
                    {!inModal &&
                        <>
                            <div className="form-info-box w-100 mt-3.5">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title mb-0">
                                        {getLangText('giveCodeToCustomersTitle')}
                                    </div>
                                    <div className="desc">
                                        {getLangText('giveCodeToCustomersDescription')}
                                    </div>
                                </div>
                            </div>
                            {userData.origin === SHOP_ORIGIN_PL &&
                                <div className="form-info-box w-100 mt-2.5">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title mb-0">
                                            {getLangText('remember')}
                                        </div>
                                        <div className="desc sm:whitespace-pre-wrap">
                                            {getLangText('doNotUseSpecialWordsInProductCode')}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {type !== PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE &&
                        <ExtraCodes
                            getFieldProps={getFieldProps}
                            setValue={setValue}
                            values={values}
                            userData={userData}
                        />
                    }
                </div>
            </section>
            {(!inModal && !!+userData.depot_system) &&
                <ShelfInfo values={values}/>
            }
            {type !== PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE && isCategoryVisible &&
                <Categories
                    getFieldProps={getFieldProps}
                    updateValues={updateValues}
                    values={values}
                    inModal={inModal}
                    setValue={setValue}
                />
            }
            {!inModal &&
                <MobileApp
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                />
            }
            {type !== PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE &&
                <Variants
                    getFieldProps={getFieldProps}
                    values={values}
                    validation={validation}
                    inModal={inModal}
                    setNestedValue={setNestedValue}
                    FormActions={FormActions}
                    userData={userData}
                    setValue={setValue}
                    defaultValues={defaultValues}
                />
            }
            {!inModal &&
                <Upsell
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                />
            }
            {(type === PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE && !!+userData.instagram_automation) &&
                <DigitalFile
                    inModal={inModal}
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                    values={values}
                    updateValues={updateValues}
                />
            }
        </>
    );
};

SharedEditProductForm.defaultProps = {
    generateCode: () => {
    },
}

export default SharedEditProductForm;
