import history from "../../../../../history";
import {parseParams} from "../../../../../../src_shared/list/ListActions";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../../shared/enums/OrderViewTypes";
import moment from "moment/moment";
import DataReducer from "../../../../../../src_shared/form/data/DataReducer";
import createReducer from "../../../../shared/helpers/createReducer";
import {getReduceMap} from "../../../../../../src_shared/filters/filterReducer";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedIsRealized = parsedParams.isRealized || '0';
const preparedIsNewClient = parsedParams.is_new_client || 'all';

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.marketing.facebookMarketing.filters;

export const getInitState = () => DataReducer.getInitState({
	fromDate: null,
	toDate: null,
	viewType: preparedViewType,
	isRealized: preparedIsRealized,
	is_new_client: preparedIsNewClient,
}, {
	fromDate: null,
	toDate: null,
	viewType: preparedViewType,
	isRealized: '0',
	is_new_client: 'all',
})

export const prefix = 'MARKETING_FACEBOOK_STATISTICS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
