import React, {memo, useEffect, useRef, useState} from 'react';
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import {useDispatch, useSelector} from "react-redux";
import useList from "../../../../../../src_shared/list/useList.js";
import {createSelector} from "reselect";
import CommentsHeader from "./components/CommentsHeader.jsx";
import VirtualizedList from "./components/VirtualizedList";
import {isMobile} from "../../../../shared/helpers/isMobile";

const CommentsListWithVirtualization = ({canAddClient = true}) => {
	const dispatch = useDispatch();
	const {userData} = useSelector((state) => state.session);
	const {videoId} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const [fullScreen, setFullScreen] = useState(false);
	const [zoomValue, setZoomValue] = useState(1);
	const facebookId = userData.facebook_id
	const listRef = useRef({});
	const mobile = isMobile();

	const {
		list: {isWaiting},
		search,
	} = useList({
		ListActions,
		getStateRoot,
		params: {
			id: facebookId,
		},
		hasParentId: true,
		preventAutoLoad: true,
	})

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (!videoId) {
				dispatch(ListActions.tryToStartFacebookStreaming(facebookId))
			}
		}, 10000);

		return () => clearInterval(intervalId);
	}, [videoId]);


	useEffect(
		() => {
			document.querySelector('html').classList.add('open-live-comments')
			return () => {
				document.querySelector('html').classList.remove('open-live-comments')
			};
		},
		[],
	);

	return (
		<div
			className={`comments-wrapper ${fullScreen ? 'full-screen' : ''}`}>
			<CommentsHeader
				setFullScreen={setFullScreen}
				setZoomValue={setZoomValue}
				fullScreen={fullScreen}
				zoomValue={zoomValue}
				search={search}
				facebookId={facebookId}
				listRef={listRef}
			/>
			<VirtualizedList
				canAddClient={canAddClient}
				isWaiting={isWaiting}
				listRef={listRef}
				zoomValue={zoomValue}
			/>
		</div>
	);
};

export default memo(CommentsListWithVirtualization);
