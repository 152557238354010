import React from 'react';
import TimeAgo from "../../../../../../src_shared/helpers/TimeAgo";
import {convertToUserTimeZone} from "../../../helpers/dateHelpers";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {findNotificationTypeById} from "../../../enums/NotificationTypes";
import useLang from "../../../../../../src_shared/hooks/useLang";

const Item = ({item, afterClick = null, inHeader = true}) => {
    const {lang} = useSelector((state) => state.i18n);
    const {getLangText} = useLang();
    const {id} = useParams();
    const handleClick = () => afterClick && afterClick(item);
    const isNew = !item.viewed;

    return (
        <Link
            onClick={handleClick}
            to={`${RouterPaths.SystemNotifications}/${item.id}`}
            className={`flex mb-[2px] hover:no-underline grow py-[12px] px-3 rounded-[8px] hover:bg-[#f7f7f7] transition-all relative ${isNew ? 'after:content-[""] after:absolute after:right-[15px] after:top-1/2 after:-translate-y-1/2 after:w-[7px] after:h-[7px] after:bg-blue-500 after:rounded-full' : ''} ${item.id === id ? 'bg-[#F6F7F9]' : ''}`}
        >
            <div className="min-w-[30px] w-[30px] h-[30px] rounded-full overflow-hidden">
                <img
                    className="w-full h-full object-cover"
                    src={`/assets/images/svg/systemNotifications/${findNotificationTypeById(item.type)?.logo}.svg`}
                    alt=""
                />
            </div>
            <div className="ml-2 overflow-hidden grow">
                <div className="text-sm flex items-center font-bold text-[#101827]">
                    {item.type &&
                        <span className="">{getLangText(findNotificationTypeById(item.type)?.label) || findNotificationTypeById(item.type)?.label}</span>
                    }
                    <span className="text-[10px] text-[#6b7280] font-medium ml-auto">
                        <TimeAgo
                            datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
                            locale={lang}
                        />
                    </span>
                </div>
                <div className="text-xs line-clamp-2 text-[#6b7280] font-medium">
                    {item.title}
                </div>
            </div>
        </Link>
    );
};

export default Item;
