import { useEffect } from 'react';

export function useClickOutside(ref, callback, omittedRefs) {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const rootDiv = document.getElementById('root');

            if (
                !rootDiv?.contains(event.target) ||
                ref.current?.contains(event.target)
            ) {
                return;
            }

            const isInsideOmittedRef = omittedRefs?.some((omittedRef) =>
                omittedRef?.current?.contains(event.target),
            );

            if (!isInsideOmittedRef) {
                callback();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, omittedRefs, callback]);
}
