import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import SwitchField from '../../../../../../../../../src_shared/components/form/switchField/SwitchField';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import cn from 'clsx';

const ScheduleShipment = ({
    values,
    setValue,
    getFieldProps,
    hasError,
    disabled,
}) => {
    const { getLangText } = useLang();
    const { lang } = useSelector((state) => state.i18n);

    return (
        <div className="flex flex-col gap-2">
            <p className="font-bold">{getLangText('scheduleShipment')}</p>
            <div
                className={cn(
                    'flex flex-col justify-center border-[1px] border-[#E5E7EB] rounded-[8px] p-[14px]',
                    disabled && 'opacity-50',
                )}>
                <SwitchField
                    {...getFieldProps('send_later_active')}
                    className="switch-form inline-label big-label light-blue-version mb-0 self-start"
                    id="send_later_active"
                    setValue={setValue}
                    disabled={disabled}
                    label={getLangText('sendLater')}
                />
                {!!+values.send_later_active && (
                    <div className="mt-3 sm:ml-[43px] flex flex-col sm:flex-row justify-between gap-2.5 animate-[slideAndShow_.3s_ease_forwards]">
                        <div className="form-group calendar-input mb-0 w-full sm:w-1/2">
                            <div className="control-label">
                                {getLangText('dateLabel')}
                            </div>
                            <DatePicker
                                portalId="my-popper"
                                className="form-control"
                                selected={values.send_later_date}
                                onChange={(value) =>
                                    setValue('send_later_date', value)
                                }
                                locale={lang}
                                dateFormat="dd.MM.y"
                            />
                            <i className="icon-calendar" />
                        </div>
                        {hasError('send_later_date') && (
                            <p className="text-[12px] text-[#ef4444] mt-1 font-medium">
                                {getLangText(hasError('send_later_date'))}
                            </p>
                        )}
                        <div className="form-group mb-0 w-full sm:w-1/2">
                            <div className="control-label">
                                {getLangText('hour')}
                            </div>
                            <div className="react-time-picker-wrapper">
                                <TimePicker
                                    clearIcon={false}
                                    className="form-control flex items-center"
                                    disableClock={true}
                                    locale={lang}
                                    onChange={(value) =>
                                        setValue('send_later_time', value)
                                    }
                                    value={values.send_later_time}
                                    format="HH:mm"
                                />
                                <i className="icon-time" />
                            </div>
                            {hasError('send_later_time') && (
                                <p className="text-[12px] text-[#ef4444] mt-1 font-medium">
                                    {getLangText(hasError('send_later_time'))}
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScheduleShipment;
