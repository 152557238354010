import React from 'react';
import Field from '../../../../src_shared/components/form/field/Field';
import ZipCodeField from '../../orders/details/deliveryAddress/components/ZipCodeInputs';
import { services } from '../../../RestServices';
import AsyncSearchSelectField from '../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField';
import useLang from '../../../../src_shared/hooks/useLang';
import InvoiceData from './InvoiceData';

const DeliveryAddressForm = ({
    values,
    setValue,
    getFieldProps,
    validateErrors,
    shopId,
}) => {
    const { getLangText } = useLang();

    const isPoland =
        values?.address?.country?.toLowerCase()?.trim() === 'polska';

    const formatOptionLabel = (option) => (
        <div style={{ display: 'flex' }}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    return (
        <>
            <div className="form-row">
                <div className="w-100">
                    <Field
                        {...getFieldProps('email')}
                        value={values.address.email}
                        label={getLangText('emailLabel')}
                        setValue={setValue}
                        type="email"
                        floatedLabel
                        extraProps={{
                            autoFocus: true,
                        }}
                        getError={validateErrors?.address?.email}
                    />
                </div>
                <div className="w-100">
                    <AsyncSearchSelectField
                        {...getFieldProps('country')}
                        value={values.address.country}
                        label={getLangText('countryLabel')}
                        setValue={setValue}
                        rest={`${services.API_CART_SHOP_COUNTRIES}?magic_url=guest&shop_id=${shopId}`}
                        formatOptionLabel={formatOptionLabel}
                        floatedLabel
                        searchOnFocus
                        isSearchable={false}
                        withSearchInput
                        getError={validateErrors?.address?.country}
                    />
                </div>
                <div className="w-50">
                    <Field
                        {...getFieldProps('first_name')}
                        value={values.address.first_name}
                        label={getLangText('firstNameLabel')}
                        setValue={setValue}
                        floatedLabel
                        getError={validateErrors?.address?.first_name}
                    />
                </div>
                <div className="w-50">
                    <Field
                        {...getFieldProps('last_name')}
                        value={values.address.last_name}
                        label={getLangText('lastNameLabel')}
                        setValue={setValue}
                        floatedLabel
                        getError={validateErrors?.address?.last_name}
                    />
                </div>
                <div className="w-50">
                    <Field
                        {...getFieldProps('street')}
                        value={values.address.street}
                        label={getLangText('streetLabel')}
                        setValue={setValue}
                        floatedLabel
                        getError={validateErrors?.address?.street}
                    />
                </div>
                <div className="w-50">
                    <Field
                        {...getFieldProps('building_number')}
                        value={values.address.building_number}
                        label={getLangText('buildingNumberLabel')}
                        setValue={setValue}
                        floatedLabel
                        getError={validateErrors?.address?.building_number}
                    />
                </div>
                <div className="w-50">
                    <Field
                        {...getFieldProps('city')}
                        value={values.address.city}
                        label={getLangText('cityLabel')}
                        setValue={setValue}
                        floatedLabel
                        getError={validateErrors?.address?.city}
                    />
                </div>
                <div className="w-50">
                    <ZipCodeField
                        {...getFieldProps('zip_code')}
                        value={values.address.zip_code}
                        label={getLangText('zipCodeLabel')}
                        setValue={setValue}
                        isPoland={isPoland}
                        floatedLabel
                        maxLength={isPoland ? 6 : 100}
                        getError={validateErrors?.address?.zip_code}
                    />
                </div>
                <div className="w-100">
                    <Field
                        {...getFieldProps('phone')}
                        value={values.address.phone}
                        label={getLangText('phoneLabel')}
                        setValue={setValue}
                        floatedLabel
                        asNumberField={isPoland}
                        extraProps={{
                            maxLength: isPoland ? 9 : 100,
                        }}
                        getError={validateErrors?.address?.phone}
                    />
                </div>
                <div className="w-100">
                    <Field
                        {...getFieldProps('extra_description')}
                        value={values.address.extra_description}
                        label={getLangText('extraDescriptionLabel')}
                        setValue={setValue}
                        floatedLabel
                    />
                </div>
                <InvoiceData
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                    values={values}
                    getError={validateErrors?.address?.extra_description}
                />
            </div>
        </>
    );
};

export default DeliveryAddressForm;
