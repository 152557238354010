import useLang from "../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";
import React from "react";
import {services} from "../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {settingsProductsImportUpdateData} from "./ProductsImportActions";
import FileUpload from "./components/FileUpload";
import {
    SHOP_ORIGIN_EN,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_IT,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_PT
} from "../../app/shared/enums/ShopOrigins";

const ProductsImport = () => {
    const {getLangText} = useLang();
    const {origin} = useSelector((state) => state.session.userData);
    const values = useSelector((state) => state.settings.productsImport);
    const dispatch = useDispatch();

    const updateData = (updatedData) => dispatch(settingsProductsImportUpdateData(updatedData));
    const updateField = (field, value) => dispatch(settingsProductsImportUpdateData({
        ...values,
        [field]: value,
    }))

    const preparedLang = () => {
        if (origin === SHOP_ORIGIN_PL) return SHOP_ORIGIN_PL
        if (origin === SHOP_ORIGIN_FR) return SHOP_ORIGIN_FR
        return SHOP_ORIGIN_EN;
    }

    const filePath = `/assets/files/${preparedLang()}/example-products-file.xlsx`

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('productsImport')}</div>
                    </li>
                </ul>
            </div>
            <div className="grid sm:grid-cols-5 gap-4">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('productsImport')} - Selmo</title>
                </Helmet>
                <div className="col-span-3">
                    <div className="loader-parent">
                        {/*<Loader isLoading={isLoading}/>*/}
                        <div className="settings-page sub-settings-page">
                            <div className="header-wrapper">
                                <h1>
                                    <img src="/assets/images/svg/orders.svg"
                                         alt="overview"/>
                                    {getLangText('productsImport')}
                                </h1>
                                {/*<div className="description">*/}
                                {/*    {getLangText('automaticallyNotificationsSubLabel')}*/}
                                {/*</div>*/}
                            </div>
                            <div className="settings-form">
                                <div className="border bg-[#f9f9f9] rounded-medium p-3 mb-2">
                                    <div className="form-label mb-0">
                                        {getLangText('exampleFileWithProducts')}
                                    </div>
                                    <a
                                        target="_blank"
                                        download={true}
                                        className="font-bold text-sm"
                                        href={filePath}
                                    >
                                        <i className="icon-orders mr-1"/>
                                        <span className="underline">{getLangText('downloadFile')}</span>
                                    </a>
                                </div>
                                <div
                                    style={{maxWidth: '100%'}}
                                    className="form-info-box w-full small-line-height mb-3">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title">{getLangText('attentionLabel')}</div>
                                        <div className="desc">{getLangText('productsImportVariantsInfo')}</div>
                                    </div>
                                </div>
                                <section className="d-block">
                                    <FileUpload
                                        values={values}
                                        updateValues={updateData}
                                        setValue={updateField}
                                        restService={services.API_IMPORT_PRODUCTS}
                                    />
                                    {!!values.errors?.length &&
                                        <div className="mt-3">
                                            <div className="font-bold text-sm mb-2">{getLangText('errors')}</div>
                                            <div className="space-y-1">
                                                {values?.errors.map((error) => (
                                                    <div
                                                        className="bg-red-50 p-2 rounded-medium text-red-700 font-bold text-sm">
                                                        {error}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="col-span-2">*/}
                {/*    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">*/}
                {/*        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>*/}
                {/*        <ul className="benefits-list">*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('automaticallyNotificationsBenefitsLabel1')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('automaticallyNotificationsBenefitsLabel2')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('automaticallyNotificationsBenefitsLabel3')}*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default ProductsImport;
