import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useAssetsLang from "../../shared/helpers/useAssetsLang";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import MobileAppPermission from "./components/MobileAppPermission";
import FaqSection from "./components/FaqSection";

const howItWorks = [
    {id: 1, label: 'grantPermissionsToPublishPost'},
    {id: 2, label: ''},
    {id: 3, label: 'addPostPhoneDirectly'},
]

const benefits = [
    {id: 1, label: 'generateDescWithProductCodes'},
    {id: 2, label: 'addWithSafeIntervals'},
    {id: 3, label: 'publishOnFacebookAndAppInTheSameTime'},
]

const HowItWorksComponent = () => {
    const {getLangText} = useLang();
    const lang = useAssetsLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    const Wrapper = ({children, className = ''}) => (
        <div className={`p-6 bg-[white] rounded-[5px] shadow-default ${className}`}>
            {children}
        </div>
    )

    return (
        <div className="">
            <div className="p-6 lg:p-[50px] bg-[white] rounded-[5px] shadow-default mb-3">
                <div class="grid sm:grid-cols-2 mb-3">
                    <div className="flex flex-col">
                        <div
                            className="whitespace-pre-wrap mt-6 big-text-vacation font-bold text-[22px] sm:text-[32px] leading-[34px] sm:leading-[46px]"
                            dangerouslySetInnerHTML={{__html: getLangText('addPostsOnFacebookInApp')}}/>
                        <div className="flex items-center mt-auto">
                            <div
                                style={{fontFamily: 'HateYourWriting'}}
                                className="text-[#5fa5fa] text-[28px] sm:text-[34px]"
                                dangerouslySetInnerHTML={{
                                    __html: getLangText('addPermissionsToApp')
                                }}
                            />
                            <img className="w-[16px] rotate-[180deg] ml-2 block mt-2"
                                 src="/assets/images/svg/arrow-blue.svg" alt=""/>
                        </div>
                    </div>
                    <div>
                        <div
                            onClick={openPreviewModal}
                            className={`relative w-full cursor-pointer group rounded-large overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.2)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full`}>
                            <img
                                className="sm:h-full w-full sm:w-auto sm:object-contain object-cover"
                                src={`/assets/images/multiLanguage/${lang === 'pl' ? 'pl' : 'en'}/salesPosts/grant-permission.webp`}
                                alt="preview"
                            />
                            <img
                                className={`pointer-events-none absolute w-[30px] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 group-hover:scale-[115%] transition-all`}
                                src="/assets/images/svg/play.svg"
                                alt="play"
                            />
                        </div>
                    </div>
                </div>
                <VideoPreview
                    hide={hidePreviewModal}
                    show={showPreviewModal}
                    videoStatsName=""
                    src={"https://www.youtube.com/embed/UGesEp0QHiE?si=UJaceMNTorO1y8zP&autoplay=1"}
                />
            </div>
            <div class="grid md:grid-cols-5 gap-4">
                <div className="md:col-span-3">
                    <Wrapper>
                        <MobileAppPermission/>
                    </Wrapper>
                    <Wrapper className="mt-3">
                        <div className="mb-3 font-bold">{getLangText('helpMostFrequentlyAskedQuestionsLabel')}</div>
                        <FaqSection/>
                    </Wrapper>
                </div>
                <div className="md:col-span-2">
                    <Wrapper>
                        <div className="font-bold mb-3">{getLangText('paymentsHowItWorksLabel')}</div>
                        <div className="space-y-3">
                            {howItWorks.map((item) => (
                                <div
                                    key={item.id}
                                    className="flex text-sm items-center"
                                >
                                    <div
                                        className="mr-2 w-[28px] h-[28px] bg-[#f0faff] text-[#0d3364] text-xs font-bold rounded-full flex items-center justify-center">
                                        {item.id}
                                    </div>
                                    {item.id === 2 ?
                                        <div className="flex items-center space-x-2 font-medium">
                                            <span className="font-bold">{getLangText('clickIcon')}</span>
                                            <i class="text-[#5fa5fa] icon-home"/>
                                            <span>{getLangText('inSelmoApp')}</span>
                                        </div> :
                                        <div
                                            className="text-[#101827] font-medium"
                                            dangerouslySetInnerHTML={{__html: getLangText(item.label)}}
                                        />
                                    }
                                </div>
                            ))}
                        </div>
                    </Wrapper>
                    <Wrapper className="mt-3">
                        <div className="font-bold mb-3">
                            {getLangText('pricingWhyWorthyTitle')}
                        </div>
                        <div className="space-y-3">
                            {benefits.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className="flex text-sm items-center"
                                >
                                    <i className="icon-tick-c text-[#5fa5fa] text-lg mr-2"/>
                                    <div className="text-[#101827] font-medium"
                                         dangerouslySetInnerHTML={{__html: getLangText(benefit.label)}}/>
                                </div>
                            ))}
                        </div>
                    </Wrapper>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksComponent;
