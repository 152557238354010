import React, { useState } from 'react';
import TemplatesMessages from './TemplatesMessages';
import useFetchData from '../../../../src_shared/hooks/useFetchData';
import { services } from '../../../RestServices';
import ComponentLoader from '../components/componentLoader/ComponentLoader';
import useLang from '../../../../src_shared/hooks/useLang';
import SearchInput from '../components/form/SearchInput';
import EditTemplatesModal from './EditTemplatesModal';
import cn from 'clsx';

const TemplatesDropdown = ({ hide, setInputValue, textAreaRef, type }) => {
    const [showModal, setShowModal] = useState(null);
    const { getLangText } = useLang();

    const [{ isLoading, data, fetchData, search }] = useFetchData({
        rest: services.API_MESSENGER_TEMPLATE,
        initialData: [],
        extraParams: { type },
    });

    return (
        <div
            className={cn({
                'overflow-hidden absolute top-[35px] left-0 bg-[white] rounded-[10px] border w-auto min-w-[400px] opacity-0 animate-[fadeIn_.5s_ease_forwards] z-30':
                    type === 'sms',
                'fixed sm:absolute bottom-[50px] sm:bottom-[30px] sm:left-auto left-0 right-0 bg-[white] sm:rounded-[10px] border sm:w-auto w-full sm:min-w-[400px] opacity-0 animate-[fadeIn_.5s_ease_forwards]':
                    type === 'messenger',
            })}>
            <div className="flex items-center p-3.5">
                <div className="font-bold grow text-sm">
                    {getLangText(
                        type === 'sms' ? 'smsTemplates' : 'savedTemplates',
                    )}
                </div>
                <button
                    type="button"
                    onClick={() => setShowModal(0)}
                    className="button text-only with-icon lgrey add-order-table">
                    <i className="icon-plus" />
                    {getLangText('settings_package_types_text_1')}
                </button>
            </div>
            <div className="px-3.5 pb-3.5 border-b-[1px] border-[#E5E7EB]">
                <SearchInput
                    className="mb-0 full-width-input"
                    placeholder={getLangText('searchPlaceholder')}
                    search={search}
                    autoFocus
                />
            </div>
            {isLoading ? (
                <ComponentLoader height={200} />
            ) : (
                <TemplatesMessages
                    fetchData={fetchData}
                    data={data}
                    setShowModal={setShowModal}
                    setInputValue={setInputValue}
                    hideDropdown={hide}
                    textAreaRef={textAreaRef}
                />
            )}
            {showModal !== null && (
                <EditTemplatesModal
                    fetchData={fetchData}
                    show={showModal}
                    hide={() => setShowModal(null)}
                />
            )}
        </div>
    );
};

export default TemplatesDropdown;
