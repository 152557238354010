import useLang from "../../../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {services} from "../../../../../RestServices";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import ClientAvatar from "../../../../live/shared/Avatar";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import SearchInput from "../../../../shared/components/form/SearchInput";

const AssignEmployeeModal = ({
                                 show,
                                 hide,
                                 defaultValues,
                                 fetchData,
                             }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const isSelmoProActive = !!+userData.selmo_pro_active;
    const [clickedId, setClickedId] = useState(null);

    const [{data, isLoading, search}] = useFetchData({
        rest: services.API_ORDER_EMPLOYEES,
        initialData: [],
    })

    const [{
        onSave,
        isWaiting,
    }] = useSubmitData({
        rest: `${services.API_ORDER_EMPLOYEES}/${defaultValues.id}`,
        initialAutoLoad: false,
        afterSubmit: () => {
            fetchData();
            dispatch(showAlert());
            hide()
        },
    });

    const handleSubmit = async (e, id) => {
        setClickedId(id)
        await onSave(e, {id})
    }


    return (
        <Modal
            dialogClassName={`default-modal ${isSelmoProActive ? '' : 'big-modal full-black-modal'}`}
            contentClassName={isSelmoProActive ? '' : 'rounded-xl overflow-hidden'}
            show={show}
            onHide={hide}
        >
            <div className="relative">
                {!isSelmoProActive &&
                    <button
                        onClick={hide}
                        type="button"
                        className="absolute text-[#717171] right-3 top-3 w-[32px] h-[32px] min-w-[32px] flex items-center justify-center rounded-full ml-2 bg-[rgba(75,85,99,0.3)]"
                    >
                        <i className="icon-cross text-[10px]"/>
                    </button>
                }
                {isSelmoProActive ?
                    <>
                        <Modal.Header className="">
                            <Modal.Title>
                                {getLangText('assignPersonFromTeam')}
                                <div className="sub-title">{getLangText('assignPersonFromTeamToThisOrder')}</div>
                            </Modal.Title>
                            <button
                                onClick={hide}
                                type="button"
                                className="button ml-auto"
                            >
                                <i className="icon-cross"/>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <Link
                                    target="_blank"
                                    to={RouterPaths.SettingsTeam}
                                    className="button border-button small-size w-full mb-2"
                                >
                                    {getLangText('createEmployeeAccount')}
                                </Link>
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    search={search}
                                    className="full-width-input mb-2"
                                />
                                <div className="loader-parent -mx-2 max-h-[500px] overflow-y-auto">
                                    {isLoading && <Loader isLoading={true} className="small-loader"/>}
                                    {data?.map((item) => (
                                        <button
                                            type="button"
                                            onClick={(e) => handleSubmit(e, item.id)}
                                            className="flex px-2 w-full py-2 items-center border-b last:border-b-0 group transition-all">
                                            <ClientAvatar
                                                className="w-38 border rounded-full"
                                                photo={item.photo}
                                                fbLogo={false}
                                            />
                                            <div className="font-bold group-hover:underline text-sm">{item.name}</div>
                                            <div
                                                className={`button border-button small-size ml-auto group-hover:bg-[#F9FAFB] ${isWaiting && item.id === clickedId ? 'loading-button' : ''}`}>
                                                {isWaiting && item.id === clickedId && <Loader isLoading={true}/>}
                                                {getLangText('assignButton')}
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                    :
                    <div className="grid md:grid-cols-2">
                        <div className="text-[#fff] p-12 px-7 border-r border-[#262626]">
                            <div className="font-bold text-[30px] leading-[42px] mb-6">
                                {getLangText('assignEmployeeToOrder')}
                            </div>
                            <div className="font-bold mb-1">
                                {getLangText('increaseEfficiencyOfOrderHandling')}
                            </div>
                            <div className="text-[#757575] font-medium leading-[28px]">
                                {getLangText('increaseEfficiencyOfOrderHandlingSubLabel')}
                            </div>
                            <Link
                                to={RouterPaths.SelmoPro}
                                style={{fontSize: 14}}
                                className={`bg-[#fff] mt-12 w-full text-center text-nowrap text-[#000] hover:text-[#000] rounded-[8px] text-sm py-[12px] px-3 font-bold button white`}
                            >
                                {getLangText('findOutMoreButton')}
                            </Link>

                        </div>
                        <div className="text-[#fff] flex flex-col items-center messenger-selmo-pro-gradient py-10 px-0 justify-center">
                            <img className="mt-auto" src="/assets/images/employee-order.webp" alt=""/>
                            <div className="flex items-center justify-center text-xs font-medium mt-auto">
                                {getLangText('availableOnlyIn')}
                                <img
                                    className="w-[85px] ml-1"
                                    src="/assets/images/svg/selmo-pro-logo.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default AssignEmployeeModal;