import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import moment from "moment/moment";
import {convertToUserTimeZone, convertToWarsawTimeZone} from "../../../components/app/shared/helpers/dateHelpers";
import _ from "lodash";

class MarketingDetailsFormModel extends Model {
    getModel() {
        return {
            ad_target: 1,
            post_type: 2,
            video_id: null,
            selected_fb_ad_post_id: null,
            products: [],
            description: '',
            budget_type: 1,
            target_current_clients: 1,
            target_new_clients: 1,
            budget_value: 50,
        };
    }

    getValidators() {
        return {
            // video_id: ValidationError.notEmpty,
            media: (value, data) => (+data.status === 1 || +data.post_type === 1) ? ValidationError.skip() : ValidationError.arrayNotEmpty(value, data),
            selected_fb_ad_post_id: (value, data) => +data.post_type === 1 ? ValidationError.notEmpty(value) : ValidationError.skip(),
            products: ValidationError.skip,
            description_text: ValidationError.skip,
            heading_text: ValidationError.skip,
            end_date_active: ValidationError.skip,
            end_date: (value, data) => +data.budget_type === 2 ? ValidationError.notEmpty(value, data) : ValidationError.skip()  ,
            budget_value: ValidationError.notEmpty,
            budget_type: ValidationError.notEmpty,
            unactive_when_sold: ValidationError.skip,
            target_new_clients: (value,data) => +data.post_type === 1 ? (!!+data.target_current_clients ? ValidationError.skip() : ValidationError.notFalse(value)) : ValidationError.skip(),
            target_current_clients: (value,data) => +data.post_type === 1 ? (!!+data.target_new_clients ? ValidationError.skip() : ValidationError.notFalse(value)) : ValidationError.skip(),

        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                end_date: (end_date) => end_date ? convertToUserTimeZone(end_date).toDate() : convertToUserTimeZone(moment(new Date()).add(1, 'day')).toDate(),
                products: (products) => products.map((product) => ({...product, id: product.product_id})),
                budget_value: (budget_value) => +budget_value,
                budget_type: (budget_type) => +budget_type,
            },
            {
                products: [],
                end_date: convertToUserTimeZone(moment(new Date()).add(1, 'day')).toDate(),
                end_time: !!+data.end_date_active ? convertToUserTimeZone(data.end_date).format('HH:mm') : convertToUserTimeZone().format('HH:mm'),
                budget_type: 'daily',
                initialMount: true,
                post_type: +data.ad_target,
                selected_post: {},
            },
        );
    }


    buildDTO(data, emptyData) {
        const preparedDateTime = (date, time) => {
            const preparedDate = moment(date).format('YYYY-MM-DD');
            return convertToWarsawTimeZone({date: preparedDate, time}).format('YYYY-MM-DD HH:mm');
        };

        const getObjectDifference = (obj1, obj2) => {
            const diff = {};

            const compareValues = (key, value1, value2) => {
                if (_.isArray(value1) && _.isArray(value2)) {
                    const removed = _.differenceWith(value2, value1, _.isEqual);
                    const added = _.differenceWith(value1, value2, _.isEqual);
                    if (added.length > 0 || removed.length > 0) {
                        return { removed, added };
                    }
                } else if (_.isObject(value1) && _.isObject(value2)) {
                    const nestedDiff = getObjectDifference(value1, value2);
                    if (Object.keys(nestedDiff).length > 0) {
                        return nestedDiff;
                    }
                } else if (!_.isEqual(value1, value2)) {
                    return {  before: value2, after: value1 };
                }
                return null;
            };

            for (const key in obj1) {
                const diffValue = compareValues(key, obj1[key], obj2[key]);
                if (diffValue) {
                    diff[key] = diffValue;
                }
            }

            for (const key in obj2) {
                if (!(key in obj1)) {
                    diff[key] = { before: obj2[key], after: undefined,  };
                }
            }

            return diff;
        };


        const diff = getObjectDifference({...data, end_date: preparedDateTime(data.end_date, data.end_time)}, {...emptyData, end_date: preparedDateTime(emptyData.end_date, emptyData.end_time)});

        return {
            // media: data.media,
            ad_target: data.ad_target,
            post_type: data.post_type,
            products: data.products,
            description_text: data.description_text,
            heading_text: data.heading_text,
            end_date_active: data.end_date_active,
            end_date: preparedDateTime(data.end_date, data.end_time),
            budget_value: data.budget_value,
            budget_type: data.budget_type,
            unactive_when_sold: data.unactive_when_sold,
            selected_fb_ad_post_id: +data.post_type === 1 ? data.selected_fb_ad_post_id : null,
            target_current_clients: +data.ad_target === 1 ? data.target_current_clients : null,
            target_new_clients: +data.ad_target === 1 ? data.target_new_clients : null,
            changes: diff,
        }
    }
}

export default new MarketingDetailsFormModel();
