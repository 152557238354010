import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import Slider from '../slider/Slider';

const Receivers = ({ availableReceivers, receivers, setValue, disabled }) => {
    const { getLangText } = useLang();

    return (
        <div className="flex flex-col gap-2">
            <p className="font-bold">{getLangText('chooseReceivers')}</p>
            <div
                className={`flex flex-col gap-2 p-3.5 border-[1px] border-[#E5E7EB] rounded-[8px] ${disabled ? 'opacity-50' : ''}`}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        <span className="text-[#6b7280] text-[12px] font-medium">
                            {getLangText('receiversLabel')}
                        </span>
                        <div className="tooltip-parent">
                            <i className="icon-info-thin text-[#9CA3AF]"></i>
                            <div className="tooltip-content w-[340px] text-wrap">
                                {getLangText('thisIsNumberOfClients')}
                            </div>
                        </div>
                    </div>
                    <div className="rounded-full px-2 border-[1px] border-[#E5E7EB]">
                        <span className="text-[#101827] text-sm font-bold">
                            {receivers}
                        </span>
                    </div>
                </div>
                <Slider
                    min={1}
                    max={availableReceivers}
                    value={receivers}
                    onChange={(value) => setValue('receivers', value)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default Receivers;
