import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useTableRowClick from "../../../../../shared/hooks/useTableRowClick";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import EmptyList from "../../../../../shared/EmptyList";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";
import SelectedRow from "../../../../../products/lists/components/selectedRow/SelectedRow";
import ProductsReserveListActions from "../../../../../products/lists/reserveProductsList/ListActions";
import {getStateRoot} from "../../../../../products/lists/productsList/listReducer";
import CheckboxField from "../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import SelmoBoosterTag from "../../../../../../../src_shared/components/ui/SelmoBoosterTag/SelmoBoosterTag";
import ClientAvatar from "../../../../../live/shared/Avatar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";
import TimeAgo from "../../../../../../../src_shared/helpers/TimeAgo";
import CustomDropdown from "../../../../../shared/components/dropdown/CustomDropdown";
import {Link} from "react-router-dom";
import {useUnitPrice} from "../../../../../shared/helpers/Price";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
                   type,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(`${RouterPaths.OrdersList}`);
    const {getPrice} = useUnitPrice();
    const {lang} = useSelector((state) => state.i18n);
    const {userData} = useSelector((state) => state.session);

    const getParsedPrice = (price) => {
        const preparedPrice = parseFloat(price.toString().replace(',', '.'))
        return getPrice(preparedPrice)
    }
    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg rounded-b-medium"
            imgSrc="/assets/images//illustration/orders-empty.svg"
            title={getLangText('noSales')}
            // text={getLangText('changeSearchOrAddReelsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible rounded-b-medium">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="products.name"
                                onClick={updateSortOptions}
                                className={getSortClass('products.name')}
                            >
							<span name="products.name">
								{getLangText('productLabel')}
							</span>
                            </th>
                            <th
                                name="selmo_clients.name"
                                onClick={updateSortOptions}
                                className={getSortClass('selmo_clients.name')}
                            >
							<span name="selmo_clients.name">
								{getLangText('clientLabel')}
							</span>
                            </th>
                            {type !== 'details' &&
                                <th
                                    name="products.price"
                                    onClick={updateSortOptions}
                                    className={getSortClass('products.price')}
                                >
                                    <span name="products.price">
                                        {getLangText('priceLabel')}
                                    </span>
                                </th>
                            }
                            <th
                                name="quantity"
                                onClick={updateSortOptions}
                                className={`${getSortClass('quantity')} text-right`}
                            >
							<span name="quantity">
								{getLangText('quantityLabel')}
							</span>
                            </th>
                            {type !== 'details' &&
                                <th
                                    name="date"
                                    onClick={updateSortOptions}
                                    className={`text-right ${getSortClass('date')}`}
                                >
                                    <span name="date">
                                        {getLangText('dateLabel')}
                                    </span>
                                </th>
                            }
                            {/*<th/>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                {...onTableRowClick(item.shop_order_id)}
                            >
                                <td className="with-photo w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <div>
                                            <figure>
                                                {item.product_photo && item.product_photo !== '0' ?
                                                    <ServerImage src={item.product_photo} alt={item.name}/> :
                                                    <img src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </figure>
                                        </div>
                                        <div>
                                            <div className="product-name">
                                                <span className="font-bold">{item.name}</span>
                                                <span className="d-lg-none">
                                                {(item.price || item.sizes_price) ?
                                                    (item.sizes_price ? `${getParsedPrice(+item.sizes_price)}` : `${getParsedPrice(+item.price)}`) :
                                                    `${getPrice(0)}`
                                                }
                                                </span>
                                                <span
                                                    className="d-lg-none">{` • ${item.quantity} ${getLangText('piecesShortLabel')}`}</span>
                                                <div className="small">{item.size_name && `${item.size_name}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="with-photo small-client full-radius w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <ClientAvatar
                                            className="d-lg-block d-none"
                                            photo={item.client_avatar}
                                            type={item.social_type}
                                        />
                                        <div>
                                            <div className="product-name">
                                                <div>
                                                    {item.client_name}
                                                </div>
                                                {!!item.is_new_client &&
                                                    <div
                                                        className="status-bg small-status inline-block mt-1 green">{getLangText('newClientLabel')}</div>
                                                }
                                                {!!item.is_mionetto && +userData.selmo_boss === 69 &&
                                                    <div
                                                        className="status-bg small-status inline-block mt-1 blue">{getLangText('newClientLabel')}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {type !== 'details' &&
                                    <td className="w-lg-auto w-100 d-lg-table-cell d-none">
                                        {(item.price || item.sizes_price) ?
                                            (item.sizes_price ? `${getParsedPrice(item.sizes_price)}` : `${getParsedPrice(item.price)}`) :
                                            `${getPrice(0)}`
                                        }
                                    </td>
                                }
                                <td className="d-lg-table-cell text-right d-none">
                                    {item.quantity}
                                </td>
                                {type !== 'details' &&
                                    <td
                                        data-thead={`${getLangText('addedLabel')}:`}
                                        className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 mb-2 text-nowrap"
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip
                                                    className="small-size"
                                                    id="time"
                                                >
                                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                                </Tooltip>
                                            }
                                        >
                                            <TimeAgo
                                                datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
                                                locale={lang}
                                            />
                                        </OverlayTrigger>
                                    </td>
                                }
                                {/*<td className="text-right absolute-dropdown-dots">*/}
                                {/*    {!hiddenActions &&*/}
                                {/*        <CustomDropdown*/}
                                {/*            icon="icon-dots"*/}
                                {/*            onClick={(e) => e.stopPropagation()}*/}
                                {/*        >*/}
                                {/*            <Link*/}
                                {/*                to={`${RouterPaths.ClientsList}/${item.shop_client_id}`}*/}
                                {/*                className="custom-option"*/}
                                {/*            >*/}
                                {/*                {getLangText('goToClientButton')}*/}
                                {/*            </Link>*/}
                                {/*            <button*/}
                                {/*                className="custom-option"*/}
                                {/*                onClick={() => onCreateOrderButtonClick(item)}*/}
                                {/*            >*/}
                                {/*                {getLangText('createOrderButton')}*/}
                                {/*            </button>*/}
                                {/*        </CustomDropdown>*/}
                                {/*    }*/}
                                {/*</td>*/}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
