import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePagination, {
    usePaginationReloadEffect,
} from './pagination/usePagination.js';
import useTable from './table/useTable.js';
import useWaiting from './waiting/useWaiting.js';
import useModal from '../modal/useModal';
import { createSelector } from 'reselect';

export const useClearState = (beforeDestroy) => {
    useEffect(
        () => () => {
            beforeDestroy();
        },
        [],
    );
};

const useListActions = (ListActions, FiltersAction) => {
    const dispatch = useDispatch();

    const loadData = useCallback(
        (parentId, signal) => dispatch(ListActions.loadData(parentId, signal)),
        [dispatch, ListActions],
    );

    const updateValueInItem = useCallback(
        (id, field, value) =>
            dispatch(ListActions.updateValueInItem(id, field, value)),
        [dispatch, ListActions],
    );

    const askForRemoveItem = useCallback(
        (id) => dispatch(ListActions.askForRemoveItem(id)),
        [dispatch, ListActions],
    );

    const cancelItemRemoval = useCallback(
        () => dispatch(ListActions.cancelItemRemoval()),
        [dispatch, ListActions],
    );

    const confirmItemRemoval = useCallback(
        () => dispatch(ListActions.confirmItemRemoval()),
        [dispatch, ListActions],
    );

    const callSearch = useCallback(
        (searchText, id, signal) =>
            dispatch(ListActions.callSearch(searchText, id, signal)),
        [dispatch, ListActions],
    );

    const callSort = useCallback(
        (sortBy, sortDir, id) =>
            dispatch(ListActions.callSort(sortBy, sortDir, id)),
        [dispatch, ListActions],
    );

    const updateItemsPerPage = useCallback(
        (name, value) => dispatch(ListActions.updateItemsPerPage(name, value)),
        [dispatch, ListActions],
    );

    const reset = useCallback(() => {
        dispatch(ListActions.reset());
        if (FiltersAction) {
            dispatch(FiltersAction.resetAllFilters());
        }
    }, [dispatch, ListActions, FiltersAction]);

    const loadPage = useCallback(
        (page, id) => dispatch(ListActions.loadPage(page, id)),
        [dispatch, ListActions],
    );

    return {
        loadData,
        loadPage,
        updateItemsPerPage,
        updateValueInItem,
        reset,

        askForRemoveItem,
        cancelItemRemoval,
        confirmItemRemoval,

        callSearch,

        callSort,
    };
};

const useList = (config) => {
    const {
        ListActions,
        FiltersActions,
        getStateRoot,
        beforeDestroy,
        hasParentId = false,
        params,
        preventAutoLoad,
    } = config;

    const dispatch = useDispatch();
    const dispatchActions = useListActions(ListActions, FiltersActions);
    const controller = new AbortController();
    const signal = controller.signal;

    const waiting = useWaiting(ListActions.waiting, getStateRoot);
    const pagination = usePagination(ListActions.pagination, getStateRoot);
    const table = useTable(ListActions.table, getStateRoot);
    const modal = useModal(ListActions.modal, getStateRoot);
    const { isFirstStepPage } = useSelector(
        createSelector(getStateRoot, (stateRoot) => stateRoot),
    );

    const loadData = (parentId) => dispatchActions.loadData(parentId, signal);

    const getSortDirClass = (name) =>
        name !== table.sort.sortBy ? '' : table.sort.sortDir;

    const getParentId = () => (hasParentId && params.id ? params.id : null);

    const getSortClass = (field) => `sort-button ${getSortDirClass(field)}`;

    const updateSortOptions = (event) => {
        const fieldName =
            event.target.getAttribute('name') ||
            event.currentTarget.getAttribute('name');

        const id = getParentId();
        const newSortDir =
            table.sort.sortDir === 'asc' || table.sort.sortBy !== fieldName
                ? 'desc'
                : 'asc';

        dispatchActions.callSort(fieldName, newSortDir, id);
    };

    const callSearch = (textSearch) => {
        const id = getParentId();
        dispatchActions.callSearch(textSearch, id, signal);
    };

    usePaginationReloadEffect({
        ...pagination,
        parentId: getParentId(),
        loadData: dispatchActions.loadData,
    });

    useClearState(beforeDestroy || dispatchActions.reset);

    useEffect(() => {
        if (!preventAutoLoad) {
            dispatch(ListActions.loadData(getParentId(), signal));
            return () => {
                controller.abort();
            };
        }
    }, []);

    return {
        pagination: {
            ...pagination,
            updateItemsPerPage: dispatchActions.updateItemsPerPage,
        },
        search: {
            ...table.search,
            callSearch,
        },

        sort: {
            ...table.sort,
            updateSortOptions,
            callSort: dispatchActions.callSort,
            getSortClass,
        },

        remove: {
            isModalVisible: modal.modalVisible,
            markedToRemove: table.remove.markedToRemove,
            askForRemoveItem: dispatchActions.askForRemoveItem,
            cancelItemRemoval: dispatchActions.cancelItemRemoval,
            confirmItemRemoval: dispatchActions.confirmItemRemoval,
        },

        list: {
            ...table.collection,
            isWaiting: waiting.waiting,
            isFirstStepPage,
            loadData: loadData,
            getParentId,
            loadPage: dispatchActions.loadPage,
            updateValueInItem: dispatchActions.updateValueInItem,
            reset: dispatchActions.reset,
        },
    };
};

export default useList;
