import React from "react";
import Field from "../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import Tags from "./components/Tags";
import useLang from "../../../../../src_shared/hooks/useLang";
import ToggleContent from "../../../../../src_shared/components/toggle/ToggleContent";

const NoteForm = ({values, getFieldProps, onSave, setValue}) => {
    const {getLangText} = useLang();

    return (
        <>
            <section className="delivery-section mb-0 border-0 pb-3">
                <ToggleContent
                    id="linkToTracking"
                    onlyIcon={true}
                    defaultVisible={!!values.tracking_link}
                    title={getLangText('linkToTracking')}
                >
                    <div className="tracking-link mt-2">
                        <div className="form-group with-save-btn absolute-btn">
                            <Field
                                {...getFieldProps('tracking_link')}
                                value={values.tracking_link}
                                showLabel={false}
                                setValue={setValue}
                                placeholder={getLangText('pasteLinkLabel')}
                            />
                            <button
                                type="button"
                                className="button border-button"
                                onClick={onSave}
                            >
                                {getLangText('saveButton')}
                            </button>
                        </div>
                    </div>
                </ToggleContent>
            </section>
            <section className="delivery-section">
                <ToggleContent
                    onlyIcon={true}
                    id="orderNoteLabel"
                    title={getLangText('orderNoteLabel')}
                    defaultVisible={!!values.note || !!values.tags?.length}
                >
                    <div className="tracking-link mt-2">
                        <div className="form-group">
                            <TextArea
                                {...getFieldProps('note')}
                                value={values.note}
                                showLabel={false}
                                setValue={setValue}
                                placeholder={getLangText('textNoteLabel')}
                            />
                            <Tags
                                tags={values.tags}
                                setValue={setValue}
                            />
                            <div className="d-flex justify-content-end mt-2">
                                <button
                                    onClick={onSave}
                                    type="button"
                                    className="button border-button"
                                >
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </div>
                </ToggleContent>
            </section>
        </>
    );
};

export default NoteForm;



