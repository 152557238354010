import React from 'react';
import ClientAvatar from "../../../../live/shared/Avatar";
import Loader from "../../../../shared/Loader";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import useLang from "../../../../../../src_shared/hooks/useLang";

const PersonItem = ({item, updateUsers, updateConnectedUsers, inModal}) => {
    const {getLangText} = useLang();

    const [{onSave: grantAccess, isWaiting: isWaitingForGrant}] = useSubmitData({
        rest: `${services.API_VIDEO_APP_USERS_CONNECT}`,
        afterSubmit: updateUsers,
    });

    const [{onSave: removeAccess, isWaiting: isWaitingForRemoving}] = useSubmitData({
        rest: `${services.API_VIDEO_APP_USER_DELETE}`,
        afterSubmit: updateConnectedUsers,
    });

    return (
        <button
            type="button"
            onClick={(e) => inModal ? grantAccess(e, {app_user_id: item.app_client_id}) : removeAccess(e, {app_user_id: item.app_client_id})}
            className={`flex items-center w-full group hover:bg-[#f9f9f9] rounded-[8px] duration-300 transition-all p-2`}
        >
            <ClientAvatar
                className="big-size"
                photo={item.photo}
            />
            <div className="text-sm ml-2">
                <div className="font-bold">{item.name}</div>
                <div className="font-medium">{item.email}</div>
            </div>
            {inModal ?
                <div
                    style={{padding: 10, fontSize: 12, fontWeight: 500}}
                    className={`button primary group-hover:bg-[#1c4ed8] small-size ml-auto ${isWaitingForGrant ? 'loading-button' : ''}`}
                >
                    {isWaitingForGrant && <Loader isLoading/>}
                    <i style={{color: '#fff', position: 'relative', top: -1}}
                        className="icon-plus text-[8px] mr-1"/>
                    {getLangText('addAccess')}
                </div> :
                <div
                    style={{padding: 10, fontSize: 12, fontWeight: 500}}
                    className={`button grey-button group-hover:bg-[#e9e9ea] group-hover:text-[#5e6470] small-size ml-auto flex items-center ${isWaitingForRemoving ? 'loading-button' : ''}`}
                >
                    {isWaitingForRemoving && <Loader isLoading/>}
                    <i className="icon-cross text-[8px] mr-1"/>
                    {getLangText('removeAccess')}
                </div>
            }
        </button>
    );
};

export default PersonItem;
