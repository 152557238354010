import React from 'react';
import {
    ORDER_PAYMENT_BANCONTACT,
    ORDER_PAYMENT_BLIK,
    ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY,
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER,
    ORDER_PAYMENT_BM_TRANSFER,
    ORDER_PAYMENT_PAYPO,
} from '../../../../../shared/enums/PaymentStatuses';
import CardDetails from '../../../../../shared/components/paymentMethods/components/CardDetails';
import AdditionalTransferAccounts from '../../../../../shared/components/paymentMethods/components/AdditionalTransferAccounts';
import useLang from '../../../../../../../src_shared/hooks/useLang';
import AutoPayBanks from './AutoPayBanks';

const Payment = ({
    item,
    values,
    onPaymentChange,
    updateValues,
    paymentValidationActive,
    onTransferAccountChange,
    shopInfo,
    cardDetailsVisible,
    getPreparedPaymentLabel,
    checkIsPaymentOnline,
}) => {
    const { getLangText } = useLang();

    return (
        <React.Fragment key={item.id}>
            {!!+item.active && (
                <div
                    className={`content p-0 payment-content with-left-border ${checkIsPaymentOnline(item.id) ? 'grey-border' : ''} ${checkIsPaymentOnline(item.id) && values.payment === item.id ? 'blue-border' : ''} ${values.payment === item.id ? 'active' : ''}`}>
                    <div
                        style={{
                            position:
                                item.id === ORDER_PAYMENT_BLUEMEDIA
                                    ? 'static'
                                    : 'relative',
                        }}
                        className={`content__top-part`}>
                        <input
                            checked={values.payment === item.id}
                            onChange={() => onPaymentChange('payment', item.id)}
                            type="radio"
                            name="payment"
                            required
                        />
                        <span className="checkmark" />
                        <div className="flex-grow-1">
                            <div className="label">
                                {item.id === ORDER_PAYMENT_MOBILEPAY ? (
                                    <figure>
                                        <img
                                            src="/assets/images/payments/mobilepay.svg"
                                            style={{ height: '18px' }}
                                        />
                                    </figure>
                                ) : (
                                    getPreparedPaymentLabel(item)
                                )}
                            </div>
                            <div className="data">
                                {(item.description &&
                                    getLangText(item.description)) ||
                                    item.description}
                                {(item.id === ORDER_PAYMENT_BLIK ||
                                    item.id === ORDER_PAYMENT_BLUEMEDIA ||
                                    item.id === ORDER_PAYMENT_PAYPO) && (
                                    <div>
                                        <div className="popular-text">
                                            <i className="icon-tick-c" />
                                            {getLangText(
                                                item.id === ORDER_PAYMENT_BLIK
                                                    ? 'fastConvenientAndSecure'
                                                    : item.id ===
                                                        ORDER_PAYMENT_PAYPO
                                                      ? 'paypoSubLabel'
                                                      : 'convenientWithoutTypingData',
                                            )}
                                        </div>
                                    </div>
                                )}
                                {(item.id === ORDER_PAYMENT_TRANSFER ||
                                    item.id === ORDER_PAYMENT_BM_TRANSFER) && (
                                    <div>
                                        <div className="popular-text grey">
                                            <i className="icon-time" />
                                            {getLangText(
                                                'paymentWillArriveWithinTwoDaysLabel',
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {item.id === ORDER_PAYMENT_BLIK && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    className="w-full object-contain"
                                    src="/assets/images/payments/blik.svg"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {item.id === ORDER_PAYMENT_STRIPE_CARD && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    className="w-full object-contain"
                                    src="/assets/images/payments/cards.svg"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {item.id?.includes(ORDER_PAYMENT_PAYPAL) && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/paypal.png"
                                    className="w-full object-contain"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {item.id === ORDER_PAYMENT_MB_WAY && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/mb-way.png"
                                    className="w-full object-contain"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {(item.id === ORDER_PAYMENT_TRANSFER ||
                            item.id === ORDER_PAYMENT_BM_TRANSFER) && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/standard.svg"
                                    className="w-full object-contain"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </figure>
                        )}
                        {item.id === ORDER_PAYMENT_BANCONTACT && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/bancontact.svg"
                                    className="w-full object-contain"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {item.id === ORDER_PAYMENT_PAYPO && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/paypo.svg"
                                    className="w-full object-contain"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                        {item.logo && (
                            <figure className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src={item.logo}
                                    className="w-full object-contain"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </figure>
                        )}
                    </div>
                    {cardDetailsVisible &&
                        item.id === ORDER_PAYMENT_STRIPE_CARD && (
                            <div className="-mt-8 p-[20px]">
                                <CardDetails
                                    values={values}
                                    updateValues={updateValues}
                                    paymentValidationActive={
                                        paymentValidationActive
                                    }
                                />
                            </div>
                        )}
                    {item.id === ORDER_PAYMENT_BLUEMEDIA && <AutoPayBanks />}
                </div>
            )}
            {item.id === ORDER_PAYMENT_TRANSFER &&
                !!item.additional_transfer_accounts?.length &&
                item.additional_transfer_accounts?.map((account) => (
                    <AdditionalTransferAccounts
                        key={account.id}
                        account={account}
                        isPopularBoxActive={false}
                        onTransferAccountChange={onTransferAccountChange}
                        shopInfo={shopInfo}
                        values={values}
                    />
                ))}
        </React.Fragment>
    );
};

export default Payment;
