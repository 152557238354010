import React, { useEffect, useRef, useState } from 'react';
import ServerImage from '../../../../shared/components/serverImage/ServerImage';
import Variants from './Variants';
import { useUnitPrice } from '../../../../shared/helpers/Price';
import useLang from '../../../../../../src_shared/hooks/useLang';
import SelectProductModal from './selectProductModal/SelectProduct';
import useFormLocal from '../../../../../../src_shared/hooks/useFormLocal';
import SelectProductModel from '../../../../../../modules/models/overlay/SelectProductModel';
import { services } from '../../../../../RestServices';
import { useParams } from 'react-router-dom';
import ConfirmModal from '../../../../orders/list/components/export/ConfirmModal';
import useSubmitData from '../../../../../../src_shared/hooks/useSubmitData';
import AdditionalSettings from './AdditionalSettings';
import CollectionVariants from './ColectionVariants';
import Loader from '../../../../../../src_shared/components/loader/Loader';
import { useSelector } from 'react-redux';
import ScannerInfoModal from './ScannerInfoModal';

const CurrentProduct = ({
    product,
    updateMainValues,
    inStock,
    activeCollectionId,
    withVariants,
    showPrice,
    show_product_name,
}) => {
    const { getPrice } = useUnitPrice();
    const { getLangText } = useLang();
    const { id } = useParams();
    const scannerInputRef = useRef();
    const [isWaitingForScan, setIsWaitingForScan] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [isFocused, setIsFocused] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showScannerModal, setShowScannerModal] = useState(false);

    const afterSubmit = (response) => {
        setShowModal(false);
        setIsWaitingForScan(false);
        updateMainValues(response);
        scannerInputRef?.current?.focus();
    };

    const [
        {
            values,
            updateValues,
            onSave,
            isWaiting: isWaitingForSelectProduct,
            getFieldProps,
        },
    ] = useFormLocal({
        rest: `${services.API_MOBILE_ACTIVE_PRODUCT}/${id}`,
        model: SelectProductModel,
        initialAutoLoad: false,
        afterSubmit,
    });

    const [{ onSave: onCancel, isWaiting: isWaitingForCancel }] = useSubmitData(
        {
            rest: `${services.API_MOBILE_ACTIVE_PRODUCT}/${id}`,
            model: SelectProductModel,
            values: {
                product_id: null,
            },
            afterSubmit: () => {
                updateMainValues({
                    active_product_info: {},
                    base: '',
                });
                setShowCancelModal(false);
                scannerInputRef?.current?.focus();
            },
        },
    );

    useEffect(() => {
        if (product) {
            updateValues({
                selectedProduct: product,
                in_stock: inStock,
                with_variants: withVariants,
                active_collection_id: activeCollectionId,
                show_price: showPrice,
                show_product_name: show_product_name,
            });
        }
    }, [
        product,
        inStock,
        withVariants,
        activeCollectionId,
        showPrice,
        show_product_name,
    ]);

    const updateOverlayFromScanner = async (e) => {
        setIsWaitingForScan(true);
        try {
            const inputValue = e.target.value;

            const jsonMatch = inputValue.match(/\{.*?\}/);

            if (jsonMatch) {
                const obj = JSON.parse(jsonMatch[0]);
                await onSave(e, { selectedProduct: { id: obj.product_id } });
            } else {
                throw new Error(
                    'Nie znaleziono poprawnego JSON-a w wartości inputa.',
                );
            }

            e.target.value = '';
        } catch (error) {
            console.error('Błąd podczas przetwarzania skanera:', error.message);
            setIsWaitingForScan(false);
        }
    };

    return (
        <>
            <div
                className={`default-shadow-box items-center summary-box mb-3 p-4`}>
                <div className="flex items-center w-full p-0 sm:grow-0 grow">
                    <div className="flex items-center w-full justify-between">
                        <div className="flex items-center">
                            <i className="icon-qr-code text-[23px] translate-y-[1px] text-[#9ca3af]" />
                            <div className="font-bold ml-2 flex flex-col">
                                <span>{getLangText('scanner')}</span>
                                <button
                                    type="button"
                                    onClick={() => setShowScannerModal(true)}
                                    className="text-desc text-xs underline font-normal">
                                    {getLangText('howItWorks')}
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div
                                className={`text-sm font-bold ${isFocused ? 'text-[#0eb981]' : 'text-red-700'}`}>
                                {getLangText(
                                    isFocused
                                        ? 'activeLabel'
                                        : 'notActiveLabel',
                                )}
                            </div>
                            {!isFocused && (
                                <button
                                    onClick={() => {
                                        scannerInputRef?.current?.focus();
                                    }}
                                    type="button"
                                    disabled={isFocused}
                                    className="button primary black small-size ml-2">
                                    {getLangText('turnOn')}
                                </button>
                            )}
                        </div>
                    </div>
                    <input
                        ref={scannerInputRef}
                        className="absolute opacity-0 pointer-events-none h-0 w-0"
                        onChange={updateOverlayFromScanner}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        type="text"
                        autoFocus
                    />
                </div>
            </div>
            <div className="default-shadow-box summary-box loader-parent">
                <div className="flex items-center sm:grow-0 grow">
                    <div>
                        <div className="font-bold">
                            {getLangText('currentPresentingLabel')}
                        </div>
                        <div className="text-desc text-sm">
                            {getLangText('thisProductYouAreSelling')}
                        </div>
                    </div>
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="button primary small-size ml-auto">
                        {getLangText(
                            product.id
                                ? 'customerBasketChangeButton'
                                : 'setButton',
                        )}
                    </button>
                </div>
                <div className="loader-parent min-height-auto sm:grow-0 grow">
                    <Loader
                        className="small-loader"
                        isLoading={isWaitingForScan}
                    />
                    {product.id ? (
                        <div>
                            <div className="current-basket-products loader-parent border rounded-[5px] overflow-hidden mt-3 bigger-size">
                                <div className="py-2 px-3 bg-[#F9FAFB] mb-3">
                                    <a
                                        target="_blank"
                                        href={`/magazyn/edytuj/${product.id}/`}
                                        className="flex items-center hover:text-[#000] group hover:no-underline"
                                        rel="noreferrer">
                                        <div className="grow">
                                            <div className="font-bold leading-[20px] group-hover:underline">
                                                {product.name}
                                            </div>
                                            {!!product.description && (
                                                <div className="text-sm text-desc">
                                                    {product.description}
                                                </div>
                                            )}
                                            {!!+activeCollectionId && (
                                                <div className="inline-block text-xs px-2 py-1 font-bold rounded-[5px] text-[#fff] bg-[#000]">
                                                    {getLangText(
                                                        'selectedCollection',
                                                    )}
                                                </div>
                                            )}
                                            <div className="space-x-1 space-y-1 text-xs">
                                                <span className="border inline-block rounded-[5px] bg-[#FFFFFF] text-[#4B5563] px-2 py-1 font-bold">
                                                    {values.product_code}&nbsp;
                                                    {product.selmo_code}
                                                </span>
                                                <span className="border inline-block rounded-[5px] bg-[#FFFFFF] text-[#4B5563] px-2 py-1 font-bold">
                                                    {getPrice(product.price)}
                                                </span>
                                            </div>
                                        </div>
                                        <figure className="w-[70px] h-[70px] min-w-[70px] rounded-[5px] overflow-hidden ml-2 self-start">
                                            {!!product.photo_small ? (
                                                <ServerImage
                                                    className="w-full h-full object-cover"
                                                    src={product.photo_small}
                                                />
                                            ) : (
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src="/assets/images/default/shop-default.svg"
                                                    alt={product.name}
                                                />
                                            )}
                                        </figure>
                                    </a>
                                </div>
                                <div className="px-3 pb-2">
                                    {activeCollectionId ? (
                                        <CollectionVariants product={product} />
                                    ) : (
                                        <Variants product={product} />
                                    )}
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() => setShowCancelModal(true)}
                                    type="button"
                                    className="button border-button small-size w-full mt-3">
                                    {getLangText('stopPresenting')}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="text-center py-3">
                                <img
                                    className="mx-auto"
                                    src="/assets/images/empty/products-empty.svg"
                                    alt=""
                                />
                                <div className="font-bold text-sm">
                                    {getLangText('noSelectedProduct')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {product.id && (
                <AdditionalSettings
                    isWaiting={isWaitingForSelectProduct}
                    onSave={onSave}
                    getFieldProps={getFieldProps}
                    activeCollectionId={!!+activeCollectionId}
                />
            )}
            {showCancelModal && (
                <ConfirmModal
                    title={getLangText('wantToStopPresenting')}
                    saveButton={getLangText('yesButton')}
                    show={showCancelModal}
                    isWaiting={isWaitingForCancel}
                    onSubmit={onCancel}
                    hide={() => setShowCancelModal(false)}
                />
            )}
            {showModal && (
                <SelectProductModal
                    onModalSave={onSave}
                    isWaiting={isWaitingForSelectProduct}
                    values={values}
                    updateValues={updateValues}
                    hideModal={() => setShowModal(false)}
                    modalVisible={showModal}
                />
            )}
            {showScannerModal && (
                <ScannerInfoModal
                    modalVisible={showScannerModal}
                    hideModal={() => setShowScannerModal(false)}
                />
            )}
        </>
    );
};

export default CurrentProduct;
