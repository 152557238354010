import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import Field from "../../../../../src_shared/components/form/field/Field";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useParams} from "react-router-dom";
import Loader from "../../../shared/Loader";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../../services/axios/axios";
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {filesSetModalVisibility} from "../../../shared/header/files/filesDropdown/FilesActions";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {services} from "../../../../RestServices";
import VariantsSquModalModel from "../../../../../modules/models/VariantsSquModalModel";

const VariantsSquModal = () => {
        const {getLangText} = useLang();
        const params = useParams()
        const {userData} = useSelector((state) => state.session);
        const dispatch = useDispatch();
        const {
            data: {values, setValue, setNestedValue},
            form: {isWaiting, getFieldProps, onSave},
            modal: {modalVisible, hideModal}
        } = useForm({
            FormActions,
            params,
            requireParentId: true,
        });

        const saveWithQrCode = async () => {
            const preparedValues = VariantsSquModalModel.buildDTO(values)
            try {
                await api.put(`/${services.API_STORAGE_PRODUCT_SIZE_STOCK}/${params.id}${getGETParamsUrl(
                    {
                        qr_code: '1'
                    }
                )}`, preparedValues)
                hideModal();
                dispatch(filesSetModalVisibility(true));
            } catch (e) {
                dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
            }
        }

        const getTotalNewSqu = () => {
            return values?.sizes.reduce((sum, size) => {
                const val = Number(size.new_squ);
                return sum + (isNaN(val) ? 0 : val);
            }, 0);
        };

        return (
            <Modal
                dialogClassName="default-modal"
                show={modalVisible}
                onHide={hideModal}
            >
                <Modal.Header className="with-border">
                    <Modal.Title>
                        {getLangText('setUpVariantsSquLabel')}
                        <div className="sub-title"
                             dangerouslySetInnerHTML={{__html: getLangText('setUpVariantsSquSubLabel')}}/>
                    </Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
                <div className="loader-parent">
                    <Loader isLoading={isWaiting}/>
                    <Modal.Body>
                        {values.sizes.map((size) => (
                            <div
                                key={size.id}
                                className="grid grid-cols-3 gap-2 sm:gap-4"
                            >
                                <div className="col-span-2">
                                    <Field
                                        {...getFieldProps('size')}
                                        value={size.size}
                                        label={getLangText('variantNameLabel')}
                                        placeholder={getLangText('variantExampleLabel')}
                                        setValue={setValue}
                                        disabled
                                    />
                                </div>
                                <Field
                                    {...getFieldProps('new_squ')}
                                    value={size.new_squ}
                                    label={getLangText('quantityLabel')}
                                    type="number"
                                    setValue={(field, value) => setNestedValue('sizes', size.id, field, value)}
                                />
                            </div>
                        ))}
                        <div className="flex items-center justify-end text-sm">
                            <div className="font-medium">{getLangText('totalLabel')}:</div>
                            <div className="font-bold ml-1">
                                {getTotalNewSqu()}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                        <button
                            type="button"
                            className="button border-button ml-auto"
                            onClick={hideModal}
                        >
                            {getLangText('cancelButton')}
                        </button>
                        <div className="flex button-with-arrow primary">
                            <button
                                type="button"
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                onClick={onSave}
                            >
                                {getLangText('saveButton')}
                            </button>
                            {!!+userData.depot_system &&
                                <CustomDropdown
                                    icon="icon-arrows"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <button
                                        onClick={saveWithQrCode}
                                        type="button"
                                        className="custom-option"
                                    >
                                        {getLangText('saveAndGenerateQrCode')}
                                    </button>
                                </CustomDropdown>
                            }
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
;

export default VariantsSquModal;
