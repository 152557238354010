import React from 'react';
import useLang from "../../../../../../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../../../../../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../../../../../../shared/helpers/dateHelpers";

const Post = ({item, setValue, values}) => {
    const {getLangText} = useLang();

    return (
        <button
            type="button"
            onClick={() => setValue(item.id)}
            key={item.id}
            className={`content payment-content d-block w-full text-left hover:bg-gray-50 transition-all ${values?.selected_fb_ad_post_id === item.id ? 'active' : ''}`}
        >
            <div
                style={{paddingTop: 15, paddingBottom: 15}}
                className="content__top-part"
            >
                <div className="flex-grow-1 d-flex align-items-center">
                    <figure
                        className="w-[50px] min-w-[50px] h-[50px] rounded-medium overflow-hidden mr-2">
                        {item.full_picture ?
                            <ServerImage
                                style={{width: '100%', height: '100%'}}
                                 className="w-full h-full object-cover"
                                src={item.full_picture}/> :
                            <img
                                style={{width: '100%', height: '100%'}}
                                className="w-full h-full object-cover"
                                src="/assets/images/default/product.svg"/>
                        }
                    </figure>
                    <div className="mr-3">
                        <div className="leading-[18px] text-sm font-bold line-clamp-2">
                            {item.message}
                        </div>
                        <div className="text-xs font-medium text-desc mt-1">
                            <span>{convertToUserTimeZone(item.created_time).format('DD.MM.YYYY')}</span>&nbsp;
                            <span>{convertToUserTimeZone(item.created_time).format('HH:mm')}</span>
                        </div>
                    </div>
                </div>
                <div className="space-y-1 flex flex-col">
                    <div
                        className={`text-right whitespace-nowrap min-w-[106px] inline-flex items-center text-xs h-[31px] transition-none font-bold ${(values?.selected_fb_ad_post_id === item.id) ? 'text-[white] bg-[#3C61EA] rounded-[5px] px-[12px]' : 'button border-button'}`}
                    >
                        {getLangText(values?.selected_fb_ad_post_id === item.id ? 'selectedPostLabel' : 'selectPostLabel')}
                    </div>
                </div>
            </div>
        </button>
    );
};

export default Post;
