import React from 'react';
import ClientAccountBar from "../components/clientAccountBar/ClientAccountBar";
import PremiumInfoBar from "../premiumInfoBar/PremiumInfoBar";
import NewClientPromoBar from "../components/NewClientPromoBar/NewClientPromoBar";
import {useSelector} from "react-redux";
import NotPaidSelmoProBar from "../components/NotPaidSelmoPro/NotPaidSelmoPro";
import {convertToUserTimeZone} from "../helpers/dateHelpers";
import RedTopBar from "../components/RedTopBar.jsx/RedTopBar";
import SelmoAppBar from "../components/SelmoAppBar.jsx/SelmoAppBar";
import ExtendPlan from "../components/ExtendPlan/ExtendPlan";
import NotPaidMarketing from "../components/NotPaidMarketing/NotPaidMarketing";

const TopInfoBars = () => {

    const {
        userData: {
            user_info,
            tutorial_done_date,
            client_on_trial,
            selmo_pro_fee_not_paid,
            facebook_ads_active,
        }
    } = useSelector((state) => state.session);
    const isNotVisible = !!+client_on_trial || user_info.status || !tutorial_done_date || (convertToUserTimeZone(tutorial_done_date).add(1, 'hours').add(1, 'minutes') <= convertToUserTimeZone(new Date())) || (convertToUserTimeZone(tutorial_done_date).add(1, 'minutes') > convertToUserTimeZone(new Date()));

    return (
        <>
            <ClientAccountBar/>
            {!!user_info?.premium_date &&
                <RedTopBar/>
            }
            {+facebook_ads_active === 3 &&
                <NotPaidMarketing />
            }
            {/*<ExtendPlan />*/}
            {/*<SelmoAppBar/>*/}
            {!!+selmo_pro_fee_not_paid ?
                <NotPaidSelmoProBar/> :
                isNotVisible ?
                    <PremiumInfoBar/> :
                    <NewClientPromoBar isNotVisible={isNotVisible}/>
            }

        </>
    );
};

export default TopInfoBars;
