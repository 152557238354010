import useLang from '../../../../src_shared/hooks/useLang';
import Field from '../../../../src_shared/components/form/field/Field';

const Product = ({
    getFieldProps,
    values,
    setValue,
    setNestedValue,
    product,
    validateErrors,
}) => {
    const { getLangText } = useLang();

    const handleRemoveProduct = (id) => {
        if (values.products?.length === 1) {
            const emptyRow = [{ id, name: '', price: '', qty: '1' }];
            setValue('products', emptyRow);
            return;
        }

        const filtered = values.products.filter((i) => i.id !== id);
        setValue('products', filtered);
    };

    return (
        <div className="flex justify-between gap-2.5">
            <div className="flex flex-col sm:flex-row justify-between sm:gap-2.5 w-full">
                <Field
                    {...getFieldProps('name')}
                    getError={validateErrors?.products?.name}
                    value={product.name}
                    setValue={(field, value) =>
                        setNestedValue('products', product.id, field, value)
                    }
                    label={getLangText('nameLabel')}
                    placeholder={getLangText('tshirt')}
                    className="absolute-error w-full"
                />
                <div className="flex gap-2.5 w-full sm:w-[280px]">
                    <Field
                        {...getFieldProps('price')}
                        getError={validateErrors?.products?.price}
                        value={product.price}
                        setValue={(field, value) =>
                            setNestedValue('products', product.id, field, value)
                        }
                        type="number"
                        label={getLangText('priceLabel')}
                        className="absolute-error"
                    />
                    <Field
                        {...getFieldProps('qty')}
                        getError={validateErrors?.products?.qty}
                        value={product.qty}
                        setValue={(field, value) =>
                            setNestedValue('products', product.id, field, value)
                        }
                        type="number"
                        label={getLangText('quantityLabel')}
                        className="absolute-error"
                    />
                </div>
            </div>
            <button
                type="button"
                onClick={() => handleRemoveProduct(product.id)}
                className="button remove-row-button self-start translate-y-[30px] ">
                <i className="icon-cross text-[#9CA3AF] text-thin text-[12px]" />
            </button>
        </div>
    );
};

export default Product;
