import React, {useMemo} from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL} from "../../../app/shared/enums/ShopOrigins";
import {useParams} from "react-router-dom";
import SelectField from "../../../../src_shared/components/form/selectField/SelectField";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const ShippingForm = ({getFieldProps, onSave, isWaiting, values}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {id} = useParams();

    const countriesOptions = useMemo(() => values.countries?.filter((a) => !!+a.active).map((i) => ({
        value: i.id,
        label: i.name
    })), [values.countries])

    return (
        <>
            <div className="d-flex flex-sm-nowrap flex-wrap mb-3 shipping-form-flex">
                <Field
                    {...getFieldProps('name')}
                    className="mb-sm-0 mb-3 mr-sm-3"
                    label={getLangText('input_label_shipping_name')}
                    placeholder={getLangText('input_placeholder_shipping_name')}
                />
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                    <Field
                        {...getFieldProps('weight_to')}
                        className="small-width mb-0 mr-sm-3"
                        label={getLangText('maximumWeightLabel')}
                        addon="KG"
                        type="number"
                        extraProps={{
                            min: 0,
                            step: "0.1"
                        }}
                    />
                }
                <Field
                    {...getFieldProps('price')}
                    className="small-width mb-0 mr-sm-3"
                    label={getLangText('input_label_shipping_price')}
                    placeholder="12,00"
                    addon={userData.currency_info?.text}
                    type="number"
                    extraProps={{
                        min: 0,
                        step: "0.01"
                    }}
                />
                <SelectField
                    {...getFieldProps('country')}
                    className="default-size mb-0 min-w-[180px]"
                    label={getLangText('input_label_shipping_country')}
                    options={countriesOptions}
                />
            </div>
            {userData.origin === SHOP_ORIGIN_PL &&
                <SwitchField
                    {...getFieldProps('is_inpost')}
                    label={`<img style="display: inline-block" src="/assets/images/inpost.svg" alt="" />${getLangText('settings_shipping_inpost_text_1')} <strong>${getLangText('settings_shipping_inpost_text_2')}</strong>`}
                    className="switch-form inline-label align-items-start big-label light-version mt-2"

                />
            }
            {userData.origin === SHOP_ORIGIN_PL &&
                <SwitchField
                    {...getFieldProps('is_orlen')}
                    label={`<img style="display: inline-block" src="/assets/images/svg/orlen-paczka.svg" alt="" />${getLangText('orlenPointToPickUpOrderLabel')}`}
                    className="switch-form inline-label align-items-start big-label light-version mt-2"

                />
            }
            {userData.origin === SHOP_ORIGIN_PL &&
                <SwitchField
                    {...getFieldProps('is_dpd')}
                    label={`<img style="display: inline-block" src="/assets/images/svg/dpd-logo.svg" alt="" />${getLangText('selectIfClientShouldSelectDPDPickupPoint')}`}
                    className="switch-form inline-label align-items-start big-label light-version mt-2"

                />
            }
            {(+userData.id === TEST_ACCOUNT_SHOP_ID || userData.origin === SHOP_ORIGIN_FR) &&
                <SwitchField
                    {...getFieldProps('is_gls')}
                    label={`<img style="display: inline-block" src="/assets/images/gls.png" alt="" />${getLangText('selectIfClientShouldSelectGLSPickupPoint')}`}
                    className="switch-form inline-label align-items-start big-label light-version mt-2"

                />
            }
            {userData.origin === SHOP_ORIGIN_FR &&
                <SwitchField
                    {...getFieldProps('is_mondial')}
                    label={`<img style="display: inline-block; width: 60px" src="/assets/images/svg/logomondial-relay.svg" alt="" />${getLangText('relaisPointToPickUpOrderLabel')}`}
                    className="switch-form inline-label align-items-start big-label light-version mt-2"

                />
            }
            {id &&
                <div>
                    <SwitchField
                        {...getFieldProps('active')}
                        label={getLangText('active_label')}
                        className="switch-form inline-label align-items-start big-label light-version mt-2"

                    />
                </div>
            }
            <div>
                <button
                    type="button"
                    onClick={onSave}
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    <Loader isLoading={isWaiting}/>
                    {getLangText('saveButton')}
                </button>
            </div>
        </>
    );
};

export default ShippingForm;
