import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Summary from "./Summary";
import Field from "../../../../../../src_shared/components/form/field/Field";

const FIRST_CONFIRMATION_VIEW = 'FIRST_CONFIRMATION_VIEW';
const LAST_CONFIRMATION_VIEW = 'LAST_CONFIRMATION_VIEW';

const RemoveProductsModal = ({
                                 show,
                                 hide,
                                 isWaiting,
                                 title,
                                 SubTitle,
                                 saveButton,
                                 onSubmit,
                                 successGenerated,
                                 generatedCount,
                                 summaryContent,
                                 submitButtonClass = 'primary',
                                 ListActions,
                             }) => {
    const {getLangText} = useLang();
    const [activeView, setActiveView] = React.useState(FIRST_CONFIRMATION_VIEW);
    const [inputValue, setInputValue] = React.useState('');
    const keyWorld = getLangText('removing');

    return (
        <Modal
            dialogClassName="default-modal small-modal w-420"
            show={show}
            onHide={hide}
        >
            {successGenerated ?
                <Summary
                    waitingForDocument={isWaiting}
                    hideModal={hide}
                    generatedOrdersCount={generatedCount}
                    onPrint={() => {
                    }}
                    labels={false}
                    summaryContent={summaryContent}
                    ListActions={ListActions}
                /> :
                <>
                    <Modal.Header>
                        <Modal.Title>
                            {title}
                            <div className="description">
                                {SubTitle && <SubTitle/>}
                            </div>
                        </Modal.Title>
                        <button
                            onClick={hide}
                            type="button"
                            className="button"
                        >
                            <i className="icon-cross"/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {activeView === LAST_CONFIRMATION_VIEW &&
                            <div>
                                <div
                                    className="form-info-box red align-items-center small-line-height w-100 mb-3 -mt-2">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title">{getLangText('actionCannotBeUnDoneLabel')}</div>
                                    </div>
                                </div>
                                <div className="font-medium text-sm mb-2" dangerouslySetInnerHTML={{__html: getLangText({key: 'typeToConfirm', data: [keyWorld]})}} />
                                <Field
                                    value={inputValue}
                                    setValue={(field, value) => setInputValue(value)}
                                    className="big-size"
                                />
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={hide}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        {activeView === LAST_CONFIRMATION_VIEW ?
                            <button
                                onClick={onSubmit}
                                type="button"
                                disabled={inputValue !== keyWorld}
                                className={`button ${submitButtonClass} px-0 ${inputValue !== keyWorld ? 'not-active' : ''} ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader isLoading/>}
                                {saveButton}
                            </button> :
                            <button
                                onClick={() => setActiveView(LAST_CONFIRMATION_VIEW)}
                                type="button"
                                className={`button ${submitButtonClass} px-0 ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {saveButton}
                            </button>
                        }
                    </Modal.Footer>
                </>
            }
        </Modal>
    );
};

export default RemoveProductsModal;
