import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import * as ReactDOM from 'react-dom';

const Portal = ({
    children,
    basedOn,
    dropdownClassName,
    offsetY = 0,
    target = document.body,
    rightPosition = false,
    menuRef = null,
    open = false,
}) => {
    const windowDimensions = useWindowDimensions();

    const { top, left, width, bottom, height, right } =
        basedOn?.current.getBoundingClientRect();
    const maxHeight = windowDimensions.height - bottom;

    const [leftPos, setLeftPos] = useState(left + 1);

    const preparedTop = () => {
        if (target?.scrollTop > 0) {
            return top + height + target.scrollTop;
        }
        return top + height;
    };

    useEffect(() => {
        console.log(left, menuRef?.current?.getBoundingClientRect());
        if (rightPosition) {
            setLeftPos(
                left - menuRef?.current?.getBoundingClientRect()?.width + width,
            );
        } else {
            setLeftPos(left + 1);
        }
    }, [open, menuRef.current, rightPosition, width, left]);

    if (!basedOn?.current || !open) {
        return null;
    }

    return ReactDOM.createPortal(
        <div
            style={{
                top: preparedTop() + offsetY,
                left: leftPos,
                minWidth: width,
                maxHeight: maxHeight,
            }}
            className={`dropdown-menu opacity-0 animate-[fadeIn_.3s_ease_forwards] custom-dropdown-menu show ${dropdownClassName}`}>
            {children}
        </div>,
        target,
    );
};

export default Portal;
