import React, { useState, useRef, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import useLang from '../../../../../../../../../src_shared/hooks/useLang';
import SearchInput from './components/searchInput/SearchInput';
import Row from './components/row/Row';
import api from '../../../../../../../../../services/axios/axios';
import { selmoUrl } from '../../../../../../../../../src_shared/api/api';
import { services } from '../../../../../../../../RestServices';
import { getGETParamsUrl } from '../../../../../../../../../src_shared/list/ListActions';
import Loader from '../../../../../../../shared/Loader';
import { useDebounce } from '../../../../../../../../../src_shared/hooks/useDebounce';
import LinkInput from './components/LinkInput/LinkInput';

const URL_REGEX =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

const TABS = [
    {
        label: 'productInApp',
        isSelmoPro: false,
    },
    {
        label: 'liveInApp',
        isSelmoPro: true,
    },
    {
        label: 'anyLink',
        isSelmoPro: false,
    },
];

const RESTS = {
    products: services.API_STORAGE_REELS_PRODUCTS,
    lives: services.API_MARKETING_MOBILE_LIVES,
};

const MOBILE_URL = 'https://mobile.selmo.io';

const AddLinkModal = ({ modalVisible, hideModal, values, setValue }) => {
    const { getLangText } = useLang();
    const tabsRef = useRef([]);
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [search, setSearch] = useState({
        products: '',
        lives: '',
    });
    const [isLoading, setIsLoading] = useState({
        products: false,
        lives: false,
    });
    const [items, setItems] = useState({
        products: [],
        lives: [],
    });
    const debouncedSearch = useDebounce(search);
    const [customLinkError, setCustomLinkError] = useState(false);

    const handleSelect = (type, id) => {
        if (values[type] === id) {
            setValue(type, null);
        } else {
            setValue(type, id);
        }
    };

    const constructLink = (type, id) => {
        return `${MOBILE_URL}/${type}/${id}`;
    };

    const handleSearch = (search, type) => {
        setSearch((prev) => ({ ...prev, [type]: search }));
    };

    const handleAddLink = () => {
        if (values.url && !values.url.match(URL_REGEX)) {
            setCustomLinkError(true);
            return;
        }

        const productUrl = constructLink('product', values.product_id);
        const liveUrl = constructLink('live', values.live_id);

        if (values.product_id && !values.message.includes(productUrl)) {
            values.message += productUrl;
        }

        if (values.live_id && !values.message.includes(liveUrl)) {
            values.message += liveUrl;
        }

        if (values.url && !values.message.includes(values.url)) {
            if (values.url.startsWith('https://')) {
                values.message += values.url;
            } else {
                values.message += `https://${values.url}`;
            }
        }

        setValue('message', values.message);
        hideModal();
    };

    const getItems = useCallback(
        async (type) => {
            setIsLoading((prev) => ({ ...prev, [type]: true }));

            const params = {
                limit: 3,
            };

            if (!!debouncedSearch[type]) {
                params.superSearch = debouncedSearch[type];
            }

            try {
                const { data } = await api.get(
                    `${selmoUrl}/${RESTS[type]}${getGETParamsUrl(params)}`,
                );
                setItems((prev) => ({ ...prev, [type]: data.items }));
            } catch (error) {
                console.error('Cannot get items');
            } finally {
                setIsLoading((prev) => ({ ...prev, [type]: false }));
            }
        },
        [debouncedSearch],
    );

    useEffect(() => {
        getItems('products');
    }, [debouncedSearch.products, getItems]);

    useEffect(() => {
        getItems('lives');
    }, [debouncedSearch.lives, getItems]);

    const renderContent = () => {
        if (activeTab.label === 'productInApp') {
            return (
                <>
                    <SearchInput
                        value={search.products}
                        placeholder={getLangText('searchProduct')}
                        onChange={(e) =>
                            handleSearch(e.target.value, 'products')
                        }
                    />
                    <div className="flex flex-col gap-3 mt-3 loader-parent">
                        {isLoading.products ? (
                            <Loader isLoading={true} />
                        ) : (
                            items.products.map(
                                ({ id, name, photo_small, selmo_code }) => (
                                    <Row
                                        key={'product-' + id}
                                        type="product"
                                        title={name}
                                        image={photo_small}
                                        code={selmo_code}
                                        isSelected={
                                            values.product_id === id ||
                                            values.message.includes(
                                                constructLink('products', id),
                                            )
                                        }
                                        onSelect={() =>
                                            handleSelect('product_id', id)
                                        }
                                    />
                                ),
                            )
                        )}
                    </div>
                </>
            );
        }

        if (activeTab.label === 'liveInApp') {
            return (
                <>
                    <SearchInput
                        value={search.lives}
                        placeholder={getLangText('searchLive')}
                        onChange={(e) => handleSearch(e.target.value, 'lives')}
                    />
                    <div className="flex flex-col gap-3 mt-3 loader-parent">
                        {isLoading.lives ? (
                            <Loader isLoading={true} />
                        ) : (
                            items.lives.map(
                                ({
                                    live_id,
                                    title,
                                    date,
                                    poster_url,
                                    live_broadcast,
                                }) => (
                                    <Row
                                        key={'live-' + live_id}
                                        type="live"
                                        title={title}
                                        date={date}
                                        image={poster_url}
                                        isLive={live_broadcast === '1'}
                                        isSelected={
                                            values.live_id === live_id ||
                                            values.message.includes(
                                                constructLink('lives', live_id),
                                            )
                                        }
                                        onSelect={() =>
                                            handleSelect('live_id', live_id)
                                        }
                                    />
                                ),
                            )
                        )}
                    </div>
                </>
            );
        }

        return (
            <LinkInput
                value={values.url}
                placeholder={getLangText('provideLink')}
                onChange={(e) => setValue('url', e.target.value)}
                hasError={customLinkError}
            />
        );
    };

    useEffect(() => {
        if (
            tabsRef.current[
                TABS.findIndex((tab) => tab.label === activeTab.label)
            ]
        ) {
            const activeTabElement =
                tabsRef.current[
                    TABS.findIndex((tab) => tab.label === activeTab.label)
                ];

            const parentRect =
                activeTabElement.parentElement.getBoundingClientRect();
            const rect = activeTabElement.getBoundingClientRect();

            setBackgroundStyle({
                left: rect.left - parentRect.left + 'px',
                width: rect.width + 'px',
                height: rect.height + 'px',
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if (modalVisible && tabsRef.current[0]) {
            const firstTabElement = tabsRef.current[0];
            const parentRect =
                firstTabElement.parentElement.getBoundingClientRect();
            const rect = firstTabElement.getBoundingClientRect();

            setBackgroundStyle({
                left: rect.left - parentRect.left + 'px',
                width: rect.width + 'px',
                height: rect.height + 'px',
            });
        }
    }, [modalVisible]);

    return (
        <Modal
            dialogClassName="default-modal w-auto"
            contentClassName="!h-full md:!h-auto"
            show={modalVisible}
            onHide={hideModal}>
            <Modal.Body className="p-4">
                <div className="flex items-center justify-end">
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button flex items-center justify-center z-30">
                        <i className="icon-cross text-[#6B7280] text-[12px]" />
                    </button>
                </div>
                <div>
                    <p className="text-md font-bold text-[#101010]">
                        {getLangText('addLink')}
                    </p>
                    <p className="text-sm text-[#6B7280]">
                        {getLangText('addSmsLinkSubLabel')}
                    </p>
                </div>
                <div className="flex p-[5px] mt-3 bg-[#F3F4F6] rounded-[8px] w-full h-[44px] relative">
                    <div
                        className="absolute bg-white border-[1px] border-[#E5E7EB] rounded-md transition-all duration-300 ease-in-out"
                        style={backgroundStyle}
                    />
                    {TABS.map((tab, index) => (
                        <button
                            key={tab.label}
                            ref={(el) => (tabsRef.current[index] = el)}
                            className={`flex w-1/3 items-center justify-center py-1 rounded-sm relative z-10 ${activeTab.label === tab.label ? '' : 'bg-transparent'}`}
                            onClick={() => setActiveTab(tab)}>
                            <span
                                className={`text-[12px] font-bold ${activeTab.label === tab.label ? 'text-black' : 'text-[#6B7280]'}`}>
                                {getLangText(tab.label)}
                            </span>
                            {tab.isSelmoPro && (
                                <img
                                    src="/assets/images/svg/pro.svg"
                                    className="w-[20px] h-[20px] ml-1 inline-block"
                                    alt="pro"
                                />
                            )}
                        </button>
                    ))}
                </div>
                {renderContent()}
            </Modal.Body>
            <Modal.Footer className="p-4 flex items-center justify-end">
                <button
                    type="button"
                    className="button border-button small-size mr-2 w-[100px]"
                    onClick={hideModal}>
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className={`button primary small-size w-[100px] ${
                        values.product_id || values.live_id || values.url
                            ? ''
                            : 'opacity-50 pointer-events-none cursor-not-allowed'
                    }`}
                    onClick={handleAddLink}>
                    {getLangText('button_add')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddLinkModal;
