import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {NavLink, useHistory, useParams} from "react-router-dom";
import React, {useMemo, useState} from "react";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../shared/components/alert/AlertActions";
import Loader from "../../../../src_shared/components/loader/Loader";
import ConfirmModal from "../../orders/list/components/export/ConfirmModal";

const tabs = [
    {id: 0, path: RouterPaths.SalesPost, label: 'editionLabel'},
    {id: 1, path: RouterPaths.SalesPostsStats, label: 'salesStatistics'},
]

const SalesPostEditWrapper = ({title, children, postId = '', isWaiting}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const [showModal, setShowModal] = useState(false);

    const [{onSave, isWaiting: isWaitingForDuplicate}] = useSubmitData({
        rest: `${services.API_VIDEO_COPY_SOCIAL_VIDEO}/${id}`,
        afterSubmit: (response) => {
            setShowModal(false);
            history.push(`${RouterPaths.SalesPost}/${response.item.id}`)
            dispatch(showAlert('successfullyDuplicatedPost', ''))
        },
    });


    const preparedTabs = useMemo(() => postId ? tabs : tabs.slice(0, 1), [postId])

    return (
        <div className={`loader-parent list-with-tabs separated-box rounded-b-lg`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getLangText(title)} - Selmo`}</title>
            </Helmet>
            <div className="tabs-header mb-3 rounded-[5px] shadow-default bg-[white]">
                <div
                    className="page-title flex items-center text-[18px] leading-[23px] font-bold px-[18px] pt-[23px] bg-[white] rounded-[5px]">
                    {isWaiting ?
                        <div className="animate-pulse h-[25px] bg-gray-200 rounded-md dark:bg-gray-700 w-[70px]"/> :
                        postId ?
                            <div>
                                Post
                                <span className="text-desc ml-1 text-[16px] font-bold">{`#${postId}`}</span>
                            </div> :
                            <span
                                className="text-[16px] font-bold"
                                dangerouslySetInnerHTML={{__html: getLangText('postAddedByFacebook')}}
                            />
                    }
                    <div className="ml-auto">
                        <button
                            onClick={() => setShowModal(true)}
                            type="button"
                            className={`button border-button left-icon ${isWaitingForDuplicate ? 'loading-button' : ''}`}
                        >
                            {isWaitingForDuplicate && <Loader isLoading/>}
                            <i class="icon-copy"/>
                            {getLangText('duplicatePost')}
                        </button>
                    </div>
                </div>
                <div
                    style={{borderBottom: 0}}
                    className="tabs-wrapper rounded-b-[5px] bg-[white]"
                >
                    {preparedTabs.map((i) => (
                        <NavLink
                            exact
                            to={`${i.path}/${id}`}
                            key={i.id}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
            </div>
            {children}
            {showModal &&
                <ConfirmModal
                    dialogClassName="w-450 small-modal"
                    title={getLangText('wantToDuplicatePost')}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaitingForDuplicate}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default SalesPostEditWrapper;
