import React, {useState} from 'react';
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import moment from "moment";
import {useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import AssignToPlace from "../../../assignToPlace/AssignToPlace";
import {services} from "../../../../../../RestServices";
import Note from "./Note";
import UnpackOrderButton from "./UnpackOrderButton";
import {
    BUTIK_MADAME_SHOP_ID,
    CHIQUE_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";
import StatusBox from "../../../../../messenger/chatList/components/StatusBox";
import OrderStatus from "../../../../../shared/components/orderStatus/OrderStatus";

const OrderInfo = ({values, fetchData, fetchProducts}) => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(true)

    return (
        <div className="mt-3 border rounded-[8px] p-3">
            <button
                onClick={() => setShowDetails(!showDetails)}
                type="button"
                className="flex grow items-center text-left w-full"
            >
                <figure className="user-avatar mr-2" style={{width: 'auto', height: 'auto'}}>
                    {values.client?.photo && values.client?.photo !== '0' ?
                        <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                            <ServerImage className="w-full h-full object-cover"
                                         src={values.client?.photo}/>
                        </div> :
                        <img src="/assets/images/default/fb-default.jpeg"/>
                    }
                    {(userData.facebook_id && userData.instagram_id) &&
                        <>
                            {values.client?.social_type === 'facebook' &&
                                <img className="msg-logo" src="/assets/images/svg/messenger-icon.svg"/>}
                            {values.client?.social_type === 'instagram' &&
                                <img className="msg-logo" src="/assets/images/svg/instagram-icon.svg"/>}
                        </>
                    }
                </figure>
                <div className="name grow text-sm sm:text-[16px]">
                    <Link
                        className="font-bold underline"
                        to={`${RouterPaths.ClientsList}/${values.client?.shop_client_id}/`}
                    >
                        {values.client?.name || `${values.first_name} ${values.last_name}`}
                    </Link>
                    {values.client?.shop_client_id &&
                        <div className="client-number sm:text-sm text-xs text-desc">
                            {getLangText('clientNumberFullLabel')}: <strong>{values.client?.shop_client_id}</strong>
                        </div>
                    }
                </div>
                <div className="ml-auto sm:block hidden">
                    <Link
                        to={`${RouterPaths.OrdersList}/${values.shop_order_id}/`}
                        className="button border-button small-size"
                    >
                        {getLangText('goToOrderButton')}
                    </Link>
                </div>
                <div className="text-desc font-bold flex items-center text-xs ml-3">
                    <div>{getLangText(showDetails ? 'hideLabel' : 'showLabel')}</div>
                    <i className={`icon-arrows ml-1 text-[6px] ${showDetails ? 'rotate-180' : ''}`}/>
                </div>
            </button>
            {showDetails &&
                <div className="mt-3">
                    <Note data={values}/>
                    <div class="flex items-start">
                        <div>
                            <div className="font-medium text-sm">
                                {getLangText('customerBasketOrderLabel')}:&nbsp;
                                <strong>{values.shop_order_id}</strong>
                            </div>
                            {(values.shelf_name) &&
                                <div className="my-1 font-medium text-sm">
                                    {getLangText('localizationLabel')}:&nbsp;
                                    <strong>{values.shelf_name}</strong>
                                </div>
                            }
                            <div className="font-medium text-sm">
                                <span
                                    className="d-lg-inline-block d-none">{getLangText('createdDateLabel')}:&nbsp;</span>
                                <span className="d-lg-none d-inline-block">{getLangText('createdLabel')}:&nbsp;</span>
                                <strong>{convertToUserTimeZone(values.date).format('DD.MM.YYYY HH:mm')}</strong>
                            </div>
                        </div>
                        <OrderStatus className="ml-auto" status={values.status}/>
                    </div>
                    {values?.owner?.name &&
                        <div className="border rounded-md p-2 mb-2 mt-2">
                            <div className="font-medium mb-1 text-sm">
                                {getLangText('assignedTo')}:
                            </div>
                            <div className="flex items-center">
                                <div className="w-[20px] h-[20px] rounded-full overflow-hidden mr-2">
                                    {values?.owner?.photo && values?.owner?.photo !== '0' ?
                                        <ServerImage className="w-full h-full object-cover"
                                                     src={values?.owner?.photo}/> :
                                        <img className="w-full h-full object-cover"
                                             src="/assets/images/default/avatar.svg" alt=""/>}
                                </div>
                                <strong className="text-nowrap">{values?.owner?.name}</strong>
                            </div>
                        </div>
                    }
                    <div className="mt-3 mb-2 sm:hidden w-full">
                        <Link
                            style={{paddingTop: 11, paddingBottom: 11}}
                            to={`${RouterPaths.OrdersList}/${values.shop_order_id}/`}
                            className="button border-button small-size w-full"
                        >
                            {getLangText('goToOrderButton')}
                        </Link>
                    </div>
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="button primary w-full"
                    >
                        {getLangText('assignPlace')}
                    </button>
                    {(+userData.id === TIM_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === CHIQUE_SHOP_ID) &&
                        <UnpackOrderButton
                            values={values}
                            fetchProducts={fetchProducts}
                        />
                    }
                    {showModal &&
                        <AssignToPlace
                            modalVisible={showModal}
                            hideModal={() => setShowModal(false)}
                            rest={services.API_WAREHOUSE_ORDER}
                            fetchData={fetchData}
                            withScanner={false}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default OrderInfo;
