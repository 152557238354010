import React, {useContext} from 'react';
import CopyButton from "../../../src_shared/components/form/copyButton/CopyButton";
import {useSelector} from "react-redux";
import useLang from "../../../src_shared/hooks/useLang";
import {ShopSettingsContext} from "./ShopSettingsRoute";
import Loader from "../../../src_shared/components/loader/Loader";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL, SHOP_ORIGIN_US} from "../../app/shared/enums/ShopOrigins";

const ShopNameBar = ({redirect = ''}) => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();
	const {shopData, isChanging} = useContext(ShopSettingsContext);

	const shopLinkToDisplay = shopData.full_url
		? `https://${shopData.domain || shopData.full_url}${getLocale(userData.origin)}/${redirect}`
		: `https://${userData.domain || userData.full_url}${getLocale(userData.origin)}/${redirect}`;

	function getLocale(origin) {
		if (origin === SHOP_ORIGIN_PL) return ''
		if (origin === SHOP_ORIGIN_US) return '/en-us';
		return `/${origin}`
	}

	return (
		<div className="shop-name-bar">
			<div>
				<div className="sub-name">{getLangText('onlineShopLabel')}</div>
				<div className="name">{userData.name}</div>
			</div>
			<div className="client-link flex-wrap d-flex align-items-center ml-sm-auto pb-0 border-0">
				{isChanging &&
					<div className="tooltip-parent sm:order-1 order-2 mt-sm-0 mt-3">
						<div className="tooltip-content">
							{getLangText('mightTakeAFewMinutesLabel')}
						</div>
						<div className="d-flex align-items-center active-change mr-3">
							<Loader isLoading className="w-auto extra-small-loader static-loader"/>
							{getLangText('publishingChangesLabel')}
						</div>
					</div>
				}
				<div className="form-group with-copy-btn with-preview overflow-visible order-sm-2 order-1 flex-sm-grow-0 flex-grow-1">
					<div className="form-control">
						<a
							href={shopLinkToDisplay}
							target="_blank"
							className="short-link"
						>
							{shopLinkToDisplay}
						</a>
						<CopyButton
							buttonClassName="ml-sm-auto border-button only-icon"
							copyText={shopLinkToDisplay}
						/>
						<a
							href={shopLinkToDisplay}
							className="button border-button small-size mb-0 ml-1 d-lg-flex d-none"
							target="_blank"
						>
							<i className="icon-eye"/>
							{getLangText('previewShopButton')}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShopNameBar;
