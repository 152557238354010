import React from 'react';
import useLang from '../../../../../../src_shared/hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import { Link2 } from 'lucide-react';
import { SHOP_ORIGIN_PL } from '../../../../shared/enums/ShopOrigins';
import useAssetsLang from '../../../../shared/helpers/useAssetsLang';

const SCANNER_LINKS = [
    'https://go.selmo.io/skaner-1',
    'https://go.selmo.io/skaner-2',
];

const ScannerInfoModal = ({ modalVisible, hideModal }) => {
    const { getLangText } = useLang();
    const lang = useAssetsLang();

    return (
        <Modal
            dialogClassName="default-modal big-modal !h-full md:!h-auto"
            contentClassName="!rounded-3xl !h-full md:!h-auto"
            show={modalVisible}
            onHide={hideModal}>
            <Modal.Body className="bg-[#101010] rounded-none overflow-hidden p-0 md:rounded-3xl">
                <button
                    onClick={hideModal}
                    type="button"
                    className="button absolute bg-[#262B32] hover:bg-[#262B32]/80 rounded-full flex items-center justify-center w-9 h-9 top-4 right-4 text-[12px] text-[#6B7280] z-30">
                    <i className="icon-cross text-[#939599] hover:text-[#939599]/80" />
                </button>
                <div className="flex flex-col md:flex-row h-full">
                    <div className="flex flex-col gap-5 md:basis-[55%] p-10 py-14">
                        <p className="text-white text-3xl font-semibold">
                            {getLangText('fasterDisplayOfProducts')}
                        </p>
                        <p className="text-[#808080] text-sm font-light">
                            {getLangText('fasterDisplayOfProductsLabel')}
                        </p>
                        {lang === SHOP_ORIGIN_PL && (
                            <>
                                <p className="text-[#808080] text-sm font-light">
                                    {getLangText(
                                        'fasterDisplayOfProductsSubLabel',
                                    )}
                                </p>
                                <div className="flex flex-col gap-2 items-start">
                                    {SCANNER_LINKS.map((link) => (
                                        <a
                                            key={link}
                                            target="_blank"
                                            href={link}
                                            className="flex items-center w-[178px] px-2 py-1 gap-[7px] rounded-sm bg-[#3b82f6]/20 hover:bg-[#3b82f6]/30 text-decoration-none"
                                            rel="noreferrer">
                                            <Link2
                                                className="w-5 h-5"
                                                color="#3b82f6"
                                                strokeWidth={2.5}
                                            />
                                            <p className="text-[13px] text-[#3b82f6] -translate-y-[1px]">
                                                {link.replace('https://', '')}
                                            </p>
                                        </a>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex items-end justify-center h-full md:h-auto md:basis-[45%] relative border-t-[1px] md:border-l-[1px] border-[#262626]">
                        <img
                            src="/assets/images/selmo-scanner-glow.webp"
                            alt="Selmo Scanner"
                            className="w-full h-full object-cover absolute z-10"
                        />
                        <div className="flex flex-row items-center justify-center gap-1 z-20 mb-8">
                            <p className="text-white text-sm">
                                {getLangText('availableOnlyIn')}
                            </p>
                            <img
                                src="/assets/images/svg/selmo-pro-logo.svg"
                                alt="Selmo Pro"
                                className="w-24"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ScannerInfoModal;
