import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loader from "../shared/Loader";
import {selmoUrl} from "../../../src_shared/api/api";
import Guest from "./Guest";
import Alert from "../shared/components/alert/Alert";
import {Helmet} from "react-helmet";
import useLang from "../../../src_shared/hooks/useLang";
import Messenger from "./messenger/Messenger";
import api from "../../../services/axios/axios";
import ServerImage from "../shared/components/serverImage/ServerImage";

const ShopCartAuth = ({match: {params}}) => {
    const [waiting, setWaiting] = useState(false);
    const {getLangText} = useLang();
    const [values, setValues] = useState({
        guest_active: true,
        country: {
            country_id: '',
            country_name: '',
        },
        facebook_id: null,
        name: '',
        logo: '',
        copy_command: '',
        is_blocked: false,
    });

    const checkConnection = async () => {
        setWaiting(true)
        try {
            const {data} = await api.get(`${selmoUrl}/apicart/orderReady/${params.hash}`)
            if (data.item.cart_url) {
                window.location.href = data.item.cart_url;
                return;
            }
            setValues({...values, ...data.item});
        } catch (e) {
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        checkConnection();
    }, [])


    return (
        <div className="cart-auth-wrapper loader-parent">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getLangText('finishShoppingInOurShopLabel')} - Selmo`}</title>
            </Helmet>
            <Loader isLoading={waiting}/>
            <div className="container my-auto">
                <div className="flex align-items-center justify-content-center">
                    <div className="text-center">
                        <div className="mb-4">
                            {values.logo ?
                                <div className="logo d-block">
                                    <ServerImage
                                        className="mx-auto w-[100px]"
                                        src={values.logo}
                                        alt={values.name}
                                    />
                                </div> :
                                <div>{values.name}</div>
                            }
                        </div>
                        <h1 className="text-3xl font-bold">{getLangText('loginButton')}</h1>
                        <div className="desc mb-4 mt-1">
                            {getLangText('finishShoppingInOurShopLabel')}
                        </div>
                        {!!+values.is_blocked ?
                            <div className="form-info-box align-items-center w-100 mb-0 small-line-height">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('cannotDoShopsInThisShop')}</div>
                                </div>
                            </div> :
                            <div>
                                {values.facebook_id &&
                                    <Messenger
                                        params={params}
                                        values={values}
                                    />
                                }
                                {!!+values.guest_active &&
                                    <div className="or-separator">
                                        <div>{getLangText('separatorOrLabel')}</div>
                                    </div>
                                }
                                {!!+values.guest_active &&
                                    <Guest
                                        values={values}
                                        hash={params.hash}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="selmo-bottom-info">
                {getLangText('shopOnPlatformLabel')}
                <a
                    href="https://www.selmo.io/"
                    className="logo"
                >
                    <img src="/assets/images/selmo-gray.svg" alt="Selmo Logo"/>
                </a>
            </div>
            <Alert/>
        </div>
    );
};

export default ShopCartAuth;
