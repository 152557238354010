export const HEADER_BOX_MESSENGER = 'HEADER_BOX_MESSENGER';
export const HEADER_BOX_PRODUCTS = 'HEADER_BOX_PRODUCTS';
export const HEADER_BOX_ORDERS = 'HEADER_BOX_ORDERS';
export const HEADER_BOX_AUTOMATION = 'HEADER_BOX_AUTOMATION';
export const HEADER_BOX_LIVES = 'HEADER_BOX_LIVES';
export const HEADER_BOX_LIVE_OVERLAY = 'HEADER_BOX_LIVE_OVERLAY';
export const HEADER_BOX_MR_AUTOMATE = 'HEADER_BOX_MR_AUTOMATE';
export const HEADER_BOX_MARKETING_FACEBOOK = 'HEADER_BOX_MARKETING_FACEBOOK';
export const FIRST_PAGE_PRODUCTS = 'FIRST_PAGE_PRODUCTS';
export const FIRST_PAGE_LIVES = 'FIRST_PAGE_LIVES';
export const FIRST_PAGE_ORDERS = 'FIRST_PAGE_ORDERS';
export const FIRST_PAGE_AUTOMATION = 'FIRST_PAGE_AUTOMATION';
export const DASHBOARD_MAIN_AFTER_TRIAL = 'DASHBOARD_MAIN_AFTER_TRIAL';
export const DASHBOARD_MAIN_TRIAL = 'DASHBOARD_MAIN_TRIAL';
export const DASHBOARD_CHECKBOX_PRODUCTS = 'DASHBOARD_CHECKBOX_PRODUCTS';
export const DASHBOARD_CHECKBOX_AUTOMATION = 'DASHBOARD_CHECKBOX_AUTOMATION';
export const DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS =
    'DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS';
export const DASHBOARD_CHECKBOX_MESSENGER = 'DASHBOARD_CHECKBOX_MESSENGER';
export const DASHBOARD_CHECKBOX_LIVE = 'DASHBOARD_CHECKBOX_LIVE';
export const HEADER_BOX_CLIENTS = 'HEADER_BOX_CLIENTS';
export const HEADER_BOX_MOBILE_NOTIFICATIONS =
    'HEADER_BOX_MOBILE_NOTIFICATIONS';
export const ACTIONS_TO_DO_ONLINE_PAYMENTS = 'ACTIONS_TO_DO_ONLINE_PAYMENTS';
export const ACTIONS_TO_DO_SELMO_PACZKA = 'ACTIONS_TO_DO_SELMO_PACZKA';
export const ACTIONS_TO_DO_ORDERS = 'ACTIONS_TO_DO_ORDERS';

const TutorialsVideo = [
    {
        id: DASHBOARD_MAIN_AFTER_TRIAL,
        src_pl: 'https://www.youtube.com/embed/LdkYQ4EFPe4?si=0xdnQbl1LZd8E3xV&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/To-R9__Je60?si=l1XYvTG2qYghD5WY&autoplay=1',
        src_ig_pl:
            'https://www.youtube.com/embed/mpGIGxHrNmg?si=JtGRBAywDZHBTctw&autoplay=1',
        header: 'seeTheTutorialVideoLabel',
    },
    {
        id: DASHBOARD_MAIN_TRIAL,
        src_pl: 'https://www.youtube.com/embed/LdkYQ4EFPe4?si=0xdnQbl1LZd8E3xV&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/To-R9__Je60?si=l1XYvTG2qYghD5WY&autoplay=1',
        src_ig_pl:
            'https://www.youtube.com/embed/mpGIGxHrNmg?si=JtGRBAywDZHBTctw&autoplay=1',
        header: 'seeTheTutorialVideoLabel',
    },
    {
        id: HEADER_BOX_MESSENGER,
        src_pl: 'https://www.youtube.com/embed/59JJ_fYuoeA?si=40o4Rv0iSqs3fo3-&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/p_bxckTYCOw?si=lxC96qp_uDIMUvN7&autoplay=1',
        src_it: 'https://www.youtube.com/embed/yaIUwaIidJs?si=J8bY-kOF4X2VVpY-&autoplay=1',
        header: 'messengerHeaderBoxTitleLabel',
    },
    {
        id: HEADER_BOX_PRODUCTS,
        src_pl: 'https://www.youtube.com/embed/xOWdbNT3zOI?si=WCkuTZjyZt8PGh3h&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/Zw9CYL5VHEU?si=tpZeYUf4LSYmu94n&autoplay=1',
        src_it: 'https://www.youtube.com/embed/TFIasQ3m9Us?si=xH_y4bLY-CAsw1Qq&autoplay=1',
        header: 'productsHeaderBoxTitleLabel',
    },
    {
        id: HEADER_BOX_AUTOMATION,
        src_pl: 'https://www.youtube.com/embed/mpGIGxHrNmg?si=Ld5ufV34-Zksiu1c',
        header: 'howAutomationWork',
    },
    {
        id: HEADER_BOX_ORDERS,
        src_pl: 'https://www.youtube.com/embed/VnmpdAlmWB8?si=HmwELxkkTjuCKYaa&autoplay=1',
        header: 'howOrdersWork',
    },
    {
        id: HEADER_BOX_LIVES,
        src_pl: 'https://www.youtube.com/embed/oNDOz_csq08?si=cdR6bE4bDbz1kABD&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/XtdLdmsaVY0?si=PMoiYBsS6IsJoi1G&autoplay=1',
        src_it: 'https://www.youtube.com/embed/mkIwK2_hOoU?si=RYpbMdG-JJ1HtM_O&autoplay=1',
        header: 'livesHeaderBoxTitleLabel',
    },
    {
        id: HEADER_BOX_CLIENTS,
        src_pl: 'https://www.youtube.com/embed/hWPP4bUeIio?si=IKuvhh9zc3k6_QcW&autoplay=1',
        header: 'sendNotificationsToClientsOneClickLabel',
    },
    {
        id: HEADER_BOX_MOBILE_NOTIFICATIONS,
        header: 'sendNotificationsToClientsOneClickLabel',
    },
    {
        id: HEADER_BOX_LIVE_OVERLAY,
        src_pl: 'https://www.youtube.com/embed/nTy9IDCf8Pc?si=ilGaPSw-i3cSBsyb&autoplay=1',
        header: 'howOverlayWorks',
    },
    {
        id: HEADER_BOX_MR_AUTOMATE,
        src_pl: 'https://www.youtube.com/embed/tQxPwJ0TP9Q?si=EFtkDV4smD8YyI7G&autoplay=1',
        header: 'mrAutomateYourSaleAssistant',
    },
    {
        id: HEADER_BOX_MARKETING_FACEBOOK,
        src_pl: 'https://www.youtube.com/embed/hNcZQIBjzwo?si=5KYgAukgZpGnrJ7q',
        header: 'howMarketingFacebookWorks',
    },
    {
        id: FIRST_PAGE_PRODUCTS,
        src_pl: 'https://www.youtube.com/embed/xOWdbNT3zOI?si=WCkuTZjyZt8PGh3h&autoplay=1',
        src_pt: 'https://www.youtube.com/embed/Zw9CYL5VHEU?si=tpZeYUf4LSYmu94n&autoplay=1',
        src_it: 'https://www.youtube.com/embed/TFIasQ3m9Us?si=xH_y4bLY-CAsw1Qq&autoplay=1',
    },
    {
        id: FIRST_PAGE_LIVES,
        src_pl: 'https://www.youtube.com/embed/oNDOz_csq08?si=cdR6bE4bDbz1kABD&autoplay=1',
    },
    {
        id: FIRST_PAGE_AUTOMATION,
        src_pl: 'https://www.youtube.com/embed/mpGIGxHrNmg?si=Ld5ufV34-Zksiu1c',
    },
    {
        id: FIRST_PAGE_ORDERS,
        src_pl: 'https://www.youtube.com/embed/VnmpdAlmWB8?si=HmwELxkkTjuCKYaa&autoplay=1',
        // src_pl: 'https://www.youtube.com/embed/e751ybZoJU0?autoplay=1'
    },
    {
        id: DASHBOARD_CHECKBOX_PRODUCTS,
        src_pl: 'https://www.youtube.com/embed/iqtLRI4jBuU?si=L_npNv-C1lgmbMkH&autoplay=1',
    },
    {
        id: DASHBOARD_CHECKBOX_AUTOMATION,
    },
    {
        id: DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS,
        src_pl: 'https://www.youtube.com/embed/hWPP4bUeIio?si=oPrt3smiMz-K0PRG&autoplay=1',
    },
    {
        id: DASHBOARD_CHECKBOX_MESSENGER,
        src_pl: 'https://www.youtube.com/embed/59JJ_fYuoeA?si=40o4Rv0iSqs3fo3-&autoplay=1',
    },
    {
        id: DASHBOARD_CHECKBOX_LIVE,
        src_pl: 'https://www.youtube.com/embed/3BoJYI2Hwcc?si=9tC-uqO6S4j99JCT&autoplay=1',
    },
    {
        id: ACTIONS_TO_DO_ONLINE_PAYMENTS,
        src_pl: 'https://www.youtube.com/embed/6jfr-qzSY5M?si=e4XtJDOoxIJ8XgEs&autoplay=1',
    },
    {
        id: ACTIONS_TO_DO_SELMO_PACZKA,
        src_pl: 'https://www.youtube.com/embed/zHFPMJnEwc4?si=7gJ_0xzaYx6SzYSK&autoplay=1',
    },
    {
        id: ACTIONS_TO_DO_ORDERS,
        src_pl: 'https://www.youtube.com/embed/VnmpdAlmWB8?si=HmwELxkkTjuCKYaa&autoplay=1',
        header: 'howOrdersWork',
    },
];

export default TutorialsVideo;
