import React, {useEffect, useMemo, useRef} from 'react';
import {Link} from "react-router-dom";
import RemoveOrder from "../components/removeOrder/RemoveOrder";
import SwitchTabs from "../components/tabs/Tabs";
import Loader from "../../loader/Loader";
import StatusSelect from "../components/StatusSelect";
import OrderProductsForm from "../../../shared/components/form/orderProductsForm/OrderProductsForm";
import {getStateRoot} from "../newAddOrderModalReducer";
import Summary from "../components/summary/Summary";
import SafetyCloseModal from "../components/safetyCloseModal/SafetyCloseModal";
import {getOrders, orderModalToggleVisibility} from "../../orderModal/OrderModalActions";
import {batch, useDispatch, useSelector} from "react-redux";
import {addOrderModalToggleVisibility} from "../AddOrderModalActions";
import {createSelector} from "reselect";
import NewAddOrderModalActions from "../NewAddOrderModalActions";
import {Modal} from "react-bootstrap";
import useLang from "../../../../../src_shared/hooks/useLang";
import CancelStatusConfirmModal from "../../../shared/cancelStatusConfirmModal/CancelStatusConfirmModal";
import {showAlert} from "../../../shared/components/alert/AlertActions";

const OrderModal = ({
                        isOpen,
                        backUrl,
                        messageScrollRef,
                        lastId,
                        setStatusVisible,
                        getOrderProducts,
                        activeConversation
                    }) => {

    const dispatch = useDispatch();
    const modalRef = useRef();
    const {activeViewType} = useSelector((state) => state.messenger.ordersViewType)
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const {
        manualProducts,
        defaultProducts,
        data,
        waiting,
        orderWaiting,
        orderCancelModalVisible,
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const lastTabIndex = useMemo(() => manualProducts[manualProducts.length - 1].tabIndex, [manualProducts]);

    const saveOrder = () => dispatch(NewAddOrderModalActions.saveOrder());
    const showSafetyCloseModal = (data) => dispatch(NewAddOrderModalActions.safetyCloseModalVisibility(data));
    const setSelectValue = (value) => dispatch(NewAddOrderModalActions.updateField('status', value));

    const hasProductsChanged = () => {
        const preparedProducts = manualProducts.filter((product) => product.product_name || product.product_price);

        return JSON.stringify(defaultProducts) !== JSON.stringify(preparedProducts)
    };

    const hideCancelOrderModal = () => {
        batch(() => {
            dispatch(NewAddOrderModalActions.showCancelOrderModal(false));
            dispatch(showAlert('yuhuLabel', 'successfulSavedLabel'))
        })
    }


    const backToOrdersModal = () => {
        if (hasProductsChanged()) {
            showSafetyCloseModal({
                visible: true,
                toSummaryView: false,
            });
            return;
        }
        handleClose()
        dispatch(getOrders(activeConversation.user_id, activeConversation.page_id));
        setTimeout(() => {
            dispatch(orderModalToggleVisibility(true))
        }, 300)

    }


    const handleClose = () => {
        if (hasProductsChanged()) {
            showSafetyCloseModal({
                visible: true,
                toSummaryView: false,
            });
            return;
        }
        modalRef.current.dialog.classList.add('hide-modal')
        setTimeout(() => {
            batch(() => {
                dispatch(NewAddOrderModalActions.resetOrderProducts())
                dispatch(addOrderModalToggleVisibility(false));
            })
        }, 300)
    };

    const getPrice = (products) => {
        const roundNumber = (num) => +(`${Math.round(`${num}e+2`)}e-2`);
        const safePrice = (product) => product.product_price || 0
        const safeQuantity = (product) => product.quantity || 0
        const productPrice = products.reduce((counter, product) => counter + safePrice(product) * safeQuantity(product), 0);
        return `${roundNumber(productPrice)} ${data.currency_text}`;
    };

    useEffect(() => {
        return () => dispatch(NewAddOrderModalActions.resetData());
    }, [])

    return (
        <>
            {isOpen &&
                <Modal
                    ref={modalRef}
                    className="add-orders-messages-modal"
                    show={isOpen}
                    onHide={handleClose}
                    keyboard={false}
                    backdrop={false}
                    animation={false}
                    enforceFocus={false}
                >
                    <Modal.Header>
                        <div className="top-part">
                            {backUrl ?
                                <button
                                    onClick={backToOrdersModal}
                                    className="button border-button back-button"
                                >
                                    <i className="icon-arrow3"/>
                                </button> :
                                <button
                                    onClick={handleClose}
                                    className="button border-button"
                                >
                                    <i className="icon-cross"/>
                                </button>
                            }
                            <div
                                className="modal-title"
                                id="exampleModalLabel"
                            >
                                <a
                                    target="_blank"
                                    href={userData.ecommerce_orders === 'SHOPIFY' ? data.link : `/zamowienia/${data.shop_order_id}`}>{getLangText('customerBasketOrderLabel')} {data.shop_order_id}</a>
                            </div>
                            <RemoveOrder orderData={data}/>
                        </div>
                        <SwitchTabs
                            getOrderProducts={() => getOrderProducts(data.shop_order_id)}
                            hasProductsChanged={hasProductsChanged}
                            showSafetyCloseModal={showSafetyCloseModal}
                            activeViewType={activeViewType}
                        />
                    </Modal.Header>
                    <Modal.Body className={activeViewType === 'summary' ? 'with-scroll' : ''}>
                        {activeViewType === 'edit' ?
                            <div className="loader-parent">
                                {waiting ? <Loader className="with-bg"/> :
                                    <>
                                        {userData.ecommerce_orders !== 'SHOPIFY' &&
                                            <StatusSelect
                                                setValue={setSelectValue}
                                                statusId={data.status}
                                            />
                                        }
                                        <OrderProductsForm
                                            actions={NewAddOrderModalActions}
                                            getStateRoot={getStateRoot}
                                        />
                                        <div className="bottom-total-price">
                                            {getLangText('totalPriceToPayLabel')}
                                            <span>{getPrice(manualProducts)}</span>
                                        </div>
                                    </>
                                }
                            </div> :
                            (
                                <Summary
                                    orderData={data}
                                    getOrder={getOrderProducts}
                                    activeConversation={activeConversation}
                                    setStatusVisible={setStatusVisible}
                                    lastId={lastId}
                                    messageScrollRef={messageScrollRef}
                                    summaryWaiting={waiting}
                                />
                            )
                        }
                    </Modal.Body>
                    {activeViewType === 'edit' &&
                        <Modal.Footer>
                            <button
                                tabIndex={lastTabIndex}
                                onClick={saveOrder}
                                type="submit" className="button primary"
                            >
                                {orderWaiting && <Loader/>}
                                {getLangText('saveChangesButton')}
                            </button>
                        </Modal.Footer>}
                </Modal>
            }
            <SafetyCloseModal
                getOrderProducts={(viewType) => getOrderProducts(data.shop_order_id, viewType)}
            />
            {orderCancelModalVisible &&
                <CancelStatusConfirmModal
                    hideModal={() => hideCancelOrderModal(false)}
                    modalVisible={orderCancelModalVisible}
                    orderId={data.id}
                    fetchData={() => {
                    }}
                    afterSubmit={() => dispatch(showAlert('yuhuLabel', 'successfulSavedLabel'))}
                />
            }
        </>
    );
};

export default OrderModal;
