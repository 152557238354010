import React, { useState } from 'react';
import useLang from '../../../../src_shared/hooks/useLang';
import { marketingTabOptions } from '../Marketing';
import { useLocation } from 'react-router-dom';
import SignupToReserveListModal from './components/SignupToReserveList';
import { useSelector } from 'react-redux';

const MarketingOther = () => {
    const { getLangText } = useLang();
    const { pathname } = useLocation();
    const [showModal, setShowModal] = useState(null);
    const { userData } = useSelector((state) => state.session);

    const statuses = {
        facebook: userData.facebook_ads_active,
        sms: userData.sms_active,
        // app: +userData.app_active,
        // email: +userData.email_active,
    };

    const getData = () =>
        marketingTabOptions({ origin: userData.origin, statuses }).find(
            (item) => item.route === pathname,
        );

    return (
        <div
            className={`bg-[#fff] relative sm:rounded-[12px] shadow-default sm:pt-20 sm:pb-20 px-6 py-12 overflow-hidden text-center marketing-top-gradient`}>
            <div className="animate-[fadeIn_.3s_ease_forwards]">
                <div className="relative w-[55px] mx-auto">
                    <img
                        className="sm:w-[55px]"
                        src={`/assets/images/svg/marketing/${getData()?.icon}.svg`}
                        alt="icon"
                    />
                </div>
                <div
                    className="text-xl font-bold mt-2 text-center"
                    dangerouslySetInnerHTML={{
                        __html: getLangText(getData()?.soonTitle),
                    }}
                />
                <div
                    className="font-medium text-desc mb-4 mt-2 text-center max-w-[530px] mx-auto"
                    dangerouslySetInnerHTML={{
                        __html: getLangText(getData()?.soonDesc),
                    }}
                />
                <button
                    type="button"
                    onClick={() => setShowModal(getData()?.id)}
                    style={{
                        fontSize: '16px',
                        borderRadius: 12,
                        paddingTop: 18,
                        paddingBottom: 18,
                    }}
                    className={`button medium-size text-center black primary`}>
                    {getLangText('signupForWaitingList')}
                </button>
                <div className="text-[#9ca3af] text-sm font-medium mt-3">
                    <i className="icon-safe mr-1" />
                    <span>{getLangText('soonAvailable')}</span>
                </div>
            </div>
            {showModal && (
                <SignupToReserveListModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(null)}
                    title={getData()?.soonTitle}
                />
            )}
        </div>
    );
};

export default MarketingOther;
